import _ from 'lodash'
import * as transfer from './internal/transfer'
import * as is from './is'

import { TIME_ZONE, TIME_ZONE_APPEND } from './internal/time-zone'

/**
 * Except String, Number, any other type is invalid.
 * If options.emptyStringToInvalid is true, the empty string to invalid string.
 *
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 * @param {Boolean} options.emptyStringToInvalid
 * @param {Array} options.include If mix in the include array, the mix is invalid.
 * @returns {String}
 */
export function formatText(
  mix,
  {
    invalid = '-',
    emptyStringToInvalid = true,
    include = [],
    prefix = '',
    suffix = '',
  } = {}
) {
  if (_.isNumber(mix) && _.isFinite(mix)) {
    mix = String(mix)
  }

  const hasMix = _.isArray(include) && include.includes(mix)
  const isEmptyMix = emptyStringToInvalid && is.isEmptyString(mix)
  const isNotStringMix = !_.isString(mix)
  if (hasMix || isEmptyMix || isNotStringMix) {
    mix = invalid
  } else if (prefix || suffix) {
    mix = prefix + mix + suffix
  }
  return mix
}

/**
 * @param {*} mix
 * @param {String} options.format default: MM/DD/YYYY HH:mm, date: L, time: TL
 * @param {String} options.invalid
 * @param {String} options.append e.g. CT => 07/01/2020 CT
 * @param {String} options.timezone
 * @param {Boolean} options.zeroInvalid
 */
export function formatDateTime(
  mix,
  {
    format = 'MM/DD/YYYY HH:mm',
    invalid = '-',
    timezone = null,
    append = '',
    zeroInvalid = true,
  } = {}
) {
  if (zeroInvalid && /^0$/.test(mix)) {
    return invalid
  }

  const m = transfer.toMoment(mix, { timezone })
  if (!m.isValid()) {
    return invalid
  }

  if (append) {
    format += ' \\' + append.split('').join('\\')
  }
  return m.format(format)
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {String} options.timezone
 * @param {Boolean} options.zeroInvalid
 */
export function formatDate(
  mix,
  {
    format = 'L',
    invalid = '-',
    timezone = null,
    append = '',
    zeroInvalid = true,
  } = {}
) {
  if (['1970-01-01', '01/01/1970'].includes(mix)) {
    return '-'
  }
  return formatDateTime(mix, { format, invalid, append, timezone, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {String} options.timezone
 * @param {Boolean} options.zeroInvalid
 */
export function formatTime(
  mix,
  {
    format = 'LT',
    invalid = '-',
    timezone = null,
    append = '',
    zeroInvalid = true,
  } = {}
) {
  return formatDateTime(mix, { format, invalid, append, timezone, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 * @description Please change timezone by such as $utils.setSessionStorage(TIME_ZONE_KEY, 'America/Los_Angeles')
 * datetimeSystemZone
 */
export function formatDateTimeSystemZone(
  mix,
  {
    format = 'MM/DD/YYYY HH:mm',
    invalid = '-',
    append = TIME_ZONE_APPEND,
    zeroInvalid = true,
  } = {}
) {
  return formatDateTime(mix, {
    format,
    invalid,
    append,
    zeroInvalid,
    timezone: TIME_ZONE,
  })
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 */
export function formatDateSystemZone(
  mix,
  {
    format = 'L',
    invalid = '-',
    append = TIME_ZONE_APPEND,
    zeroInvalid = true,
  } = {}
) {
  return formatDateTimeSystemZone(mix, { format, invalid, append, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 */
export function formatTimeSystemZone(
  mix,
  {
    format = 'LT',
    invalid = '-',
    append = TIME_ZONE_APPEND,
    zeroInvalid = true,
  } = {}
) {
  return formatDateTimeSystemZone(mix, { format, invalid, append, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.invalid
 * @param {Function} options.roundMethod e.g Math.round
 */
export function formatNumber(
  mix,
  { format = '0,0.00', invalid = '-', roundMethod = null } = {}
) {
  const res = transfer.toNumeral(mix, { invalid: null })

  return res.value() === null ? invalid : res.format(format, roundMethod)
}

/**
 *  This method is rounding. 1.225 => 1.23
 *
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.invalid
 * @param {Boolean} options.isDecimalProcessing
 * @returns {String} 1000 => $1,000.00
 */
export function formatCurrency(
  mix,
  { format = '$0,0.00', invalid = '-', isDecimalProcessing = false } = {}
) {
  const formattedCurrency = formatNumber(Math.abs(mix), { format, invalid })
  if (mix || mix === 0) {
    if (isDecimalProcessing && Math.abs(mix) < 1 && mix !== 0) {
      return mix > 0 ? '<$1' : '-$1'
    }
    return mix >= 0 ? formattedCurrency : `-${formattedCurrency}`
  } else {
    return '-'
  }
}

/**
 * This method is not rounding. 1.225 => 1.22
 *
 * @see currency
 */
export function formatAvailableCredit(
  mix,
  { format = '$0,0.00', invalid = '-' } = {}
) {
  return formatNumber(mix, { format, invalid, roundMethod: Math.floor })
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.append
 * @param {String} options.invalid
 */
export function formatRate(
  mix,
  { format = '0.00000', append = '%', invalid = '-' } = {}
) {
  const res = formatNumber(mix, { format, invalid })

  if (res === invalid) {
    return res
  }

  return `${res}${append}`
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.append
 * @param {String} options.invalid
 * @param {Number} options.magnification
 */
export function formatPercentage(
  mix,
  { format = '0.00', append = '%', invalid = '-', magnification = 1 } = {}
) {
  const res = transfer.toNumeral(mix, { invalid: null })
  let value = res.value()

  if (value === null) {
    return invalid
  }

  value = res.multiply(magnification).format(format)
  return `${value}${append}`
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 * zipcode to zipCode
 */
export function formatZipCode(mix, { invalid = '-' } = {}) {
  if (is.isEmpty(mix)) {
    return invalid
  }

  mix = String(mix).replace(/[^\d]/g, '')

  if (mix.length < 5) {
    return invalid
  }

  if (mix.length === 5) {
    return mix
  }

  mix = mix.padEnd(9, '0')
  return mix.substring(0, 5) + '-' + mix.substring(mix.length - 4)
}

/**
 * Mask string. e.g (123456789 => *****6789)
 *
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 * @param {String} options.placeholder
 * @param {Number} options.length Reserved string. positive number, padding end (123456789 => 1234*****);
 *                                negative number, padding start (123456789 => *****6789).
 */
export function formatMask(
  mix,
  { invalid = '-', placeholder = '*', length = -4 } = {}
) {
  if (!_.isString(mix) && !_.isNumber(mix)) {
    return invalid
  }

  mix = String(mix)
  const l = mix.length
  if (l <= Math.abs(length) || length === 0) {
    return mix
  }

  if (length > 0) {
    mix = mix.substring(0, length).padEnd(l, placeholder)
  } else {
    mix = mix.substring(l + length).padStart(l, placeholder)
  }

  return mix
}

/**
 * This has some different from _.startCase(). __FOO_BAR__ => 'Foo Bar'
 *
 * @see _.startCase()
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 */
export function formatStartCase(mix, { invalid = '-' } = {}) {
  mix = formatText(mix, { invalid })
  if (mix === invalid) {
    return invalid
  }

  if (/[-_]/.test(mix)) {
    mix = mix.toLowerCase()
  }

  return _.startCase(String(mix))
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 */
export function formatCapitalize(mix, { invalid = '-' } = {}) {
  if (!_.isString(mix) || is.isEmptyString(mix)) {
    return invalid
  }

  return _.capitalize(mix)
}

/**
 * format enum dictionary to options like
 *
 * @param {object} param
 * @param {object[]} param.dictionary
 * @param {string} [param.value] key
 * @param {string} [param.text] value
 * @param {string} [param.sortKey] sort
 *
 * @returns {Array<{ value: any, text: any }>}
 */
export function formatToOptions({
  dictionary = [],
  value = 'key',
  text = 'value',
  sortKey = 'sort',
}) {
  dictionary = _.sortBy(dictionary, sortKey)
  return _.map(dictionary, (item) => ({
    value: item[value],
    text: item[text],
  }))
}

/**
 * Formats a value for i18n.
 * @param {string | Array<string> | Object} value - The value to be formatted.
 * @param {Function} translateFunction - The i18n translation function.
 * @param {string} [keyForObj] - When value is an object, specifies which key's value to format.
 * @returns {string | Array<string> | Object} - The formatted i18n string, or the original value.
 */

export function formatI18n(value, translateFunction, keyForObj = 'text') {
  const isValidStringFormat = (val) =>
    typeof val === 'string' && /^[^\s_]+(_[^\s_]+)+$/i.test(val)

  if (isValidStringFormat(value)) {
    return translateFunction(value)
  }

  if (Array.isArray(value)) {
    return value.map((item) =>
      isValidStringFormat(item) ? translateFunction(item) : item
    )
  }

  if (
    typeof value === 'object' &&
    value !== null &&
    keyForObj &&
    isValidStringFormat(value[keyForObj])
  ) {
    return { ...value, [keyForObj]: translateFunction(value[keyForObj]) }
  }

  return value
}

/**
 * Format string based on pattern. e.g ('1234567890', '999-999-9999') => '123-456-7890'
 *
 * @param {*} mix - Input string or number
 * @param {Object} options
 * @param {String} options.pattern - Format pattern (e.g. '999-999-9999')
 * @param {String} options.invalid - Invalid placeholder
 * @param {String} options.placeholder - Character in pattern to be replaced (default: '9')
 * @returns {String} Formatted string according to pattern
 */
export function formatPattern(
  mix,
  { pattern = '999-999-9999', invalid = '-', placeholder = '9' } = {}
) {
  if (!_.isString(mix) && !_.isNumber(mix)) {
    return invalid
  }

  // Get all digits from input, preserving * characters
  const cleanedInput = String(mix).replace(/[^\d*]/g, '')

  // Count required digits from pattern
  const requiredLength = (pattern.match(new RegExp(placeholder, 'g')) || [])
    .length

  // Validate input length
  if (cleanedInput.length !== requiredLength) {
    return invalid
  }

  let result = pattern
  let inputIndex = 0

  // Replace each placeholder with actual digits or *
  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i] === placeholder) {
      result =
        result.substring(0, i) +
        cleanedInput[inputIndex++] +
        result.substring(i + 1)
    }
  }

  return result
}
