import { ref } from 'vue'
import { assetAPI } from '@/shared/api/index'
import { toBigNumber } from '@/shared/utils'

const assetList = ref([])

const isListAssetExistencesLoading = ref(false)

export function useAssetData(stage) {
  const isPledgeAssetLoading = ref(false)

  function updatePledgeStatus(assetId, params) {
    isPledgeAssetLoading.value = true
    return assetAPI.updatePledgeStatus(stage, assetId, params).finally(() => {
      isPledgeAssetLoading.value = false
    })
  }

  const isUnpledgeAssetLoading = ref(false)
  function updateAssetUnpledge(assetId, params) {
    isUnpledgeAssetLoading.value = true
    return assetAPI.updateAssetUnpledge(stage, assetId, params).finally(() => {
      isUnpledgeAssetLoading.value = false
    })
  }

  const isPreviewUnpledgeNoticeLoading = ref(false)
  const unpledgeNoticeContent = ref('')
  function getUnpledgeNoticePreview(assetId) {
    isPreviewUnpledgeNoticeLoading.value = true
    return assetAPI
      .getUnpledgeNoticePreview(stage, assetId)
      .then((data) => {
        unpledgeNoticeContent.value = data.content
      })
      .finally(() => {
        isPreviewUnpledgeNoticeLoading.value = false
      })
  }

  const isUnpledgeNoticeLoading = ref(false)
  function createUnpledgeNotice(assetId, params) {
    isUnpledgeNoticeLoading.value = true
    return assetAPI.createUnpledgeNotice(stage, assetId, params).finally(() => {
      isUnpledgeNoticeLoading.value = false
    })
  }

  const isCreateAssetLoading = ref(false)
  const newCreatedAssetId = ref('')
  function createAsset(params) {
    isCreateAssetLoading.value = true
    return assetAPI
      .createAsset(stage, params)
      .then((data) => {
        newCreatedAssetId.value = data.assetId
      })
      .finally(() => {
        isCreateAssetLoading.value = false
      })
  }

  const isUpdateAssetLoading = ref(false)
  function updateAsset(assetId, params) {
    isUpdateAssetLoading.value = true
    return assetAPI.updateAsset(stage, assetId, params).finally(() => {
      isUpdateAssetLoading.value = false
    })
  }

  const assetDetails = ref({})
  const isAssetDetailsLoading = ref(false)
  function getAssetDetail(assetId) {
    isAssetDetailsLoading.value = true
    return assetAPI
      .getAssetDetail(stage, assetId)
      .then((data) => {
        assetDetails.value = data
      })
      .finally(() => {
        isAssetDetailsLoading.value = false
      })
  }

  const isAssetHoldingsLoading = ref(false)
  const assetHoldings = ref([])
  function getAssetHoldings(assetId, params) {
    isAssetHoldingsLoading.value = true
    return assetAPI
      .getAssetHoldings(stage, assetId, params)
      .then((data) => {
        assetHoldings.value = data.items
      })
      .finally(() => {
        isAssetHoldingsLoading.value = false
      })
  }

  const isListAssetsLoading = ref(false)
  const hasNextPageAssets = ref(false)
  let assetsReqParams = {}
  const asyncAssetListRequestCount = ref(0)

  async function getAssets(
    collateralId,
    { pageNumber = 1, pageSize = 10 } = {}
  ) {
    isListAssetsLoading.value = true
    const asyncCount = ++asyncAssetListRequestCount.value
    assetsReqParams = {
      'filters.collateralId': collateralId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    }

    try {
      const data = await assetAPI.getAssets(stage, assetsReqParams)
      if (asyncCount !== asyncAssetListRequestCount.value) return
      hasNextPageAssets.value = data.pageNumber < data.totalPages
      const newItems = data.items.map((item) => ({
        ...item,
        enableEdit: false,
      }))
      assetList.value = [...assetList.value, ...newItems]
    } catch (error) {
      console.error('Error fetching assets:', error)
    } finally {
      isListAssetsLoading.value = false
    }
  }

  const getNextPageAssets = () => {
    if (hasNextPageAssets.value && assetsReqParams['filters.collateralId']) {
      assetsReqParams.pageNumber++
      getAssets(assetsReqParams['filters.collateralId'], assetsReqParams)
    }
  }

  const assetExistenceResult = ref({})
  const getAssetExistences = (params) => {
    isListAssetExistencesLoading.value = true
    return assetAPI
      .getAssetExistences(params)
      .then((data) => {
        assetExistenceResult.value = data
      })
      .finally(() => {
        isListAssetExistencesLoading.value = false
      })
  }

  const isDeleteAssetLoading = ref(false)
  const deleteAsset = (assetId) => {
    isDeleteAssetLoading.value = true
    return assetAPI.deleteAsset(stage, assetId).finally(() => {
      isDeleteAssetLoading.value = false
    })
  }

  const isUpdateAssetHoldingsLoading = ref(false)
  const updateAssetHoldings = (assetId, params) => {
    isUpdateAssetHoldingsLoading.value = true
    return assetAPI.updateAssetHoldings(stage, assetId, params).finally(() => {
      isUpdateAssetHoldingsLoading.value = false
    })
  }

  const isAssetHistoriesLoading = ref(false)
  const assetHistoriesRes = ref([])
  const getAssetHistories = (assetId, params) => {
    isAssetHistoriesLoading.value = true
    return assetAPI
      .getAssetHistories(stage, assetId, params)
      .then((data) => {
        assetHistoriesRes.value = data
      })
      .finally(() => {
        isAssetHistoriesLoading.value = false
      })
  }

  const isCollateralAssetsLoading = ref(false)
  const collateralAssets = ref([])

  const getCollateralAssets = (params, archiveAmendmentId) => {
    isCollateralAssetsLoading.value = true

    return assetAPI
      .getCollateralAssets(stage, params, archiveAmendmentId)
      .then((data) => {
        collateralAssets.value = data?.assets || []
      })
      .finally(() => {
        isCollateralAssetsLoading.value = false
      })
  }

  const isCalculateAssetsLoading = ref(false)
  const calculateAssets = ref({})

  const getCalculateAssets = (params) => {
    isCalculateAssetsLoading.value = true
    return assetAPI
      .calculateAssets(params)
      .then((res) => {
        res.assetsInCalculation =
          res.collateral?.investmentAccounts?.length || 0
        res.uniqueHoldings = res.collateral?.collateralHoldings?.length || 0
        res.totalMarketValue = 0
        res.totalLendingValue = 0
        res.totalMaintenanceValue = 0
        res.collateral &&
          res.collateral.collateralHoldings.map((holding) => {
            res.totalMarketValue = Number(
              toBigNumber(res.totalMarketValue).plus(
                holding.marketValueInBaseCurrency || 0
              )
            )
            res.totalLendingValue = Number(
              toBigNumber(res.totalLendingValue).plus(holding.lendingValue || 0)
            )
            res.totalMaintenanceValue = Number(
              toBigNumber(res.totalMaintenanceValue).plus(
                holding.maintenanceValue || 0
              )
            )
          })
        calculateAssets.value = res
      })
      .finally(() => {
        isCalculateAssetsLoading.value = false
      })
  }

  const isAssetLettersLoading = ref(false)
  const assetLetters = ref([])
  const getAssetLetters = (params) => {
    isAssetLettersLoading.value = true
    return assetAPI
      .listAssetLetters(stage, params)
      .then((data) => {
        assetLetters.value = data.assetLetters
      })
      .finally(() => {
        isAssetLettersLoading.value = false
      })
  }

  const isRevokeAssetLoading = ref(false)
  const revokeAsset = (assetId) => {
    isRevokeAssetLoading.value = true
    return assetAPI.revokeAsset(stage, assetId).finally(() => {
      isRevokeAssetLoading.value = false
    })
  }

  /**
   * Fetches asset-related mails by asset ID and email info ID.
   *
   * @param {number} assetId - The ID of the asset related to the emails.
   * @param {number} emailInfoId - The ID of the specific email info.
   * @property {number} assetId - The unique identifier for the asset.
   * @property {number} emailInfoId - The unique identifier for the email info.
   */

  const isAssetRelatedMailsLoading = ref(false)
  const assetRelatedMails = ref({})
  const getAssetRelatedMails = async (assetId, emailInfoId) => {
    isAssetRelatedMailsLoading.value = true
    try {
      assetRelatedMails.value = assetAPI.getAssetRelatedMails(
        assetId,
        emailInfoId
      )
    } finally {
      isAssetRelatedMailsLoading.value = false
    }
  }

  const isUpdatePrimaryAccountForAssetLoading = ref(false)
  const setPrimaryAccountForAsset = (params) => {
    isUpdatePrimaryAccountForAssetLoading.value = true
    return assetAPI.updatePrimaryAccountForAsset(stage, params).finally(() => {
      isUpdatePrimaryAccountForAssetLoading.value = false
    })
  }

  const isAssetUnpledgeValidationsLoading = ref(false)
  const assetUnpledgeValidations = ref([])
  const getAssetUnpledgeValidations = (assetId) => {
    isAssetUnpledgeValidationsLoading.value = true
    return assetAPI.getAssetUnpledgeValidations(stage, assetId).then((data) => {
      assetUnpledgeValidations.value = data
    }).finally(() => {
      isAssetUnpledgeValidationsLoading.value = false
    })
  }

  return {
    isPledgeAssetLoading,
    updatePledgeStatus,

    isUnpledgeAssetLoading,
    updateAssetUnpledge,

    unpledgeNoticeContent,
    isPreviewUnpledgeNoticeLoading,
    getUnpledgeNoticePreview,

    isUnpledgeNoticeLoading,
    createUnpledgeNotice,

    isCreateAssetLoading,
    newCreatedAssetId,
    createAsset,

    isUpdateAssetLoading,
    updateAsset,

    assetDetails,
    isAssetDetailsLoading,
    getAssetDetail,

    isAssetHoldingsLoading,
    assetHoldings,
    getAssetHoldings,

    isListAssetsLoading,
    hasNextPageAssets,
    assetList,
    getAssets,
    getNextPageAssets,

    isListAssetExistencesLoading,
    assetExistenceResult,
    getAssetExistences,

    isDeleteAssetLoading,
    deleteAsset,

    isUpdateAssetHoldingsLoading,
    updateAssetHoldings,

    isAssetHistoriesLoading,
    assetHistoriesRes,
    getAssetHistories,

    isCollateralAssetsLoading,
    collateralAssets,
    getCollateralAssets,

    isCalculateAssetsLoading,
    calculateAssets,
    getCalculateAssets,

    isAssetLettersLoading,
    assetLetters,
    getAssetLetters,

    isRevokeAssetLoading,
    revokeAsset,

    isAssetRelatedMailsLoading,
    assetRelatedMails,
    getAssetRelatedMails,

    isUpdatePrimaryAccountForAssetLoading,
    setPrimaryAccountForAsset,

    isAssetUnpledgeValidationsLoading,
    assetUnpledgeValidations,
    getAssetUnpledgeValidations,
  }
}
