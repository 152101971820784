import { API_VERSION, http } from '@/shared/api/http.js'

export const exceptionAPI = {
  /**
   * Retrieves a list of exceptions for a given underwriting and exception context.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {GetExceptionsParams} [params] - Optional parameters for filtering the list.
   * @returns {Promise<ListExceptionInfoResp>} A promise that resolves with the list of exceptions.
   * @typedef {Object} GetExceptionsParams
   * @property {string} [group] - The group to filter exceptions by.
   *
   * @typedef {Object} ListExceptionInfoResp
   * @property {ExceptionInfoDTO[]} exceptionInfos - Array of exception information.
   *
   * @typedef {Object} ExceptionInfoDTO
   * @property {number} exceptionId - Unique identifier of the exception.
   * @property {string} group - Group associated with the exception.
   * @property {string} type - Type of the exception.
   * @property {string} label - Label of the exception.
   * @property {string} status - Status of the exception.
   * @property {string} reasonCode - Code explaining the reason for the exception.
   * @property {string} reason - Description of the reason for the exception.
   * @property {string} exceptionNote - Detailed note about the exception.
   * @property {number} creatorProfileId - Profile ID of the creator.
   * @property {string} creatorUserName - Username of the creator.
   * @property {number} updateProfileId - Profile ID of the last updater.
   * @property {string} updateUserName - Username of the last updater.
   * @property {Object} detail - Additional details about the exception.
   * @property {number} createdAt - Timestamp of exception creation.
   *
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/DEVPT-uw-hershey/api/underwriting/exception/listUnderwritingException.md
   * @APICode stage.underwritings.exceptions.list
   */
  getListUnderwritingExceptions(stage, underwritingId, params = {}) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/exceptions`,
      params
    )
  },

  /**
   * Retrieve exception details by underwriting ID and exception ID.
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} underwritingId - The ID of the underwriting.
   * @param {number} exceptionId - The ID of the exception.
   * @returns {Promise<ExceptionInfo>} - A promise that resolves with the exception details.
   * @typedef {Object} ExceptionInfo
   * @property {number} exceptionId - The ID of the exception.
   * @property {string} group - The group of the exception.
   * @property {string} type - The type of the exception.
   * @property {string} label - The label of the exception.
   * @property {string} status - The status of the exception.
   * @property {string} reasonCode - The reason code of the exception.
   * @property {string} reason - The reason for the exception.
   * @property {string} exceptionNote - The note associated with the exception.
   * @property {number} creatorProfileId - The profile ID of the creator.
   * @property {string} creatorUserName - The username of the creator.
   * @property {number} updateProfileId - The profile ID of the person who last updated the exception.
   * @property {string} updateUserName - The username of the person who last updated the exception.
   * @property {Object} detail - Additional details about the exception.
   * @property {number} createdAt - The timestamp when the exception was created.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/DEVPT-uw-hershey/api/underwriting/exception/getUnderwritingExceptions.md
   * @APICode {stage}.underwritings.exceptions.get
   */
  getUnderwritingExceptions(stage, underwritingId, exceptionId) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/exceptions/${exceptionId}`
    )
  },

  /**
   * Creates an underwriting exception.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {CreateUnderwritingExceptionRequest} exceptionRequest - The underwriting exception request details.
   * @returns {Promise<Object>} - A promise that resolves with the response data.
   * @APICode {stage}.underwritings.exceptions.create
   */
  createUnderwritingException(stage, underwritingId, exceptionRequest) {
    return http.post(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/exceptions`,
      exceptionRequest
    )
  },

  /**
   * Deletes an underwriting exception based on the provided underwriting ID and exception ID.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {string} exceptionId - The ID of the exception to be deleted.
   * @returns {Promise} - A promise that resolves when the exception is deleted.
   * @APICode {stage}.underwritings.exceptions.delete
   */
  deleteUnderwritingException(stage, underwritingId, exceptionId) {
    return http.delete(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/exceptions/${exceptionId}`
    )
  },

  /**
   * Updates exceptions within an underwriting, which is identified by the underwritingId and the exceptionId.
   * @param {string} stage - The stage of the underwriting.
   * @param {number} underwritingId - The ID of the underwriting.
   * @param {number} exceptionId - The ID of the exception to update.
   * @param {UpdateUnderwritingExceptionPayload} payload - The payload containing the exception update information.
   * @returns {Promise<Object>} - A promise that resolves with the response of the update operation.
   * @APICode {stage}.underwritings.exceptions.update
   */
  updateUnderwritingException(stage, underwritingId, exceptionId, payload) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/exceptions/${exceptionId}`,
      payload
    )
  },

  /**
   * Approve an exception associated with an underwriting.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {string} exceptionId - The ID of the exception to be approved.
   * @returns {Promise<ApprovalResponse>} A promise that resolves with the approval response.
   * @APICode {stage}.underwritings.exceptions.approve
   */
  createApproveException(stage, underwritingId, exceptionId) {
    return http.post(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/exceptions/${exceptionId}/approvals`
    )
  },

  /**
   * Rejects an underwriting exception.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {string} exceptionId - The ID of the underwriting exception to reject.
   * @returns {Promise<Object>} - A promise that resolves when the exception rejection is processed.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/DEVPT-uw-hershey/api/underwriting/exception/rejectUnderwritingExceptions.md
   * @APICode {stage}.underwritings.exceptions.reject
   */
  rejectUnderwritingException(stage, underwritingId, exceptionId) {
    return http.post(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/exceptions/${exceptionId}/rejections`
    )
  },
}
