import {
  GroupTypeEnum,
  ModulePathsEnum,
  ResultToggleMoreLength,
} from './global-search.enum'

const proposalPath = '/nav/proposals'
const estimatePath = '/nav/estimate'
const applicationPath = '/application'
const underwritingPath = '/underwriting'

const PersonTagEnum = {
  client: 'CLIENT',
  business: 'BUSINESS',
  trust: 'TRUST',
  primaryClient: 'PRIMARY_CLIENT',
  primaryAdvisor: 'PRIMARY_ADVISOR',
  relationshipManager: 'RELATIONSHIP_MANAGER',
}

const personSortOrderByTagMap = {
  [PersonTagEnum.client]: 1,
  [PersonTagEnum.business]: 2,
  [PersonTagEnum.trust]: 3,
  [PersonTagEnum.primaryClient]: 4,
  [PersonTagEnum.primaryAdvisor]: 5,
  [PersonTagEnum.relationshipManager]: 6,
}

export default {
  computed: {
    originationSearchGroups() {
      return [
        {
          groupName: this.$t('search_result_group_estimate'),
          typeCode: GroupTypeEnum.estimate,
          requiredMenu: estimatePath,
          normalizeResult: this.originationNormalizeResult,
          getResultContents: this.getEstimateResultContents,
          modulePaths: ModulePathsEnum.origination,
          requiredMenuCode: 'ESTIMATE',
        },
        {
          groupName: this.$t('search_result_group_proposal'),
          typeCode: GroupTypeEnum.proposal,
          requiredMenu: proposalPath,
          normalizeResult: this.originationNormalizeResult,
          getResultContents: this.getProposalResultContents,
          modulePaths: ModulePathsEnum.origination,
        },
        {
          groupName: this.$t('search_result_group_application'),
          typeCode: GroupTypeEnum.application,
          requiredMenu: applicationPath,
          normalizeResult: this.originationNormalizeResult,
          getResultContents: this.getApplicationResultContents,
          modulePaths: ModulePathsEnum.origination,
          requiredMenuCode: 'Origination',
        },
      ]
    },
  },
  methods: {
    originationNormalizeResult(result, getResultContents) {
      let isShowApplicationNumber = false
      const { resultRouter, applicationNumber } = result || {}
      if (!applicationNumber) {
        isShowApplicationNumber = false
      } else {
        const isUnderwriting = resultRouter?.indexOf('underwriting') !== -1
        const isApplication = resultRouter?.indexOf('application') !== -1
        const applicationConfig =
          this.$store?.state?.application?.applicationListConfig
        const underwritingConfig =
          this.$store?.state?.application?.underwritingListConfig
        if (isUnderwriting) {
          isShowApplicationNumber =
            underwritingConfig?.UNDERWRITING_LIST?.applicationNumber?.visible
        } else if (isApplication) {
          isShowApplicationNumber =
            applicationConfig?.APPLICATION_LIST?.applicationNumber?.visible
        }
      }

      return {
        resultTitle:
          this.$utils.format.text(result?.name) +
          (isShowApplicationNumber
            ? ` (${this.$utils.format.text(applicationNumber)})`
            : ''),
        ...getResultContents(result),
      }
    },

    normalizeOriginationPersons(persons) {
      return persons.map((person) => {
        const personName = this.$utils.format.text(person?.personName)
        const email = this.$utils.format.text(person?.email) || ''
        let roles = person?.roles ?? []
        roles = roles.map((role) => {
          return this.$t('search_result_role_' + role.toLowerCase())
        })
        if (!roles.length && !email) {
          return personName
        }
        let textKey = personName
        if (roles.length && email) {
          textKey = 'search_result_person_with_email_and_roles'
        } else if (roles.length) {
          textKey = 'search_result_person_with_roles'
        } else if (email) {
          textKey = 'search_result_person_with_email'
        }
        return {
          contentText: this.$t(textKey, {
            personName: personName,
            email: email,
            roles: roles.join(this.$t('connector_comma')),
          }),
        }
      })
    },

    sortResultContents(results) {
      results.forEach((item) => {
        item.searchResultOrder = personSortOrderByTagMap[item.roles[0]]
      })
      results.sort((result1, result2) => {
        const resultOrder1 = result1.searchResultOrder
        const resultOrder2 = result2.searchResultOrder
        if (resultOrder1 === resultOrder2) {
          const resultName1 = (result1.personName || '').toLowerCase()
          const resultName2 = (result2.personName || '').toLowerCase()
          if (resultName2 < resultName1) {
            return 1
          }
          return resultName2 > resultName1 ? -1 : 0
        }
        return resultOrder1 - resultOrder2
      })
    },

    getCommonResultContents(result) {
      const assets = result?.accountNumbers ?? []
      const showMoreAssets =
        assets.length > ResultToggleMoreLength.originationAssets ? false : null

      const parties = result.parties || []
      const persons = result.advisors || []

      parties.forEach((item) => {
        item.personName = item.partyName
      })

      let resultContents = this.$lodash.concat(parties, persons)

      this.sortResultContents(resultContents)

      resultContents = this.normalizeOriginationPersons(resultContents)

      const showMoreContent =
        resultContents.length > ResultToggleMoreLength.originationPerson
          ? false
          : null

      const status = this.$t(
        'search_result_status_' + result?.status.toLowerCase()
      )

      return {
        status,
        assets,
        resultContents,
        showMoreAssets,
        showMoreContent,
      }
    },

    getApplicationResultContents(result) {
      const commonResultContents = this.getCommonResultContents(result)

      let resultRouter = ''
      if (
        result?.applicationStage === GroupTypeEnum.underwriting &&
        this.hasAuthed('uwGeneral')
      ) {
        resultRouter = `${underwritingPath}?id=${result?.applicationId}`
      } else if (this.hasAuthed('generator')) {
        resultRouter = `${applicationPath}/${result?.applicationId}`
      }

      return {
        ...commonResultContents,
        resultRouter,
      }
    },
    getEstimateResultContents(result) {
      let resultRouter = ''
      const commonResultContents = this.getCommonResultContents(result)
      if (!this.hasAuthed('locProposalGeneral', 1)) {
        resultRouter = `/nav/estimate?estimateId=${result?.estimateId}`
      }

      return {
        ...commonResultContents,
        resultRouter,
      }
    },
    getProposalResultContents(result) {
      let resultRouter = ''
      const commonResultContents = this.getCommonResultContents(result)

      if (!this.hasAuthed('locProposalGeneral', 1)) {
        resultRouter = `/nav/proposal/${result?.proposalId}`
      }

      return {
        ...commonResultContents,
        resultRouter,
      }
    },
  },
}
