export const CUSTOM_FIELDS_INPUT_MAX_LENGTH = 500
export const API_MAX_PAGE_SIZE = 200
export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const TABLE_FIRST_PAGE_SIZE = 75
export const EXPORT_MAX_SIZE = 99999999
export const SENSITIVE_INFO_SHOW_LENGTH = 4
export const MAX_LENGTH_OF_TRUST_AMEND = 5
export const NameProhibitedWords = ['jr', 'and', 'partnership']
export const PARTY_MAX_COUNT = {
  BORROWER: 10,
  GUARANTOR: 10,
  CONTROL_PERSON: 5,
  AUTHORIZED_INDIVIDUAL: 10,
  AUTHORIZED_USER: 10,
  TRUSTEE: 5,
  GRANTOR: 3,
  REVOKER: 3,
}

export const FILE_API_VERSION = '/v2'

export const TABLE_MAX_WIDTH = 240

export const ADD_LOAN_FACILITY_STAGE = 'ADD_LOAN_FACILITY_STAGE'

export const UPDATE_LOAN_FACILITY = 'UPDATE_LOAN_FACILITY'

export const CREATE_LOAN_FACILITY = 'CREATE_LOAN_FACILITY'

export const WORKFLOW_CALL_BACK_EVENT = 'EVENT_CALL_BACK'

export const PHONE_COUNTRY_CODES = [
  '+93',
  '+355',
  '+213',
  '+1684',
  '+376',
  '+244',
  '+1264',
  '+1268',
  '+54',
  '+374',
  '+297',
  '+61',
  '+43',
  '+994',
  '+1242',
  '+973',
  '+880',
  '+1246',
  '+375',
  '+32',
  '+501',
  '+229',
  '+1441',
  '+975',
  '+591',
  '+387',
  '+267',
  '+55',
  '+246',
  '+1284',
  '+673',
  '+359',
  '+226',
  '+257',
  '+855',
  '+237',
  '+1',
  '+238',
  '+599',
  '+1345',
  '+236',
  '+235',
  '+56',
  '+86',
  '+61',
  '+61',
  '+57',
  '+269',
  '+243',
  '+242',
  '+682',
  '+506',
  '+225',
  '+385',
  '+53',
  '+599',
  '+357',
  '+420',
  '+45',
  '+253',
  '+1767',
  '+1',
  '+593',
  '+20',
  '+503',
  '+240',
  '+291',
  '+372',
  '+251',
  '+500',
  '+298',
  '+679',
  '+358',
  '+33',
  '+594',
  '+689',
  '+241',
  '+220',
  '+995',
  '+49',
  '+233',
  '+350',
  '+30',
  '+299',
  '+1473',
  '+590',
  '+1671',
  '+502',
  '+44',
  '+224',
  '+245',
  '+592',
  '+509',
  '+504',
  '+852',
  '+36',
  '+354',
  '+91',
  '+62',
  '+98',
  '+964',
  '+353',
  '+44',
  '+972',
  '+39',
  '+1876',
  '+81',
  '+44',
  '+962',
  '+7',
  '+254',
  '+686',
  '+383',
  '+965',
  '+996',
  '+856',
  '+371',
  '+961',
  '+266',
  '+231',
  '+218',
  '+423',
  '+370',
  '+352',
  '+853',
  '+389',
  '+261',
  '+265',
  '+60',
  '+960',
  '+223',
  '+356',
  '+692',
  '+596',
  '+222',
  '+230',
  '+262',
  '+52',
  '+691',
  '+373',
  '+377',
  '+976',
  '+382',
  '+1664',
  '+212',
  '+258',
  '+95',
  '+264',
  '+674',
  '+977',
  '+31',
  '+687',
  '+64',
  '+505',
  '+227',
  '+234',
  '+683',
  '+672',
  '+850',
  '+1670',
  '+47',
  '+968',
  '+92',
  '+680',
  '+970',
  '+507',
  '+675',
  '+595',
  '+51',
  '+63',
  '+48',
  '+351',
  '+1',
  '+974',
  '+262',
  '+40',
  '+7',
  '+250',
  '+590',
  '+290',
  '+1869',
  '+1758',
  '+590',
  '+508',
  '+1784',
  '+685',
  '+378',
  '+239',
  '+966',
  '+221',
  '+381',
  '+248',
  '+232',
  '+65',
  '+1721',
  '+421',
  '+386',
  '+677',
  '+252',
  '+27',
  '+82',
  '+211',
  '+34',
  '+94',
  '+249',
  '+597',
  '+47',
  '+268',
  '+46',
  '+41',
  '+963',
  '+886',
  '+992',
  '+255',
  '+66',
  '+670',
  '+228',
  '+690',
  '+676',
  '+1868',
  '+216',
  '+90',
  '+993',
  '+1649',
  '+688',
  '+1340',
  '+256',
  '+380',
  '+971',
  '+44',
  '+1',
  '+598',
  '+998',
  '+678',
  '+39',
  '+58',
  '+84',
  '+681',
  '+212',
  '+967',
  '+260',
  '+263',
  '+358',
]

export const CASH_TICKER = '$CASH'
export const IPO30_CODE = 'IPO30'
export const RATE_FORMAT = '0.00000'
