/** @const */
let pubSubInstance = null
let subscriberId = 0

/**
 * Publish & Subscribe
 */
export class PubSub {
  /**
   * Shared instance
   * @returns PubSub Instance
   */
  static shared() {
    if (!pubSubInstance) {
      pubSubInstance = new PubSub()
    }
    return pubSubInstance
  }

  /** @constructor */
  constructor() {
    this.subscribers = {}
  }

  /**
   * Subscribe event
   * @param {string} eventName
   * @param {function} callback
   * @returns
   */
  subscribe(eventName, callback) {
    if (!this.subscribers[eventName]) {
      this.subscribers[eventName] = []
    }
    this.subscribers[eventName].push({
      subscriberId: ++subscriberId,
      callback: callback,
    })
    return subscriberId
  }

  /**
   * Unsubscribe event
   * @param {string} eventName
   * @param {function} callback
   * @returns
   */
  unsubscribe(eventName, callback) {
    if (!this.subscribers[eventName]) return
    this.subscribers[eventName] = this.subscribers[eventName].filter((item) => {
      return item.callback !== callback
    })
  }

  /**
   * Unsubscribe event
   * @param {string} eventName
   * @param {string} subscriberId returned by subscribe()
   */
  unsubscribeById(eventName, id) {
    if (!this.subscribers[eventName]) return
    this.subscribers[eventName] = this.subscribers[eventName].filter((item) => {
      return item.subscriberId !== id
    })
  }

  /**
   * Publish event
   * @param {string} eventName
   * @param {object} [data] event data
   * @param {...any} args
   * @returns
   */
  publish(eventName, data, ...args) {
    if (!this.subscribers[eventName]) return
    this.subscribers[eventName].forEach((item) => {
      item.callback(data, ...args)
    })
  }
}
