import { getCurrentInstance } from 'vue'

export const useI18n = () => {
  // Return mock translation function if in test environment
  if (process.env.NODE_ENV === 'test') {
    return {
      t: (key) => key,
    }
  }

  let vm = getCurrentInstance()
  if (vm) {
    return {
      t: vm.proxy.$ts,
    }
  }

  vm = window.vm
  if (vm) {
    return {
      t: vm.$t.bind(vm),
    }
  }

  if (window.microAppData.i18n) {
    return {
      t: window.microAppData.i18n.t.bind(window.microAppData.i18n),
    }
  }

  throw new Error('must be called in setup')
}
