import { APIStageEnum, API_VERSION, http } from './http'

export const loanFacilityAPI = {
  /**
   * List Loan Facilities
   * @APICode: service.loan-facilities.list
   * @param {Object} params - Request parameters
   * @param {number} params.pageNum - Page number
   * @param {number} params.pageSize - Page size
   * @param {string} [params.sort] - Sorting criteria
   * @param {Object} [params.filters] - Filters for querying loan facilities
   * @returns {Promise<Object>} - Paginated list of loan facilities
   */
  getLoanFacilityList(stage, params, config) {
    return http.get(`${API_VERSION}/${stage}/loan-facilities`, params, config)
  },

  /**
   * List all the loans available.
   * @param {Object} params - The request parameters.
   * @param {number} params.pageNum - The page number.
   * @param {number} params.pageSize - The size of the page.
   * @param {string} [params.sort] - The sorting criteria.
   * @param {string} [params.searchTerm] - The search term.
   * @param {Array<string>} [params.filters.status] - The status filter.
   * @param {number} [params.filters.totalLoanFacilityId] - The total loan facility ID filter.
   * @param {number} [params.filters.loanFacilityId] - The loan facility ID filter.
   * @param {Array<number>} [params.filters.distributionChannelInstitutionId] - The distribution channel institution ID filter.
   * @param {number} [params.filters.createdAt.max] - The maximum creation date filter.
   * @param {number} [params.filters.createdAt.min] - The minimum creation date filter.
   * @param {number} [params.filters.balance.max] - The maximum balance filter.
   * @param {number} [params.filters.balance.min] - The minimum balance filter.
   * @param {number} [params.filters.availableCredit.max] - The maximum available credit filter.
   * @param {number} [params.filters.availableCredit.min] - The minimum available credit filter.
   * @param {number} [params.filters.approvedAmount.max] - The maximum approved amount filter.
   * @param {number} [params.filters.approvedAmount.min] - The minimum approved amount filter.
   * @param {Date} [params.filters.issueDate.max] - The maximum issue date filter.
   * @param {Date} [params.filters.issueDate.min] - The minimum issue date filter.
   * @param {Date} [params.filters.maturityDate.max] - The maximum maturity date filter.
   * @param {Date} [params.filters.maturityDate.min] - The minimum maturity date filter.
   * @param {string} [params.filters.rateType] - The rate type filter.
   * @param {number} [params.filters.term] - The term filter.
   * @returns {Promise<Object>} The list of loans.
   */
  listLoans(params) {
    // Uncomment the following line to use the actual API call
    return http.get(`${API_VERSION}/service/loans`, params)
  },

  /**
   * Get single loan facility based on provided facility loan id.
   * @param {Object} params - The request parameters.
   * @param {number} params.loanFacilityId - The ID of the loan facility to retrieve.
   * @returns {Promise<Object>} The promise object that will resolve to the loan facility data.
   * @apiCode service.loan-facilities.get
   * @archives apiCode {stage}.archives.loan-facilities.get
   */
  getSingleLoanFacility(
    params,
    stage = APIStageEnum.service,
    archiveAmendmentId = null
  ) {
    const { loanFacilityId } = params
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/loan-facilities/${loanFacilityId}`
      )
    }
    return http.get(`${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}`)
  },

  /**
   * Update Loan Facility
   * This API is used to update the loan facility.
   *
   * @param {Object} params - The parameters for updating the loan facility.
   * @param {string} params.loanFacilityId - The ID of the loan facility to be updated.
   * @param {Object} payload - The payload containing the details to update.
   * @returns {Promise<Object>} The updated loan facility details.
   * @APICode {stage}.loan-facilities.update
   * @see https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/loan-facility/updateLoanFacility.md
   */
  updateLoanFacility(params, body = params) {
    const { loanFacilityId, stage = APIStageEnum.service } = params
    return http.patch(
      `${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}`,
      body
    )
  },

  /**
   * Get the flag information of the loan facility based on the provided facility loan id.
   * @param {string} loanFacilityId - The ID of the loan facility.
   * @param {string} stage - The stage of the loan facility.
   * @returns {Promise<LoanFacilityFlagInfo>} A promise that resolves with the flag information of the loan facility.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/loan-facility/getLoanFacilityFlagInfo.md
   * @APICode service.loan-facilities.flag-infos.get
   */
  getLoanFacilityFlagInfo(loanFacilityId, stage) {
    return http.get(
      `${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}/flag-infos`
    )
  },

  /**
   * Update the approved amount of a loan facility.
   *
   * @param {Object} params - The parameters for the request.
   * @param {number} params.loanFacilityId - The ID of the loan facility to update.
   * @param {number} params.approvedAmount - The new approved amount for the loan facility.
   * @returns {Promise<Object>} The response from the server.
   */
  updateLoanFacilityApprovedAmount(params) {
    const { loanFacilityId, approvedAmount } = params
    return http.patch(
      `${API_VERSION}/service/loan-facilities/${loanFacilityId}/approved-amounts`,
      { approvedAmount }
    )
  },

  /**
   * Updates the advisor information associated with a firm and its representative code in a loan facility.
   * @param {String} loanFacilityId - The ID of the loan facility.
   * @param {Object} updateData - The data to update the advisor information.
   * @param {String} updateData.clientRepCode - The client representative code.
   * @param {String} updateData.clientRepCodeId - The ID of the client representative code.
   * @param {String} updateData.advisorId - The ID of the advisor.
   * @param {String} updateData.advisorProfileId - The profile ID of the advisor.
   * @param {String} updateData.advisorName - The name of the advisor.
   * @param {String} updateData.firmInstitutionId - The institution ID of the firm.
   * @param {String} updateData.firmInternalGroupId - The internal group ID of the firm.
   * @returns {Promise}
   */
  updateFirmRepCodeAdvisor(loanFacilityId, updateData) {
    return http.patch(
      `${API_VERSION}/service/loan-facilities/${loanFacilityId}/channels`,
      updateData
    )
  },

  /**
   * Create Loan Facility
   * @APICode: service.loan-facilities.create
   * @Auth required: YES
   * @Permissions required: service.loan-facilities.create
   * @param {Object} params - The request payload
   * @param {String} params.totalLoanFacilityId - The ID of the total loan facility
   * @param {String} params.loanFacilityType - The type of the loan facility
   * @param {String} params.loanFacilityNumber - The number of the loan facility
   * @param {String} params.nickname - The nickname of the loan facility
   * @param {String} params.loanFacilityName - The name of the loan facility
   * @param {String} params.status - The status of the loan facility
   * @param {Number} params.balance - The balance of the loan facility
   * @param {Number} params.pendingDraw - The pending draw of the loan facility
   * @param {Number} params.pendingPayment - The pending payment of the loan facility
   * @param {String} params.issueDate - The issue date of the loan facility
   * @param {String} params.typeOfPurpose - The type of purpose of the loan facility
   * @param {String} params.purpose - The purpose of the loan facility
   * @param {String} params.distributionChannelInstitutionId - The distribution channel institution ID
   * @param {String} params.lenderInstitutionId - The lender institution ID
   * @param {String} params.servicingInstitutionId - The servicing institution ID
   * @param {String} params.fundingInstitutionId - The funding institution ID
   * @param {String} params.firmInstitutionId - The firm institution ID
   * @param {String} params.firmInternalGroupId - The firm internal group ID
   * @param {String} params.relationshipManagerProfileId - The relationship manager profile ID
   * @param {String} params.primaryOfficerProfileId - The primary officer profile ID
   * @param {String} params.plCenterId - The PL center ID
   * @param {String} params.fedCallCode - The fed call code
   * @param {Array} params.userDefinedFields - The user defined fields
   * @returns {Promise}
   */
  createLoanFacility(body) {
    return http.post(`${API_VERSION}/service/loan-facilities`, body)
  },

  /**
   * Retrieves a paginated list of loan facilities based on specified conditions.
   *
   * @param {Object} params - The request parameters.
   * @param {number} params.pageNum - The page number.
   * @param {number} params.pageSize - The size of the page.
   * @param {string} [params.sort] - The sorting criteria.
   * @param {number} [params.filters.totalLoanFacilityId] - Filter by total loan facility ID.
   * @param {Array<string>} [params.filters.loanFacilityStatusIn] - Filter by loan facility status.
   * @param {string} [params.filters.loanFacilityStatus] - Filter by a single loan facility status.
   * @param {Array<number>} [params.filters.distributionChannelInstitutionIdIn] - Filter by distribution channel institution IDs.
   * @param {number} [params.filters.primaryAdvisorProfileId] - Filter by primary advisor profile ID.
   * @param {string} [params.filters.search] - Filter by keyword search.
   * @param {boolean} [params.filters.drawFreeze] - Filter by draw freeze status.
   * @param {boolean} [params.filters.allConditionsApproved] - Filter by all conditions approved status.
   * @param {BigDecimal} [params.filters.minBalance] - Filter by minimum balance.
   * @param {BigDecimal} [params.filters.maxBalance] - Filter by maximum balance.
   * @param {BigDecimal} [params.filters.minAvailableCredit] - Filter by minimum available credit.
   * @param {BigDecimal} [params.filters.maxAvailableCredit] - Filter by maximum available credit.
   * @param {BigDecimal} [params.filters.minLoanToCapacity] - Filter by minimum loan to capacity.
   * @param {BigDecimal} [params.filters.maxLoanToCapacity] - Filter by maximum loan to capacity.
   * @returns {Promise<Object>} The paginated list of loan facilities.
   * @auth required
   * @permission service.loan-facilities.list
   */
  getListLoanFacilities(params, config) {
    return http.get(`${API_VERSION}/service/loan-facilities`, params, config)
  },
  getCustodiansByInstitutionId(institutionId) {
    return http.get(
      `${API_VERSION}/configuration/institutions/${institutionId}/custodians`
    )
  },

  /**
   * Activates a conditional approved loan facility by its ID.
   * @param {string} loanFacilityId The ID of the loan facility to activate.
   * @returns {Promise<void>} A promise that resolves when the loan facility activation is successful.
   *
   * @APICode service.loan-facilities.activations.update
   */
  updateActivation(loanFacilityId) {
    return http.patch(
      `${API_VERSION}/service/loan-facilities/${loanFacilityId}/activations`
    )
  },

  /**
   * Updates the conditional approval information of a loan facility.
   * @typedef {Object} UpdateLoanFacilityConditionalApprovalPayload
   * @property {boolean} [isExistingLiabilitiesPaidOff] - Indicates if existing liabilities are paid off.
   * @property {string} conditionalType - Type of the conditional approval.
   * @property {boolean} isMeetCondition - Indicates if the condition is met.
   * @param {number} loanFacilityId of - The ID the loan facility.
   * @param {UpdateLoanFacilityConditionalApprovalPayload} payload - The payload for the update.
   * @returns {Promise<Object>} - A promise that resolves with the response of the update operation.
   *
   * @APICode service.loan-facilities.conditional-approvals.update
   */
  updateConditionalApproval(loanFacilityId, payload) {
    return http.patch(
      `${API_VERSION}/service/loan-facilities/${loanFacilityId}/conditional-approvals`,
      payload
    )
  },
  /**
   * Exports loan facilities data to an Excel file with optional encryption.
   * @param {Object} params - The request parameters.
   * @param {number} params.pageNum - The page number.
   * @param {number} params.pageSize - The size of the page.
   * @param {string} [params.sort] - The sorting criteria.
   * @param {string} [params.searchTerm] - The search term.
   * @param {Array<string>} [params.filters.status] - The status filter.
   * @param {number} [params.filters.totalLoanFacilityId] - The total loan facility ID filter.
   * @param {number} [params.filters.loanFacilityId] - The loan facility ID filter.
   * @param {Array<number>} [params.filters.distributionChannelInstitutionId] - The distribution channel institution ID filter.
   * @param {number} [params.filters.createdAt.max] - The maximum creation date filter.
   * @param {number} [params.filters.createdAt.min] - The minimum creation date filter.
   * @param {number} [params.filters.balance.max] - The maximum balance filter.
   * @param {number} [params.filters.balance.min] - The minimum balance filter.
   * @param {number} [params.filters.availableCredit.max] - The maximum available credit filter.
   * @param {number} [params.filters.availableCredit.min] - The minimum available credit filter.
   * @param {number} [params.filters.approvedAmount.max] - The maximum approved amount filter.
   * @param {number} [params.filters.approvedAmount.min] - The minimum approved amount filter.
   * @param {Date} [params.filters.issueDate.max] - The maximum issue date filter.
   * @param {Date} [params.filters.issueDate.min] - The minimum issue date filter.
   * @param {Date} [params.filters.maturityDate.max] - The maximum maturity date filter.
   * @param {Date} [params.filters.maturityDate.min] - The minimum maturity date filter.
   * @param {string} [params.filters.rateType] - The rate type filter.
   * @param {number} [params.filters.term] - The term filter.
   * @param {boolean} [params.shouldEncrypt=true] - Determines if the export should be encrypted.
   * @param {string} [params.headerNames] - A comma-separated list of header names file for the Excel.
   * @param {string} [params.columnNames] - A comma-separated list of column names file for the Excel.
   * @returns {Promise<ArrayBuffer>} A promise that resolves with the binary stream of the Excel file.
   * @APICode service.loan-facilities.exports.get
   */
  exportLoanFacilities(params = {}) {
    return http.download(
      `${API_VERSION}/service/loan-facilities/exports`,
      params
    )
  },

  /**
   * Updates the information entry for a given stage and infoEntryId.
   * @param {string} stage - The stage of the application.
   * @param {string} loanFacilityId - The ID of the application.
   * @param {string} infoEntryId - The ID of the information entry to update.
   * @param {InformationEntryUpdateReqDTO} updateData - The data to update the information entry with.
   * @returns {Promise<void>} - A promise that resolves when the information entry is updated.
   * @typedef {Object} InformationEntryUpdateReqDTO
   * @property {string} wealthManager - The wealth manager identifier.
   * @property {string} bankerName - The name of the banker.
   * @property {string} organizationCode - The organization code.
   * @property {string} referralSource - The source of the referral.
   * @property {string} sublimitOption - The option for sublimit.
   * @property {number} sublimitAmount - The amount for sublimit.
   * @property {string} expectedAnnualUsage - The expected annual usage.
   * @property {string} requesterDate - The date when the request was made.
   * @property {string} requesterName - The name of the requester.
   * @APICode {stage}.info-entries.update
   */
  updateInfoEntry(stage, loanFacilityId, infoEntryId, updateData) {
    return http.patch(
      `${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}/info-entries/${infoEntryId}`,
      updateData
    )
  },

  /**
   * Retrieves information entries associated with a specific application ID.
   * @param {string} stage - The stage of the application.
   * @param {number} loanFacilityId - The unique identifier of the application.
   * @APICode stage.loan-facilities.info-entries.list
   * @archives apiCode {stage}.archive.loan-facilities.info-entries.list
   */
  getInformationEntries(stage, loanFacilityId, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/loan-facilities/${loanFacilityId}/info-entries`
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}/info-entries`
    )
  },

  /**
   * Retrieves specific other information of a loan facility based on the loanFacility ID.
   * @param {string} stage - The processing stage of the loan facility.
   * @param {string} loanFacilityId - The ID of the loan facility.
   * @returns {Promise<OtherInfoResponse>} - A promise that resolves with the other information of the loan facility.
   * @typedef {Object} OtherInfoResponse
   * @property {OtherInfoItem[]} items - An array of other information items.
   * @typedef {Object} OtherInfoItem
   * @property {string} documentationNeedDate - The date when documentation is needed.
   * @property {boolean} isItRush - Indicates if the process is in rush.
   * @property {string} rushReason - The reason for the rush.
   * @property {string} rushReasonForTyping - Typed reason for the rush.
   * @property {string} borrowerResidesIn - Where the borrower resides.
   * @property {string} borrowerClosingIn - Where the borrower is closing.
   * @property {string} florideSolution - The floride solution used.
   * @property {string} transactionSummary - Summary of the transaction.
   * @property {string} borrowerSummary - Summary of the borrower.
   * @property {string} relAndStrSummary - Summary of relevant and strategic information.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/loan-facility/other-info/listLoanFacilityOtherInfo.md
   * @APICode {stage}.loan-facilities.other-infos.list
   * @APICode {stage}.archives.loan-facilities.other-infos.list
   */
  getLoanFacilityOtherInfos(stage, loanFacilityId, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/loan-facilities/${loanFacilityId}/other-infos`
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}/other-infos`
    )
  },

  /**
   * Update other information related to a particular stage and otherInfoId.
   * @param {String} stage - The stage of the loan facility process.
   * @param {String} loanFacilityId - The ID of the loan facility.
   * @param {String} otherInfoId - The ID of the other information to update.
   * @param {OtherInfoUpdateReqDTO} otherInfoData - The data to update the other info.
   * @returns {Promise<Object>} - A promise that resolves with the success response.
   * @APICode {stage}.other-infos.update
   */
  updateOtherInfo(stage, loanFacilityId, otherInfoId, otherInfoData) {
    return http.patch(
      `${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}/other-infos/${otherInfoId}`,
      otherInfoData
    )
  },
  /**
   * Retrieves the statistics of loan facility status.
   * @returns {Promise<LoanFacilityStatistics>} - A promise that resolves with the statistics of loan facilities.
   * @APICode service.loan-facilities.statistics.get
   */
  getLoanFacilityStatistics(payload) {
    return http.get(
      `${API_VERSION}/service/loan-facilities/statistics`,
      payload
    )
  },

  /**
   * Map LOC products to a specific loan facility.
   * @param {string} stage - The stage of the loan facility.
   * @param {string} loanFacilityId - The ID of the loan facility to map LOC products to.
   * @returns {Promise<Object>} A promise that resolves with the result of the mapping operation.
   * @APICode {stage}.loan-facilities.loc-product-maps.update
   */
  mapLoanFacilityLocProduct(stage, loanFacilityId) {
    return http.patch(
      `${API_VERSION}/${stage}/loan-facilities/${loanFacilityId}/loc-product-maps`
    )
  },
}
