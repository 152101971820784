import { http, API_VERSION } from '@/shared/api/http.js'

/**
 * @typedef {Object} CreateChangeLoanFacilityApplicationReqDTO
 * @property {string[]} taskTypes - The types of tasks to be created.
 * @property {number} loanFacilityId - The ID of the loan facility.
 * @property {number} loanId - The ID of the loan.

 * @typedef {Object} CreateAmendmentApplicationRespDTO
 * @property {number} applicationId - The ID of the created application.
 */

export const amendmentAPI = {
  /**
   * Create application for amendment change loan facility request.
   * @param {string} stage - The stage of the amendment process.
   * @param {CreateChangeLoanFacilityApplicationReqDTO} payload - The request payload.
   * @returns {Promise<CreateAmendmentApplicationRespDTO>} - A promise that resolves with the ID of the created application.
   * @APICode amendment.applications.change-loan-facilities.create
   */
  createChangeLoanFacilityApplication(payload) {
    return http.post(
      `${API_VERSION}/amendment/applications/change-loan-facilities`,
      payload
    )
  },

  /**
   * Create a new loan facility amendment application.
   * @typedef {Object} Questionnaire
   * @property {string} questionLevel - The level of the question.
   * @property {number} partyId - The ID of the party.
   * @property {string} questionCode - The code of the question.
   * @property {string} answerBody - The body of the answer.
   * @property {number} noticeOfIncompleteId - The ID of the notice of incomplete.
   * @property {string} formType - The type of the form.
   * @typedef {Object} QuestionnaireAndLevels
   * @property {Questionnaire[]} questionnaires - An array of questionnaires.
   * @property {string[]} questionLevelList - A list of question levels.
   * @typedef {Object} NewLoanFacilityRequest
   * @property {number} totalLoanFacilityId - The ID of the total loan facility.
   * @property {string} loanFacilityType - The type of the loan facility.
   * @property {string} loanFacilityName - The name of the loan facility.
   * @property {number} clientRepCodeId - The ID of the client representative code.
   * @property {number} primaryAdvisorProfileId - The ID of the primary advisor profile.
   * @property {number} relationshipManagerProfileId - The ID of the relationship manager profile.
   * @property {string} locProductId - The product ID of the line of credit.
   * @property {number} distributionChannelInstitutionId - The ID of the distribution channel institution.
   * @property {QuestionnaireAndLevels} questionnaireAndLevels - The questionnaires and their levels.
   * @returns {Promise<NewLoanFacilityResponse>} - A promise that resolves with the new loan facility amendment application response.
   * @APISpecUrl  https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/application/amendment/createNewLoanFacilityApplication.md
   * @APICode amendment.applications.loan-facilities.post
   */

  createNewLoanFacilityAmendmentApplication(params) {
    return http.post(
      `${API_VERSION}/amendment/applications/loan-facilities`,
      params
    )
  },

  getAmendmentReadinessResult(params) {
    return http.get(`${API_VERSION}/amendment/data-readiness/results`, params)
  },
}
