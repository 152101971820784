import { ref } from 'vue'
import { totalLoanFacilityAPI } from '../../../api'
import {
  APIStageEnum,
  LineOriginationSourceEnum,
  SessionKeyEnum,
  getSessionStorageItem,
  isEqualStringIgnoreCase,
  setSessionStorageItem,
} from '../../../utils'

const isTotalLoanFacilityLoading = ref(false)
const totalLoanFacility = ref(
  getSessionStorageItem(SessionKeyEnum.totalLoanFacility, {})
)

const normalizeTotalLoanFacility = (totalLoanFacility) => {
  totalLoanFacility.isImportUI = isEqualStringIgnoreCase(
    totalLoanFacility.originationSource,
    LineOriginationSourceEnum.importUI
  )
  return totalLoanFacility
}

/**
 * @typedef {Object} TotalLoanFacility
 * @property {number} totalLoanFacilityId - The ID of the total loan facility.
 * @property {string} totalFacilityNumber - The total facility number.
 * @property {string} totalFacilityName - The name of the total facility.
 * @property {string} totalFacilityNickname - The nickname of the total facility.
 * @property {string} status - The status of the total loan facility.
 * @property {string} issuedDate - The issued date of the total loan facility.
 * @property {number} secured - Indicates if the total loan facility is secured.
 */

/**
 * Composition API to interact with total loan facility data.
 *
 * @returns {Object} Object containing attributes and methods for total loan facility interaction.
 */
export const useTotalLoanFacilityData = (stage = APIStageEnum.service) => {
  /**
   * Fetches a total loan facility by its ID.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to retrieve.
   */
  const getTotalLoanFacility = async (
    totalLoanFacilityId,
    _stage = stage,
    archiveAmendmentId
  ) => {
    isTotalLoanFacilityLoading.value = true
    totalLoanFacility.value = {}
    try {
      let data = await totalLoanFacilityAPI.getTotalLoanFacility(
        _stage,
        totalLoanFacilityId,
        archiveAmendmentId
      )
      data = normalizeTotalLoanFacility(data)
      setSessionStorageItem(SessionKeyEnum.totalLoanFacility, data)
      totalLoanFacility.value = data
      return data
    } catch (e) {
      console.error(e)
    } finally {
      isTotalLoanFacilityLoading.value = false
    }
  }

  const isTotalLoanFacilityUpdating = ref(false)

  /**
   * Updates a total loan facility.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to update.
   * @param {TotalLoanFacility} params - The params containing the details to update.
   */
  const updateTotalLoanFacility = async (totalLoanFacilityId, params) => {
    isTotalLoanFacilityUpdating.value = true
    try {
      await totalLoanFacilityAPI.updateTotalLoanFacility(
        totalLoanFacilityId,
        params
      )
    } finally {
      isTotalLoanFacilityUpdating.value = false
    }
  }

  const isTotalLoanFacilityDeleting = ref(false)

  /**
   * Deletes a total loan facility by its ID.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to be deleted.
   */
  const deleteTotalLoanFacility = async (totalLoanFacilityId) => {
    isTotalLoanFacilityDeleting.value = true
    try {
      await totalLoanFacilityAPI.deleteTotalLoanFacility(totalLoanFacilityId)
    } finally {
      isTotalLoanFacilityDeleting.value = false
    }
  }

  const isTotalLoanFacilityListLoading = ref(false)
  const totalLoanFacilityList = ref({})

  /**
   * Retrieves a list of total loan facilities based on various filters, sorted and paginated.
   *
   * @param {ListTotalLoanFacilitiesParams} params - The parameters for the loan facilities list.
   * @returns {Promise<LoanFacilityListResponse>} - A promise that resolves with the list of loan facilities.
   */
  const listTotalLoanFacilities = async (stage, params) => {
    isTotalLoanFacilityListLoading.value = true
    try {
      totalLoanFacilityList.value =
        await totalLoanFacilityAPI.listTotalLoanFacilities(stage, params)
    } finally {
      isTotalLoanFacilityListLoading.value = false
    }
  }

  return {
    isTotalLoanFacilityListLoading,
    totalLoanFacilityList,
    listTotalLoanFacilities,
    isTotalLoanFacilityLoading,
    totalLoanFacility,
    getTotalLoanFacility,
    normalizeTotalLoanFacility,
    isTotalLoanFacilityUpdating,
    updateTotalLoanFacility,
    isTotalLoanFacilityDeleting,
    deleteTotalLoanFacility,
  }
}
