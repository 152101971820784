import _ from 'lodash'
import numeral from 'numeral' // for math computed
import moment from 'moment'
import timeZone from 'moment-timezone'
import 'moment-business-days'
import BigNumber from 'bignumber.js'
import * as is from '../is'
import * as pattern from './pattern.js'
import { timeZones } from './time-zone.js'

/**
 * adding a zone
 * @param
 */
timeZone.tz.add(timeZones)

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 */
export function toString(mix, { invalid = '' } = {}) {
  if (_.isNumber(mix)) {
    mix = String(mix)
  } else if (!_.isString(mix)) {
    mix = invalid
  }

  return mix
}

/**
 * Convert date to YYYY-MM-DD, for post data.
 *
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format
 * @return {String} 2020-04-01
 */
export function toDate(mix, { format = 'YYYY-MM-DD', invalid = '' } = {}) {
  if (is.isEmpty(mix)) {
    return invalid
  }

  const m = moment(mix, pattern.validDateFormat(), true)
  if (!m.isValid()) {
    return invalid
  }

  return m.format(format)
}

/**
 * Convert number like value to numeral.
 *
 * @param {*} mix
 * @param {Object} options
 * @param {Number} options.invalid
 */
export function toNumeral(mix, { invalid = 0 } = {}) {
  if (_.isNumber(mix) && _.isFinite(mix)) {
    return numeral(mix)
  }

  if (_.isString(mix)) {
    mix = numeral(mix)
    if (_.isNumber(mix.value())) {
      return mix
    }
  }

  return numeral(invalid)
}

/**
 * @param {*} mix
 * @param {Object} options
 * @returns {Number}
 */
export function toNumber(mix, { invalid = 0 } = {}) {
  mix = toNumeral(mix, { invalid: null }).value()

  return mix === null ? invalid : mix
}

/**
 * @param {*} mix
 * @param {Object} options
 * @returns {BigNumber}
 */
export function toBigNumber(mix, { invalid = 0 } = {}) {
  mix = toNumber(mix, { invalid })

  return BigNumber(mix)
}

/**
 * Convert currency format string to valid number. e.g. $1,223.00123 to 1223.00
 *
 * @param {*} mix
 * @param {string} options.format
 * @param {string} options.invalid
 */
export function toCurrency(mix, { format = '0.00', invalid = 0 } = {}) {
  mix = toNumeral(mix, { invalid })

  return mix.format(format)
}

/**
 * @param {*} mix
 * @param {*} options.timezone
 */
export function toMoment(mix, { timezone = null } = {}) {
  let m
  if (timezone) {
    m = moment.tz(mix, pattern.validDateFormat(), true, timezone)
    if (!m.isValid() && pattern.unsignedInteger().test(mix)) {
      m = moment.tz(new Date(Number(mix)), timezone)
    }
  } else {
    m = moment(mix, pattern.validDateFormat(), true)
    if (!m.isValid() && pattern.unsignedInteger().test(mix)) {
      m = moment(new Date(Number(mix)))
    }
  }

  return m
}

/**
 * convert any to string
 * @param {any} value
 * @param {string} [placeholder] return placeholder when value is undefined
 * @return return string or null
 */
export function stringValue(value, placeholder) {
  const valueType = typeof value
  const placeholderType = typeof placeholder
  switch (true) {
    case valueType === 'string' && !!value:
      return value
    case valueType === 'string' && placeholderType !== 'string':
      return value
    case valueType === 'number':
      return value.toString()
    default:
      return placeholderType === 'string' ? placeholder : null
  }
}

/**
 * convert any to number
 * @param {any} value
 * @param {number} [placeholder] return placeholder when value is undefined
 * @return return number or null
 */
export function numberValue(value, placeholder) {
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'string') {
    const floatValue = parseFloat(value)
    if (!isNaN(floatValue)) {
      return floatValue
    }
  }
  return typeof placeholder !== 'number' ? null : placeholder
}

/**
 * Convert any to object
 * @param {any} value
 * @param {object} placeholder
 * @return {object | null}
 */
export function objectValue(value, placeholder) {
  if (value !== null && typeof value === 'object') {
    return value
  }
  return typeof placeholder !== 'object' ? null : placeholder
}

/**
 * convert any to boolean
 * @param {any} value
 * @param {boolean} [placeholder] return placeholder when value is undefined
 * @return Boolean or null
 */
export function booleanValue(value, placeholder) {
  if (typeof value === 'boolean') {
    return value
  }
  if (typeof value === 'number') {
    return value !== 0
  }
  if (typeof value === 'string') {
    return !(value === '' || value === '0' || value === 'false')
  }
  return typeof placeholder !== 'boolean' ? null : placeholder
}

/**
 * Convert array to array
 * @param {Array} arr
 * @param {Object} options
 * @param {Array} options.defaultValue
 * @param {Function} options.itemInitFunction
 * @returns {Array}
 */
export function arrayValue(
  arr,
  { defaultValue = null, itemInitFunction = null } = {}
) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return defaultValue
  }
  if (itemInitFunction) {
    return arr.map((item) => itemInitFunction(item))
  }
  return arr
}

/**
 * Convert string to title case
 * @param {string} str
 * @returns {string}
 */
export function toTitleCase(str) {
  return _.words(str).map(_.capitalize).join(' ')
}

export function tryJsonParse(value, defaultValue) {
  if (_.isString(value)) {
    try {
      value = JSON.parse(value)
    } catch (error) {
      value = defaultValue
    }
  }
  return value ?? defaultValue
}
