<template>
  <s-modal
    :title="$ts('message')"
    :visible.sync="visible"
    size="lg"
    @close="onClose"
  >
    <div>{{ $ts('overwrite_person_info_confirm_content') }}</div>
    <s-table
      v-if="visible"
      class="mt-24"
      :columns="columns"
      :dataSource="[userInfo]"
      :summary="$ts('user_info')"
    >
    </s-table>
    <template slot="footer">
      <s-button
        button-type="outline-primary"
        :aria-label="$ts('cancel')"
        @click.native="onClose"
      >
        {{ $ts('cancel') }}
      </s-button>
      <s-button
        :disabled="loading"
        :aria-label="$ts('confirm')"
        @click.native="onConfirm"
      >
        {{ $ts('confirm') }}
      </s-button>
    </template>
  </s-modal>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      userInfo: {},
      confirmCallback: null,
      cancelCallback: null,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$ts('username'),
          dataKey: 'email',
          width: '300',
        },
        {
          title: this.$ts('name'),
          dataKey: 'fullName',
          filter: this.$utils.format.text,
          width: '200',
        },
      ]
    },
  },
  methods: {
    show() {
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.cancelCallback && this.cancelCallback()
    },
    onConfirm() {
      this.visible = false
      this.confirmCallback && this.confirmCallback(this.userInfo)
    },
  },
}
</script>
