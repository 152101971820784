import { http, API_VERSION } from '../../http'

export const statisticsAPI = {
  /**
   * permission code: ${stage}.total-loan-facilities.statistics.{aggregationLevel}.approvals.list
  **/
  getApprovedTotalLoanFacilitiesBreakdown(stage, aggregationLevel) {
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/statistics/${aggregationLevel}/approvals`
    )
  },

  /**
   * permission code: ${stage}.total-loan-facilities.statistics.{aggregationLevel}.growth-trends.list
  **/
  getGrowthTrends(stage, aggregationLevel) {
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/statistics/${aggregationLevel}/growth-trends`
    )
  },

  /**
   * permission code: ${stage}.total-loan-facilities.statistics.{aggregationLevel}.risk-capacities.get
  **/
  getRiskCapacity(stage, aggregationLevel) {
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/statistics/${aggregationLevel}/risk-capacities`
    )
  },

  /**
   * permission code: ${stage}.total-loan-facilities.statistics.{aggregationLevel}.risk-counts.get
  **/
  getRiskCount(stage, aggregationLevel) {
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/statistics/${aggregationLevel}/risk-counts`
    )
  },

  /**
   * permission code: ${stage}.total-loan-facilities.statistics.{aggregationLevel}.risk-ratings.list
  **/
  getRiskRating(stage, aggregationLevel) {
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/statistics/${aggregationLevel}/risk-ratings`
    )
  },

  /**
   * permission code: ${stage}.total-loan-facilities.statistics.{aggregationLevel}.risk-trends.list
  **/
  getRiskTrends(stage, aggregationLevel) {
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/statistics/${aggregationLevel}/risk-trends`
    )
  },

  /**
   * permission code: ${stage}.total-loan-facilities.statistics.{aggregationLevel}.risk-ratings.history.list
  **/
  getRiskRatingHistory(stage, aggregationLevel) {
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/statistics/${aggregationLevel}/risk-ratings/history`
    )
  },
}
