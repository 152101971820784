export const windowResize = (function () {
  const callbacks = []
  let running = false

  // fired on resize event
  function resize() {
    if (!running) {
      running = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks)
      } else {
        setTimeout(runCallbacks, 66)
      }
    }
  }

  // run the actual callbacks
  function runCallbacks() {
    callbacks.forEach(function (callback) {
      try {
        callback()
      } catch (error) {
        console.error('Error executing window resize callback:', error)
      }
    })

    running = false
  }

  // adds callback to loop
  function addCallback(callback) {
    if (callback) {
      callbacks.push(callback)
    }
  }

  function removeCallback(callback) {
    const index = callbacks.indexOf(callback)
    if (index !== -1) {
      callbacks.splice(index, 1)
    }
  }

  return {
    add: function (callback) {
      if (!callbacks.length) {
        window.addEventListener('resize', resize)
      }
      addCallback(callback)
    },
    remove: function (callback) {
      removeCallback(callback)
      if (!callbacks.length) {
        window.removeEventListener('resize', resize)
      }
    },
  }
})()
