import { http, API_VERSION } from '@/shared/api/http'
export const creditPolicyAPI = {
  listCreditPolicies(stage) {
    // permission code: {stage}.credit-policies.list
    return http.get(`${API_VERSION}/${stage}/credit-policies`)
  },

  /**
   * List credit policy classifications based on various parameters.
   * @param {ExceptionCollateralTypeReqDTO} [params] - The query parameters for the request.
   * @returns {Promise<ExceptionCollateralTypeRespDTO[]>} - A promise that resolves with an array of credit policy classifications.
   * @typedef {Object} ExceptionCollateralTypeReqDTO
   * @property {String} [exceptionLevel] - The level of exception to apply.
   * @property {Number} [exceptionBasic] - The basic exception parameter.
   * @property {Number} [collateralId] - The ID of the collateral.
   * @property {Number} [locProductId] - The ID of the LOC product.
   * @property {Number} [institutionId] - The ID of the institution.
   *
   * @typedef {Object} ExceptionCollateralTypeRespDTO
   * @property {Number} collateralTypeId - The ID of the collateral type.
   * @property {String} collateralTypeName - The name of the collateral type.
   *
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/credit-policy/listCreditPolicyClassifications.md
   * @APICode service.credit-policy-classifications.list
   */
  listCreditPolicyClassifications(params = {}) {
    return http.get(
      `${API_VERSION}/service/credit-policies/classifications`,
      params
    )
  },

  /**
   * Create a new credit policy calculation based on security details.
   * @param {SecurityCPCCalculationReqDTO} securityCPCCalculationReqDTO - The security details for the credit policy calculation.
   * @returns {Promise<SecurityCPCCalculationRespDTO>} - A promise that resolves with the updated security and CPC details.
   * @typedef {Object} SecurityCPCCalculationReqDTO
   * @property {number} secId - The ID of the security.
   * @property {number} dataSourceInstitutionId - The ID of the data source institution.
   * @property {string} sourceSecurityId - The ID of the source security.
   * @property {string} sourceSecurityType - The type of the source security.
   * @property {string} cusip - The CUSIP number.
   * @property {string} isin - The ISIN number.
   * @property {string} sedol - The SEDOL number.
   * @property {string} ticker - The ticker symbol.
   * @property {string} securityName - The name of the security.
   * @property {string} assetClass - The asset class.
   * @property {string} universe - The universe the security belongs to.
   * @property {string} investmentClass - The investment class.
   * @property {string} domicile - The domicile of the security.
   * @property {string} exchange - The exchange where the security is traded.
   * @property {string} ipoDate - The IPO date of the security.
   * @property {number} volThreeMonthAvg - The three-month average volume.
   * @property {number} marketCap - The market capitalization.
   * @property {number} totalSharesOutstanding - The total shares outstanding.
   * @property {string} stockSector - The sector of the stock.
   * @property {string} fundCategoryCode - The fund category code.
   * @property {number} fundTna - The total net assets of the fund.
   * @property {number} avgCreditQuality - The average credit quality.
   * @property {string} spRating - The Standard & Poor's rating.
   * @property {string} moodyRating - The Moody's rating.
   * @property {string} fitchRating - The Fitch rating.
   * @property {string} maturityDate - The maturity date of the security.
   * @property {string} muniState - The state of the municipal.
   * @property {number} providerAum - The assets under management by the provider.
   * @property {CreditPolicyDTO} creditPolicy - The credit policy details.
   * @typedef {Object} CreditPolicyDTO
   * @property {number} creditPolicyId - The ID of the credit policy.
   * @property {CreditPolicySecurityTypeDTO[]} securityTypeList - The list of security types.
   * @property {CreditPolicyClassificationDTO[]} classificationList - The list of classifications.
   * @property {CreditPolicyClassificationExceptionDTO[]} classificationExceptionList - The list of classification exceptions.
   * @property {CreditPolicyClassificationRateDTO[]} classificationRateList - The list of classification rates.
   * @typedef {Object} CreditPolicySecurityTypeDTO
   * @property {number} securityTypeId - The ID of the security type.
   * @property {string} securityTypeName - The name of the security type.
   * @property {string} conditions - The conditions associated with the security type.
   * @typedef {Object} CreditPolicyClassificationDTO
   * @property {number} classificationId - The ID of the classification.
   * @property {string} classificationName - The name of the classification.
   * @property {string} conditions - The conditions associated with the classification.
   * @typedef {Object} CreditPolicyClassificationExceptionDTO
   * @property {number} cpClassificationExceptionId - The ID of the classification exception.
   * @property {number} secId - The ID of the security.
   * @property {string} cusip - The CUSIP number.
   * @property {string} isin - The ISIN number.
   * @property {string} sedol - The SEDOL number.
   * @property {string} ticker - The ticker symbol.
   * @property {string} sourceSecurityId - The ID of the source security.
   * @property {string} securityName - The name of the security.
   * @property {number} adjustedCpClassificationId - The ID of the adjusted classification.
   * @property {string} adjustedAssetClass - The adjusted asset class.
   * @property {string} adjustedUniverse - The adjusted universe.
   * @property {string} adjustedInvestmentClass - The adjusted investment class.
   * @property {number} status - The status of the exception.
   * @typedef {Object} CreditPolicyClassificationRateDTO
   * @property {number} securityTypeId - The ID of the security type.
   * @property {number} classificationId - The ID of the classification.
   * @property {number} advanceRate - The advance rate.
   * @property {number} requiredEquityRate - The required equity rate.
   * @property {number} concentrationLimitRate - The concentration limit rate.
   * @property {number} concentrationLimitAmount - The concentration limit amount.
   * @property {string} eligibilityMessage - The eligibility message.
   * @typedef {Object} SecurityCPCCalculationRespDTO
   * @property {number} secId - The ID of the security.
   * @property {number} dataSourceInstitutionId - The ID of the data source institution.
   * @property {string} sourceSecurityId - The ID of the source security.
   * @property {string} sourceSecurityType - The type of the source security.
   * @property {string} cusip - The CUSIP number.
   * @property {string} isin - The ISIN number.
   * @property {string} sedol - The SEDOL number.
   * @property {string} ticker - The ticker symbol.
   * @property {string} securityName - The name of the security.
   * @property {string} assetClass - The asset class.
   * @property {string} universe - The universe the security belongs to.
   * @property {string} investmentClass - The investment class.
   * @property {number} securityTypeId - The ID of the security type.
   * @property {number} assetTypeId - The ID of the asset type.
   * @property {number} advanceRate - The advance rate.
   * @property {number} requiredEquityRate - The required equity rate.
   * @property {number} concentrationLimitRate - The concentration limit rate.
   * @property {number} concentrationLimitAmount - The concentration limit amount.
   * @property {number} collateralTypeId - The ID of the collateral type.
   * @property {string} collateralTypeName - The name of the collateral type.
   * @APICode credit-policies.classifications.securities.calculations.create
   */
  createCreditPolicyCalculation(securityCPCCalculationReqDTO) {
    return http.post(
      `${API_VERSION}/credit-policies/classifications/securities/calculations`,
      securityCPCCalculationReqDTO
    )
  },

  /**
   * List credit policies based on the provided totalLoanFacilityId.
   * @param {Object} [params] - The parameters for the request.
   * @param {number} [params.totalLoanFacilityId] - The ID of the total loan facility.
   * @returns {Promise<CreditPolicy[]>} - A promise that resolves with the list of credit policies.
   * @typedef {Object} CreditPolicy
   * @property {number} creditPolicyId - The ID of the credit policy.
   * @property {string} modifiedDate - The date when the credit policy was modified.
   * @property {string} name - The name of the credit policy.
   * @property {boolean} isPurposeLoan - Indicates if the policy is for a purpose loan.
   * @property {string} docFileName - The name of the policy document file.
   * @property {string} docFileS3Key - The S3 key for the policy document file.
   * @property {string} collateralAnalysisRuleFileName - The name of the collateral analysis rule file.
   * @property {string} collateralAnalysisRuleFileS3Key - The S3 key for the collateral analysis rule file.
   * @property {string} tableFileName - The name of the loan table file.
   * @property {string} tableFileS3Key - The S3 key for the loan table file.
   * @property {string} originalFileName - The name of the original policy file.
   * @property {string} originalFileS3Key - The S3 key for the original policy file.
   * @property {string} templateFileName - The name of the policy template file.
   * @property {string} templateFileS3Key - The S3 key for the policy template file.
   * @property {string} previousFile - The name of the previous policy version file.
   * @property {string} lendingInstitutionId - The ID of the lending institution.
   * @APICode credit-policies.list
   */
  listCreditPoliciesByTotalLoanFacilityId(params) {
    return http.get(`${API_VERSION}/credit-policies`, params)
  },

  /**
   * Retrieves a list of collateral type classifications based on a search filter.
   * @param {Object} [options] - The options for filtering the classifications.
   * @param {string} [options.search] - The search filter to apply.
   * @returns {Promise<CollateralTypeClassificationsResponse>} - A promise that resolves with the list of collateral type classifications.
   * @typedef {Object} CollateralTypeClassificationsResponse
   * @property {number} pageNumber - The current page number.
   * @property {number} pageSize - The number of items per page.
   * @property {number} totalPages - The total number of pages.
   * @property {number} totalItems - The total number of items.
   * @property {CollateralTypeClassificationItem[]} items - The list of collateral type classifications.
   * @typedef {Object} CollateralTypeClassificationItem
   * @property {number} collateralTypeId - The ID of the collateral type.
   * @property {string} collateralTypeName - The name of the collateral type.
   * @property {string} condition - The condition associated with the collateral type.
   * @APICode credit-policies.collateral-type-classifications.list
   */
  listCreditPolicyCollateralClassifications(params) {
    return http.get(
      `${API_VERSION}/credit-policies/collateral-type-classifications`,
      params
    )
  },

  /**
   * Retrieve credit policy rates based on the credit policy ID and optional search filters.
   * @param {string} creditPolicyId - The ID of the credit policy.
   * @param {GetCreditPolicyRatesParams} [params] - Optional search filters.
   * @returns {Promise<CreditPolicyRatesResponse>} - A promise that resolves with the credit policy rates data.
   * @typedef {Object} GetCreditPolicyRatesParams
   * @property {String} [search] - The search filter string.
   * @typedef {Object} CreditPolicyRatesResponse
   * @property {Number} pageNumber - The current page number.
   * @property {Number} pageSize - The size of each page.
   * @property {Number} totalPages - The total number of pages.
   * @property {Number} totalItems - The total number of items.
   * @property {CreditPolicyRateItem[]} items - The list of credit policy rates.
   * @typedef {Object} CreditPolicyRateItem
   * @property {Number} collateralTypeId - The ID of the collateral type.
   * @property {String} collateralTypeName - The name of the collateral type.
   * @property {Number} securityTypeId - The ID of the security type.
   * @property {String} securityTypeName - The name of the security type.
   * @property {Number} advanceRate - The advance rate.
   * @property {Number} maintenanceRate - The maintenance rate.
   * @property {Number} concentrationLimitRate - The concentration limit rate.
   * @APICode credit-policies.credit-policy-rates.list
   */
  listCreditPolicyRates(params) {
    return http.get(
      `${API_VERSION}/credit-policies/${params.creditPolicyId}/credit-policy-rates`,
      params
    )
  },

  /**
   * Retrieves credit policy rules based on the credit policy ID.
   * @param {number} creditPolicyId - The ID of the credit policy.
   * @returns {Promise<CreditPolicyRulesResponse>} - A promise that resolves with the credit policy rules data.
   * @typedef {Object} CreditPolicyRulesResponse
   * @property {number} pageNumber - The current page number.
   * @property {number} pageSize - The number of items per page.
   * @property {number} totalPages - The total number of pages.
   * @property {number} totalItems - The total number of items.
   * @property {CreditPolicyRule[]} items - An array of credit policy rules.
   * @typedef {Object} CreditPolicyRule
   * @property {string} ruleId - The ID of the rule.
   * @property {string} condition - The condition of the rule.
   * @property {string} action - The action of the rule.
   * @APICode credit-policies.credit-policy-rules.list
   */
  listCreditPolicyRules(params) {
    return http.get(
      `${API_VERSION}/credit-policies/${params.creditPolicyId}/credit-policy-rules`,
      params
    )
  },

  /**
   * Fetch security type classifications based on search criteria.
   * @param {string} search - The search query for the security type classifications.
   * @returns {Promise<SecurityTypeClassificationResponse>} - A promise that resolves with the security type classifications.
   * @typedef {Object} SecurityTypeClassificationResponse
   * @property {number} pageNumber - The current page number.
   * @property {number} pageSize - The size of each page.
   * @property {number} totalPages - The total number of pages.
   * @property {number} totalItems - The total number of items.
   * @property {SecurityTypeClassification[]} items - The list of security type classifications.
   * @typedef {Object} SecurityTypeClassification
   * @property {number} securityTypeId - The unique identifier for the security type.
   * @property {string} securityTypeName - The name of the security type.
   * @property {string} condition - The condition associated with the security type.
   * @APICode credit-policies.security-type-classifications.list
   */
  listCreditPolicySecurityClassifications(params) {
    return http.get(
      `${API_VERSION}/credit-policies/security-type-classifications`,
      params
    )
  },
}
