import { ref } from 'vue'
import { locProductAPI } from '../../../api'

const locProducts = ref({})

export const useLocProductData = () => {
  const isListLocProductsLoading = ref(false)

  const getListLocProducts = (params) => {
    isListLocProductsLoading.value = true
    return locProductAPI
      .getListLocProducts(params)
      .then((res) => {
        locProducts.value = res?.items || []
      })
      .finally(() => {
        isListLocProductsLoading.value = false
      })
  }

  const getLocProductDetail = (locProductId) => {
    return locProductAPI.getLocProductDetail(locProductId)
  }

  return {
    locProducts,
    listLocProducts: locProducts,
    isListLocProductsLoading,
    getListLocProducts,
    getLocProductDetail,
  }
}
/**
 * @deprecated
 * @see {@link useLocProductData}
 */
export const useLocProduct = useLocProductData
