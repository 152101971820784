import { http, API_VERSION, APIStageEnum } from '@/shared/api/http'
import { isEmpty } from '@/shared/utils'

export const collateralAPI = {
  /**
   * Retrieves the summary of collateral assets for a given stage and collateral ID.
   * @param {string} stage - The stage of the loan process.
   * @param {number} collateralId - The ID of the collateral.
   * @returns {Promise<MutiStageCollateralAssetsSummary>} - A promise that resolves with the summary of collateral assets.
   * @typedef {Object} MutiStageCollateralAssetsSummary
   * @property {number} totalAccountCounts - The total number of accounts.
   * @property {number} totalHoldingCounts - The total number of holdings.
   * @property {number} totalMarketValue - The total market value.
   * @property {number} totalLendingValue - The total lending value.
   * @property {number} totalMaintenanceValue - The total maintenance value.
   * @property {Array<MutiStageCollateralAsset>} assets - The list of collateral assets.
   * @typedef {Object} MutiStageCollateralAsset
   * @property {string} assetId - The ID of the asset.
   * @property {string} accountNumber - The account number.
   * @property {string} assetType - The type of the asset.
   * @property {string} assetName - The name of the asset.
   * @property {number} custodianInstitutionId - The custodian institution ID.
   * @property {string} accountLatestSourceType - The latest source type of the account.
   * @property {string} holdingsAsOfDate - The date of holdings as of.
   * @property {number} numberOfHoldings - The number of holdings.
   * @property {number} marketValue - The market value.
   * @property {number} lendingValue - The lending value.
   * @property {number} maintenanceValue - The maintenance value.
   * @property {string} eligibleType - The eligible type.
   * @property {number} securityIntermediaryInstitutionId - The security intermediary institution ID.
   * @property {string} dataSource - The data source.
   * @property {number} holdingPledgedCount - The holding pledged count.
   * @property {number} holdingUpdatedAt - The updated at timestamp for holdings.
   * @APICode service.collateral-packages.assets
   */
  getCollateralAssetsSummary(stage, collateralId, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/collateral-packages/${collateralId}/assets`
      )
    }
    return http.get(`${API_VERSION}/${stage}/collateral-packages/${collateralId}/assets`)
  },

  /**
   * Retrieves the exception status of collateral package calculations for a given stage and collateral ID.
   * @param {string} stage - The stage of the collateral.
   * @param {number} collateralId - The ID of the collateral.
   * @param {Object} params - The query parameters for the API call.
   * @param {string} params.calculationStage - The calculation stage to retrieve the exception status for.
   * @returns {Promise<PortfolioExceptionStatus>} - A promise that resolves with the exception status of the collateral calculations.
   * @typedef {Object} PortfolioExceptionStatus
   * @property {boolean} lendingValuePortfolioExceptionApplied - Indicates if lending value portfolio exception is applied.
   * @property {boolean} maintenanceValuePortfolioExceptionApplied - Indicates if maintenance value portfolio exception is applied.
   * @property {boolean} incorrectExceptionResultAdjusted - Indicates if incorrect exception result was adjusted.
   * @APICode service.collaterals.calculations.portfolioExceptionStatuses.get
   */
  getCollateralCalculationExceptionStatus(stage, collateralId, params) {
    return http.get(
      `${API_VERSION}/${stage}/collaterals/${collateralId}/calculations/portfolio-exception-statuses`,
      params
    )
  },

  /**
   * @typedef {Object} Asset
   * @property {String} assetId The unique identifier for the asset.
   * @property {String} assetName The name of the asset.
   * @property {String} assetNumber The number of the asset.
   * @property {String} assetType The type of the asset.
   * @property {Number} advanceRate The advance rate of the asset.
   * @property {String} assetClassification The classification of the asset.
   * @property {String} collateralBaseCurrencyCode The base currency code for the collateral.
   * @property {Number} concentrationLimitAmount The concentration limit amount for the asset.
   * @property {Number} concentrationLimitRate The concentration limit rate for the asset.
   * @property {String} custodianCode The code of the custodian.
   * @property {String} custodianInstitutionId The institution ID of the custodian.
   * @property {String} eligibleStatus The eligibility status of the asset.
   * @property {String} externalAssetId The external ID of the asset.
   * @property {String} investmentAccountTypeCode The investment account type code.
   * @property {Boolean} isMarginInvestmentAccount Indicates if it is a margin investment account.
   * @property {Boolean} isPrimaryInvestmentAccount Indicates if it is a primary investment account.
   * @property {Number} lendingValue The lending value of the asset.
   * @property {Number} maintenanceRate The maintenance rate for the asset.
   * @property {Number} maintenanceValue The maintenance value for the asset.
   * @property {Number} marginBalance The margin balance of the asset.
   * @property {Number} marketValue The market value of the asset.
   * @property {String} pledgedDate The date the asset was pledged.
   * @property {String} pledgingErrorDetail Details of any pledging errors.
   * @property {String} pledgingStatus The pledging status of the asset.
   * @property {String} priceTier The price tier of the asset.
   * @property {Number} requiredEquity The required equity for the asset.
   * @property {String} siInstitutionId The SI institution ID.
   * @property {String} asOfDate The date of the asset information.
   * @property {String} calculateAt The calculation date of the asset information.
   */

  /**
   * @typedef {Object} CollateralPackage
   * @property {String} assetName The name of the collateral package.
   * @property {String} externalCollateralPackageNumber The external number of the collateral package.
   * @property {String} collateralBaseCurrencyCode The base currency code for the collateral.
   * @property {Number} availableCreditAmount The available credit amount of the collateral.
   * @property {Number} requiredEquity The required equity for the collateral.
   * @property {Number} excessEquity The excess equity of the collateral.
   * @property {Number} excessEquityRatio The excess equity ratio of the collateral.
   * @property {Number} excessLendingValue The excess lending value of the collateral.
   * @property {Number} lendingValue The lending value of the collateral.
   * @property {Number} loanToValue The loan-to-value ratio of the collateral.
   * @property {Number} advanceRate The advance rate of the collateral.
   * @property {Number} maintenanceRate The maintenance rate for the collateral.
   * @property {Number} maintenanceValue The maintenance value for the collateral.
   * @property {Number} marketValue The market value of the collateral.
   * @property {Number} eligibleMarketValue The eligible market value of the collateral.
   * @property {Number} pendingReleaseAmount The pending release amount of the collateral.
   * @property {String} qualityRating The quality rating of the collateral.
   * @property {String} liquidityRating The liquidity rating of the collateral.
   * @property {String} volatilityRating The volatility rating of the collateral.
   * @property {String} diversificationRating The diversification rating of the collateral.
   * @property {String} collateralRating The collateral rating.
   * @property {String} asOfDate The date of the collateral information.
   * @property {String} calculateAt The calculation date of the collateral information.
   * @property {Boolean} isEligibleForAutoApproval Indicates if the collateral is eligible for auto-approval.
   * @property {Number} maxAutoApprovalAmount The maximum auto-approval amount for the collateral.
   * @property {Number} creditPolicyId The credit policy ID associated with the collateral.
   * @property {Number} collateralFlags The collateral flags.
   * @property {Number} totalBalance The total balance of the collateral.
   * @property {Number} totalLoanFacilityId The total loan facility ID associated with the collateral.
   * @property {String} stoplightCode The stoplight code of the collateral.
   * @property {Asset[]} assets The list of assets associated with the collateral.
   */

  /**
   * Get collateral package details.
   * @param {GetCollateralPackageDetailsParams} params - The parameters required to get collateral package details.
   * @returns {Promise<CollateralPackageResponse>} A promise that resolves with the details of the collateral package.
   * @APICode {stage}.collateral-packages.get / archives.collateral-packages.get
   *
   */
  getCollateralPackageDetails(stage, collateralId, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(`${API_VERSION}/${stage}/archives/${archiveAmendmentId}/collateral-packages/${collateralId}`)
    }
    return http.get(`${API_VERSION}/${stage}/collateral-packages/${collateralId}`)
  },

  getCollateralAdditionalInformation(stage, collateralId) {
    return http.get(`${API_VERSION}/${stage}/collateral-packages/${collateralId}/additional-infos`)
  },

  /**
   * @typedef {Object} CollateralPackagesFilterItems
   * @property {FilterDTO<Integer>} collateralFlags - Filter for collateral flags.
   * @property {FilterDTO<LocalDate>} effectiveDate - Filter for effective date.
   * @property {FilterDTO<BigDecimal>} lendingValue - Filter for lending value.
   * @property {FilterDTO<BigDecimal>} marketValue - Filter for market value.
   * @property {FilterDTO<BigDecimal>} eligibleMarketValue - Filter for eligible market value.
   * @property {FilterDTO<BigDecimal>} balance - Filter for balance.
   * @property {FilterDTO<BigDecimal>} concentration - Filter for concentration.
   * @property {FilterDTO<BigDecimal>} cashRate - Filter for cash rate.
   * @property {FilterDTO<BigDecimal>} liquidRate - Filter for liquid rate.
   * @property {FilterDTO<BigDecimal>} fixedIncomeRate - Filter for fixed income rate.
   * @property {FilterDTO<BigDecimal>} allocEquityRate - Filter for allocated equity rate.
   * @property {FilterDTO<BigDecimal>} allocCashRate - Filter for allocated cash rate.
   * @property {FilterDTO<String>} riskRating - Filter for risk rating.
   * @property {FilterDTO<String>} diversification - Filter for diversification.
   * @property {FilterDTO<String>} liquidity - Filter for liquidity.
   * @property {FilterDTO<String>} quality - Filter for quality.
   * @property {FilterDTO<String>} concentratedSecurityType - Filter for concentrated security type.
   * @property {FilterDTO<Boolean>} hasAutoReleaseEligibility - Filter for auto-release eligibility.
   */

  /**
   * @typedef {Object} ListCollateralPackagesRequest
   * @property {number} pageNumber - The page number of the results.
   * @property {number} pageSize - The size of each page.
   * @property {string} search - The search term.
   * @property {string[]} searchKeys - The keys to search on.
   * @property {string} sort - The sort order.
   * @property {CollateralPackagesFilterItems} filters - The filters to apply.
   */

  /**
   * @typedef {Object} ListCollateralPackageRespDTO
   * @property {number} totalLoanFacilityId - The total loan facility ID.
   * @property {number} collateralId - The collateral ID.
   * @property {number} loanFacilityId - The loan facility ID.
   * @property {string} locProductId - The LOC product ID.
   * @property {number} creditPolicyId - The credit policy ID.
   * @property {number} lenderInstitutionId - The lender institution ID.
   * @property {number} portfolioId - The portfolio ID.
   * @property {number} subPortfolioId - The sub-portfolio ID.
   * @property {string} externalCollateralId - The external collateral ID.
   * @property {string} collateralName - The name of the collateral.
   * @property {number} collateralFlags - The collateral flags.
   * @property {string} asOfDate - The date of the collateral.
   * @property {number} marketValue - The market value of the collateral.
   * @property {number} pendingRelease - The pending release amount.
   * @property {number} portfolioEquityPercent - The portfolio equity percentage.
   * @property {number} portfolioBondPercent - The portfolio bond percentage.
   * @property {number} portfolioCashPercent - The portfolio cash percentage.
   * @property {number} portfolioOtherPercent - The portfolio other percentage.
   * @property {number} otherAssetsPercent - The other assets percentage.
   * @property {number} regWMarketValue - The Reg W market value.
   * @property {number} section23aNetMarketValue - The section 23a net market value.
   * @property {number} intradayMarketValue - The intraday market value.
   * @property {number} intradayMarketValueChangePercentage - The intraday market value change percentage.
   * @property {number} eligibleMarketValue - The eligible market value.
   * @property {number} intradayEligibleMarketValue - The intraday eligible market value.
   * @property {number} intradayEligibleMarketValueChangePercentage - The intraday eligible market value change percentage.
   * @property {number} lendingValue - The lending value.
   * @property {number} intradayLendingValue - The intraday lending value.
   * @property {number} intradayLendingValueChangePercentage - The intraday lending value change percentage.
   * @property {number} maintenanceValue - The maintenance value.
   * @property {number} intradayMaintenanceValue - The intraday maintenance value.
   * @property {number} intradayMaintenanceValueChangePercentage - The intraday maintenance value change percentage.
   * @property {number} requiredEquity - The required equity.
   * @property {number} intradayRequiredEquity - The intraday required equity.
   * @property {number} intradayRequiredEquityChangePercentage - The intraday required equity change percentage.
   * @property {number} intradayCalcAt - The intraday calculation timestamp.
   * @property {number} avgAdvanceRate - The average advance rate.
   * @property {number} avgMaintenanceRate - The average maintenance rate.
   * @property {number} diversificationScore - The diversification score.
   * @property {string} diversificationRating - The diversification rating.
   * @property {number} volatilityScore - The volatility score.
   * @property {string} volatilityRating - The volatility rating.
   * @property {number} liquidityScore - The liquidity score.
   * @property {string} liquidityRating - The liquidity rating.
   * @property {number} qualityScore - The quality score.
   * @property {string} qualityRating - The quality rating.
   * @property {number} collateralScore - The collateral score.
   * @property {string} collateralRating - The collateral rating.
   * @property {number} maxHoldingId - The max holding ID.
   * @property {number} maxHoldingWeight - The max holding weight.
   * @property {string} concentratedSecurityType - The concentrated security type.
   * @property {string} concentratedSecurityName - The name of the concentrated security.
   * @property {number} concentratedIssuerId - The concentrated issuer ID.
   * @property {string} maxIssuerName - The name of the max issuer.
   * @property {number} maxIssuerWeight - The max issuer weight.
   * @property {number} liquidRate - The liquid rate.
   * @property {number} allocEquityRate - The allocated equity rate.
   * @property {number} allocFiRate - The allocated fixed income rate.
   * @property {number} allocCashRate - The allocated cash rate.
   * @property {string} status - The status of the collateral package.
   * @property {number} listFlags - The list flags.
   * @property {string} assetNumbers - The asset numbers.
   * @property {string} assetNames - The asset names.
   * @property {number} customerData1 - Custom data field 1.
   * @property {number} customerData2 - Custom data field 2.
   * @property {number} customerData3 - Custom data field 3.
   * @property {string} collateralBaseCurrencyCode - The base currency code of the collateral.
   * @property {boolean} hasAutoReleaseEligibility - Indicates if there is auto-release eligibility.
   * @property {number} excessLendingValue - The excess lending value.
   * @property {number} collateralUserDefinedNumber0 - Custom collateral field 0.
   * @property {number} collateralUserDefinedNumber1 - Custom collateral field 1.
   * @property {number} collateralUserDefinedNumber2 - Custom collateral field 2.
   * @property {number} collateralUserDefinedNumber3 - Custom collateral field 3.
   * @property {number} collateralUserDefinedNumber4 - Custom collateral field 4.
   * @property {number} calculatedAt - The timestamp when the collateral was calculated.
   * @property {number} exceptionCount - The exception count.
   */
  /**
   * Retrieves a list of collateral packages based on filters.
   * @param {string} stage - The stage of the collateral packages.
   * @param {ListCollateralPackagesRequest} [options] - The options for filtering, sorting, and pagination.
   * @returns {Promise<Array<ListCollateralPackageRespDTO>>} A promise that resolves with the list of collateral packages.
   * @APICode {stage}.collateral-packages.list
   */
  getListCollateralPackages(stage, options = {}) {
    const queryParams = {
      listCode: options.listCode || 'ALL_COLLATERALS',
      pageNumber: options.pageNumber || 1,
      pageSize: options.pageSize || 10,
      search: options.search,
      searchKeys: options.searchKeys,
      sort: options.sort,
      ...options.filters,
    }
    options.status && (queryParams['filters.status.values'] = options.status)
    return http.get(`${API_VERSION}/${stage}/collateral-packages`, queryParams)
  },

  /**
   * @typedef {Object} UpdateCollateralPackageDetailsParams
   * @property {String} collateralName - The name of the collateral.
   * @property {Number} [creditPolicyId] - The ID of the credit policy.
   * @property {String} [referralId] - The referral ID related to application & amendment.
   * @property {String} [status] - The status of the collateral package.
   * @property {Number} [pendingRelease] - The amount pending release.
   * @property {Boolean} [isEligibleForAutoApproval] - Flag indicating if eligible for auto approval.
   */
  /**
   * Updates the details of an existing collateral package.
   * @param {String} stage - The stage of the API.
   * @param {Number} collateralId - The unique ID of the collateral package.
   * @param {UpdateCollateralPackageDetailsParams} updateParams - The parameters to update the collateral package.
   * @returns {Promise<void>} A promise that resolves when the update is successful.
   * @APICode service.collateral-packages.update
   */
  updateCollateralPackageDetails(stage, collateralId, updateParams) {
    return http.patch(`${API_VERSION}/${stage}/collateral-packages/${collateralId}`, updateParams)
  },

  /**
   * Retrieve the real-time data related to a specific collateral identified by its ID.
   * @param {number} collateralId - The ID of the collateral to retrieve real-time data for.
   * @returns {Promise<CollateralRealTimeData>} A promise that resolves with the real-time collateral data.
   * @typedef {Object} CollateralRealTimeData
   * @property {boolean} showRealTime - Indicates whether to show real-time data.
   * @property {number} asOfDate - The timestamp of the "as of" date.
   * @property {number} lastUpdateTime - The timestamp of the last update time.
   * @APICode service.collateral-packages.holdings-real-time-data.get
   */
  getCollateralRealTimeData(stage, collateralId) {
    return http.get(`${API_VERSION}/${stage}/collateral-packages/${collateralId}/holdings-real-time-data`)
  },

  getMaxCollateralReleaseValue(state, collateralPackageId) {
    return http.get(`${API_VERSION}/${state}/collateral-packages/${collateralPackageId}/collateral-release-values`)
  },

  /**
   * Retrieve detailed information about asset holdings based on various filter criteria and asset ID.
   * @param {string} stage The stage of the API.
   * @param {number} collateralId The collateral ID.
   * @param {CollateralHoldingsQueryParams} [queryParams] Optional query parameters.
   * @returns {Promise<CollateralHoldingsResponse>} The response containing the asset holdings.
   */

  getCollateralPackageHoldings(stage, collateralPackageId, params) {
    return http.get(`${API_VERSION}/${stage}/collateral-packages/${collateralPackageId}/holdings`, params)
  },

  /** Collateral Asset Type Holding **/
  getCollateralAssetTypeHoldings(stage, collateralId, params) {
    // permission code: {stage}.collateral-packages.holdings.asset-type.list
    return http.get(`${API_VERSION}/${stage}/collateral-packages/${collateralId}/holdings/asset-type`, params)
  },

  getLineHealthWithHoldings(stage, collateralPackageId, holdingList) {
    return http.post(`${API_VERSION}/${stage}/collateral-packages/${collateralPackageId}/calculate-only`, {
      holdingList: holdingList,
    })
  },

  /**
   * Pledge assets for a given collateral in a specific universe and stage.
   * @param {String} stage - The stage of the collateral.
   * @param {Number} collateralId - The ID of the collateral.
   * @returns {Promise<PledgeResponse>} - A promise that resolves with the result of the asset pledging process.
   * @APICode {stage}.collateral-packages.pledges
   */
  pledgeAssets(stage, collateralId) {
    return http.post(`${API_VERSION}/${stage}/collateral-packages/${collateralId}/pledges`)
  },
  /**
   * Fetches a custom collateral analysis report for a specific collateral.
   * @param {Number} collateralId - The ID of the collateral.
   * @param {Number} archiveId - The ID of the archive.
   * @APICode amendment.collaterals.custom-reports.collateral-analyses.get
   * @APICode amendment.archives.collaterals.custom-reports.collateral-analyses.get
   */
  getCustomCollateralAnalysisReport(collateralId, archiveId) {
    if (isEmpty(archiveId)) {
      return http.get(`${API_VERSION}/amendment/collaterals/${collateralId}/custom-reports/collateral-analyses`)
    }
    return http.get(
      `${API_VERSION}/amendment/archives/${archiveId}/collaterals/${collateralId}/custom-reports/collateral-analyses`
    )
  },
  /**
   * Creates a collateral package with the specified details.
   * @param {string} stage - The stage of the collateral package.
   * @param {CreateCollateralPackageRequest} createCollateralPackageRequest - The request object containing collateral package details.
   * @returns {Promise<CreateCollateralPackageResponse>} A promise that resolves with the created collateral package ID.
   * @typedef {Object} CreateCollateralPackageRequest
   * @property {?number} estimateId - The ID related to the estimate.
   * @property {?number} proposalId - The ID related to the proposal.
   * @property {?number} applicationId - The ID related to the application.
   * @property {number} totalLoanFacilityId - The ID related to the total loan facility.
   * @property {?number} amendmentId - The ID related to the amendment.
   * @property {string} externalCollateralId - The external ID of the collateral.
   * @property {number} creditPolicyId - The ID of the credit policy.
   * @property {string} referralId - The referral ID.
   * @property {?number} lenderInstitutionId - The ID of the lender institution.
   * @property {?number} distributionChannelInstitutionId - The ID of the distribution channel institution.
   * @property {string} collateralName - The name of the collateral.
   * @property {string} status - The status of the collateral.
   * @property {string} collateralBaseCurrencyCode - The base currency code for the collateral.
   * @property {?number} originalCollateralId - The original ID of the collateral.
   * @typedef {Object} CreateCollateralPackageResponse
   * @property {number} collateralId - The ID of the created collateral package.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/collateral/collateral/createCollateralPackage.md
   * @APICode {stage}.collateral-packages.create
   */
  createCollateralPackage(stage, createCollateralPackageRequest) {
    return http.post(`${API_VERSION}/${stage}/collateral-packages`, createCollateralPackageRequest)
  },
}
