import { upperCase } from 'lodash'
import { useStore } from './useStore'

export const useUserInfoStore = () => {
  const { state } = useStore()
  const { userInfo } = state
  const { profile, fullName, username: userName, userId } = userInfo || {}
  const { roleType } = profile || {}
  const isLender = ![RoleTypeEnum.investment, RoleTypeEnum.customer].includes(
    String(roleType).toLocaleUpperCase()
  )

  const isCustomer = upperCase(roleType) === RoleTypeEnum.customer
  const isInvestment = upperCase(roleType) === RoleTypeEnum.investment

  const roleConfig = computed(() => userInfo?.roleConfiguration || {})
  const menuList = computed(() => roleConfig.value.menuList || [])
  const hasUnmaskPIIAuth = computed(
    () => roleConfig.value.isAllowExportWithoutEncryption
  )

  const drawOverLimitSetting = computed(
    () => roleConfig.value.drawOverLimitSetting
  )
  const extraDrawPercentAllowed = computed(
    () => roleConfig.value.extraDrawPercentAllowed
  )

  function isCurrentUser(_userId) {
    return _userId && userId === _userId
  }

  function isCurrentProfile(_profileId) {
    return _profileId && String(profile.profileId) === String(_profileId)
  }

  return {
    roleType,
    isLender,
    userName,
    fullName,
    isCustomer,
    isInvestment,
    profile,

    roleConfig,
    drawOverLimitSetting,
    extraDrawPercentAllowed,

    menuList,
    hasUnmaskPIIAuth,
    isCurrentUser,
    isCurrentProfile,
    userId,
    userInfo,
  }
}
