import { computed, ref } from 'vue'
import { configurationAPI } from '../../api/configuration.js'
import { useStore, registerSharedStore } from './useStore.js'

export const InstitutionConfigCategoryCodeEnum = {
  proposal: 'proposal',
  application: 'digitalApplication',
  underwriting: 'underwriting',
  service: 'servicing',
  cm: 'collateralDataHub',
  sma: 'sma',
  amendment: 'amendment',
  apiIntegration: 'apiIntegration',
}

const getListInstitutionLevelConfigs = (store, stage, params) => {
  return configurationAPI
    .listInstitutionLevelConfigs(stage, params)
    .then((res) => {
      store.commit('shared/SET_INSTITUTION_CONFIG', {
        config: res,
        categoryCode: params.categoryCode,
      })
    })
}

export const useEstimateInstitutionConfigStore = () => {
  const isEstimateInstitutionConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const estimateInstitutionConfig = computed(
    () => sharedState.value.estimateInstitutionConfig
  )

  const getEstimateListInstitutionLevelConfigs = () => {
    isEstimateInstitutionConfigLoading.value = true
    return getListInstitutionLevelConfigs(store, 'estimate').finally(() => {
      isEstimateInstitutionConfigLoading.value = false
    })
  }
  return {
    isEstimateInstitutionConfigLoading,
    estimateInstitutionConfig,
    getEstimateListInstitutionLevelConfigs,
  }
}

export const useProposalInstitutionConfigStore = () => {
  const isProposalInstitutionConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const proposalInstitutionConfig = computed(
    () => sharedState.value.proposalInstitutionConfig
  )

  const getProposalListInstitutionLevelConfigs = () => {
    isProposalInstitutionConfigLoading.value = true
    return getListInstitutionLevelConfigs(store, 'proposal').finally(() => {
      isProposalInstitutionConfigLoading.value = false
    })
  }
  return {
    isProposalInstitutionConfigLoading,
    proposalInstitutionConfig,
    getProposalListInstitutionLevelConfigs,
  }
}

export const useApplicationInstitutionConfigStore = () => {
  const isApplicationInstitutionConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const applicationInstitutionConfig = computed(
    () => sharedState.value.applicationInstitutionConfig
  )

  const getApplicationListInstitutionLevelConfigs = () => {
    isApplicationInstitutionConfigLoading.value = true
    return getListInstitutionLevelConfigs(store, 'application').finally(() => {
      isApplicationInstitutionConfigLoading.value = false
    })
  }
  return {
    isApplicationInstitutionConfigLoading,
    applicationInstitutionConfig,
    getApplicationListInstitutionLevelConfigs,
  }
}

export const useUnderwritingInstitutionConfigStore = () => {
  const isUnderwritingInstitutionConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const underwritingInstitutionConfig = computed(
    () => sharedState.value.underwritingInstitutionConfig
  )

  const getUnderwritingListInstitutionLevelConfigs = () => {
    isUnderwritingInstitutionConfigLoading.value = true
    return getListInstitutionLevelConfigs(store, 'underwriting').finally(() => {
      isUnderwritingInstitutionConfigLoading.value = false
    })
  }
  return {
    isUnderwritingInstitutionConfigLoading,
    underwritingInstitutionConfig,
    getUnderwritingListInstitutionLevelConfigs,
  }
}

export const useServiceInstitutionConfigStore = () => {
  const isServiceInstitutionConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const servicingInstitutionConfig = computed(
    () => sharedState.value.servicingInstitutionConfig
  )

  const collateralDataHubInstitutionConfig = computed(
    () => sharedState.value.collateralDataHubInstitutionConfig
  )

  const smaInstitutionConfig = computed(
    () => sharedState.value.smaInstitutionConfig
  )

  const apiIntegrationInstitutionConfig = computed(
    () => sharedState.value.apiIntegrationInstitutionConfig
  )

  const generalInstitutionConfig = computed(
    () => sharedState.value.generalInstitutionConfig
  )

  const getServiceListInstitutionLevelConfigs = (params) => {
    isServiceInstitutionConfigLoading.value = true
    return getListInstitutionLevelConfigs(store, 'service', params).finally(
      () => {
        isServiceInstitutionConfigLoading.value = false
      }
    )
  }
  return {
    isServiceInstitutionConfigLoading,
    servicingInstitutionConfig,
    collateralDataHubInstitutionConfig,
    smaInstitutionConfig,
    apiIntegrationInstitutionConfig,
    generalInstitutionConfig,
    getServiceListInstitutionLevelConfigs,
  }
}

export const useAmendmentInstitutionConfigStore = () => {
  const isAmendmentInstitutionConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const amendmentInstitutionConfig = computed(
    () => sharedState.value.amendmentInstitutionConfig
  )

  const getAmendmentListInstitutionLevelConfigs = (params) => {
    isAmendmentInstitutionConfigLoading.value = true
    return getListInstitutionLevelConfigs(store, 'amendment', params).finally(
      () => {
        isAmendmentInstitutionConfigLoading.value = false
      }
    )
  }
  return {
    isAmendmentInstitutionConfigLoading,
    amendmentInstitutionConfig,
    getAmendmentListInstitutionLevelConfigs,
  }
}
