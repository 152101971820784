import { http, API_VERSION } from '@/shared/api/http'

export const signatureAPI = {
  /**
   * Prepares signatures for a given stage with the specified payload.
   *
   * @param {string} stage - The stage of the business process (e.g., ORIGINATION, SERVICE, AMENDMENT).
   * @param {Object} params - The payload for signature preparation.
   * @param {number} params.applicationId - The ID of the application.
   * @param {number} params.underwritingId - The ID of the underwriting.
   * @param {boolean} params.isLoanFacilitySetLine - Indicates if the loan facility set line is included.
   * @param {boolean} params.isSignatureHighLight - Indicates if the signature highlight is enabled.
   * @returns {Promise<Object>} The prepared signature records.
   * @apiCode {stage}.signatures.preparations.create
   */
  prepareSignatures(stage, params) {
    return http.post(`${API_VERSION}/${stage}/signatures/preparations`, params)
  },

  /**
   * Recover deleted document sign list by stage and document sign IDs.
   * @param {string} stage - The stage of the document sign list to recover.
   * @param {RecoverDocSignListReqDTO} recoverDocSignListReqDTO - The request payload to recover document sign list.
   * @returns {Promise<Object>} - A promise that resolves when the document sign list is recovered.
   * @typedef {Object} RecoverDocSignListReqDTO
   * @property {Array.<number>} documentSignIds - An array of document sign IDs to recover.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/document/signature/RecoverDocSignList.md
   * @APICode {stage}.signatures.documents.recovers.post
   */
  createRecoverDocSignList(stage, recoverDocSignListReqDTO) {
    return http.post(`${API_VERSION}/${stage}/signatures/documents/recovers`, recoverDocSignListReqDTO)
  },

  /**
   * Submits signatures for a business object at a given stage.
   * @param {String} stage - The stage of the process.
   * @param {SignatureSubmissionRequest} signatureSubmissionRequest - The request payload containing signature submission details.
   * @returns {Promise<SignatureSubmissionResponse>} - A promise that resolves when the submission is successful.
   * @APICode {stage}.signatures.submissions.create
   */
  createSignaturesSubmission(stage, params) {
    return http.post(`${API_VERSION}/${stage}/signatures/submissions`, params)
  },
  /**
   * Queries the document sign list view based on the stage and other optional parameters.
   * @param {string} stage - The stage of the process.
   * @param {Object} [params] - The parameters for the request.
   * @param {number} [params.applicationId] - The ID of the application. if service stage, it's loanFacilityId.
   * @param {number} [params.underwritingId] - The ID of the underwriting. if service stage, it's loanFacilityId.
   * @param {boolean} [params.isLoanFacilitySetLine] - Indicates if the loan facility set line.
   * @param {boolean} [params.isEffective] - Indicates if it is effective.
   * @param {boolean} [params.isPreview] - Indicates if it is a preview.
   * @returns {Promise<DocSignListRespDTO>} - A promise that resolves with the document sign list view.
   * @typedef {Object} DocSignListRespDTO
   * @property {string} subject - The subject of the document.
   * @property {string} status - The status of the document.
   * @property {string} createdAt - The creation timestamp of the document.
   * @property {string} lastChangedAt - The last changed timestamp of the document.
   * @property {string} expiredAt - The expiration timestamp of the document.
   * @property {null|Object} signingFlow - The signing flow information.
   * @property {string[]} signMethod - The sign methods available.
   * @property {DocSignDocumentDTO[]} esignDocuments - The list of e-sign documents.
   * @property {DocSignDocumentDTO[]} wsignDocuments - The list of wet sign documents.
   * @property {DocSignSignerDTO[]} esignSigners - The list of e-sign signers.
   * @property {DocSignDocumentDTO[]} preSignDocuments - The list of pre-sign documents.
   * @APICode {stage}.signatures.list
   */
  getDocSignListView(stage, params = {}) {
    return http.get(`${API_VERSION}/${stage}/signatures`, params)
  },

  /**
   * Downloads signature template files based on business object ID and type.
   * @param {string} stage - The stage of the business.
   * @param {DownloadSignatureTemplateParams} params - The parameters for downloading the template.
   * @returns {Promise<SignatureTemplateDownloadRespDTO>} A promise that resolves with the download token.
   * @APICode {stage}.signatures.templates.get
   */
  getSignatureTemplates(stage, params) {
    return http.get(`${API_VERSION}/${stage}/signatures/templates`, params)
  },

  /**
   * Uploads a Wet-Sign document and retrieves an upload token.
   * @param {string} stage - The stage of the document in the process.
   * @param {WetSignUploadTokenRequest} requestPayload - The payload for the upload token request.
   * @returns {Promise<WetSignUploadTokenResponse>} - A promise that resolves with the upload token response.
   * @typedef {Object} WetSignUploadTokenRequest
   * @property {number} [applicationId] - The application ID associated with the document.
   * @property {number} [underwritingId] - The underwriting ID associated with the document.
   * @property {boolean} isLoanFacilitySetLine - Indicates if the loan facility set line is true.
   * @property {number} docSignId - The ID of the document signature.
   * @property {string} fileName - The name of the file being uploaded.
   * @property {string} mimeType - The MIME type of the file.
   * @typedef {Object} WetSignUploadTokenResponse
   * @property {string} token - The upload token.
   * @property {number} fileStorageId - The ID of the file storage.
   * @APICode {stage}.signatures.wsigns.documents.create
   */
  createWetSignUploadToken(stage, params) {
    return http.post(
      `${API_VERSION}/${stage}/signatures/wsigns/documents`,
      params
    )
  },

  /**
   * Uncomplete a W-sign document by removing its association with any record and updating its status.
   * @param {string} stage - The stage of the W-sign document.
   * @param {number} signId - The ID of the W-sign document to be uncompleted.
   * @returns {Promise<Object>} A promise that resolves when the W-sign document is successfully uncompleted.
   * @APICode {stage}.signatures.wsigns.uncompleteds.delete
   */
  deleteWSignUncompleted(stage, signId) {
    return http.delete(
      `${API_VERSION}/${stage}/signatures/${signId}/wsigns/uncompleteds`
    )
  },

  /**
   * Replace the Multi-Factor Authentication (MFA) method for a signer.
   * @param {string} stage - The stage of the process.
   * @param {string} signerId - The ID of the signer whose MFA method is being updated.
   * @param {UpdateMfaMethodRequestDTO} params - The request payload containing the MFA method details.
   * @returns {Promise} - A promise that resolves when the MFA method has been updated.
   * @typedef {Object} UpdateMfaMethodRequestDTO
   * @property {number} applicationId - The application ID related to the signer.
   * @property {number} underwritingId - The underwriting ID related to the signer.
   * @property {boolean} isLoanFacilitySetLine - Indicates whether the loan facility set line is applicable.
   * @property {string} mfaMethod - The new MFA method to be set (SMS or PHONE).
   * @APICode {stage}.signatures.signers.mfa-methods.update
   */
  replaceSignerMfaMethod(stage, signerId, params) {
    return http.put(
      `${API_VERSION}/${stage}/signatures/signers/${signerId}/mfa-methods`,
      params
    )
  },

  /**
   * Resends signatures for documents that have not been signed yet or have encountered issues during the signing process.
   * @param {string} stage - The stage of the document.
   * @param {SignatureRedeliveryRequest} params - The payload containing signature redelivery information.
   * @returns {Promise} - A promise that resolves when the signature redelivery is processed.
   * @APICode ORIGINATION.signatures.redeliveries.create, SERVICE.signatures.redeliveries.create, AMENDMENT.signatures.redeliveries.create
   */
  createSignatureRedelivery(stage, params) {
    return http.post(`${API_VERSION}/${stage}/signatures/redeliveries`, params)
  },

  /**
   * Refreshes the signature status based on the provided business object ID and type.
   * @param {string} stage - The stage of the business.
   * @param {Object} params - The parameters for refreshing the signature status.
   * @param {number} params.applicationId - The ID of the application (required for origination or amendment stage).
   * @param {number} [params.underwritingId] - The ID of the underwriting (required for underwriting stage).
   * @param {boolean} [params.isLoanFacilitySetLine] - Indicates if the loan facility set line.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   * @APICode {stage}.signatures.status.refreshes.get
   *
   * @typedef {Object} RefreshSignatureParams
   * @property {number} applicationId
   * @property {number} [underwritingId]
   * @property {boolean} [isLoanFacilitySetLine]
   */
  refreshSignatureStatus(stage, params) {
    return http.get(
      `${API_VERSION}/${stage}/signatures/status/refreshes`,
      params
    )
  },
  /**
   * Query docSign operate history list by stage, applicationId, underwritingId, amendmentId, and isLoanFacilitySetLine parameters.
   * @param {Object} params - The parameters for the request.
   * @param {string} params.stage - The stage of the business (ORIGINATION, SERVICE, AMENDMENT).
   * @param {number} [params.applicationId] - The ID of the application (required for origination and amendment stages).
   * @param {number} [params.underwritingId] - The ID of the underwriting (required for underwriting stage).
   * @param {boolean} [params.isLoanFacilitySetLine] - Flag to determine if the loan facility set line is included.
   * @returns {Promise<DocSignOperateHistoryRespDTO>} - A promise that resolves with the document sign operate history data.
   * @APICode {stage}.signatures.historys.list
   */
  getDocSignOperateHistory(stage, params) {
    return http.get(`${API_VERSION}/${stage}/signatures/historys`, params)
  },

  /**
   * Switches the electronic signature (Esign) record to wet signature (Wsign).
   * @param {string} stage - The stage of the business.
   * @param {SwitchSignatureRequestDTO} data - The request payload to switch signature.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   * @typedef {Object} SwitchSignatureRequestDTO
   * @property {number} [applicationId] - The ID of the application.
   * @property {number} [underwritingId] - The ID of the underwriting.
   * @property {boolean} [isLoanFacilitySetLine] - Indicates if the loan facility set line is true.
   * @APICode {stage}.signatures.switch-to-wsigns.update
   */
  updateSwitchSignatureToWsign(stage, params) {
    return http.patch(
      `${API_VERSION}/${stage}/signatures/switch-to-wsigns`,
      params
    )
  },

  /**
   * Updates the signer's email and phone information.
   * @param {string} stage - The stage of the signature process.
   * @param {number} singerId - The ID of the signer.
   * @param {UpdateSignerInfoParams} params - The parameters for updating the signer information.
   * @returns {Promise<Object>} A promise that resolves when the signer information is updated.
   * @typedef {Object} UpdateSignerInfoParams
   * @property {string} email - The new email of the signer.
   * @property {string} phone - The new phone number of the signer.
   * @APICode {stage}.signature.signers.update
   */
  updateSignerInformation(stage, singerId, params) {
    return http.patch(
      `${API_VERSION}/${stage}/signatures/signers/${singerId}`,
      params
    )
  },

  /**
   * Update the expiration date of signatures associated with a business object.
   * @param {string} stage - The stage of the business.
   * @param {UpdateSignatureExpirationRequestDTO} params - The request data for updating signature expiration.
   * @returns {Promise<Object>} - A promise that resolves with the response of the update operation.
   * @typedef {Object} UpdateSignatureExpirationRequestDTO
   * @property {number} [applicationId] - The application ID related to the signatures.
   * @property {number} [underwritingId] - The underwriting ID related to the signatures.
   * @property {boolean} [isLoanFacilitySetLine] - Indicates if the loan facility set line is present.
   * @property {number} expireAfter - The expiration time in seconds after which the signature should expire.
   * @APICode {stage}.signatures.expiration-dates.update
   */
  updateSignatureExpiration(stage, params) {
    return http.patch(
      `${API_VERSION}/${stage}/signatures/expiration-dates`,
      params
    )
  },

  /**
   * Deletes a document in the pre-sign stage.
   * @param {string} stage - The stage of the document.
   * @param {number} signId - The ID of the signature associated with the document.
   * @returns {Promise} - A promise that resolves when the document is deleted.
   * @APICode {stage}.signatures.documents.delete
   */
  deleteSignaturesDocument(stage, signId) {
    return http.delete(`${API_VERSION}/${stage}/signatures/documents/${signId}`)
  },

  /**
   * Submit signatures for one step sign.
   * @param {string} stage - The stage of the process.
   * @param {SignatureSubmissionReqDTO} payload - The payload containing signature information.
   * @returns {Promise<Object>} A promise that resolves upon successful submission.
   * @typedef {Object} SignatureSubmissionReqDTO
   * @property {number} applicationId - The ID of the application.
   * @property {number} underwritingId - The ID of the underwriting.
   * @property {boolean} isLoanFacilityBooking - Indicates if the booking is for a loan facility.
   * @property {string} signingMethod - The method of signing.
   * @APICode {stage}.signatures.one-step-signs.create
   */
  createOneStepSign(stage, payload) {
    return http.post(
      `${API_VERSION}/${stage}/signatures/one-step-signs`,
      payload
    )
  },

  /**
   * Retrieve the status of a signing process at a specific stage, indicating the progress.
   * @param {GetSignaturesProgressesParams} params - The parameters for the API call.
   * @returns {Promise<SignStatusRespDTO>} A promise that resolves with the status of the signing process.
   * @APICode {stage}.signatures.progresses.get
   */
  getSignaturesProgresses(stage, params) {
    return http.get(`${API_VERSION}/${stage}/signatures/progresses`, params)
  },
}
