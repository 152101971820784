export const calc = {
  division(arg1, arg2) {
    let result = ''
    if (
      arg1 === null ||
      arg1 === undefined ||
      arg2 === null ||
      arg2 === undefined
    ) {
      result = null
    } else {
      let t1 = 0
      let t2 = 0
      let r1
      let r2
      try {
        t1 = arg1.toString().split('.')[1].length
      } catch (e) {
        t1 = 0
      }
      try {
        t2 = arg2.toString().split('.')[1].length
      } catch (e) {
        t2 = 0
      }
      r1 = Number.parseInt(arg1.toString().replace('.', ''))
      r2 = Number.parseInt(arg2.toString().replace('.', ''))
      result = calc.multiplication(r1 / r2, Math.pow(10, t2 - t1))
    }
    return result
  },
  multiplication(arg1, arg2) {
    let result = ''
    if (
      arg1 === null ||
      arg1 === undefined ||
      arg2 === null ||
      arg2 === undefined
    ) {
      result = null
    } else if (arg1 !== '') {
      let m = 0
      const s1 = arg1.toString()
      const s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) {
        m += 0
      }
      try {
        m += s2.split('.')[1].length
      } catch (e) {
        m += 0
      }
      result =
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
    }
    return result
  },
  addition(arg1, arg2) {
    let r1, r2, m
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    return Math.round(arg1 * m + arg2 * m) / m
  },
  subtraction(arg1, arg2) {
    let r1, r2, m, n
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    n = r1 >= r2 ? r1 : r2
    return ((arg1 * m - arg2 * m) / m).toFixed(n)
  },
}
