import { ref } from 'vue'
import { underwritingAPI } from '@/shared/api'

/**
 * @typedef {Object} Label
 * @property {string} labelCode - The code of the label.
 * @property {string} labelName - The name of the label.
 */

/**
 * @typedef {Object} UnderwritingItem
 * @property {string} applicationId - The application ID.
 * @property {string} [callCode] - Call code.
 * @property {string} distributionChannelInstitutionId - Institution ID of the distribution channel.
 * // ... (Other properties of UnderwritingItem are omitted for brevity)
 */

/**
 * @typedef {Object} UnderwritingList
 * @property {Array<UnderwritingItem>} items - List of underwriting tasks.
 * @property {number} pageNumber - The current page number.
 * @property {number} pageSize - The size of the page.
 * @property {number} totalItems - The total number of items.
 * @property {number} totalPages - The total number of pages.
 */

/**
 * @typedef {Object} UnderwritingFilterCriteria
 * @property {boolean} [unassignedTasks] - To query data that is not assigned.
 * @property {boolean} [individualTasks] - To query data that is assigned to individuals.
 * @property {boolean} [allWorkingGroupTasks] - To query data that is assigned to groups.
 * // ... (Other properties of UnderwritingFilterCriteria are omitted for brevity)
 */

/**
 * Composition API to interact with underwriting data.
 *
 * @returns {Object} Object containing attributes and methods for underwriting interaction.
 */
export const useUnderwritingData = (stage) => {
  const isUnderwritingLoading = ref(false)
  const underwritingList = ref({})
  const regBTrackerData = ref({})
  const myTasksData = ref([])

  /**
   * Retrieves a list of underwriting tasks based on the provided filter criteria.
   *
   * @param {UnderwritingFilterCriteria} [criteria] - The filter criteria for underwriting tasks.
   */
  const getUnderwritingList = async (criteria = {}) => {
    isUnderwritingLoading.value = true
    try {
      underwritingList.value = await underwritingAPI.getUnderwritingList(
        criteria
      )
    } finally {
      isUnderwritingLoading.value = false
    }
  }

  const getRegBTrackerData = async (criteria = {}) => {
    isUnderwritingLoading.value = true
    try {
      regBTrackerData.value = await underwritingAPI.getRegBTrackerData(criteria)
    } finally {
      isUnderwritingLoading.value = false
    }
  }

  const getUnderwritingLoanTypeDetail = async (criteria = {}) => {
    isUnderwritingLoading.value = true
    try {
      return await underwritingAPI.getUnderwritingLoanTypeDetail(criteria)
    } finally {
      isUnderwritingLoading.value = false
    }
  }

  const getUnderwritingCount = async (criteria = {}) => {
    isUnderwritingLoading.value = true
    try {
      return await underwritingAPI.getUnderwritingCount(criteria)
    } finally {
      isUnderwritingLoading.value = false
    }
  }

  const getMyTasksData = async (criteria = {}) => {
    isUnderwritingLoading.value = true
    try {
      myTasksData.value = await underwritingAPI.getMyTasksData(criteria)
    } finally {
      isUnderwritingLoading.value = false
    }
  }

  const isGetUnderwritingSummaryLoading = ref(false)
  const underwritingSummary = ref({})

  /**
   * Fetches the summary details of an underwriting process by stage and ID.
   *
   * @param {String} stage - The stage of the underwriting process.
   * @param {Number} underwritingId - The ID of the underwriting process.
   * @returns {Promise<UnderwritingSummary>} A promise that resolves with the summary details of the underwriting process.
   */
  const getUnderwritingSummary = async (underwritingId, _stage = stage) => {
    isGetUnderwritingSummaryLoading.value = true
    try {
      underwritingSummary.value = await underwritingAPI.getUnderwritingSummary(
        _stage,
        underwritingId
      )
    } finally {
      isGetUnderwritingSummaryLoading.value = false
    }
  }

  const isUnderwritingResetLoading = ref(false)
  const replaceUnderwritingReset = async (underwritingId, _stage = stage) => {
    isUnderwritingResetLoading.value = true
    try {
      await underwritingAPI.replaceUnderwritingReset(_stage, underwritingId)
    } finally {
      isUnderwritingResetLoading.value = false
    }
  }

  /**
   * Updates the underwriting page review status.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} applicationId - The ID of the application.
   * @param {PageReviewStatusParams} params - The parameters for updating the page review status.
   * @property {string} stage - The current stage in the underwriting process.
   * @property {number} applicationId - The unique identifier for the application.
   * @property {PageReviewStatusParams} params - The review status parameters.
   */

  const isUnderwritingPageReviewStatusLoading = ref(false)
  const updateUnderwritingPageReviewStatus = async (
    applicationId,
    params,
    _stage = stage
  ) => {
    isUnderwritingPageReviewStatusLoading.value = true
    try {
      await underwritingAPI.updateUnderwritingPageReviewStatus(
        _stage,
        applicationId,
        params
      )
    } finally {
      isUnderwritingPageReviewStatusLoading.value = false
    }
  }

  const isRiskRateOptionsLoading = ref(false)
  const riskRateOptions = ref({})

  /**
   * Retrieves various risk rate options based on the underwriting application ID.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting application.
   * @returns {Promise<UnderwritingRiskRateOptions>} A promise that resolves with the risk rate options.
   *
   * @typedef {Object} UnderwritingRiskRateOptions
   * @property {String[]} loanRiskRatingOptions - Array of loan risk rating options.
   * @property {String[]} borrowerRiskRatingOptions - Array of borrower risk rating options.
   * @property {Boolean} brrDisplayAllEntity - Indicates if all entities are displayed for BRR.
   * @property {Boolean} supportBrr - Indicates if BRR is supported.
   * @deprecated please use general lender institution config instead of risk rate options
   */
  const getRiskRateOptions = async (underwritingId) => {
    isRiskRateOptionsLoading.value = true
    try {
      riskRateOptions.value = await underwritingAPI.getRiskRateOptions(
        stage,
        underwritingId
      )
    } finally {
      isRiskRateOptionsLoading.value = false
    }
  }
  /**
   * Updates the underwriting assignee.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {UpdateAssigneeParams} params - The parameters to update the assignee.
   */
  const isUnderwritingAssigneeLoading = ref(false)

  const updateUnderwritingAssignee = async (
    underwritingId,
    params,
    _stage = stage
  ) => {
    isUnderwritingAssigneeLoading.value = true
    try {
      await underwritingAPI.updateUnderwritingAssignee(
        _stage,
        underwritingId,
        params
      )
    } finally {
      isUnderwritingAssigneeLoading.value = false
    }
  }

  /**
   * Updates the final approval process for an underwriting.
   *
   * @param {string} underwritingId - The ID of the underwriting.
   */
  const replaceFinalApprovals = async (underwritingId) => {
    isUnderwritingLoading.value = true
    try {
      return await underwritingAPI.replaceFinalApprovals(stage, underwritingId)
    } finally {
      isUnderwritingLoading.value = false
    }
  }

  /**
   * Creates a credit approval.
   *
   * @param {CreditApproval} params - The parameters for creating the credit approval.
   * @property {string} stage - The stage of the credit approval process.
   * @property {number} underwritingId - The ID of the underwriting associated with the credit approval.
   */
  const isCreditApprovalLoading = ref(false)
  const createCreditApproval = async (params) => {
    isCreditApprovalLoading.value = true
    try {
      await underwritingAPI.createCreditApproval(stage, params)
    } finally {
      isCreditApprovalLoading.value = false
    }
  }

  /**
   * Updates the decline status of an underwriting.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} underwritingId - The ID of the underwriting.
   * @param {UpdateDeclineStatusParams} params - The parameters for the decline status update.
   * @property {string} stage - The current stage of the underwriting process.
   * @property {number} underwritingId - The unique identifier for the underwriting.
   * @property {UpdateDeclineStatusParams} params - Parameters for updating the decline status.
   */
  const isDeclineStatusLoading = ref(false)
  const updateDeclineStatus = async (stage, underwritingId, params) => {
    isDeclineStatusLoading.value = true
    try {
      await underwritingAPI.updateDeclineStatus(stage, underwritingId, params)
    } finally {
      isDeclineStatusLoading.value = false
    }
  }

  const isUnderwritingMarginUpdating = ref(false)
  const updateUnderwritingMargin = async (applicationId, payload) => {
    isUnderwritingMarginUpdating.value = true
    try {
      return await underwritingAPI.updateUnderwritingMargin(
        stage,
        applicationId,
        payload
      )
    } finally {
      isUnderwritingMarginUpdating.value = false
    }
  }
  /**
   * Creates a decline preview for an underwriting.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} underwritingId - The ID of the underwriting.
   * @param {DeclinePreviewPayload} payload - The payload containing the decline information.
   * @property {string} stage - The current stage in the underwriting process.
   * @property {number} underwritingId - The unique identifier for the underwriting.
   * @property {DeclinePreviewPayload} payload - The information required to generate a decline preview.
   */
  const isDeclinePreviewLoading = ref(false)
  const declinePreview = ref({})
  const createDeclinePreview = async (
    underwritingId,
    payload,
    _stage = stage
  ) => {
    isDeclinePreviewLoading.value = true
    try {
      declinePreview.value = await underwritingAPI.createDeclinePreview(
        _stage,
        underwritingId,
        payload
      )
    } finally {
      isDeclinePreviewLoading.value = false
    }
  }

  /**
   * Retrieves the available operations for a given underwriting based on its status and stage.
   *
   * @param {Object} params - The parameters to retrieve available operations.
   * @param {string} params.stage - The stage of the underwriting.
   * @param {string} params.underwritingId - The ID of the underwriting.
   * @param {string} params.pageCode - The code of the page.
   * @returns {Promise<void>} A promise that resolves when the operations are fetched.
   */

  const isUnderwritingOperationsLoading = ref(false)
  const underwritingOperations = ref([])
  const getAvailableOperations = async (underwritingId, params, _stage) => {
    isUnderwritingOperationsLoading.value = true
    try {
      underwritingOperations.value =
        await underwritingAPI.getAvailableOperations(
          _stage || stage,
          underwritingId,
          params
        )
    } finally {
      isUnderwritingOperationsLoading.value = false
    }
  }

  const isGetListCovenantLoading = ref(false)
  const isUpdateCovenantLoading = ref(false)
  const covenantList = ref([])

  /**
   * Fetches a list of all covenants associated with an underwriting stage and ID.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting to list covenants for.
   * @property {string} stage - The stage of the underwriting process.
   * @property {string} underwritingId - The ID of the underwriting.
   */
  const getListCovenant = async (stage, underwritingId) => {
    isGetListCovenantLoading.value = true
    try {
      const res = await underwritingAPI.getListCovenant(stage, underwritingId)
      const { covenants } = res || {}
      covenantList.value = covenants
    } finally {
      isGetListCovenantLoading.value = false
    }
  }

  /**
   * Replaces existing covenants for a given underwriting stage and ID with new ones.
   *
   * @param {string} stage - The underwriting stage.
   * @param {string} underwritingId - The underwriting ID.
   * @param {UpdateCovenantRequest} updateCovenantRequest - The request payload containing new covenants.
   * @property {string} stage - The underwriting stage.
   * @property {string} underwritingId - The underwriting ID.
   * @property {UpdateCovenantRequest} updateCovenantRequest - The request payload containing new covenants.
   */
  const updateCovenant = async (
    stage,
    underwritingId,
    updateCovenantRequest
  ) => {
    isUpdateCovenantLoading.value = true
    try {
      return underwritingAPI.updateCovenant(
        stage,
        underwritingId,
        updateCovenantRequest
      )
    } finally {
      isUpdateCovenantLoading.value = false
    }
  }

  const isRiskPointsLoading = ref(false)
  const riskPoints = ref({})

  /**
   * Retrieves risk points for underwritings in a finished state, filtered by the page code.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @property {string} stage - The stage of the underwriting process.
   * @param {number} underwritingId - The ID of the underwriting.
   * @property {number} underwritingId - The ID of the underwriting.
   * @param {Object} params - The query parameters for the request.
   * @property {string} params.pageCode - The code of the page to filter the risk points.
   * @returns {Promise<CommonResponsePage>} - A promise that resolves with the risk points data.
   */
  const getRiskPoints = async (stage, underwritingId, params) => {
    isRiskPointsLoading.value = true
    try {
      riskPoints.value = await riskPointsAPI.getRiskPoints(
        stage,
        underwritingId,
        params
      )
    } finally {
      isRiskPointsLoading.value = false
    }
  }

  const isGenerateLoanModPDFLoading = ref(false)
  const generateLoanModPDF = async (underwritingId) => {
    isGenerateLoanModPDFLoading.value = true
    try {
      return await underwritingAPI.getLoanModificationsPDF(
        stage,
        underwritingId
      )
    } finally {
      isGenerateLoanModPDFLoading.value = false
    }
  }

  return {
    isGenerateLoanModPDFLoading,
    generateLoanModPDF,

    isUnderwritingLoading,
    underwritingList,
    getUnderwritingList,
    regBTrackerData,
    getRegBTrackerData,
    getUnderwritingLoanTypeDetail,
    getUnderwritingCount,
    myTasksData,
    getMyTasksData,

    getUnderwritingSummary,
    isGetUnderwritingSummaryLoading,
    underwritingSummary,

    isUnderwritingResetLoading,
    replaceUnderwritingReset,

    isUnderwritingPageReviewStatusLoading,
    updateUnderwritingPageReviewStatus,

    isRiskRateOptionsLoading,
    riskRateOptions,
    getRiskRateOptions,

    updateUnderwritingAssignee,
    isUnderwritingAssigneeLoading,

    replaceFinalApprovals,

    createCreditApproval,
    isCreditApprovalLoading,

    updateDeclineStatus,
    isDeclineStatusLoading,

    updateUnderwritingMargin,
    createDeclinePreview,
    declinePreview,
    isDeclinePreviewLoading,

    isUnderwritingOperationsLoading,
    underwritingOperations,
    getAvailableOperations,

    isGetListCovenantLoading,
    covenantList,
    getListCovenant,

    isUpdateCovenantLoading,
    updateCovenant,

    isRiskPointsLoading,
    riskPoints,
    getRiskPoints,
  }
}
