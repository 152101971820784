import { ref } from 'vue'
import { PartyTopFieldsModuleEnum } from './usePartyFieldModuleKeys'
import { useConstraints } from '@/shared/composables/useConstraint'
import { APIStageEnum } from '@/shared/utils'

const { newViewConstraint } = useConstraints()

function newPartyOverviewConstraint(constraint) {
  // user Actions include activity message / invite / disable / unlock  .etc
  return {
    APIStage: constraint?.APIStage ?? APIStageEnum.service,
    totalLoanFacilityId: constraint?.totalLoanFacilityId,
    isAmendmentRequest: constraint?.isAmendmentRequest,
    totalLoanFacilityImportUI: constraint?.totalLoanFacilityImportUI ?? false,
    topFieldModule:
      constraint?.topFieldModule ?? PartyTopFieldsModuleEnum.service,
    parties: newViewConstraint(constraint?.parties),
    roleInformationColumn: newViewConstraint(constraint?.roleInformationColumn),
    viewFullSSNTaxID: newViewConstraint(constraint?.viewFullSSNTaxID),
    authentication: newViewConstraint(constraint?.authentication),
    addParty: newViewConstraint(constraint?.addParty),
    refreshParties: newViewConstraint(constraint?.refreshParties),
    editParty: newViewConstraint(constraint?.editParty),
    showAccessCode: newViewConstraint(constraint?.showAccessCode),
    editUser: newViewConstraint(constraint?.editUser),
    deleteParty: newViewConstraint(constraint?.deleteParty),
    searchParty: newViewConstraint(constraint?.searchParty),
    assignRoleAction: newViewConstraint(constraint?.assignRoleAction),
    userActions: newViewConstraint(constraint?.userActions),
    setDeceasedIndicator: newViewConstraint(constraint?.setDeceasedIndicator),
    addRemoveWithdrawalOverride: newViewConstraint(
      constraint?.addRemoveWithdrawalOverride
    ),
    removeAutomatedDrawRestriction: newViewConstraint(
      constraint?.removeAutomatedDrawRestriction
    ),
    unassignedTab: newViewConstraint(constraint?.unassignedTab),
    primaryContact: newViewConstraint(constraint?.primaryContact),
    primaryBorrower: newViewConstraint(constraint?.primaryBorrower),
    otherAffiliatedParties: newViewConstraint(
      constraint?.otherAffiliatedParties
    ),
    facilityViewTab: newViewConstraint(constraint?.facilityViewTab),
    isShowPrimaryIcon: constraint?.isShowPrimaryIcon ?? false,
    get showSavePartyButton() {
      return this.addParty.visible || this.editParty.visible
    },
  }
}

const constraint = ref(newPartyOverviewConstraint())

export const usePartyOverviewConstraint = () => {
  return {
    constraint,
    newPartyOverviewConstraint,
  }
}
