import { API_VERSION, http } from '@/shared/api/http.js'

/**
 * @typedef {Object} StatusCounts
 * @property {number} pendingSubmission - The count of applications pending submission.
 * @property {number} pendingSignature - The count of applications pending signature.
 * @property {number} underwriting - The count of applications under underwriting.
 * @property {number} pendingClosingSignature - The count of applications pending closing signature.
 * @property {number} finalReview - The count of applications in final review.
 * @property {number} completed - The count of completed applications.
 * @property {number} declined - The count of declined applications.
 */

/**
 * @typedef {Object} ApplicationStatisticsResp
 * @property {StatusCounts} statusCounts - The counts of applications grouped by their current status.
 */

export const applicationAPI = {
  /**
   * Retrieves the count of application requests grouped by their current status.
   * @param {string} stage - The stage of the application process.
   * @returns {Promise<ApplicationStatisticsResp>} A promise that resolves with the application statistics data.
   * @APICode {stage}.applications.statistics.get
   */
  getApplicationStatistics(stage) {
    return http.get(`${API_VERSION}/${stage}/applications/statistics`)
  },

  /**
   * Retrieve a paginated list of application requests based on the given filters, sorting, and pagination parameters.
   * @param {string} stage - The application stage.application requests.
   * @APICode {stage}.applications.list
   */
  listApplicationRequest(stage, filters = {}) {
    return http.get(`${API_VERSION}/${stage}/applications`, filters)
  },

  /**
   * List application request export data.
   * @param {string} stage - The stage of the application.
   * @APICode {stage}.applications.exports.get
   */
  listApplicationRequestExport(stage, params = {}) {
    return http.download(`${API_VERSION}/${stage}/applications/exports`, params)
  },

  /**
   * Retrieves the details of a specific application by its unique identifier.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The unique identifier of the application.
   * @APICode {stage}.applications.get
   */
  getApplicationDetails(stage, applicationId) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}`)
  },

  /**
   * Get application margin details by applicationId.
   * @param {string} applicationId - The ID of the application.
   * @param {string} stage - The stage of the application.
   * @returns {Promise<ApplicationMarginDetails>} - A promise that resolves with the application margin details.
   * @typedef {Object} ApplicationMarginDetails
   * @property {string} applicationId - The ID of the application.
   * @property {string} loanFacilityId - The ID of the loan facility.
   * @property {string} annualPercentRate - The annual percentage rate.
   * @property {string} originalMarginPercent - The original margin percentage.
   * @property {string} originalFloorRatePercent - The original floor rate percentage.
   * @property {string} originalCeilingRatePercent - The original ceiling rate percentage.
   * @property {string} originalInterestPercentForDistributionChannel - The original interest percentage for the distribution channel.
   * @property {string} originalInterestPercentForServicer - The original interest percentage for the servicer.
   * @property {string} originalInterestPercentForAdvisor - The original interest percentage for the advisor.
   * @property {string} originalInterestPercentForFirm - The original interest percentage for the firm.
   * @property {string} originalInterestRatePercent - The original interest rate percentage.
   * @property {string} requestMarginPercent - The requested margin percentage.
   * @property {string} requestFloorRatePercent - The requested floor rate percentage.
   * @property {string} requestCeilingRatePercent - The requested ceiling rate percentage.
   * @property {string} requestInterestPercentForDistributionChannel - The requested interest percentage for the distribution channel.
   * @property {string} requestInterestPercentForServicer - The requested interest percentage for the servicer.
   * @property {string} requestInterestPercentForAdvisor - The requested interest percentage for the advisor.
   * @property {string} requestInterestPercentForFirm - The requested interest percentage for the firm.
   * @property {string} requestInterestRatePercent - The requested interest rate percentage.
   * @property {string} overrideMarginPercent - The override margin percentage.
   * @property {string} overrideFloorRatePercent - The override floor rate percentage.
   * @property {string} overrideCeilingRatePercent - The override ceiling rate percentage.
   * @property {string} overrideInterestPercentForDistributionChannel - The override interest percentage for the distribution channel.
   * @property {string} overrideInterestPercentForServicer - The override interest percentage for the servicer.
   * @property {string} overrideInterestPercentForAdvisor - The override interest percentage for the advisor.
   * @property {string} overrideInterestPercentForFirm - The override interest percentage for the firm.
   * @property {string} overrideInterestRatePercent - The override interest rate percentage.
   * @property {number} originalIndexRateId - The original index rate ID.
   * @property {number} requestIndexRateId - The requested index rate ID.
   * @property {number} overrideIndexRateId - The override index rate ID.
   * @property {string} originalIndexRateType - The original index rate type.
   * @property {string} requestIndexRateType - The requested index rate type.
   * @property {string} overrideIndexRateType - The override index rate type.
   * @APICode {stage}.applications.margins.get
   */
  getApplicationMarginDetails(stage, applicationId) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/margin-change-requests`)
  },

  /**
   * Update the margin rates for a specific application.
   * @param {string} stage - The stage of the application.
   * @param {number} applicationId - The ID of the application.
   * @param {UpdateApplicationMarginPayload} payload - The margin change request details.
   * @returns {Promise<Object>} A promise that resolves when the margin rates are updated.
   * @typedef {Object} UpdateApplicationMarginPayload
   * @property {number} marginPercent - The percentage of the margin.
   * @property {number} interestPercentForAdvisor - The interest percentage for the advisor.
   * @property {number} interestPercentForServicer - The interest percentage for the servicer.
   * @property {number} interestPercentForDistributionChannel - The interest percentage for the distribution channel.
   * @property {number} interestPercentForFirm - The interest percentage for the firm.
   * @property {number} floorRate - The minimum rate for the margin.
   * @property {number} ceilingRate - The maximum rate for the margin.
   * @property {number} indexRateId - The ID of the index rate.
   * @property {string} overrideReason - The reason for overriding the current rates.
   * @APICode {stage}.applications.margin-change-requests.update
   */
  updateApplicationMargin(stage, applicationId, payload) {
    return http.patch(`${API_VERSION}/${stage}/applications/${applicationId}/margin-change-requests`, payload)
  },

  /**
   * Get application approved amount detail based on the stage and application ID provided.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The unique identifier for the application.
   * @returns {Promise<ApprovedAmountChangeResponse>} - A promise that resolves with the approved amount change details.
   * @typedef {Object} ApprovedAmountChangeResponse
   * @property {number} applicationId - The unique identifier for the application.
   * @property {number} loanFacilityId - The unique identifier for the loan facility.
   * @property {number} originalApprovedAmount - The original approved amount.
   * @property {number} requestApprovedAmount - The requested approved amount.
   * @property {number} overrideApprovedAmount - The override approved amount.
   * @property {number} finalApprovedAmount - The final approved amount after changes.
   * @APICode {stage}.applications.approved-amount-change-requests.get
   */
  getApplicationApprovedAmountDetail(stage, applicationId) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/approved-amount-change-requests`)
  },

  /**
   * Retrieve the filter ranges for application requests, excluding any working groups with a status of PREPARE.
   * @param {String} stage - The stage of the application.
   * @APICode {stage}.applications.borders.get
   */
  getApplicationBorders(stage) {
    return http.get(`${API_VERSION}/${stage}/applications/borders`)
  },

  /**
   * Assign a requester to an application based on the specified application and user profile ID.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application.
   * @param {AssignApplicationRequesterPayload} payload - The payload containing the request user profile ID.
   * @returns {Promise<Object>} A promise that resolves when the assignment is successful.
   * @typedef {Object} AssignApplicationRequesterPayload
   * @property {string} assignedRoleProfileId - The user profile ID of the requester to be assigned.
   * @APICode {stage}.applications.assignees.update
   */
  updateAssignApplicationRequester(stage, applicationId, payload) {
    return http.patch(`${API_VERSION}/${stage}/applications/${applicationId}/assignees`, payload)
  },

  getDocumentTaskStatus(applicationId) {
    return http.get(`/v5/applications/${applicationId}/sign/sign-task-status`)
  },
  /**
   * Cancel the application by updating the status and associated summaries, and sending an event log.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application to cancel.
   * @returns {Promise<Object>} - A promise that resolves when the application cancellation is processed.
   * @APICode {stage}.applications.cancellations.update
   */
  updateApplicationCancellation(stage, applicationId, data) {
    return http.patch(`${API_VERSION}/${stage}/applications/${applicationId}/cancellations`, data)
  },
  /**
   * Update the application with the given payload data.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The unique identifier of the application.
   * @param {UpdateApplicationPayload} payload - The payload data for updating the application.
   * @returns {Promise<Object>} A promise that resolves with the response of the update operation.
   * @typedef {Object} UpdateApplicationPayload
   * @property {string} loanRiskRating - The loan risk rating of the application.
   * @property {string} purpose - The purpose of the loan.
   * @property {string} purposeNote - Additional notes regarding the purpose.
   * @property {string} typeOfPurpose - The type of loan purpose.
   * @property {string} externalFacilityCid - The external facility customer identifier.
   * @property {string} justification - The justification for the loan.
   * @property {string} loanFacilityType - The type of loan facility.
   * @property {string} netWorthEstimate - The estimated net worth range.
   * @property {number} requestApprovedAmount - The requested amount to be approved.
   * @property {string} yearsWithAdvisor - The number of years with the advisor.
   * @APICode {stage}.applications.update
   */
  updateApplication(stage, applicationId, payload) {
    return http.patch(`${API_VERSION}/${stage}/applications/${applicationId}`, payload)
  },

  /**
   * Submits an application request to update the status of an application to 'underwriting'.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application to submit.
   * @returns {Promise<ApplicationSubmissionResponse>} - A promise that resolves with the underwriting ID.
   * @typedef {Object} ApplicationSubmissionResponse
   * @property {string} underwritingId - The ID of the underwriting summary created.
   * @APICode {stage}.applications.submissions.create
   */
  createApplicationSubmission(stage, applicationId) {
    return http.post(`${API_VERSION}/${stage}/applications/${applicationId}/submissions`)
  },

  /**
   * Verify the margin after collateral changes in response.
   * @param {string} stage - The stage of the application.
   * @param {number} applicationId - The unique identifier of the application.
   * @returns {Promise<MarginCheckResponse>} - A promise that resolves with the margin check response.
   * @typedef {Object} MarginCheckResponse
   * @property {number} newMarginPercent - The new margin percentage.
   * @property {number} newInterestPercentForChannel - The new interest percentage for the channel.
   * @property {number} newInterestPercentForServicer - The new interest percentage for the servicer.
   * @property {number} newInterestPercentForAdvisor - The new interest percentage for the advisor.
   * @property {number} newInterestPercentForFirm - The new interest percentage for the firm.
   * @property {number} newInterestPercentForLender - The new interest percentage for the lender.
   * @APICode {stage}.applications.check-margins.get
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/application/amendment/marginCheck.md
   */
  getCheckMargins(stage, applicationId) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/check-margins`)
  },

  /**
   * Deletes an application by stage and application ID.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The unique identifier of the application.
   * @returns {Promise<void>} - A promise that resolves when the application is deleted.
   * @APICode {stage}.applications.delete
   */
  deleteApplication(stage, applicationId) {
    return http.delete(`${API_VERSION}/${stage}/applications/${applicationId}`)
  },
  /**
   * Get Application's Borrowers Financial Information.
   * @access da.pfs.get
   * @param {string} applicationId - The ID of the application.
   * @returns {Promise} - A Promise that resolves with the borrowers financial information.
   */
  getFinancialInformation(applicationId, module) {
    return http.get(`${API_VERSION}/applications/${applicationId}/core-pfs/financial-info`, null, {
      headers: {
        'SN-REQUEST-MODULE': module,
      },
    })
  },

  /**
   * Saves financial information for a given application.
   * @access da.pfs.save
   * @param {string} applicationId - The ID of the application to save financial information for.
   * @param {string} module - The business module that is saving the financial information.
   * @param {Object} params - The financial information to save.
   * @returns {Promise} A Promise that resolves with the saved financial information.
   */
  saveFinancialInformation(applicationId, module, params) {
    return http.post(`${API_VERSION}/applications/${applicationId}/core-pfs/financial-info`, params, {
      headers: {
        'SN-REQUEST-MODULE': module,
      },
    })
  },

  /**
   * Get financial related questions for a given application ID.
   * @access da.pfs.question-config
   * @param {string} applicationId - The ID of the application to retrieve questions for.
   * @returns {Promise} - A Promise that resolves with the financial information questions.
   */
  getFinancialInformationQuestions(applicationId, module) {
    return http.get(`${API_VERSION}/applications/${applicationId}/core-pfs/questions-and-answers/config`, null, {
      headers: {
        'SN-REQUEST-MODULE': module,
      },
    })
  },
  getApplicationDataFields: function (params) {
    return http.get(`${API_VERSION}/applications/data-point`, params)
  },
  getApplicationCount: function (params) {
    return http.get(`${API_VERSION}/applications/count`, params)
  },
  getApplicationLoanTypeDetail: function (params) {
    return http.get(`${API_VERSION}/applications/dash-board/loanType`, params)
  },

  /**
   * Checks the data readiness of an application by examining various sections of application data and configurations.
   * @param {string} universe - The universe in which the application operates.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The unique identifier of the application.
   * @param {GetDataReadinessParams} [params] - The parameters for the data readiness check.
   * @returns {Promise<DataReadinessResponse[]>} - A promise that resolves with the data readiness information.
   * @typedef {Object} GetDataReadinessParams
   * @property {string[]} [sectionCodes] - The list of section codes to check for data readiness.
   * @typedef {Object} DataReadinessResponse
   * @property {string} workflowKeys - The code identifying the section.
   * @property {boolean} applicationId - Indicates whether the section is active.
   * @APICode {stage}.data-readiness.results.get
   */
  getApplicationDataReadiness(stage, params = {}) {
    return http.get(`${API_VERSION}/${stage}/data-readiness/results`, params)
  },

  /**
   * Retrieves counteroffers for a specific application within a stage.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application.
   * @param {Object} [options] - The request options.
   * @param {string} [options.status] - The status filter for counteroffers.
   * @returns {Promise<CounterOfferResponse>} A promise that resolves with the counteroffer data.
   * @APICode {stage}.applications.counteroffers.get
   */
  getCounterOffers(stage, applicationId, options = {}) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/counteroffers`, options)
  },

  /**
   * Get the incomplete notices for an application in a specific stage.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application.
   * @param {GetIncompleteNoticesParams} [params] - The parameters for the request.
   * @returns {Promise<NoticeResponseDTO>} - A promise that resolves with the incomplete notice data.
   * @typedef {Object} GetIncompleteNoticesParams
   * @property {String} [status] - The status of the incomplete notice.
   *
   * @typedef {Object} NoticeResponseDTO
   * @property {number} noticeOfIncompleteId - The ID of the incomplete notice.
   * @property {string} noticeStatus - The status of the notice.
   * @property {number} updatedAt - The timestamp of the last update.
   * @property {NoticeReasonDTO[]} noticeReasonList - List of reasons for the notice.
   *
   * @typedef {Object} NoticeReasonDTO
   * @property {string} value - The value of the reason.
   * @property {string} label - The label of the reason.
   * @property {string} detail - The detail of the reason.
   * @property {string} message - The message associated with the reason.
   *
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch-DEVPT-15109/api/application/notice/getIncompletedNotice.md
   * @APICode stage.applications.notice-of-incomplete.get
   */
  getIncompleteNotices(stage, applicationId, params = {}) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/incomplete-notices`, params)
  },
  /**
   * Retrieves a list of assisting users related to a specific application.
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application.
   * @returns {Promise<AssistingUser[]>} - A promise that resolves with an array of assisting users.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch-DEVPT-15109/api/application/assisting-user/listApplicationAssistingUser.md
   * @APICode {stage}.applications.assisting-users.get
   */
  getAssistingUsers(stage, applicationId) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/assisting-users`)
  },

  /**
   * Retrieves the available operations for a given application.
   * @param {string} universe - The universe identifier.
   * @param {string} stage - The stage identifier.
   * @param {string} applicationId - The application identifier.
   * @returns {Promise<OperationRespDTO[]>} - A promise that resolves with the list of available operations.
   * @typedef {Object} OperationRespDTO
   * @property {String} code - The operation code.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch-DEVPT-15109/api/application/getApplicationAvailableOperations.md
   * @APICode {stage}.applications.available-operations.get
   */
  getAvailableOperations(stage, applicationId) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/available-operations`)
  },

  /**
   * Retrieves a list of users and their respective working groups that can be assigned to an application process.
   * @param {string} stage - The stage of the application process.
   * @param {number} applicationId - The ID of the application.
   * @param {Object} [options] - The options object.
   * @param {number} [options.roleId] - (Optional) The role ID to filter assignable users.
   * @param {boolean} [options.needUserReturn] - (Optional) Whether user information should be returned.
   * @returns {Promise<AssignableUsersResponse>} A promise that resolves with the list of assignable users and roles.
   * @APICode {stage}.applications.assignable-users.get
   */
  getListAssignableUsers(stage, applicationId, params) {
    return http.get(`${API_VERSION}/${stage}/applications/${applicationId}/assignable-users`, params)
  },

  /**
   * Send a reminder email to an application assignee.
   * @param {string} stage - The stage of the application processing.
   * @param {number} applicationId - The ID of the application.
   * @returns {Promise<void>} A promise that resolves when the email is successfully sent.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch-DEVPT-15109/api/application/email/sendApplicationRemindEmail.md
   * @APICode application.reminders.send
   */
  sendApplicationReminder(stage, applicationId) {
    return http.post(`${API_VERSION}/${stage}/applications/${applicationId}/reminders`)
  },
}
