import Vue, { ref, unref } from 'vue'
import {
  toLower,
  uniqueId,
  has,
  isArray,
  reduce,
  concat,
  cloneDeep,
  map,
  some,
} from 'lodash'
import {
  combinePartyFullName,
  combinePartyShortName,
  combineAddress,
  combinePartyNameWithoutMiddleName,
  getIsShowUserStatus,
  getFirstNonEmptyPropertyValue,
  destructuringParties,
  getPrimaryBorrower,
  getBorrowerList,
  getPartyList,
} from '../../../utils/party-utils'
import { partyAPI } from '@/shared/api'
import {
  ContactPhoneTypeEnum,
  PeopleEntityTypeEnum,
  PeopleRelationShipEnum,
  SessionKeyEnum,
  getSessionStorageItem,
  phoneFieldByTypeMap,
  isEmpty,
  AmendmentPartyStatusEnum,
  isNormalEmpty,
  APIStageEnum,
} from '@/shared/utils'
import OverwritePersonDialog from '@/shared/components/overwrite-person-dialog/overwrite-person-dialog.vue'
import { usePartyOverviewConstraint } from '@/shared/components/party-overview/composable/usePartyOverviewConstraint'

const party = ref({
  ...newBusiness(),
  ...newTrust(),
  ...newIndividual(),
})
const backupParty = ref({})
const partyLoading = ref(false)
async function getSingleParty({
  stage,
  partyId,
  partyType,
  partiesObject,
  loading,
  archiveAmendmentId,
}) {
  partyLoading.value = loading ?? true
  try {
    const res = await partyAPI.getSingleParty(
      stage,
      partyId,
      archiveAmendmentId
    )
    const { normalizeSingleParty } = useParty()
    const partyObject = normalizeSingleParty(res, unref(partiesObject))
    const partyInfo =
      partyObject[toLower(partyType)] ??
      getFirstNonEmptyPropertyValue(partyObject)
    party.value = partyInfo
    handleBackupParty()
    return party.value
  } finally {
    partyLoading.value = false
  }
}

function handleBackupParty() {
  backupParty.value = cloneDeep(party.value)
}

function handleRestoreParty() {
  party.value = cloneDeep(backupParty.value)
}

function newSingleParty(partyType) {
  switch (partyType) {
    case PeopleEntityTypeEnum.individual:
      return newIndividual()
    case PeopleEntityTypeEnum.business:
      return newBusiness()
    case PeopleEntityTypeEnum.trust:
      return newTrust()
    default:
      return newIndividual()
  }
}

function newIndividual() {
  const totalLoanFacilityId = getSessionStorageItem(
    SessionKeyEnum.totalLoanFacilityId
  )
  return {
    totalLoanFacilityId,
    newPartyId: uniqueId('_new_party_'),
    partyId: null,
    parentPartyId: null,
    originalPartyId: null,
    amendmentStatus: null,
    amendmentAction: null,
    externalId: null,
    firstName: null,
    middleName: null,
    lastName: null,
    fullName: null,
    fullNameWithoutMiddleName: null,
    shortName: null,
    shortNameEdited: false,
    email: null,
    suffix: null,
    dateOfBirth: null,
    maritalStatus: null,
    annualIncomeLevel: null,
    homePhone: null,
    cellPhone: null,
    workPhone: null,
    preferredPhone: null,
    socialSecurityNumber: null,
    countryOfCitizenship: null,
    countryOfResidence: null,
    usPermanentResident: false,
    isCreditFrozen: null,
    externalPartyNumber: null,
    mailingSameAsHome: false,
    homeAddress: {
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      city: null,
      state: null,
      province: null,
      postalCode: null,
      country: null,
    },
    mailingAddress: {
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      city: null,
      state: null,
      province: null,
      postalCode: null,
      country: null,
    },
    isLenderEmployee: false,
    isPledgor: false,
    isBorrower: false,
    isGuarantor: false,
    isAuthorizedIndividual: false,
    isTrustee: false,
    isControlPerson: false,
    isAssignRole: false,
    employments: [],
    identifications: [],
    userDefinedFields: [],
    userDefinedFieldsVO: Object.create(null),
    authorizedSigner: Object.create(null),
    isIndividual: true,
    partyType: PeopleEntityTypeEnum.individual,
    entityType: PeopleEntityTypeEnum.individual,
  }
}

function newIdentification(idType = null) {
  return {
    idType,
    idNumber: null,
    issuingState: null,
    issuingAuthority: null,
    issueDate: null,
    expireDate: null,
  }
}
function newEmployment() {
  return {
    employmentStatus: null,
    position: null,
    employerName: null,
    employerAddressLine1: null,
    employerAddressLine2: null,
    employerAddressCity: null,
    employerAddressState: null,
    employerAddressZipCode: null,
    employerAddressProvince: null,
    employerAddressCountry: null,
  }
}

function newBusiness() {
  const totalLoanFacilityId = getSessionStorageItem(
    SessionKeyEnum.totalLoanFacilityId
  )
  return {
    totalLoanFacilityId,
    newPartyId: uniqueId('_new_party_'),
    partyId: null,
    originalPartyId: null,
    amendmentStatus: null,
    amendmentAction: null,
    externalId: null,
    legalName: null,
    fullName: null,
    fullNameWithoutMiddleName: null,
    shortName: null,
    shortNameEdited: false,
    taxId: null,
    mainPhone: null,
    email: null,
    naicsCode: null,
    typeOfBusiness: null,
    countryOfLegalEstablishment: null,
    businessRegistrationType: null,
    grossAnnualRevenue: null,
    sourceOfFunds: null,
    businessFormationDate: null,
    businessFormationState: null,
    isNonprofit: null,
    isOperatingBusiness: null,
    externalPartyNumber: null,
    isPledgor: false,
    isBorrower: false,
    isGuarantor: false,
    isAssignRole: false,
    contactAddress: {
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      city: null,
      state: null,
      province: null,
      postalCode: null,
      country: null,
    },
    userDefinedFields: [],
    userDefinedFieldsVO: Object.create(null),
    authorizedIndividuals: [],
    businessOwnerships: [],
    controlPersons: [],
    isBusiness: true,
    partyType: PeopleEntityTypeEnum.business,
    entityType: PeopleEntityTypeEnum.business,
    phone: null,
    workPhone: null,
  }
}

function newTrust() {
  const totalLoanFacilityId = getSessionStorageItem(
    SessionKeyEnum.totalLoanFacilityId
  )
  return {
    totalLoanFacilityId,
    newPartyId: uniqueId('_new_party_'),
    partyId: null,
    originalPartyId: null,
    externalId: null,
    amendmentStatus: null,
    amendmentAction: null,
    legalName: null,
    shortName: null,
    shortNameEdited: false,
    trustIncorporationState: null,
    trustIncorporationDate: null,
    taxId: null,
    isRevocable: null,
    trusteeAuthority: null,
    isRevocableByOthers: false,
    isTrustAmended: false,
    trustAmendments: [],
    contactAddress: {
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      city: null,
      state: null,
      province: null,
      postalCode: null,
      country: null,
    },
    userDefinedFields: [],
    userDefinedFieldsVO: Object.create(null),
    isTrust: true,
    trustees: [],
    partyType: PeopleEntityTypeEnum.trust,
    entityType: PeopleEntityTypeEnum.trust,
  }
}

function normalizeSingleParty(party, parties) {
  const individual = party?.individual || {}
  party.individual = normalizeIndividualParty(individual, parties)

  const business = party?.business || {}
  party.business = normalizeBusinessParty(business, parties)

  const trust = party?.trust || {}
  party.trust = normalizeTrustParty(trust, parties)

  return party
}

function normalizeIndividualParty(party, parties) {
  const { individuals } = destructuringParties(parties)
  party = mergeObjectValidProperty(newIndividual(), party)
  party.originalExternalId = party.externalId

  party = setBasicPartyInfo(party)
  party = resetAnnualIncomeLevel(party)
  party = setPreferredPhone(party)
  party = setIsPrimaryBorrowerAndContact(party, parties)
  party = setPartyUserDefinedFieldsVO(party)
  party = combineIndividualChild(party, parties)
  party = setPartyInMultiLineAndEmailUpdating(party, parties)
  const partyInList =
    individuals.find((individual) => individual.partyId === party.partyId) || {}
  party = mergeNonExistProperty(party, partyInList)

  party = {
    ...party,
    isShowUserStatus: getIsShowUserStatus(party),
    phone: party.cellPhone || party.homePhone || party.workPhone,
    combineHomeAddress: combineAddress(party.homeAddress),
    combineMailingAddress: combineAddress(party.mailingAddress),
  }

  return party
}

function mergeObjectValidProperty(Object1, Object2) {
  for (const key of Object.keys(Object2)) {
    const isNotEmpty =
      !isEmpty(Object2[key]) || Object2[key] === false || Object2[key] === 0
    if (!has(Object1, key) || isNotEmpty) {
      Object1[key] = Object2[key]
    }
  }
  return Object1
}

function mergeNonExistProperty(Object1, Object2) {
  for (const key of Object.keys(Object2)) {
    if (!has(Object1, key)) {
      Object1[key] = Object2[key]
    }
  }
  return Object1
}

function resetAnnualIncomeLevel(party) {
  const firstEmployment = party?.employments?.[0] || {}
  firstEmployment.annualIncomeLevel = party?.annualIncomeLevel
  if (isArray(party?.employments)) {
    party.employments[0] = firstEmployment
  }
  return party
}

function setBasicPartyInfo(party) {
  party.originalPartyId = party.partyId
  party.shortName ||= combinePartyShortName(party)
  return party
}

function setPreferredPhone(party) {
  if (!party.preferredPhone) {
    if (party.cellPhone) {
      party.preferredPhone = ContactPhoneTypeEnum.cell
    }
    if (party.homePhone) {
      party.preferredPhone = ContactPhoneTypeEnum.home
    }
    if (party.workPhone) {
      party.preferredPhone = ContactPhoneTypeEnum.work
    }
  }
  return party
}

function setIsPrimaryBorrowerAndContact(party, parties) {
  const { partyBusinessRelList } = destructuringParties(parties)
  party.isPrimaryBorrower = partyBusinessRelList.some(
    (rel) => rel.partyId === party.partyId && rel.isPrimaryBorrower
  )
  party.isPrimaryContact = partyBusinessRelList.some(
    (rel) => rel.partyId === party.partyId && rel.isPrimaryContact
  )
  return party
}

function setPartyInMultiLineAndEmailUpdating(party, parties) {
  const { individuals } = destructuringParties(parties)
  const partyInList =
    individuals.find((individual) => individual.partyId === party.partyId) || {}
  party.hasMultipleParty = partyInList?.hasMultipleParty
  party.inUserNameChangeProcess = partyInList?.inUserNameChangeProcess
  return party
}

function setPartyUserDefinedFieldsVO(party) {
  const userDefinedFields = party?.userDefinedFields || []
  party.userDefinedFieldsVO = reduce(
    userDefinedFields,
    (result, field) => {
      result[field.id] = field.value
      return result
    },
    {
      partyId: party.partyId,
    }
  )
  return party
}

function combineIndividualChild(individual, parties) {
  const { individuals } = destructuringParties(parties)

  if (individual?.authorizedSigner?.partyId) {
    const authorizedSignerInList = individuals.find(
      (item) => item?.partyId === individual?.authorizedSigner?.partyId
    )
    individual.authorizedSigner = normalizeIndividualParty(
      {
        ...authorizedSignerInList,
        ...individual?.authorizedSigner,
        parentPartyId: individual.partyId,
      },
      parties
    )
  }

  individual.children = [
    {
      partyId: individual.authorizedSigner?.partyId,
      relationshipType: PeopleRelationShipEnum.authorizedSigner,
    },
  ].filter((child) => child.partyId)

  individual.isCompleted = true
  individual.ownerCompleted = true
  return individual
}

function normalizeBusinessParty(party, parties) {
  const { businesses } = destructuringParties(parties)
  party = mergeObjectValidProperty(newBusiness(), party)

  party = setBasicPartyInfo(party)
  party = setIsPrimaryBorrowerAndContact(party, parties)
  party = setPartyUserDefinedFieldsVO(party)
  party = combineBusinessChildParty(party, parties)
  const partyInList =
    businesses.find((business) => business.partyId === party.partyId) || {}
  party = mergeNonExistProperty(party, partyInList)

  party = {
    ...party,
    phone: party?.homePhone || party?.cellPhone || party?.workPhone,
    combineContactAddress: combineAddress(party.contactAddress),
  }

  return party
}

function combineBusinessChildParty(business, parties) {
  business = combineEntityIndividualChildren({
    entity: business,
    childrenKey: 'authorizedIndividuals',
    parties,
  })

  const businessOwnerships = business?.businessOwnerships || []
  business.businessOwnerships = normalizeOwnership(
    business,
    businessOwnerships,
    parties
  )

  business = combineEntityIndividualChildren({
    entity: business,
    childrenKey: 'controlPersons',
    parties,
  })

  const authorizedIndividuals = business?.authorizedIndividuals || []
  const controlPersons = business?.controlPersons || []
  const relationshipManager = business?.relationshipManager || {}

  business.children = getBusinessChildren({
    authorizedIndividuals,
    businessOwnerships,
    controlPersons,
    relationshipManager: getRelationshipManagerForBusiness(business, parties),
  })

  business.unCompletedSubRoles = [
    {
      relationshipType: PeopleRelationShipEnum.businessAuthorized,
      visible: isEmpty(authorizedIndividuals),
    },
    {
      relationshipType: PeopleRelationShipEnum.businessOwner,
      visible: isEmpty(businessOwnerships),
    },
  ].filter((role) => role.visible)
  business.ownerUnCompletedSubRoles = [
    {
      relationshipType: PeopleRelationShipEnum.businessAuthorized,
      visible: isEmpty(authorizedIndividuals),
    },
  ].filter((role) => role.visible)

  business.isCompleted = isEmpty(business.unCompletedSubRoles)
  business.ownerCompleted = isEmpty(business.ownerUnCompletedSubRoles)
  business.trusteeCompleted = !!(
    relationshipManager && relationshipManager.partyId
  )
  return business
}

function getRelationshipManagerForBusiness(business, partiesObject) {
  const { trusts, individuals } = destructuringParties(partiesObject)
  let relationshipManager
  for (const trust of trusts) {
    if (relationshipManager) break
    const corporateTrustees = trust?.corporateTrustees || []
    for (const corporateTrustee of corporateTrustees) {
      if (corporateTrustee?.partyId === business?.partyId) {
        relationshipManager = corporateTrustee?.relationshipManager
        break
      }
    }
  }
  if (relationshipManager) {
    const relationshipManagerInList = individuals.find(
      (individual) => individual?.partyId === relationshipManager?.partyId
    )
    relationshipManager = {
      ...relationshipManagerInList,
      ...relationshipManager,
      relationshipType: PeopleRelationShipEnum.relationshipManager,
    }
  }
  return relationshipManager
}

function combineEntityIndividualChildren({ entity, childrenKey, parties }) {
  const { individuals } = destructuringParties(parties)
  const children = entity?.[childrenKey] || []
  entity[childrenKey] = children.map((child) => {
    const childInList = individuals.find(
      (individual) => individual?.partyId === child.partyId
    )
    child = {
      ...childInList,
      ...child,
      parentPartyId: entity.partyId,
    }
    return normalizeIndividualParty(child, parties)
  })
  return entity
}

function getBusinessChildren({
  authorizedIndividuals,
  businessOwnerships,
  controlPersons,
  relationshipManager,
}) {
  return concat(
    authorizedIndividuals.map((authorizedIndividual) => {
      return {
        partyId: authorizedIndividual.partyId,
        relationshipType: PeopleRelationShipEnum.businessAuthorized,
      }
    }),
    businessOwnerships.map((ownership) => {
      return {
        partyId: ownership.partyId,
        relationshipType: PeopleRelationShipEnum.businessOwner,
      }
    }),
    controlPersons.map((controlPerson) => {
      return {
        partyId: controlPerson.partyId,
        relationshipType: PeopleRelationShipEnum.controlPerson,
      }
    }),
    relationshipManager
  ).filter((child) => child?.partyId)
}

function normalizeOwnership(business, businessOwnerships, parties) {
  const { individuals, businesses, trusts } = destructuringParties(parties)
  return businessOwnerships.map((businessOwnership) => {
    const individualOwnerInList = individuals.find(
      (individual) => individual?.partyId === businessOwnership.partyId
    )
    const businessOwnerInList = businesses.find(
      (individual) => individual?.partyId === businessOwnership.partyId
    )
    const trustOwnerInList = trusts.find(
      (individual) => individual?.partyId === businessOwnership.partyId
    )
    let owner = {}
    if (individualOwnerInList) {
      owner = normalizeIndividualParty(
        {
          ...individualOwnerInList,
          ...businessOwnership,
          parentPartyId: business.partyId,
        },
        parties
      )
    }
    if (businessOwnerInList) {
      owner = normalizeBusinessParty({
        ...businessOwnerInList,
        ...businessOwnership,
        parentPartyId: business.partyId,
      })
    }
    if (trustOwnerInList) {
      owner = normalizeTrustParty(
        {
          ...trustOwnerInList,
          ...businessOwnership,
          parentPartyId: business.partyId,
        },
        parties
      )
    }
    return owner
  })
}

function normalizeTrustParty(party, parties) {
  const { trusts } = destructuringParties(parties)
  party = mergeObjectValidProperty(newTrust(), party)

  party = setBasicPartyInfo(party)
  party = setIsPrimaryBorrowerAndContact(party, parties)
  party = setPartyUserDefinedFieldsVO(party)
  party = combineTrustChildParty(party, parties)
  const partyInList =
    trusts.find((trust) => trust.partyId === party.partyId) || {}
  party = mergeNonExistProperty(party, partyInList)

  party = {
    ...party,
    phone: party?.mainPhone || party?.workPhone,
    combineContactAddress: combineAddress(party.contactAddress),
  }

  return party
}

function combineTrustChildParty(trust, parties) {
  const corporateTrustees = trust?.corporateTrustees || []
  trust.corporateTrustees = normalizeCorporateTrustee(
    trust,
    corporateTrustees,
    parties
  )

  trust = combineEntityIndividualChildren({
    entity: trust,
    childrenKey: 'individualTrustees',
    parties,
  })

  trust = combineEntityIndividualChildren({
    entity: trust,
    childrenKey: 'grantors',
    parties,
  })

  trust = combineEntityIndividualChildren({
    entity: trust,
    childrenKey: 'revokers',
    parties,
  })

  trust.trustees = [...trust.corporateTrustees, ...trust.individualTrustees]

  const revokers = trust?.revokers || []
  const individualTrustees = trust?.individualTrustees || []
  trust.children = getTrustChildren({
    corporateTrustees: trust.corporateTrustees,
    individualTrustees,
    grantors: trust?.grantors,
    revokers,
  })

  trust.unCompletedSubRoles = [
    {
      relationshipType: PeopleRelationShipEnum.trustTrustee,
      visible: isEmpty(trust.trustees),
    },
    {
      relationshipType: PeopleRelationShipEnum.relationshipManager,
      visible: some(trust.corporateTrustees, (corporateTrustee) => {
        return isEmpty(corporateTrustee.relationshipManager)
      }),
    },
  ].filter((role) => role.visible)
  trust.isCompleted = isEmpty(trust.unCompletedSubRoles)
  trust.ownerCompleted = isEmpty(trust.unCompletedSubRoles)

  return trust
}

function getTrustChildren({
  corporateTrustees,
  individualTrustees,
  grantors,
  revokers,
}) {
  return concat(
    corporateTrustees.map((corporateTrustee) => {
      return {
        ...corporateTrustee,
        partyId: corporateTrustee.partyId,
        relationshipType: PeopleRelationShipEnum.trustTrustee,
      }
    }),
    individualTrustees.map((individualTrustee) => {
      return {
        ...individualTrustee,
        partyId: individualTrustee.partyId,
        relationshipType: PeopleRelationShipEnum.trustTrustee,
      }
    }),
    grantors.map((grantor) => {
      return {
        ...grantor,
        partyId: grantor.partyId,
        relationshipType: PeopleRelationShipEnum.trustGrantor,
      }
    }),
    revokers.map((revoker) => {
      return {
        ...revoker,
        partyId: revoker.partyId,
        relationshipType: PeopleRelationShipEnum.trustRevoker,
      }
    })
  ).filter((child) => child.partyId)
}

function normalizeCorporateTrustee(trust, corporateTrustees, parties) {
  const { individuals, businesses, trusts } = destructuringParties(parties)
  return corporateTrustees.map((corporateTrustee) => {
    const trustTrusteeInList = trusts.find(
      (item) => item.partyId === corporateTrustee.partyId
    )
    const businessTrusteeInList = businesses.find(
      (business) => business.partyId === corporateTrustee.partyId
    )
    let trustee
    if (trustTrusteeInList) {
      trustee = normalizeBusinessParty({
        ...trustTrusteeInList,
        ...corporateTrustee,
        parentPartyId: trust.partyId,
      })
    }
    if (businessTrusteeInList) {
      trustee = normalizeBusinessParty({
        ...businessTrusteeInList,
        ...corporateTrustee,
        parentPartyId: trust.partyId,
      })
    }
    const relationshipManagerInList = individuals.find(
      (individual) =>
        individual?.partyId === corporateTrustee?.relationshipManager?.partyId
    )
    let relationshipManager = {
      ...relationshipManagerInList,
      ...corporateTrustee?.relationshipManager,
      parentPartyId: corporateTrustee.partyId,
    }
    relationshipManager = isNormalEmpty(relationshipManager.partyId)
      ? corporateTrustee?.relationshipManager
      : normalizeIndividualParty(relationshipManager, parties)
    return {
      ...trustee,
      relationshipManager,
    }
  })
}

async function saveRelationParty({
  stage,
  party,
  parentPartyId,
  relationshipType,
}) {
  const isNewParty = !party.partyId

  party = isNewParty
    ? await createRelationParty({
        stage,
        party,
        parentPartyId,
        relationshipType,
      })
    : await updateRelationParty(stage, party)

  await savePartyRelation({
    stage,
    party,
    parentPartyId,
    relationshipType,
  })

  return party
}

async function createRelationParty({ stage, party }) {
  const createPartyAPIMap = {
    [PeopleEntityTypeEnum.individual]: partyAPI.createIndividualParty,
    [PeopleEntityTypeEnum.business]: partyAPI.createBusinessParty,
    [PeopleEntityTypeEnum.trust]: partyAPI.createTrustParty,
  }
  const createEntityPartyAPI = createPartyAPIMap[party?.entityType]
  const createPartyRes = await createEntityPartyAPI(
    stage,
    normalizeSaveParty(party)
  )
  party.partyId = createPartyRes.partyId
  return party
}

async function updateRelationParty(stage, party) {
  const updatePartyAPIMap = {
    [PeopleEntityTypeEnum.individual]: partyAPI.updateIndividualParty,
    [PeopleEntityTypeEnum.business]: partyAPI.updateBusinessParty,
    [PeopleEntityTypeEnum.trust]: partyAPI.updateTrustParty,
  }
  const updatePartyAPI = updatePartyAPIMap[party?.entityType]
  await updatePartyAPI(stage, normalizeSaveParty(party))
  return party
}

async function savePartyRelation({
  stage,
  party,
  parentPartyId,
  relationshipType,
}) {
  const isChangeParty =
    party?.peRelId && party?.originalPartyId !== party?.partyId

  if (isChangeParty) {
    await deletePartyRelations({
      stage: stage,
      partyId: party.originalPartyId,
      parentPartyId: parentPartyId,
      relationshipType: relationshipType,
    })
    party.peRelId = null
  }

  if (isNormalEmpty(party?.peRelId)) {
    const relationPayload = getCreateRelationPayload({
      party,
      parentPartyId,
      relationshipType,
    })
    const res = await partyAPI.createPartyRelation(stage, relationPayload)
    party.peRelId = res?.partyRelIds?.[0] || uniqueId('_party_rel_')
    party.originalPartyId = party.partyId
    return
  }

  if (!isNormalEmpty(party?.peRelId)) {
    const relationPayload = {
      ownershipPercent: party?.ownershipPercent ?? null,
      title: party?.title ?? null,
    }
    await partyAPI.updatePartyRelation(stage, party?.peRelId, relationPayload)
  }
}

function getCreateRelationPayload({ party, parentPartyId, relationshipType }) {
  return {
    totalLoanFacilityId: party.totalLoanFacilityId,
    partyRelationList: [
      {
        partyId: party.partyId,
        parentPartyId,
        relationshipType,
        ownershipPercent: party?.ownershipPercent ?? null,
        title: party?.title ?? null,
      },
    ],
  }
}

async function deletePartyRelations({
  stage,
  party,
  partyId,
  parentPartyId,
  relationshipType,
  partyRelationId,
}) {
  if ((!party?.originalPartyId && !partyId) || !parentPartyId) {
    return
  }

  const payload = {
    partyRelationList: [
      {
        partyId: party?.originalPartyId || partyId,
        parentPartyId,
        relationshipType,
        partyRelationId,
      },
    ],
  }
  await partyAPI.deletePartyRelations(stage, payload)
}

function normalizeSaveParty(party) {
  party = cloneDeep(party)
  const needRemoveInvalidParam = [
    'itin',
    'taxId',
    'socialSecurityNumber',
    'ssnOrItin',
  ]
  for (const field of needRemoveInvalidParam) {
    if (party?.[field] && party?.[field].length === 4) {
      delete party?.[field]
    }
  }
  const firstEmployment = party?.employments?.[0] || {}
  if (!isEmpty(firstEmployment)) {
    party.annualIncomeLevel = firstEmployment.annualIncomeLevel
  }
  party = setPartyUserDefinedFields(party)
  party = setPartyAmendmentStatus(party)
  party.shortName = isNormalEmpty(party.shortName)
    ? combinePartyShortName(party)
    : party.shortName
  party.preferredPhone = setPreferredPhoneParams(party)
  return party
}

function setPartyAmendmentStatus(party) {
  const { constraint } = usePartyOverviewConstraint()
  const { APIStage, initialPartyStatus, isAmendmentRequest } = constraint.value

  if (APIStage === APIStageEnum.amendment) {
    if (initialPartyStatus) {
      if (isAmendmentRequest) {
        party.amendmentStatus =
          party.amendmentStatus || AmendmentPartyStatusEnum.initial
      } else {
        party.amendmentStatus =
          party.amendmentStatus || AmendmentPartyStatusEnum.preReviewed
      }
    } else {
      party.amendmentStatus = isAmendmentRequest
        ? AmendmentPartyStatusEnum.preReviewed
        : AmendmentPartyStatusEnum.reviewed
    }
  }

  return party
}

function setPreferredPhoneParams(party) {
  if (party[phoneFieldByTypeMap[party.preferredPhone]]) {
    return party.preferredPhone
  } else {
    if (party.cellPhone) {
      return ContactPhoneTypeEnum.cell
    } else if (party.homePhone) {
      return ContactPhoneTypeEnum.home
    } else if (party.workPhone) {
      return ContactPhoneTypeEnum.work
    } else {
      return null
    }
  }
}

function setPartyUserDefinedFields(party) {
  const userDefinedFieldsVO = party?.userDefinedFieldsVO || {}
  const userDefinedFields = map(userDefinedFieldsVO, (value, key) => ({
    id: key,
    value: value,
  }))
  party.userDefinedFields = userDefinedFields
  return party
}

function hasPartyRelationWithAnother(party, partiesObject) {
  const partyChildrenNotEmpty = !isEmpty(party.children)
  const partyList = getPartyList(partiesObject)

  const inOtherPartyChildren = partyList.some((otherParty) => {
    const otherPartyChildren = otherParty?.children || []
    return otherPartyChildren.some((child) => {
      return child.partyId === party?.partyId
    })
  })
  return partyChildrenNotEmpty || inOtherPartyChildren
}

function getDeceasedBorrowerInfo(partiesObject, loanFacilityId) {
  const { individuals, partyBusinessRelList } =
    destructuringParties(partiesObject)

  const loanFacilityBorrowerPartyIds = partyBusinessRelList
    .filter((rel) => rel.loanFacilityId === loanFacilityId && rel.isBorrower)
    .map((rel) => rel.partyId)

  const activeIndividuals = individuals.filter(
    (individual) =>
      individual.userId &&
      loanFacilityBorrowerPartyIds.includes(individual.partyId)
  )
  const deceasedBorrowerNames = activeIndividuals
    .filter((individual) => individual.isDeceased)
    .map((deceased) => deceased.fullName)
  return {
    deceasedBorrowerNames,
    isAllDeceased:
      !isEmpty(activeIndividuals) &&
      activeIndividuals.length === deceasedBorrowerNames.length,
    hasDeceased: deceasedBorrowerNames.length > 0,
  }
}

function getPrimaryBorrowerName(partiesObject) {
  const borrowerList = getBorrowerList(partiesObject)
  const partyList = getPartyList(partiesObject)
  let primaryBorrower = getPrimaryBorrower(partiesObject)
  primaryBorrower = primaryBorrower || borrowerList?.[0] || partyList?.[0] || {}
  return primaryBorrower?.fullName
}

function showOverwritePersonDialog({
  userInfo,
  confirmCallback,
  cancelCallback,
}) {
  const Dialog = Vue.extend(OverwritePersonDialog)
  const dialogVM = new Dialog().$mount()
  document.body.appendChild(dialogVM.$el)
  dialogVM.userInfo = userInfo
  dialogVM.confirmCallback = confirmCallback
  dialogVM.cancelCallback = cancelCallback
  dialogVM.show()
}

export function useParty() {
  const isUpdateIndividualPartyLoading = ref(false)

  async function updateIndividualParty(stage, params) {
    isUpdateIndividualPartyLoading.value = true
    try {
      await partyAPI.updateIndividualParty(stage, params)
    } finally {
      isUpdateIndividualPartyLoading.value = false
    }
  }

  const isPartyUserRestrictionsLoading = ref(false)

  /**
   * Unrestricts a user by removing certain restrictions associated with their party ID.
   *
   * @param {string} stage - The stage of the party.
   * @param {string} partyId - The party ID of the user.
   * @returns {Promise<ApiResponse>} A promise that resolves when the user restrictions are removed.
   */
  const updateUserUnrestrictions = async (stage, partyId) => {
    isPartyUserRestrictionsLoading.value = true
    try {
      return await partyAPI.updateUserUnrestrictions(stage, partyId)
    } finally {
      isPartyUserRestrictionsLoading.value = false
    }
  }

  return {
    party,
    backupParty,
    handleBackupParty,
    handleRestoreParty,

    partyLoading,
    getSingleParty,
    newIndividual,
    newBusiness,
    newTrust,
    newIdentification,
    newEmployment,
    normalizeSingleParty,
    normalizeIndividualParty,
    normalizeBusinessParty,
    normalizeTrustParty,

    combineIndividualChild,
    combineBusinessChildParty,
    combineTrustChildParty,

    combineAddress,
    combinePartyFullName,
    combinePartyShortName,
    combinePartyNameWithoutMiddleName,

    newSingleParty,
    saveRelationParty,
    normalizeSaveParty,
    hasPartyRelationWithAnother,
    deletePartyRelations,

    updateIndividualParty,
    isUpdateIndividualPartyLoading,
    getDeceasedBorrowerInfo,

    getPrimaryBorrowerName,
    showOverwritePersonDialog,

    updateUserUnrestrictions,
    isPartyUserRestrictionsLoading,
  }
}
