import { commonAPI } from '@/shared/api'
import { formatDate } from '@/shared/utils'
import { computed, ref } from 'vue'

const holidays = ref([])
const isHolidayLoading = ref(false)

async function getHolidaysByDateRange(startDate) {
  startDate = startDate || formatDate(new Date(), { format: 'YYYY-MM-DD' })
  isHolidayLoading.value = true
  const res = await commonAPI.getHolidaysByDateRange(startDate).finally(() => {
    isHolidayLoading.value = false
  })
  holidays.value = res?.holidays ?? []
  return holidays
}

export function useHolidayData() {
  const holidayDates = computed(() => holidays.value.map((item) => item.date))

  return {
    holidays,
    holidayDates,
    isHolidayLoading,
    getHolidaysByDateRange,
  }
}
