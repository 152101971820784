import { http } from './http'

const CONFIGURATION_API_BASE_PATH = '/v5/configuration'
const EXTERNAL_CONFIGURATION_BASE_PATH = '/v5/external-configuration'

/**
 * configCenterAPI
 * @desc Manager apis related Config Center
 */
export const configCenterAPI = {
  /**
   * Get terms by website code and filter by category code & termCodes
   * @permissionCode config.system-terms.get
   * @param required {string} websiteCode
   * @param required {string} categoryCode
   * @param Array {string} termCodes
   * @returns
   */
  getTerms(websiteCode, categoryCode, termCodes) {
    return http.get(
      `${EXTERNAL_CONFIGURATION_BASE_PATH}/website/${websiteCode}/system-terms`,
      {
        categoryCode,
        termCodes: termCodes.join(','),
      }
    )
  },

  /**
   * Gets the default custom table view options
   * @param {string} moduleCode - the module code
   * @param {string} listCode - rule: tab.name + '_LIST' + '_' + profileId(profileId allow null)
   * @returns
   */
  getCustomTableViews(params) {
    return http.get(`${CONFIGURATION_API_BASE_PATH}/mylistView`, params)
  },

  /**
   * Add custom table view option
   * @param {string} listViewName - Add current list view name
   * @param {boolean} isDefaultView - current view is default , default: false
   * @param {string} moduleCode - the module code
   * @param {string} listCode - rule: tab.name + '_LIST' + '_' + profileId(profileId allow null)
   * @param {Array} customValue - An array of all filtering options
   * @returns
   */
  addCustomTableView(params) {
    return http.post(`${CONFIGURATION_API_BASE_PATH}/listView`, params)
  },

  /**
   * update custom table view option
   * @param {string} listViewId - Update the unique ID of the current view
   * @param {string} listViewName - update current list view name
   * @param {boolean} isDefaultView - current view is default , default: false
   * @param {string} moduleCode - the module code
   * @param {string} listCode - rule: tab.name + '_LIST' + '_' + profileId(profileId allow null)
   * @param {Array} customValue - An array of all filtering options
   * @returns
   */
  updateCustomTableView(listViewId, params) {
    return http.put(
      `${CONFIGURATION_API_BASE_PATH}/listView/${listViewId}`,
      params
    )
  },

  /**
   * delete current view option
   * @param {string} listViewId - delete the unique ID of the current view
   * @returns
   */
  deleteCustomTableView(listViewId) {
    return http.delete(`${CONFIGURATION_API_BASE_PATH}/listView/${listViewId}`)
  },

  /**
   * Get function module guide config
   * @permissionCode config.module-config.get
   * @param {string} moduleCode
   */
  getFunctionModuleGuideConfig(moduleCode, distributionChannelInstitutionId) {
    return http.get(`${CONFIGURATION_API_BASE_PATH}/module/config`, {
      moduleCode: moduleCode,
      distributionChannelInstitutionId,
    })
  },

  /**
   * get list default fields by module and list code
   * @permissionCode config.listColumnSettings.get
   * @param {string} module required , 'application', 'underwriting'
   * @param {string} [listCode] optional
   */
  getListDefaultFields({
    module,
    listCode,
    lendingInstitutionId,
    channelInstitutionId,
  }) {
    return http.get(
      `${CONFIGURATION_API_BASE_PATH}/${module}/list-column-settings`,
      {
        listCode: listCode,
        lendingInstitutionId: lendingInstitutionId,
        channelInstitutionId: channelInstitutionId,
      }
    )
  },

  getOptionSettings({ websiteCode, menuCode }) {
    return http.get(
      `${CONFIGURATION_API_BASE_PATH}/website/${websiteCode}/menu/${menuCode}/option-settings`
    )
  },

  downloadStaticDocument(name) {
    return `${CONFIGURATION_API_BASE_PATH}/static-resources/${name}/download`
  },

  /**
   * Retrieves the list of channels from the configuration API.
   * @returns {Promise} A promise that resolves with the list of channels.
   */
  getChannelList() {
    return http.get(`${CONFIGURATION_API_BASE_PATH}/institutions/channels`)
  },

  /**
   * Retrieves the list of custodians for a specific channel institution.
   *
   * @param {string} channelInstitutionId - The ID of the channel institution.
   * @returns {Promise} - A promise that resolves to the list of custodians.
   */
  getChannelCustodianList(channelInstitutionId) {
    return http.get(
      `${CONFIGURATION_API_BASE_PATH}/institutions/${channelInstitutionId}/custodians`
    )
  },

  /**
   * Retrieves the list of channel rep codes for a given channel institution ID.
   *
   * @param {string} channelInstitutionId - The ID of the channel institution.
   * @returns {Promise} - A promise that resolves to the list of channel rep codes.
   */
  getChannelRepCodeList(channelInstitutionId, params) {
    return http.get(
      `${CONFIGURATION_API_BASE_PATH}/channels/${channelInstitutionId}/repcodes`,
      params
    )
  },
}
