import { parseTraceId } from '@/shared/utils'
import { EventSourcePolyfill } from 'event-source-polyfill'
import qs from 'qs'

import { isDevelopment, WORKFLOW_CALL_BACK_EVENT } from '../utils'
const baseHttp = function () {
  return window?.Vue?.prototype.$http
}

const httpHeaders = () => {
  return window?.microAppData?.store?.state?.httpHeaders
}

export const ApiVersion = {
  v5: 'v5',
}
export const API_VERSION = '/v5'
export const FILE_API_VERSION = '/v2'
export { APIStageEnum } from '../utils/enum'

const controllers = {}
const pendingRequestMap = new Map()

let eventSourceInstance = null

const handleWorkflowRequest = async (requestFactory) => {
  const instance = await createEventSource()
  const { headers, data } = await requestFactory()
  const requestId = parseTraceId(headers['x-sn-trace-id'], 'Root')
  const sseData = await instance.waitForResponse(requestId)
  return Promise.resolve(sseData?.result || data)
}

export const http = {
  autoAbortRepeatGet: (
    path,
    params,
    {
      headers,
      isReturnResponseData = true,
      showWarningMessage = false,
      translateWarningMessage = false,
    } = {}
  ) => {
    const controllerKey = String(path).split('?')[0] || ''
    if (controllerKey && controllers[controllerKey]) {
      controllers[controllerKey].abort('repeat request aborted!')
    }
    controllers[controllerKey] = new AbortController()
    return baseHttp()
      .get(path, {
        params,
        signal: controllers[controllerKey].signal,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
        headers: headers,
        showWarningMessage,
        translateWarningMessage,
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  get: function (
    path,
    params,
    {
      headers,
      isReturnResponseData = true,
      showWarningMessage = false,
      translateWarningMessage = false,
    } = {}
  ) {
    const requestId = `${path}${qs.stringify(params)}`

    if (pendingRequestMap.has(requestId)) {
      isDevelopment &&
        console.warn('[http] Duplicated request:', path, params || '')
      return pendingRequestMap.get(requestId)
    }

    const requestConfig = {
      params,
      paramsSerializer: (value) => qs.stringify(value, { indices: false }),
      headers,
      showWarningMessage,
      translateWarningMessage,
    }

    const request = baseHttp()
      .get(path, requestConfig)
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
      .finally(() => {
        pendingRequestMap.delete(requestId)
      })

    pendingRequestMap.set(requestId, request)
    return request
  },
  head: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .head(path, params)
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  post: function (
    path,
    params,
    {
      headers = {},
      isReturnResponseData = true,
      isWorkflow = false,
      ignoreErrorCodes = [],
      showWarningMessage = false,
      translateWarningMessage = false,
      responseType,
    } = {}
  ) {
    const config = {
      headers,
      ignoreErrorCodes,
      showWarningMessage,
      translateWarningMessage,
      responseType,
    }
    if (isWorkflow) {
      return handleWorkflowRequest(() => baseHttp().post(path, params, config))
    }

    return baseHttp()
      .post(path, params, config)
      .then((res) => (isReturnResponseData ? res?.data : res))
  },
  put: function (
    path,
    params,
    { isReturnResponseData = true, isWorkflow = false } = {}
  ) {
    if (isWorkflow) {
      return handleWorkflowRequest(() => baseHttp().put(path, params))
    }

    return baseHttp()
      .put(path, params)
      .then((res) => (isReturnResponseData ? res?.data : res))
  },
  patch: function (
    path,
    params,
    { isReturnResponseData = true, isWorkflow = false } = {}
  ) {
    if (isWorkflow) {
      return handleWorkflowRequest(() => baseHttp().patch(path, params))
    }

    return baseHttp()
      .patch(path, params)
      .then((res) => (isReturnResponseData ? res?.data : res))
  },
  delete: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .delete(path, {
        data: params,
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  download: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .get(path, {
        params,
        responseType: 'blob',
        paramsSerializer: (value) => {
          return qs.stringify(value, { indices: false })
        },
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  request: function ({ method = 'get', url = '', config = {} }) {
    const options = {
      method: method,
      url: url,
    }
    return baseHttp()(Object.assign(options, config))
  },

  getUri(config) {
    return baseHttp().getUri(config)
  },
}

export function createEventSource() {
  return new Promise((resolve, reject) => {
    if (!eventSourceInstance) {
      const tokens = httpHeaders()
      const url = `${API_VERSION}/commons/messages/subscribe`
      eventSourceInstance = new EventSourcePolyfill(url, {
        heartbeatTimeout: 24 * 60 * 60 * 1000,
        headers: {
          'Content-Type': 'text/event-stream',
          accept: '*/*',
          'Cache-Control': 'no-cache',
          Connection: 'keep-alive',
          ...tokens,
        },
      })
    } else {
      resolve(eventSourceInstance)
    }

    eventSourceInstance.onopen = function () {
      console.log('EventSource success!')
      resolve(eventSourceInstance)
    }

    eventSourceInstance.onerror = function (e) {
      if (e.status === 500) {
        eventSourceInstance.close()
        eventSourceInstance = null
        reject(e)
      }
    }

    eventSourceInstance.waitForResponse = (requestId = '') => {
      return new Promise((resolve, reject) => {
        eventSourceInstance.addEventListener(
          WORKFLOW_CALL_BACK_EVENT,
          function (e) {
            if ([e.id, e.lastEventId].includes(requestId)) {
              try {
                resolve(JSON.parse(e.data))
              } catch (error) {
                reject(e.data)
              }
            }
          }
        )
      })
    }
  })
}
