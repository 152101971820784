import { every, isEqual, some } from 'lodash'
import { computed, ref, unref } from 'vue'
import { useParties } from '../../party/usePartiesData'
import { useCollateralAssetData } from '../../collateral'
import { useAmendmentData } from './useAmendmentData'
import { applicationAPI } from '@/shared/api'
import { useI18n } from '@/shared/composables/i18n/useI18n'
import { useInstitutionConfig } from '@/shared/composables/config/useInstitutionConfig'
import {
  APIStageEnum,
  AmendmentActionEnum,
  AmendmentPartyStatusEnum,
  AmendmentTypeEnum,
  DocumentStatusEnum,
  combineOwnerListForAssets,
  getDeletedPartyList,
  getExistNotReviewedParty,
  getNewPartyList,
  isEmpty,
  isEqualStringIgnoreCase,
  isIncludeStringIgnoreCase,
  isNormalEmpty,
  toBigNumber,
} from '@/shared/utils'
import { useApplicationAttentions } from '../useApplicationAttentions'

const informationEntryDisableReasonList = ref([])
const otherInformationDisableReasonList = ref([])
const primaryPurposeDisableReason = ref([])
const closingInformationDisableReason = ref([])
const dealSummaryDisableReason = ref([])
const additionQuestionDisableReason = ref([])
const otherDocuments = ref([])

const apiStage = APIStageEnum.amendment

export function useRequestAmendmentData({
  amendLOCCanExcessEstimate = false,
} = {}) {
  const { t } = useI18n()
  const {
    amendment,
    applicationId,
    supportingDocs,
    isRequestorProfile,
    canAmendCollateral,
    isFacilityAmountEstimated,
    estimatedLoanFacilityAmount,
  } = useAmendmentData({ apiStage, isReview: false })
  const { partiesObject } = useParties()

  const recommendMargins = ref()

  const canUpdateRequestAmendment = computed(() => {
    return amendment.value?.isApplicationInProgress && isRequestorProfile.value
  })
  const needInputDiffLOC = computed(() => {
    const { canAmendLocAmount, requestApprovedAmount, originalApprovedAmount } =
      amendment.value
    if (!canAmendLocAmount) return false
    return (
      isNormalEmpty(requestApprovedAmount) ||
      toBigNumber(requestApprovedAmount).isEqualTo(
        toBigNumber(originalApprovedAmount)
      )
    )
  })
  const needChangeLocAmount = computed(() => {
    const { canAmendLocAmount, originalApprovedAmount } = amendment.value
    const estimateLOC = estimatedLoanFacilityAmount.value ?? 0
    const originalLOCGreaterMaxLOC =
      !unref(amendLOCCanExcessEstimate) &&
      !canAmendLocAmount &&
      canAmendCollateral.value &&
      toBigNumber(originalApprovedAmount).isGreaterThan(
        toBigNumber(estimateLOC)
      )

    return (
      originalLOCGreaterMaxLOC ||
      (!unref(amendLOCCanExcessEstimate) && !isFacilityAmountEstimated.value)
    )
  })
  const breakdownNotChange = computed(() => {
    const {
      originalInterestPercentForAdvisor,
      requestInterestPercentForAdvisor,
      originalInterestPercentForDistributionChannel,
      requestInterestPercentForDistributionChannel,
      originalInterestPercentForFirm,
      requestInterestPercentForFirm,
      originalInterestPercentForServicer,
      requestInterestPercentForServicer,
      // originalInterestPercentForLender,
      // requestInterestPercentForLender,
    } = amendment.value
    const requestBreakdownIsEmpty = [
      // requestInterestPercentForLender,
      requestInterestPercentForAdvisor,
      requestInterestPercentForServicer,
      requestInterestPercentForDistributionChannel,
      requestInterestPercentForFirm,
    ].every(isNormalEmpty)

    if (requestBreakdownIsEmpty) return true

    const breakdownRequestIsEqualOriginal = [
      // {
      //   request: requestInterestPercentForLender,
      //   original: originalInterestPercentForLender,
      // },
      {
        request: requestInterestPercentForAdvisor,
        original: originalInterestPercentForAdvisor,
      },
      {
        request: requestInterestPercentForServicer,
        original: originalInterestPercentForServicer,
      },
      {
        request: requestInterestPercentForDistributionChannel,
        original: originalInterestPercentForDistributionChannel,
      },
      {
        request: requestInterestPercentForFirm,
        original: originalInterestPercentForFirm,
      },
    ].every((item) =>
      toBigNumber(item.request).isEqualTo(toBigNumber(item.original))
    )
    return breakdownRequestIsEqualOriginal
  })
  const indexNotChange = computed(() => {
    const { originalIndexRateId, requestIndexRateId } = amendment.value
    return (
      isEmpty(requestIndexRateId) ||
      isEqual(originalIndexRateId, requestIndexRateId)
    )
  })
  const interestNotChange = computed(() => {
    const {
      canAmendMargin,
      originalMarginPercent,
      requestMarginPercent,
      originalFloorRatePercent,
      requestFloorRatePercent,
    } = amendment.value
    if (!canAmendMargin) return false
    const marginNotChange =
      isNormalEmpty(requestMarginPercent) ||
      toBigNumber(requestMarginPercent).isEqualTo(
        toBigNumber(originalMarginPercent)
      )
    const floorNotChange =
      isNormalEmpty(requestMarginPercent) ||
      toBigNumber(requestFloorRatePercent).isEqualTo(
        toBigNumber(originalFloorRatePercent)
      )
    return (
      marginNotChange &&
      floorNotChange &&
      breakdownNotChange.value &&
      indexNotChange.value
    )
  })
  const isSupportingDocsNotReady = computed(() => {
    if (isEmpty(supportingDocs.value)) return false
    return some(supportingDocs.value, (_file) => {
      return !isEqualStringIgnoreCase(_file.fileState, DocumentStatusEnum.ready)
    })
  })

  const nonExistentNewOrDeletedParty = computed(() => {
    const newPartyList = getNewPartyList(partiesObject.value)
    const deletedPartyList = getDeletedPartyList(partiesObject.value)
    const needAddOrDeleteParty = isIncludeStringIgnoreCase(
      amendment.value.requestTypes,
      AmendmentTypeEnum.peopleEntities
    )
    return (
      needAddOrDeleteParty && isEmpty(newPartyList) && isEmpty(deletedPartyList)
    )
  })

  const notReviewedStatus = [AmendmentPartyStatusEnum.initial]

  const hasNotReviewedParty = computed(() => {
    return getExistNotReviewedParty(partiesObject.value, notReviewedStatus)
  })

  const needAddOrRemoveAssets = computed(() => {
    if (!canAmendCollateral.value) return false
    return every(amendment.value.assets, (item) => {
      return !isIncludeStringIgnoreCase(
        [AmendmentActionEnum.add, AmendmentActionEnum.update],
        item.amendmentAction
      )
    })
  })

  const { customizeNeedsAttention } = useInstitutionConfig()

  const { someAttentionNotResolved } = useApplicationAttentions()

  const { assets } = useCollateralAssetData()
  const hasUnownedInvestmentAssets = computed(() => {
    const _assets = combineOwnerListForAssets(assets.value, partiesObject.value)
    return some(_assets, (asset) => isEmpty(asset.owners))
  })

  const summaryDisableReasons = computed(() => {
    return [
      {
        text: t('please_enter_a_valid_line_of_credit_amount'),
        visible: needInputDiffLOC.value,
      },
      {
        text: t(
          'loan_amount_can_t_exceed_estimated_value_supported_by_collateral'
        ),
        visible: needChangeLocAmount.value,
      },
      {
        text: t(
          'current_request_must_include_rate_change_according_to_the_request_type_you_selected'
        ),
        visible: interestNotChange.value,
      },
      {
        text: t('action_is_required_for_people_and_entities'),
        visible: nonExistentNewOrDeletedParty.value,
      },
      {
        text: t('please_review_all_information_for_individuals_and_entities'),
        visible: hasNotReviewedParty.value,
      },
      {
        text: t('action_is_required_for_collateral_account_s'),
        visible: needAddOrRemoveAssets.value,
      },
      {
        text: t(
          'please_make_sure_all_accounts_are_associated_with_at_least_one_owner'
        ),
        visible: hasUnownedInvestmentAssets.value,
      },
      {
        text: t('we_found_unresolved_issues_on_uploaded_document_s'),
        visible: isSupportingDocsNotReady.value,
      },
      {
        text: t('please_resolve_all_items_in_the_needs_attention_section'),
        visible:
          customizeNeedsAttention.value && someAttentionNotResolved.value,
      },
    ]
      .filter((item) => item.visible)
      .map((item) => item.text)
  })

  const disableRequestReasons = computed(() => [
    ...summaryDisableReasons.value,
    ...informationEntryDisableReasonList.value,
    ...otherInformationDisableReasonList.value,
    ...additionQuestionDisableReason.value,
  ])

  async function getRecommendMargins() {
    recommendMargins.value = null
    const data = await applicationAPI.getCheckMargins(
      APIStageEnum.amendment,
      applicationId.value
    )
    recommendMargins.value = data
    return data
  }

  return {
    disableRequestReasons,
    informationEntryDisableReasonList,
    otherInformationDisableReasonList,
    primaryPurposeDisableReason,
    closingInformationDisableReason,
    dealSummaryDisableReason,
    additionQuestionDisableReason,

    isSupportingDocsNotReady,
    canUpdateRequestAmendment,

    otherDocuments,

    recommendMargins,
    getRecommendMargins,
  }
}
