import { ref } from 'vue'
import { loanAPI } from '@/shared/api'

export const LoanListSearchKeyEnum = {
  loanNumber: 'loanNumber',
  borrowerNames: 'borrowerNames',
  loanName: 'loanName',
}

export const useLoanData = () => {
  const isGetLoansLoading = ref(false)
  const loanList = ref({})

  /**
   * Fetches loans based on the provided parameters.
   *
   * @param {LoanListParams} params - The parameters for listing the loans.
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const getLoans = async (params) => {
    isGetLoansLoading.value = true
    try {
      params.pageNum ??= params.pageNumber ?? 1
      const data = await loanAPI.getLoans(params)
      loanList.value = data
      return data
    } finally {
      isGetLoansLoading.value = false
    }
  }

  const isLoanUpdating = ref(false)

  /**
   * Updates a specific loan's details.
   *
   * @param {string} loanId - The ID of the loan to be updated.
   * @param {UpdateLoanDetailsPayload} payload - The payload containing the loan details to be updated.
   * @param {Object} [options] - Additional options for the request.
   * @param {string} [options.requestFrom] - The source of the request.
   * @returns {Promise<Object>} - A promise that resolves with the response of the update operation.
   */
  const updateLoan = async (loanId, payload, options = {}) => {
    isLoanUpdating.value = true
    try {
      return await loanAPI.updateLoan(loanId, payload, options)
    } finally {
      isLoanUpdating.value = false
    }
  }

  return {
    isGetLoansLoading,
    loanList,
    getLoans,

    isLoanUpdating,
    updateLoan,
  }
}
