import { computed, reactive, ref, toRefs, unref } from 'vue'
import {
  find,
  forEach,
  has,
  identity,
  includes,
  isObject,
  merge,
  omit,
  pickBy,
} from 'lodash'
import { loanFacilityAPI, configurationAPI } from '@/shared/api'
import { amendmentAPI } from '@/shared/api/application/amendment'
import { applicationAPI } from '@/shared/api/application/application'
import { APIStageEnum } from '@/shared/api/http'
import { collateralAPI } from '@/shared/api/collateral/collateral'

import {
  useCollateralAssetData,
  useDocumentData,
  useGeneralConfig,
  useLoanData,
  useLoanFacilityData,
  useLocProductData,
  useTotalLoanFacilityData,
  useUnderwritingData,
} from '@/shared/composables/data'
import { useRoute, useRouter } from '@/shared/composables/route/useRoute'
import { useAmendmentDataFields } from '@/shared/composables/store/useDataFieldsStore'
import {
  useAmendmentInstitutionConfigStore,
  useServiceInstitutionConfigStore,
} from '@/shared/composables/store/useInstitutionLevelConfigStore'
import {
  useServiceLocProductConfigStore,
  useAmendmentLocProductConfigStore,
  useUnderwritingLocProductConfigStore,
} from '@/shared/composables/store/useLocProductLevelConfigStore'
import { useUserInfoStore } from '@/shared/composables/store/useUserInfoStore'

import {
  API_MAX_PAGE_SIZE,
  AmendmentStatusEnum,
  AmendmentTypeEnum,
  ApplicationStatusEnum,
  DocumentCodeEnum,
  EnumConfigKey,
  RouterBase,
  UnderwritingStatus,
  UnderwritingSubStatus,
  ConfigCategoryEnum,
  isEmpty,
  isEqualStringIgnoreCase,
  isIncludeStringIgnoreCase,
  isNormalEmpty,
  toBigNumber,
  covertMarginDetailData,
} from '@/shared/utils'
import { useEnumConfigStore } from '@/shared/composables/store/useConfigEnumStore'

const SUPPORTING_DOCS_MAX_COUNT = 10

const amendment = ref(newAmendment())
const creating = ref(false)
const isAmendmentRequestEdit = ref(false)
const applicationId = computed(() => {
  return amendment.value.applicationId
})

export const AmendmentStageEnum = {
  application: 'application',
  underwriting: 'underwriting',
}

const archiveAmendmentId = computed(() => {
  if (amendment.value?.isArchived) {
    return amendment.value.applicationId
  }
  return null
})

function newAmendment() {
  return {
    applicationId: null,
    loanFacilityId: null,
    loanFacilityNumber: null,
    loanId: null,
    loanNumber: null,
    totalLoanFacilityId: null,
    loanFacilityType: null,
    loanFacilityName: null,
    requestUserId: null,
    requestUserProfileId: null,
    requestUserName: null,
    reviewerUserProfileId: null,
    reviewerUserName: null,
    primaryUnderwriterProfileId: null,
    primaryUnderwriterName: null,
    status: null,
    subStatus: null,
    originalApprovedAmount: null,
    requestApprovedAmount: null,
    overrideApprovedAmount: null,
    typeOfPurpose: null,
    purpose: null,
    purposeNote: null,
    justification: null,
    returnedDescription: null,
    externalFacilityCid: null,
    lenderInstitutionId: null,
    requestTypes: null,
    requestedAt: null,
    marketValue: null,
    lendingValue: null,
    loanType: null,
    primaryPurpose: null,
    primaryAdvisorName: null,
    overrideIndexRateType: null,
    overrideMarginPercent: null,
    overrideFloorRatePercent: null,
    overrideInterestRatePercent: null,
    sublimitAmount: null,
    sublimitOption: null,
    taskTypes: null,
    loanAmountDetail: [],
    // stored total loan facility in amendment
    totalLoanFacility: {},
    // stored loan facilities in amendment
    loanFacilityList: [],
    // stored loans in amendment
    loanList: [],
    // stored supporting docs in amendment
    supportingDocs: [],
    isApplicationInProgress: null,
    isReturnedRequest: null,
    isArchived: null,
    isInUnderwriting: null,
    isSubStatusInCreditApproval: null,
    isCompleted: null,
    isCanceled: null,
    canAmendLocAmount: null,
    canAmendMargin: null,
    canAmendIndex: null,
  }
}

function mergeAndNormalizeAmendment(target, source) {
  if (!isObject(source) || isEmpty(source)) return target

  target = mergeValidData(target, source)

  target = setIsApplicationInProgress(target)
  target = setIsArchived(target)
  target = setIsReturnedRequest(target)
  target = setIsCompleted(target)
  target = setIsCanceled(target)
  target = setIsInUnderwriting(target)

  target = setCanAmendLocAmount(target)
  target = setCanAmendMargin(target)

  return toRefs(reactive(target))
}

function mergeValidData(target, source) {
  const cannotMergeAttributes = ['justification']
  for (const key of Object.keys(source)) {
    if (
      (!has(target, key) || !isEmpty(source[key]) || source[key] === 0) &&
      !(
        isIncludeStringIgnoreCase(cannotMergeAttributes, key) &&
        isAmendmentRequestEdit.value
      )
    ) {
      target[key] = source[key]
    }
  }
  return target
}

function setIsApplicationInProgress(target) {
  target.isApplicationInProgress = [
    ApplicationStatusEnum.applicationInProgress,
  ].some((item) => isEqualStringIgnoreCase(target.status, item))
  return target
}

function setIsArchived(target) {
  target.isArchived = [
    UnderwritingStatus.finished,
    UnderwritingStatus.declined,
    UnderwritingStatus.cancelled,
  ].some((item) => isEqualStringIgnoreCase(target.status, item))
  return target
}

function setIsReturnedRequest(amendment) {
  amendment.isReturnedRequest =
    amendment.isApplicationInProgress && !!amendment.reviewerUserProfileId
  return amendment
}

function setIsCompleted(amendment) {
  amendment.isCompleted = isEqualStringIgnoreCase(
    ApplicationStatusEnum.completed,
    UnderwritingStatus.finished,
    amendment.status
  )
  return amendment
}

function setIsCanceled(amendment) {
  amendment.isCanceled = isEqualStringIgnoreCase(
    ApplicationStatusEnum.cancelled,
    amendment.status
  )
  return amendment
}

function setIsInUnderwriting(amendment) {
  amendment.isInUnderwriting = isIncludeStringIgnoreCase(
    [UnderwritingStatus.active],
    amendment.status
  )

  amendment.isSubStatusInCreditApproval = isIncludeStringIgnoreCase(
    [UnderwritingSubStatus.creditApprove],
    amendment.subStatus
  )
  return amendment
}

function setCanAmendLocAmount(target) {
  target.canAmendLocAmount = includes(
    target.requestTypes,
    AmendmentTypeEnum.loanAmount
  )
  return target
}

function setCanAmendMargin(target) {
  target.canAmendMargin = [
    AmendmentTypeEnum.interestRate,
    AmendmentTypeEnum.floorRate,
    AmendmentTypeEnum.indexChange,
  ].some((amendmentTypeItem) =>
    includes(target.requestTypes, amendmentTypeItem)
  )

  target.canAmendIndex = [
    AmendmentTypeEnum.indexChange,
    AmendmentTypeEnum.interestRate,
  ].some((amendmentTypeItem) =>
    includes(target.requestTypes, amendmentTypeItem)
  )
  return target
}

async function createChangeLoanFacilityApplication({
  changeTypes,
  loanFacilityId,
  loanId,
}) {
  creating.value = true
  try {
    const response = await amendmentAPI.createChangeLoanFacilityApplication({
      changeTypes,
      loanFacilityId,
      loanId,
    })
    return response
  } finally {
    creating.value = false
  }
}

export function useAmendmentData({
  apiStage = APIStageEnum.amendment,
  isReview = true,
} = {}) {
  const router = useRouter()
  const route = useRoute()
  const phaseId = isReview ? 'underwritingId' : 'applicationId'

  const { isCurrentUser, isCurrentProfile } = useUserInfoStore()
  const { getTotalLoanFacility } = useTotalLoanFacilityData(apiStage)
  const { getCollateralPackageAssets } = useCollateralAssetData(apiStage)
  const { getLoanFacilityList } = useLoanFacilityData()

  const { getUnderwritingSummary, underwritingSummary } =
    useUnderwritingData(apiStage)

  const { getAmendmentDataFields } = useAmendmentDataFields()

  const { getLoans } = useLoanData()
  const { getDocuments } = useDocumentData(apiStage)
  const { getListLocProducts } = useLocProductData()
  const { getGeneralConfig } = useGeneralConfig()

  const isRequestor = computed(() => {
    return isCurrentUser(amendment.value?.requestUserId)
  })
  const isRequestorProfile = computed(() => {
    return isCurrentProfile(amendment.value?.requestUserProfileId)
  })
  const canAmendCollateral = computed(() => {
    const { requestTypes } = amendment.value || {}
    return isIncludeStringIgnoreCase(
      requestTypes,
      AmendmentTypeEnum.pledgedAccount
    )
  })

  const supportingDocs = computed(() => unref(amendment).supportingDocs)

  const { getEnumOptionLabel } = useEnumConfigStore()

  const amendmentTags = computed(() => {
    const { requestTypes } = amendment.value
    const finalRequestType = requestTypes || []

    return Object.values(AmendmentTypeEnum)
      .map((type) => {
        return {
          visible: finalRequestType.includes(type),
          label: getEnumOptionLabel(EnumConfigKey.amendmentChangeType, type),
        }
      })
      .filter((item) => item.visible)
  })

  const isFacilityAmountEstimated = computed(() => {
    return checkFacilityAmountEstimated({
      key: isReview ? 'overrideApprovedAmount' : 'requestApprovedAmount',
    })
  })
  const collateralInfo = computed(
    () => amendment.value.totalLoanFacility?.collateralInfo
  )
  const isCallPledgeApi = computed(
    () => amendment.value.callLoanBookAPIWhenApprove === false
  )
  const estimatedLoanFacilityAmount = computed(() => {
    if (!collateralInfo.value) return
    const { lendingValue } = collateralInfo.value
    const { loanFacilityId, loanFacilityList } = amendment.value

    let _estimatedAmount = toBigNumber(lendingValue ?? 0)
    forEach(
      loanFacilityList,
      (line) => {
        if (line.loanFacilityId === loanFacilityId) return
        _estimatedAmount = _estimatedAmount.minus(
          toBigNumber(line.loanFacilityAmount)
        )
      },
      0
    )
    return _estimatedAmount.toNumber()
  })

  function goToAmendmentApplicationPage({ applicationId, loanFacilityId }) {
    const query = {
      applicationId,
      loanFacilityId,
    }
    router.push({
      path: '/service/new-amendment',
      query,
    })
  }

  const { getServiceListInstitutionLevelConfigs } =
    useServiceInstitutionConfigStore()
  const { getAmendmentListInstitutionLevelConfigs } =
    useAmendmentInstitutionConfigStore()

  const { getServiceListLocProductLevelConfigs } =
    useServiceLocProductConfigStore()
  const { getUnderwritingListLocProductLevelConfigs } =
    useUnderwritingLocProductConfigStore()
  const { getAmendmentListLocProductLevelConfigs } =
    useAmendmentLocProductConfigStore()

  async function getApplicationDetails(applicationId) {
    let res = await applicationAPI.getApplicationDetails(
      apiStage,
      applicationId
    )
    if (isReview) {
      await getUnderwritingSummary(applicationId)
      const mergeUnderwriting = omit(underwritingSummary.value, 'balance')
      res = merge(res, mergeUnderwriting)
    }

    amendment.value = mergeAndNormalizeAmendment(amendment.value, res)

    await Promise.all([
      loadTotalLoanFacility(),
      loadLoanFacilityList(),
      loadLoanList(),
      getEntryInfo(amendment.value.loanFacilityId, archiveAmendmentId.value),
      getLoanFacilityDetail(amendment.value.loanFacilityId),
    ])
  }

  async function getShouldCallPledgeAssetApi() {
    const res = await configurationAPI.listInstitutionLevelConfigs(apiStage, {
      loanFacilityId: amendment.value.loanFacilityId,
      categoryCode: 'underwriting',
    })
    amendment.value.callLoanBookAPIWhenApprove =
      res?.lenderInstitutionLevelConfigs?.callLoanBookAPIWhenApprove
  }

  async function loadTotalLoanFacility() {
    const totalLoanFacilityId = unref(amendment)?.totalLoanFacilityId
    if (!totalLoanFacilityId) return
    const data = await getTotalLoanFacility(
      totalLoanFacilityId,
      apiStage,
      archiveAmendmentId.value
    )
    amendment.value.lendingValue = data.collateralInfo?.lendingValue
    amendment.value = mergeAndNormalizeAmendment(amendment.value, {
      totalLoanFacility: data,
    })
    await loadCollateralAssets()
    await getListLocProducts({
      channelInstitutionId: data.distributionChannelInstitutionId,
    })
  }

  async function loadCollateralAssets() {
    const collateralId = collateralInfo.value.collateralId
    if (!collateralId) return
    const data = await getCollateralPackageAssets(
      collateralId,
      archiveAmendmentId.value
    )
    amendment.value = mergeAndNormalizeAmendment(amendment.value, {
      assets: data,
    })
  }

  async function getApplicationApprovedAmountDetail(applicationId) {
    const res = await applicationAPI.getApplicationApprovedAmountDetail(
      apiStage,
      applicationId
    )
    amendment.value = mergeAndNormalizeAmendment(amendment.value, res)
  }

  async function getMarginDetail(applicationId) {
    let res = await applicationAPI.getApplicationMarginDetails(
      apiStage,
      applicationId
    )
    res = covertMarginDetailData(res)
    amendment.value = mergeAndNormalizeAmendment(amendment.value, res)
  }

  async function getEntryInfo(loanFacilityId, archiveAmendmentId) {
    const data = await loanFacilityAPI.getInformationEntries(
      apiStage,
      loanFacilityId,
      archiveAmendmentId
    )
    const res = data.items || []
    amendment.value = mergeAndNormalizeAmendment(amendment.value, res[0] || {})
  }

  async function getLoanFacilityDetail(loanFacilityId) {
    const data = await loanFacilityAPI.getSingleLoanFacility(
      { loanFacilityId },
      apiStage,
      archiveAmendmentId.value
    )
    const res = data || {}

    amendment.value.distributionChannelInstitutionId =
      res.distributionChannelInstitutionId
    amendment.value.loanFacilityName = res.loanFacilityName
    amendment.value.clientRepCodeId = res.clientRepCodeId
    amendment.value.primaryAdvisorProfileId = res.primaryAdvisorProfileId
    amendment.value.primaryAdvisorName = res.primaryAdvisorName
    amendment.value.typeOfPurpose = res.typeOfPurpose
    amendment.value.purpose = res.purpose
    amendment.value.purposeNote = res.purposeNote
    amendment.value.drawNeedsExtraApproval = res.drawNeedsExtraApproval
    amendment.value.drawNeedsExtraApprovalReasonCode =
      res.drawNeedsExtraApprovalReasonCode
    amendment.value.drawNeedsExtraApprovalReasonDetail =
      res.drawNeedsExtraApprovalReasonDetail
    amendment.value.hasPurposeLoan = res.hasPurposeLoan
    amendment.value.netWorthEstimate = res.netWorthEstimate
    amendment.value.yearsWithAdvisor = res.yearsWithAdvisor
    return Promise.resolve(res)
  }

  async function loadLoanFacilityList() {
    const totalLoanFacilityId = unref(amendment)?.totalLoanFacilityId
    if (!totalLoanFacilityId) return
    const data = await getLoanFacilityList({
      'filters.totalLoanFacilityId': unref(amendment)?.totalLoanFacilityId,
      pageSize: API_MAX_PAGE_SIZE,
    })
    amendment.value = mergeAndNormalizeAmendment(amendment.value, {
      loanFacilityList: data?.items || [],
    })
  }

  async function loadLoanList() {
    const totalLoanFacilityId = unref(amendment)?.totalLoanFacilityId
    if (!totalLoanFacilityId) return
    const data = await getLoans({
      'filters.totalLoanFacilityId': unref(amendment)?.totalLoanFacilityId,
      'filters.loanFacilityId': unref(amendment)?.loanFacilityId,
      pageSize: API_MAX_PAGE_SIZE,
    })
    amendment.value = mergeAndNormalizeAmendment(amendment.value, {
      loanList: data?.items || [],
    })
  }

  async function loadDocuments() {
    if (!applicationId.value) return
    const params = {
      pageNum: 1,
      pageSize: SUPPORTING_DOCS_MAX_COUNT,
      [`filters.${phaseId}`]: applicationId.value,
      'filters.documentCodeList': [DocumentCodeEnum.amendmentSupportingDoc],
    }
    const data = await getDocuments(params)
    amendment.value.supportingDocs = data?.items || []
  }

  const getFullAmendmentLoading = ref(false)

  async function getFullAmendment(
    _applicationId = amendment.value.applicationId
  ) {
    getFullAmendmentLoading.value = true
    try {
      await getApplicationDetails(_applicationId)
      await Promise.all([
        getGeneralConfig(),
        getMarginDetail(_applicationId),
        getApplicationApprovedAmountDetail(_applicationId),
        loadDocuments(),
      ])
      mergeLoanAmountDetails()
    } finally {
      getFullAmendmentLoading.value = false
    }
  }

  const getAmendInstitutionAndLocProductConfig = async () => {
    await Promise.all([
      getServiceListInstitutionLevelConfigs({
        loanFacilityId: unref(amendment).loanFacilityId,
        categoryCode: ConfigCategoryEnum.servicing,
      }),
      getAmendmentListInstitutionLevelConfigs({
        loanFacilityId: unref(amendment).loanFacilityId,
        categoryCode: ConfigCategoryEnum.amendment,
      }),
      getServiceListLocProductLevelConfigs({
        locProductId: unref(amendment).locProductId,
        categoryCode: ConfigCategoryEnum.servicing,
      }),
      getAmendmentListLocProductLevelConfigs({
        locProductId: unref(amendment).locProductId,
        categoryCode: ConfigCategoryEnum.amendment,
      }),
      getUnderwritingListLocProductLevelConfigs({
        locProductId: unref(amendment).locProductId,
        categoryCode: ConfigCategoryEnum.underwriting,
      }),
      getAmendmentDataFields(),
    ])
  }

  function mergeLoanAmountDetails() {
    const { loanAmountDetail, loanList } = amendment.value
    forEach(loanAmountDetail, (detail) => {
      const loan = find(loanList, { loanId: detail.loanId })
      detail.loanName ||= loan?.loanName
      // initial loan amount default value
      detail.overrideApprovedAmount ||= detail.requestApprovedAmount ||=
        detail.originalApprovedAmount
    })
  }

  function linkToAmendList(status = AmendmentStatusEnum.pendingSubmission) {
    router.push({
      path: `${RouterBase.service}/amendment-list`,
      query: pickBy({ status }, identity),
    })
  }

  function checkFacilityAmountEstimated({
    key = 'requestApprovedAmount',
  } = {}) {
    const { canAmendLocAmount, [key]: amount } = amendment.value
    if (!canAmendLocAmount) return true
    return toBigNumber(
      estimatedLoanFacilityAmount.value
    ).isGreaterThanOrEqualTo(toBigNumber(amount))
  }

  const initAmendment = () => {
    if (
      isNormalEmpty(amendment.value?.applicationId) ||
      amendment.value?.applicationId !== route.query?.applicationId
    ) {
      amendment.value = mergeAndNormalizeAmendment(newAmendment(), route.query)
    }
  }

  const isCollateralPledging = ref(false)
  const pledgeAssets = async () => {
    isCollateralPledging.value = true
    try {
      return await collateralAPI.pledgeAssets(
        APIStageEnum.amendment,
        collateralInfo.value.collateralId
      )
    } finally {
      isCollateralPledging.value = false
    }
  }

  return {
    amendment,
    applicationId,
    creating,

    canAmendCollateral,
    collateralInfo,
    isRequestor,
    isRequestorProfile,
    supportingDocs,
    amendmentTags,
    isFacilityAmountEstimated,
    estimatedLoanFacilityAmount,
    checkFacilityAmountEstimated,

    createChangeLoanFacilityApplication,
    goToAmendmentApplicationPage,

    getApplicationDetails,
    getApplicationApprovedAmountDetail,
    getMarginDetail,
    getEntryInfo,
    loadDocuments,

    getLoanFacilityDetail,

    initAmendment,
    mergeAndNormalizeAmendment,
    newAmendment,
    getFullAmendmentLoading,
    getFullAmendment,
    getAmendInstitutionAndLocProductConfig,

    linkToAmendList,
    SUPPORTING_DOCS_MAX_COUNT,

    archiveAmendmentId,

    loadCollateralAssets,

    isCollateralPledging,
    pledgeAssets,

    getShouldCallPledgeAssetApi,
    isCallPledgeApi,

    isAmendmentRequestEdit,
  }
}
