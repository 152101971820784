import { reactive, ref } from 'vue'
import { has } from 'lodash'
import { activityLogAPI, creditExceptionAPI } from '../../api'
import {
  APIStageEnum,
  ActivityLogEventCodeEnum,
  DataSourceValueEnum,
} from '../../utils/enum'
import { useI18n } from '../i18n/useI18n'
import {
  formatText,
  formatPercentage,
  formatCurrency,
  formatDateSystemZone,
  isNormalEmpty,
  formatDate,
  getSessionStorageItem,
  isEqualStringIgnoreCase,
} from '@/shared/utils'

const activityLogDataChangeFormatter = reactive({
  creditPolicyMap: {},
  creditExceptionReasonByCodeMap: {},
  collateralDataSourceMap: {},
})

const formatRate = (value) => {
  return formatPercentage(value, { magnification: 100 })
}

const formatAmount = (value) => {
  return formatCurrency(value, { format: '$0,0' })
}

const formatPortfolioRate = (value, options) => {
  const formatResult = formatRate(value)
  if (
    [
      ActivityLogEventCodeEnum.portfolioTypeAdjustmentExceptionAdded,
      ActivityLogEventCodeEnum.portfolioTypeAdjustmentExceptionUpdated,
      ActivityLogEventCodeEnum.portfolioTypeAdjustmentExceptionDeleted,
    ].includes(options?.eventCode)
  ) {
    return formatResult.startsWith('-') ? formatResult : `+${formatResult}`
  }
  return formatResult
}

/**
 * @typedef {Object} EventTimeFilter
 * @property {number} [min] - Minimum event time (timestamp).
 * @property {number} [max] - Maximum event time (timestamp).
 */

/**
 * @typedef {Object} ActivityLogsFilters
 * @property {number} [applicationId] - Filter by application ID.
 * @property {number} [totalLoanFacilityId] - Filter by total loan facility ID.
 * @property {number} [loanFacilityId] - Filter by loan facility ID.
 * @property {number} [loanId] - Filter by loan ID.
 * @property {number} [amendmentId] - Filter by amendment ID.
 * @property {number} [collateralId] - Filter by collateral ID.
 * @property {Array<string>} [eventCodes] - Filter by event codes.
 * @property {number} [operatorUserId] - Filter by operator user ID.
 * @property {Array<string>} [eventCategories] - Filter by event categories.
 * @property {Array<string>} [eventObjTypes] - Filter by event object types.
 * @property {EventTimeFilter} [eventTime] - Filter for event time range.
 */

/**
 * @typedef {Object} ActivityLogsBordersParams
 * @property {number} pageSize - Number of records per page.
 * @property {number} pageNumber - Page number.
 * @property {string} [searchTerm] - Term to search within activity logs.
 * @property {boolean} [isLifeOfLine] - Indicator for life of line.
 * @property {ActivityLogsFilters} [filters] - Filters to apply to the activity logs query.
 */

/**
 * @typedef {Object} EventTime
 * @property {number} min - Minimum event time (timestamp).
 * @property {number} max - Maximum event time (timestamp).
 */

/**
 * @typedef {Object} ActivityLogsBordersResponse
 * @property {Object<string, string>} operators - Map containing operator details.
 * @property {EventTime} eventTime - Nested object containing min and max event times.
 * @property {Object<string, string>} eventCategories - Map containing event categories.
 */

/**
 * Composition API to interact with activity logs data.
 *
 * @returns {Object} Object containing attributes and methods for activity logs interaction.
 */
export const useActivityLogsData = (stage = APIStageEnum.service) => {
  const { t } = useI18n()

  const isExportActivityLogsLoading = ref(false)
  const activityLogsBorders = ref({})
  const isActivityLogsBordersLoading = ref(false)
  const exportActivityLogsData = ref({})
  const { eventReasonOptions = [], riskEventType = [] } = getSessionStorageItem('GENERAL_CONFIG', {})

  const fieldByNameMap = {
    adjustedCollateralTypeId: {
      labelKey: 'collateral_type',
      valueFilter: formatText,
    },
    adjustedCollateralType: {
      labelKey: 'adjusted_collateral_type',
      valueFilter: formatText,
    },
    adjustedAssetClass: {
      labelKey: 'asset_class',
      valueFilter: formatText,
    },
    adjustedAssetType: {
      labelKey: 'asset_type',
      valueFilter: formatText,
    },
    adjustedAdvanceRate: {
      labelKey: 'advance_rate',
      valueFilter: formatRate,
    },
    adjustedMaintenanceRate: {
      labelKey: 'maintenance_rate',
      valueFilter: formatRate,
    },
    adjustedConcentrationLimit: {
      labelKey: 'concentration_limit',
      valueFilter: formatRate,
    },
    adjustedUniverse: {
      labelKey: 'asset_type',
      valueFilter: formatText,
    },
    advanceRate: {
      labelKey: 'advance_rate',
      valueFilter: formatPortfolioRate,
    },
    maintenanceRate: {
      labelKey: 'maintenance_rate',
      valueFilter: formatPortfolioRate,
    },
    inititalMaintenanceRate: {
      labelKey: 'maintenance_rate',
      valueFilter: formatPortfolioRate,
    },
    expireDate: {
      labelKey: 'expiration_date_effective_until',
      valueFilter: (value) => {
        return formatDateSystemZone(value, { append: '' })
      },
    },
    reason: { labelKey: 'reason', valueFilter: formatText },
    reasonDetail: {
      labelKey: 'reason_other',
      valueFilter: formatText,
    },
    initialAdvanceRate: {
      labelKey: 'advance_rate',
      valueFilter: formatRate,
    },
    initialMaintenanceRate: {
      labelKey: 'maintenance_rate',
      valueFilter: formatRate,
    },
    concentrationLimitRate: {
      labelKey: 'concentration_limit',
      valueFilter: formatRate,
    },
    concentrationLimitAmount: {
      labelKey: 'concentration_limit',
      valueFilter: formatAmount,
    },
    initialConcentrationLimitAmount: {
      labelKey: 'concentration_limit',
      valueFilter: formatAmount,
    },
    initialConcentrationLimitRate: {
      labelKey: 'concentration_limit',
      valueFilter: formatRate,
    },
    lendingValueAdjustmentRate: {
      labelKey: 'lending_value',
      valueFilter: formatRate,
    },
    lendingValueAdjustmentAmount: {
      labelKey: 'lending_value',
      valueFilter: formatAmount,
    },
    maintenanceValueAdjustmentRate: {
      labelKey: 'maintenance_value',
      valueFilter: formatRate,
    },
    maintenanceValueAdjustmentAmount: {
      labelKey: 'maintenance_value',
      valueFilter: formatAmount,
    },
    planDueDate: {
      labelKey: 'plan_due_date',
      valueFilter: formatDate,
    },
    liquidationDate: {
      labelKey: 'liquidation_date',
      valueFilter: formatDate,
    },
    eventSuppressDateThreshold: {
      labelKey: 'suppressed_date',
      valueFilter: formatDate,
    },
    eventSuppressValueThreshold: {
      labelKey: 'suppressed_value',
      valueFilter: formatAmount,
    },
    creditPolicyId: {
      labelKey: 'credit_policy',
      valueFilter: (val) => {
        val = +val === 0 ? null : val
        return formatText(
          activityLogDataChangeFormatter?.creditPolicyMap[val] || val
        )
      },
    },
    assetNumber: {
      labelKey: 'asset_number',
      valueFilter: formatText,
    },
    collateralName: {
      labelKey: 'collateral_nickname',
      valueFilter: formatText,
    },
    hasAutoReleaseEligibility: {
      labelKey: 'auto_release_approval',
      valueFilter: (val) => {
        const text = { true: t('eligible'), false: t('not_eligible') }[
          String(val)
        ]
        return formatText(text)
      },
    },
    positionsLatestSourceType: {
      labelKey: 'holding_data_source',
      valueFilter: (val) => {
        const maps = {
          [DataSourceValueEnum.batchFile]: t('batch_file'),
          [DataSourceValueEnum.upload]: t('manual_upload'),
          [DataSourceValueEnum.api]: t('api'),
        }
        return formatText(maps[val] || val)
      },
    },
    accountLatestSourceType: {
      labelKey: 'account_data_source',
      valueFilter: (val) =>
        formatText(
          activityLogDataChangeFormatter?.collateralDataSourceMap[val] || val
        ),
    },
    assetName: {
      labelKey: 'asset_name',
      valueFilter: formatText,
    },
    custodianInstitution: {
      labelKey: 'custodian',
      valueFilter: formatText,
    },
    siInstitution: {
      labelKey: 'security_intermediary',
      valueFilter: formatText,
    },
    riskEventReason: {
      labelKey: 'call_reason',
      valueFilter: (val) => {
        const reason = eventReasonOptions.find((item) => isEqualStringIgnoreCase(item.enumCode, val)) || {}
        return reason.displayLabel || '-'
      },
    },
    callType: {
      labelKey: 'call_type',
      valueFilter: (val) => {
        return val
          ? riskEventType.find(item => isEqualStringIgnoreCase(item.enumCode, String(val)))?.displayLabel
          : '-'
      },
    }
  }

  /**
   * Exports activity log data to an Excel file with optional encryption.
   *
   * @param {ExportActivityLogsParams} params - The request parameters.
   */
  const exportActivityLogs = async (params) => {
    isExportActivityLogsLoading.value = true
    try {
      exportActivityLogsData.value = await activityLogAPI.exportActivityLogs(
        params,
        stage
      )
    } finally {
      isExportActivityLogsLoading.value = false
    }
  }

  /**
   * Fetches activity logs borders based on provided filters.
   *
   * @param {string} stage - The stage of the activity logs.
   * @param {ActivityLogsBordersParams} params - The parameters to filter activity logs.
   * @returns {Promise<ActivityLogsBordersResponse>} - A promise that resolves with the activity logs borders.
   */
  const getActivityLogsBorders = async (params) => {
    isActivityLogsBordersLoading.value = true
    try {
      activityLogsBorders.value = await activityLogAPI.getActivityLogsBorders(
        params,
        stage
      )
    } finally {
      isActivityLogsBordersLoading.value = false
    }
  }

  const isActivityLogsLoading = ref(false)
  const activityLogData = ref({})

  /**
   * List activity logs based on specified parameters.
   *
   * @param {string} stage - The stage parameter from the path.
   * @param {ListActivityLogsParams} params - The parameters for listing activity logs.
   * @returns {Promise<void>} A promise that resolves when the data is fetched.
   */
  const listActivityLogs = async (params) => {
    isActivityLogsLoading.value = true
    try {
      activityLogData.value = await activityLogAPI.listActivityLogs(
        params,
        stage
      )
      activityLogList.value = parseActivityLogs(activityLogData.value.items)
    } finally {
      isActivityLogsLoading.value = false
    }
  }

  const creditExceptionReasons = ref([])
  const getCreditExceptionReasons = async () => {
    try {
      const reasonsData = await creditExceptionAPI.getCreditExceptionReasons(
        stage
      )
      creditExceptionReasons.value = reasonsData.reasons
      reasonsData.reasons.forEach((item) => {
        activityLogDataChangeFormatter.creditExceptionReasonByCodeMap[
          item.code
        ] = item.label
      })
    } catch (error) {
      console.error('Failed to get credit exception reasons', error)
    }
  }

  let pageNumber = 1
  const pageSize = 200
  const hasMoreActivityLogs = ref(false)
  const isActivityLogListLoading = ref(false)
  const activityLogList = ref([])
  const getActivityLogs = async (params) => {
    pageNumber = 1
    isActivityLogListLoading.value = true
    try {
      if (
        Object.keys(
          activityLogDataChangeFormatter?.creditExceptionReasonByCodeMap
        ).length <= 0
      ) {
        await getCreditExceptionReasons()
      }
      const result = await activityLogAPI.listActivityLogs(
        {
          ...params,
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        stage
      )
      hasMoreActivityLogs.value = result.totalPages > pageNumber
      const logs = parseActivityLogs(result.items)
      if (pageNumber === 1) {
        activityLogList.value = logs
      } else {
        activityLogList.value.concat(logs)
      }
    } finally {
      isActivityLogListLoading.value = false
    }
  }

  const getMoreActivityLogs = async (params) => {
    pageNumber += 1
    isActivityLogListLoading.value = true
    try {
      const result = await activityLogAPI.listActivityLogs(
        {
          ...params,
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        stage
      )
      hasMoreActivityLogs.value = result.totalPages > pageNumber
      activityLogList.value.concat(parseActivityLogs(result.items))
    } finally {
      isActivityLogListLoading.value = false
    }
  }

  const parseActivityLogs = (items, conditionalFun) => {
    return (items || []).map((item) => {
      if (typeof conditionalFun === 'function') {
        return {
          ...item,
          ...(conditionalFun(item)
            ? {
                ...parseMessageContent(item.messageContent),
                dataChanges: parseDataChanges(item.dataChanges, item),
              }
            : {}),
        }
      } else {
        return {
          ...item,
          dataChangesVisible: false,
          ...parseMessageContent(item.messageContent),
          dataChanges: parseDataChanges(item.dataChanges, item),
        }
      }
    })
  }

  const parseMessageContent = (messageContent) => {
    const result = {
      description: '',
      customMessages: [],
    }
    if (messageContent) {
      const regex = /\n\s*.*?:[\s\S]*?(?=\n\s*.*:|$)/g
      const matches = messageContent.match(regex)

      if (matches && matches.length) {
        matches.forEach((item) => {
          const content = {}
          const reg = /\n(.*?):/
          const match = item.match(reg)
          if (match && match[0] && match[1]) {
            content.name = match[1].trim()
            content.label = item.replace(match[0], '').trim()
            result.customMessages.push(content)
          }
        })
        result.customMessages = result.customMessages.filter((message) =>
          message.label.trim()
        )
        const startIndex = messageContent.indexOf(matches[0])
        result.description = messageContent.substring(0, startIndex).trim()
      } else {
        result.description = messageContent.trim()
      }
    }
    return result
  }

  const needMergedFields = [
    { field: 'concentrationLimitAmount', fieldMerge: 'concentrationLimitRate' },
    {
      field: 'initialConcentrationLimitAmount',
      fieldMerge: 'initialConcentrationLimitRate',
    },
    {
      field: 'lendingValueAdjustmentAmount',
      fieldMerge: 'lendingValueAdjustmentRate',
    },
    {
      field: 'maintenanceValueAdjustmentAmount',
      fieldMerge: 'maintenanceValueAdjustmentRate',
    },
    {
      field: 'advanceRate',
      fieldMerge: 'initialAdvanceRate',
      isBeforeValue: true,
    },
    {
      field: 'concentrationLimitRate',
      fieldMerge: 'initialConcentrationLimitRate',
      isBeforeValue: true,
    },
    {
      field: 'maintenanceRate',
      fieldMerge: 'inititalMaintenanceRate',
      isBeforeValue: true,
    },
  ]

  const parseDataChanges = (dataChanges, eventItem) => {
    let results = []
    const dataChangeArr = dataChanges || []
    dataChangeArr.forEach((item) => {
      if (fieldByNameMap[item.fieldName]) {
        item.fieldLabel = t(
          fieldByNameMap[item.fieldName]?.labelKey || item.fieldName
        )

        item.formattedValueAfter = fieldByNameMap[item.fieldName]?.valueFilter
          ? fieldByNameMap[item.fieldName].valueFilter(
              item.valueAfter,
              eventItem
            )
          : item.valueAfter
        if (has(item, 'valueBefore')) {
          item.formattedValueBefore = fieldByNameMap[item.fieldName]
            ?.valueFilter
            ? fieldByNameMap[item.fieldName].valueFilter(
                item.valueBefore,
                eventItem
              )
            : item.valueBefore
        }

        const isNotReasonChange = !['reasonCode', 'reasonDetail'].includes(
          item.fieldName
        )

        const hasValue =
          !isNormalEmpty((item.valueBefore || '').replace('-', '')) ||
          !isNormalEmpty((item.valueAfter || '').replace('-', ''))

        if (isNotReasonChange && hasValue) {
          results.push(item)
        }
      }
    })

    results = mergeAttributes(results, needMergedFields)

    return results
  }

  function mergeAttributes(arr, attributes) {
    if (!arr.length || !attributes.length) {
      return arr
    }

    attributes.forEach((attribute) => {
      arr = mergeAttribute(arr, attribute)
    })

    return arr
  }

  function mergeAttribute(arr, attributes) {
    const { field, fieldMerge, isBeforeValue } = attributes
    const fieldChange = arr.find((change) => change.fieldName === field)
    const fieldMergeChange = arr.find(
      (change) => change.fieldName === fieldMerge
    )

    if (fieldChange && fieldMergeChange) {
      if (isBeforeValue) {
        fieldMergeChange.formattedValueBefore = isFormatEmpty(
          fieldMergeChange.formattedValueBefore
        )
          ? fieldMergeChange.formattedValueAfter
          : fieldMergeChange.formattedValueBefore
      }

      fieldChange.formattedValueBefore = isFormatEmpty(
        fieldChange.formattedValueBefore
      )
        ? fieldMergeChange.formattedValueBefore
        : fieldChange.formattedValueBefore

      fieldChange.formattedValueAfter = isFormatEmpty(
        fieldChange.formattedValueAfter
      )
        ? fieldMergeChange.formattedValueAfter
        : fieldChange.formattedValueAfter
      return arr.filter((change) => change.fieldName !== fieldMerge)
    } else {
      return arr
    }
  }

  function isFormatEmpty(val) {
    return [null, '', undefined, '-'].includes(val)
  }

  return {
    isActivityLogsLoading,
    activityLogData,
    listActivityLogs,
    isActivityLogsBordersLoading,
    activityLogsBorders,
    getActivityLogsBorders,
    isExportActivityLogsLoading,
    exportActivityLogsData,
    exportActivityLogs,
    getActivityLogs,
    getMoreActivityLogs,
    activityLogList,
    hasMoreActivityLogs,
    isActivityLogListLoading,
    fieldByNameMap,
    parseActivityLogs,
    parseDataChanges,
    activityLogDataChangeFormatter,
    creditExceptionReasons,
    getCreditExceptionReasons,
  }
}
