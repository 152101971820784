import { API_VERSION, http } from './http.js'
import { APIStageEnum } from '../utils/enum'
/**
 * @typedef {Object} EventTimeFilter
 * @property {Long} min - The minimum event time in milliseconds.
 * @property {Long} max - The maximum event time in milliseconds.
 */

/**
 * @typedef {Object} ListActivityLogsFilters
 * @property {Long} [applicationId] - The ID of the application.
 * @property {Long} [totalLoanFacilityId] - The ID of the total loan facility.
 * @property {Long} [loanFacilityId] - The ID of the loan facility.
 * @property {Long} [loanId] - The ID of the loan.
 * @property {Long} [amendmentId] - The ID of the amendment.
 * @property {Long} [collateralId] - The ID of the collateral.
 * @property {String[]} [eventCodes] - List of event codes.
 * @property {Long} [operatorUserId] - The ID of the operator user.
 * @property {String[]} [eventCategories] - List of event categories.
 * @property {String[]} [eventObjTypes] - List of event object types.
 * @property {EventTimeFilter} [eventTime] - The filter for event time.
 */

/**
 * @typedef {Object} ListActivityLogsParams
 * @property {Integer} pageSize - The size of each page.
 * @property {Integer} pageNumber - The number of the current page.
 * @property {String} [searchTerm] - The search term used for filtering logs.
 * @property {Boolean} [isLifeOfLine] - Whether to display logs as Life of Line.
 * @property {ListActivityLogsFilters} [filters] - The filters applied to the logs.
 */

/**
 * @typedef {Object} ActivityLogItem
 * @property {Long} eventId - Unique identifier for the event.
 * @property {Long} eventTime - The time when the event occurred.
 * @property {String} eventCode - Code representing the type of event.
 * @property {String} eventCategory - Category of the event.
 * @property {String} messageContent - Content of the event message.
 * @property {String} eventDetail - Detailed information about the event.
 * @property {Long} eventObjId - ID of the associated object for the event.
 * @property {String} eventObjType - Type of the object associated with the event.
 * @property {Long} applicationId - ID of the application associated with the event.
 * @property {Long} totalLoanFacilityId - ID of the total loan facility associated with the event.
 * @property {Long} collateralId - ID of the collateral associated with the event.
 * @property {Long} amendmentId - ID of the amendment associated with the event.
 * @property {Long} loanId - ID of the loan associated with the event.
 * @property {String} loanNumber - Number of the loan.
 * @property {Long} loanFacilityId - ID of the loan facility.
 * @property {String} loanFacilityNumber - Number of the loan facility.
 * @property {String} loanFacilityName - Name of the loan facility.
 * @property {String} status - Status of the transaction.
 * @property {BigDecimal} currentBalance - Current balance of the account.
 * @property {Boolean} canReverse - Whether the event can be reversed.
 * @property {String} reverseReason - Reason for reversing the event.
 * @property {Long} paymentScheduleId - ID of the payment schedule.
 * @property {Integer} sequenceNumber - Sequence number of the event.
 * @property {String} statementName - Name of the statement.
 * @property {Long} statementId - ID of the statement.
 */

/**
 * @typedef {Object} ActivityLogPage
 * @property {Integer} pageNumber - The current page number.
 * @property {Integer} pageSize - The size of each page.
 * @property {Integer} totalPages - Total number of pages available.
 * @property {Integer} totalItems - Total number of items available.
 * @property {ActivityLogItem[]} items - List of activity log items.
 */
export const activityLogAPI = {
  /**
   * Exports activity log data to an Excel file with optional encryption.
   * @param {Object} params - The request parameters.
   * @param {number} params.pageSize - The number of items per page.
   * @param {number} params.pageNumber - The page number.
   * @param {string} [params.search] - The search query.
   * @param {boolean} [params.isLifeOfLine] - Flag to indicate if life of line is considered.
   * @param {number} [params.filters.applicationId] - Filter by application ID.
   * @param {number} [params.filters.totalLoanFacilityId] - Filter by total loan facility ID.
   * @param {number} [params.filters.loanFacilityId] - Filter by loan facility ID.
   * @param {number} [params.filters.loanId] - Filter by loan ID.
   * @param {number} [params.filters.amendmentId] - Filter by amendment ID.
   * @param {number} [params.filters.collateralId] - Filter by collateral ID.
   * @param {Array<string>} [params.filters.eventCodes] - Filter by event codes.
   * @param {Array<string>} [params.filters.eventCategories] - Filter by event categories.
   * @param {Array<string>} [params.filters.eventObjTypes] - Filter by event object types.
   * @param {number} [params.filters.eventTime.min] - Filter by minimum event time.
   * @param {number} [params.filters.eventTime.max] - Filter by maximum event time.
   * @param {boolean} [params.shouldEncrypt=true] - Determines if the export should be encrypted.
   * @param {string} [params.headerNames='header1,header2'] - Comma-separated string of header names.
   * @param {string} [params.columnNames='column1,column2'] - Comma-separated string of column names.
   * @returns {Promise<ArrayBuffer>} - A promise that resolves with the binary stream of the Excel file.
   * @APICode {stage}.activity-logs.exports.get
   */
  exportActivityLogs(params = {}, stage = APIStageEnum.service) {
    return http.download(
      `${API_VERSION}/${stage}/activity-logs/exports`,
      params
    )
  },
  /**
   * Lists activity logs based on provided filters.
   * @param {string} stage - The stage of the activity logs.
   * @param {ActivityLogsBordersParams} params - The parameters to filter activity logs.
   * @returns {Promise<ActivityLogsBordersResponse>} - A promise that resolves with the activity logs borders.
   * @typedef {Object} ActivityLogsBordersParams
   * @property {number} pageSize - Number of records per page.
   * @property {number} pageNumber - Page number.
   * @property {string} [searchTerm] - Term to search within activity logs.
   * @property {boolean} [isLifeOfLine] - Indicator for life of line.
   * @property {ActivityLogsFilters} [filters] - Filters to apply to the activity logs query.
   * @typedef {Object} ActivityLogsFilters
   * @property {number} [applicationId] - Filter by application ID.
   * @property {number} [totalLoanFacilityId] - Filter by total loan facility ID.
   * @property {number} [loanFacilityId] - Filter by loan facility ID.
   * @property {number} [loanId] - Filter by loan ID.
   * @property {number} [amendmentId] - Filter by amendment ID.
   * @property {number} [collateralId] - Filter by collateral ID.
   * @property {Array<string>} [eventCodes] - Filter by event codes.
   * @property {number} [operatorUserId] - Filter by operator user ID.
   * @property {Array<string>} [eventCategories] - Filter by event categories.
   * @property {Array<string>} [eventObjTypes] - Filter by event object types.
   * @property {EventTimeFilter} [eventTime] - Filter for event time range.
   * @typedef {Object} EventTimeFilter
   * @property {number} [min] - Minimum event time (timestamp).
   * @property {number} [max] - Maximum event time (timestamp).
   * @typedef {Object} ActivityLogsBordersResponse
   * @property {Object<string, string>} operators - Map containing operator details.
   * @property {EventTime} eventTime - Nested object containing min and max event times.
   * @property {Object<string, string>} eventCategories - Map containing event categories.
   * @typedef {Object} EventTime
   * @property {number} min - Minimum event time (timestamp).
   * @property {number} max - Maximum event time (timestamp).
   * @APICode {stage}.activity-logs.borders.get
   */
  getActivityLogsBorders(params, stage = APIStageEnum.service) {
    return http.get(`${API_VERSION}/${stage}/activity-logs/borders`, params)
  },

  /**
   * List activity logs based on specified parameters.
   * @param {string} stage - The stage parameter from the path.
   * @param {ListActivityLogsParams} params - The parameters for listing activity logs.
   * @returns {Promise<ActivityLogPage>} - A promise that resolves to the page of activity logs.
   * @APICode {stage}.activity-logs.list
   */
  listActivityLogs(params, stage = APIStageEnum.service) {
    return http.get(`${API_VERSION}/${stage}/activity-logs`, params)
  },

  /**
   * Logs an activity based on the object's type, ID, and value.
   * @param {ActivityLogInfo} activityLogInfo - The information about the activity to log.
   * @returns {Promise<Object>} - A promise that resolves when the activity is logged.
   * @typedef {Object} ActivityLogInfo
   * @property {String} objId - The ID of the object related to the activity.
   * @property {String} objValue - The value of the object related to the activity.
   * @property {String} eventCode - The code representing the event type.
   * @APICode {stage}.activity-logs.create
   */
  createActivityLog(params, stage = APIStageEnum.service) {
    return http.post(`${API_VERSION}/${stage}/activity-logs`, params)
  },

  /**
   * Creates an internal activity log entry.
   * @param {CreateInternalTrackingReqDTO} params - The internal activity log request data.
   * @returns {Promise<Object>} - A promise that resolves when the activity log is created.
   * @typedef {Object} CreateInternalTrackingReqDTO
   * @property {number} objId - The object ID, such as a report ID.
   * @property {string} objectType - The type of the object, e.g., 'SMA REPORT'.
   * @property {string} internalUserActivityCode - The code representing the user activity.
   * @property {string} requestId - The unique identifier for the activity request.
   * @property {string} [reportName] - The name of the report involved in the activity.
   * @property {string[]} [fieldNames] - The names of the fields selected in the report.
   * @property {string} [fieldName] - The name of the field used for filtering or sorting.
   * @property {string} [value] - The value used for filtering.
   * @property {string} [order] - The order used for sorting.
   * @property {string} [searchValue] - The value used for searching.
   * @property {string} activityCategory - The category of the activity.
   * @APICode internal-activity-logs.create
   */
  createInternalActivityLog(params, config) {
    return http.post(`${API_VERSION}/internal-activity-logs`, params, config)
  },
}
