import { documentAPI } from '@/shared/api/index'
import { useCollateralData } from '../collateral/useCollateralData'
import { ref } from 'vue'
import { downloadFile, previewFile } from '@/shared/utils'

export function useDocumentData(stage) {
  const isUploadDocumentLoading = ref(false)
  const uploadResult = ref({})
  function uploadDocument(params) {
    return documentAPI
      .uploadDocument(stage, params)
      .then((data) => {
        uploadResult.value = data
        return data
      })
      .finally(() => {
        isUploadDocumentLoading.value = false
      })
  }

  const isBindDocumentRelationsLoading = ref(false)
  const bindDocumentRelationsResult = ref('')
  async function bindDocumentRelations(params) {
    const { totalLoanFacilityId, collateralId } = params
    if (!totalLoanFacilityId && collateralId) {
      const { getCollateralPackageDetails, collateralPackageDetails } =
        useCollateralData('service')
      await getCollateralPackageDetails(collateralId)
      params.totalLoanFacilityId =
        collateralPackageDetails.value.totalLoanFacilityId
      delete params.collateralId
    }
    isBindDocumentRelationsLoading.value = true
    return documentAPI
      .bindDocumentRelations(stage, params)
      .then((data) => {
        bindDocumentRelationsResult.value = data
      })
      .finally(() => {
        isBindDocumentRelationsLoading.value = false
      })
  }

  const documentInfo = ref([])
  const isBasicDocumentsLoading = ref(false)
  function getBasicDocuments(fileStorageIds) {
    isBasicDocumentsLoading.value = true
    return documentAPI
      .getBasicDocuments(stage, fileStorageIds)
      .then((data) => {
        documentInfo.value = data
      })
      .finally(() => {
        isBasicDocumentsLoading.value = false
      })
  }

  const isGetDocumentsLoading = ref(false)
  const documentsResult = ref([])
  function getDocuments(params) {
    isGetDocumentsLoading.value = true
    return documentAPI
      .listDocuments(stage, params)
      .then((data) => {
        documentsResult.value = data
        return data
      })
      .finally(() => {
        isGetDocumentsLoading.value = false
      })
  }

  const isGetDocumentsTokenLoading = ref(false)
  const documentsToken = ref(null)
  function getDocumentsToken(params) {
    isGetDocumentsTokenLoading.value = true
    return documentAPI
      .getDocumentDownloadToken(stage, params)
      .then((data) => {
        documentsToken.value = data
        return data
      })
      .finally(() => {
        isGetDocumentsTokenLoading.value = false
      })
  }

  function getDownloadFile(token, preview) {
    return documentAPI.getDownloadFile(token, preview)
  }

  function getPreviewFileUrl(token) {
    return documentAPI.getPreviewFileUrl(token)
  }

  function getUploadFileUrl(token) {
    return documentAPI.getUploadFileUrl(token)
  }
  /**
   * @param {string} token
   * @param {object} params
   * @param {boolean} [params.preview]
   * @param {string} [params.filename]
   * @param {string} [params.mime]
   */
  async function downloadDocument(token, params) {
    const { preview = false, filename, mime } = params
    const file = await documentAPI.downloadFile(token, preview)
    const fn = preview ? previewFile : downloadFile
    fn(file, filename, mime)
  }

  const isDeleteDocumentLoading = ref(false)
  function deleteDocument(documentId) {
    isGetDocumentsTokenLoading.value = true
    return documentAPI.deleteDocument(stage, documentId).finally(() => {
      isDeleteDocumentLoading.value = false
    })
  }

  /**
   * Get render document template download token.
   *
   * @param {string} stage - The stage of the document template.
   * @param {TemplateTokenParams} params - The parameters for the API call.
   * @returns {Promise<TokenRespDTO>} A promise that resolves with the download token.
   */

  const isTemplateRenderingLoading = ref(false)
  const downloadToken = ref(null)
  const getTemplateToken = async (params, _stage = stage) => {
    isTemplateRenderingLoading.value = true
    try {
      downloadToken.value = await documentAPI.getTemplateToken(_stage, params)
    } finally {
      isTemplateRenderingLoading.value = false
    }
  }

  return {
    uploadResult,
    isUploadDocumentLoading,
    uploadDocument,

    bindDocumentRelations,
    isBindDocumentRelationsLoading,
    bindDocumentRelationsResult,

    documentInfo,
    isBasicDocumentsLoading,
    getBasicDocuments,

    isGetDocumentsLoading,
    documentsResult,
    getDocuments,

    isGetDocumentsTokenLoading,
    documentsToken,
    getDocumentsToken,

    getDownloadFile,

    getPreviewFileUrl,

    getUploadFileUrl,

    isDeleteDocumentLoading,
    deleteDocument,

    downloadDocument,

    isTemplateRenderingLoading,
    downloadToken,
    getTemplateToken,
  }
}
