import { API_VERSION, http } from './http.js'

export const commonAPI = {
  /**
   * Verifies the address details with the USPS verification service.
   * @param {AddressParams} params - The address parameters to be verified.
   * @typedef {Object} AddressParams
   * @property {string} addressLine1 - The first line of the address.
   * @property {string} [addressLine2] - The second line of the address (optional).
   * @property {string} city - The city part of the address.
   * @property {string} state - The state part of the address.
   * @property {string} [postalCode] - The postal code of the address (optional).
   * @property {string} [countryOrRegion] - The country or region of the address (optional).
   * @returns {Promise<VerifyAddressResponse>} A promise that resolves with the verification result.
   * @typedef {Object} VerifyAddressResponse
   * @property {boolean} verifySuccessful - Indicates if the address verification was successful.
   * @property {string} message - The message related to the address verification.
   * @APICode commons.address-verifications.get
   */
  verifyUSPSAddress(params) {
    return http.get(`${API_VERSION}/commons/address-verifications`, params)
  },

  /**
   * Retrieve the list of holidays within a specific date range.
   * @param {string} startDate - The start date to filter holidays, in YYYY-MM-DD format.
   * @returns {Promise<Holiday[]>} - A promise that resolves with an array of holidays.
   * @APICode fully_authenticated
   */
  getHolidaysByDateRange(startDate) {
    return http.get(`${API_VERSION}/commons/holidays`, { startDate })
  },

  /**
   * Decrypts a specific field based on the object ID and field type.
   * @param {Object} params - The decryption parameters.
   * @param {string} params.decryptField - The type of field to decrypt.
   * @param {number} params.objectId - The ID of the object associated with the field.
   * @returns {Promise<DecryptionRespDTO>} - A promise that resolves with the decrypted field data.
   * @APICode commons.decryptions.get
   */
  getDecryption(params) {
    return http.get(`${API_VERSION}/commons/decryptions`, params)
  },
}
