import { http, API_VERSION, APIStageEnum } from '../http'

export const totalLoanFacilityAPI = {
  /**
   * Retrieves the total loan facility data based on the totalLoanFacilityId.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to retrieve.
   * @returns {Promise<Object>} The total loan facility data.
   * @permission service.total-loan-facilities.get
   */
  getTotalLoanFacility(stage, totalLoanFacilityId, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/total-loan-facilities/${totalLoanFacilityId}`
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/total-loan-facilities/${totalLoanFacilityId}`
    )
  },

  /**
   * Create a new total loan facility with various institutional details.
   * @param {CreateTotalLoanFacilityRequest} createTotalLoanFacilityRequest - The information required to create a total loan facility.
   * @returns {Promise<CreateTotalLoanFacilityResponse>} A promise that resolves with the ID of the newly created total loan facility.
   * @typedef {Object} CreateTotalLoanFacilityRequest
   * @property {string} totalLoanFacilityNumber - The number of the total loan facility.
   * @property {number} distributionChannelInstitutionId - The ID of the distribution channel institution.
   * @property {number} lenderInstitutionId - The ID of the lender institution.
   * @property {number} servicingInstitutionId - The ID of the servicing institution.
   * @property {number} fundingInstitutionId - The ID of the funding institution.
   * @property {number} firmInstitutionId - The ID of the firm institution.
   * @property {number} firmInternalGroupId - The ID of the firm's internal group.
   * @typedef {Object} CreateTotalLoanFacilityResponse
   * @property {number} totalLoanFacilityId - The unique ID of the newly created total loan facility.
   * @APICode service.total-loan-facilities.create
   */
  createTotalLoanFacility(createTotalLoanFacilityRequest) {
    return http.post(
      `${API_VERSION}/service/total-loan-facilities`,
      createTotalLoanFacilityRequest
    )
  },

  /**
   * Updates a total loan facility based on the provided totalLoanFacilityId.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to update.
   * @param {Object} params - The params containing the details to update.
   * @param {string} params.totalFacilityNumber - The new total facility number.
   * @param {string} params.totalFacilityName - The new total facility name.
   * @param {string} params.totalFacilityNickname - The new total facility nickname.
   * @param {string} params.status - The new status of the total loan facility.
   * @param {string} params.issuedDate - The new issued date of the total loan facility.
   * @param {number} params.secured - Indicates if the total loan facility is secured.
   * @returns {Promise<Object>} - A promise that resolves with the response of the update operation.
   * @permission service.total-loan-facilities.update
   */
  updateTotalLoanFacility(totalLoanFacilityId, params) {
    return http.put(
      `${API_VERSION}/service/total-loan-facilities/${totalLoanFacilityId}`,
      params
    )
  },

  /**
   * Deletes a Total Loan Facility by its ID.
   *
   * @param {number} totalLoanFacilityId - The ID of the loan facility to be deleted.
   * @returns {Promise<Object>} A promise that resolves with a success message or rejects with an error.
   * @permission service.total-loan-facilities.delete
   */
  deleteTotalLoanFacility(totalLoanFacilityId) {
    return http.delete(
      `${API_VERSION}/service/total-loan-facilities/${totalLoanFacilityId}`
    )
  },
  /**
   * Retrieves a list of total loan facilities based on various filters, sorted and paginated.
   * @param {ListTotalLoanFacilitiesParams} params - The parameters for the loan facilities list.
   * @returns {Promise<LoanFacilityListResponse>} - A promise that resolves with the list of loan facilities.
   * @APICode service.total-loan-facilities.list
   */
  listTotalLoanFacilities(stage, params) {
    return http.get(`${API_VERSION}/${stage}/total-loan-facilities`, params)
  },
}
