import { ref } from 'vue'
import { map } from 'lodash'
import { configurationAPI } from '@/shared/api/index'

const sicCodeOptions = ref([])

export function useConfiguration(stage) {
  const custodianConfigurations = ref([])
  const isCustodianConfigurationsLoading = ref(false)

  function listCustodianConfigurations(params) {
    isCustodianConfigurationsLoading.value = true
    return configurationAPI
      .listCustodianConfigurations(stage, params)
      .then((data) => {
        custodianConfigurations.value = data.custodians.map((custodian) => {
          return {
            ...custodian,
            institutionId: String(custodian.institutionId),
            securityIntermediaries: custodian.securityIntermediaries.map(
              (si) => {
                return {
                  ...si,
                  institutionId: String(si.institutionId),
                }
              }
            ),
          }
        })
      })
      .finally(() => {
        isCustodianConfigurationsLoading.value = false
      })
  }

  function getSicLists() {
    configurationAPI.getSicLists().then((sicCodeList) => {
      sicCodeOptions.value = map(sicCodeList, (sicCodeItem) => {
        return {
          label: sicCodeItem.sicCode + ' - ' + sicCodeItem.description,
          text: sicCodeItem.sicCode + ' - ' + sicCodeItem.description,
          value: sicCodeItem.sicCode,
        }
      })
    })
  }

  return {
    custodianConfigurations,
    listCustodianConfigurations,
    isCustodianConfigurationsLoading,

    getSicLists,
    sicCodeOptions,
  }
}
