import { computed, ref } from 'vue'
import _ from 'lodash'
import { useAmendmentData } from '../application/amendment/useAmendmentData'
import { useParty } from './usePartyData'
import { partyAPI } from '@/shared/api'
import {
  PeopleEntityTypeEnum,
  isEqualStringIgnoreCase,
  destructuringParties,
  getPartyList,
  getBorrowerList,
  getPrimaryBorrower,
  AmendmentPartyActionEnum,
} from '@/shared/utils'
import { usePartyOverviewConstraint } from '@/shared/components/party-overview/composable/usePartyOverviewConstraint'

const partiesObject = ref({})
const partiesLoading = ref(false)

const {
  normalizeIndividualParty,
  normalizeBusinessParty,
  normalizeTrustParty,
} = useParty()

function normalizeParties(partiesObject) {
  const { individuals, businesses, trusts } =
    destructuringParties(partiesObject)

  partiesObject.individuals = individuals.map((individual) =>
    normalizeIndividualParty(individual, partiesObject)
  )

  partiesObject.businesses = businesses.map((business) =>
    normalizeBusinessParty(business, partiesObject)
  )

  partiesObject.trusts = trusts.map((trust) =>
    normalizeTrustParty(trust, partiesObject)
  )

  return partiesObject
}

function getGuarantorList(partiesObject) {
  const partyList = getPartyList(partiesObject)
  return partyList.filter((party) => party?.isGuarantor)
}

function getBorrowerAddressList(partiesObject) {
  const borrowerList = getBorrowerList(partiesObject)
  const addressList = []
  for (const borrower of borrowerList) {
    if (
      isEqualStringIgnoreCase(
        borrower.entityType,
        PeopleEntityTypeEnum.individual
      )
    ) {
      addressList.push(_.cloneDeep(borrower.homeAddress))
      addressList.push(_.cloneDeep(borrower.mailAddress))
    }
    if (
      [PeopleEntityTypeEnum.business, PeopleEntityTypeEnum.trust].some(
        (item) => {
          return isEqualStringIgnoreCase(borrower.entityType, item)
        }
      )
    ) {
      addressList.push(_.cloneDeep(borrower.contactAddress))
    }
  }
  return _.compact(addressList)
}

function getBorrowerWithCreditReportList(partiesObject) {
  const borrowerList = getBorrowerList(partiesObject)
  borrowerList.length &&
    borrowerList.forEach((borrower) => {
      const creditReport = creditReports.value.filter((report) =>
        isEqualStringIgnoreCase(report.partyId, borrower.partyId)
      )
      borrower.creditReport = _.cloneDeep(creditReport)
    })
  return borrowerList
}

function getPrimaryBorrowerWithCreditReport(partiesObject) {
  const primaryBorrower = getPrimaryBorrower(partiesObject)
  if (primaryBorrower) {
    const creditReport = creditReports.value.filter((report) =>
      isEqualStringIgnoreCase(report.partyId, primaryBorrower?.partyId)
    )
    primaryBorrower.creditReport = _.cloneDeep(creditReport)
  }
  return primaryBorrower
}

function getAssetPartyRelList(partyId, partiesObject) {
  const { assetPartyRelList } = destructuringParties(partiesObject)
  return assetPartyRelList.filter((party) => party.partyId === partyId)
}

function getPartyBusinessRelationsByPartyId(partyId, parties) {
  const partyBusinessRelList = parties?.partyBusinessRelList || []
  return partyBusinessRelList.filter((party) => party.partyId === partyId)
}

function getEntities(partiesObject) {
  const { businesses, trusts } = destructuringParties(partiesObject)
  return _.concat(businesses, trusts)
}

const isPartyCreditReportLoading = ref(false)
const creditReports = ref([])

/**
 * Creates a credit report for a specified party.
 *
 * @param {string} stage - The stage of the party.
 * @param {number} partyId - The ID of the party.
 * @param {CreditReportReqDTO} creditReportReqDTO - The credit report request data.
 */
const isCreatePartyCreditReportLoading = ref(false)
async function createPartyCreditReport(stage, partyId, creditReportReqDTO) {
  isCreatePartyCreditReportLoading.value = true
  try {
    await partyAPI.createPartyCreditReport(stage, partyId, creditReportReqDTO)
  } finally {
    isCreatePartyCreditReportLoading.value = false
  }
}

const isPullPartyCreditReportLoading = ref(false)
const pullPartyCreditReportData = ref({})
/**
 * Pulls the credit report for a specified party.
 *
 * @param {string} stage - The stage of the party.
 * @param {string} partyId - The ID of the party.
 */
async function pullPartyCreditReport(stage, partyId) {
  isPullPartyCreditReportLoading.value = true
  try {
    pullPartyCreditReportData.value = await partyAPI.pullPartyCreditReport(
      stage,
      partyId
    )
  } finally {
    isPullPartyCreditReportLoading.value = false
  }
}

const isPullLexisNexisReportLoading = ref(false)
const lexisNexisReportDetails = ref({})
/**
 * Pulls a Lexis Nexis report based on partyId.
 *
 * @param {string} stage - The stage of the loan application process.
 * @param {string} partyId - The unique identifier for the party.
 */
async function pullLexisNexisReport(stage, partyId) {
  isPullLexisNexisReportLoading.value = true
  try {
    lexisNexisReportDetails.value = await partyAPI.pullLexisNexisReport(
      stage,
      partyId
    )
  } finally {
    isPullLexisNexisReportLoading.value = false
  }
}

const isUpdatePartyLoading = ref(false)
/**
 * Updates the party information by partyId.
 *
 * @param {string} stage - The loan application stage.
 * @param {string} partyId - The unique identifier for the party.
 * @param {UpdatePartyRequest} data - The data to update the party information.
 * @param {string} [requestFrom] - Optional parameter to specify the request source.
 */
async function updateParty(stage, partyId, data, requestFrom) {
  isUpdatePartyLoading.value = true
  try {
    await partyAPI.updateParty(stage, partyId, data, requestFrom)
  } finally {
    isUpdatePartyLoading.value = false
  }
}

const isUpdatePartyAdditionalInfoLoading = ref(false)
/**
 * Updates the credit additional information for a specific party.
 *
 * @param {string} stage - The stage of the party credit info.
 * @param {string} partyId - The ID of the party.
 * @param {UpdatePartyAdditionalInfoRequest} updatePartyAdditionalInfoRequest - The request payload containing override settings.
 */
async function updatePartyAdditionalInfo(
  stage,
  partyId,
  updatePartyAdditionalInfoRequest
) {
  isUpdatePartyAdditionalInfoLoading.value = true
  try {
    await partyAPI.updatePartyAdditionalInfo(
      stage,
      partyId,
      updatePartyAdditionalInfoRequest
    )
  } finally {
    isUpdatePartyAdditionalInfoLoading.value = false
  }
}

const { constraint, newPartyOverviewConstraint } = usePartyOverviewConstraint()

export function useParties() {
  const { archiveAmendmentId } = useAmendmentData()

  async function getParties(stage, totalLoanFacilityId, _loading = true) {
    partiesLoading.value = _loading
    await partyAPI
      .getListParties(stage, { totalLoanFacilityId }, archiveAmendmentId.value)
      .then((res) => {
        partiesObject.value = normalizeParties(res)
        return partiesObject.value
      })
      .finally(() => {
        partiesLoading.value = false
      })
  }

  /**
   * Fetches credit reports for a party within a specific stage.
   *
   * @param {string} stage - The stage of the party.
   * @param {Object} [params] - The parameters for the request.
   * @param {number} [params.applicationId] - The ID of the application.
   * @param {number} [params.totalLoanFacilityId] - The ID of the total loan facility.
   * @param {number} [params.proposalId] - The ID of the proposal.
   * @returns {Promise<CreditReport[]>} - A promise that resolves with the list of credit reports.
   */
  async function getListPartyCreditReports(stage, params = {}) {
    isPartyCreditReportLoading.value = true
    try {
      creditReports.value = await partyAPI.getListPartyCreditReports(
        stage,
        params,
        archiveAmendmentId.value
      )
    } finally {
      isPartyCreditReportLoading.value = false
    }
  }

  const isListBusinessOwnerLexisNexisReportLoading = ref(false)
  const businessOwnerLexisNexisReports = ref([])
  /**
   * Lists business owner's Lexis-Nexis reports based on given parameters.
   *
   * @param {string} stage - The stage of the loan application process.
   * @param {Object} params - The parameters for the request.
   */
  async function listBusinessOwnerLexisNexisReport(stage, params = {}) {
    isListBusinessOwnerLexisNexisReportLoading.value = true
    try {
      businessOwnerLexisNexisReports.value =
        await partyAPI.listBusinessOwnerLexisNexisReport(
          stage,
          params,
          archiveAmendmentId.value
        )
    } finally {
      isListBusinessOwnerLexisNexisReportLoading.value = false
    }
  }

  const isGetPartyAdditionalInfosLoading = ref(false)
  const creditAdditionalInfos = ref([])
  /**
   * Retrieves all credit-related and override information for a specified stage.
   *
   * @param {string} stage - The stage for which to fetch credit additional infos.
   * @param {GetPartyAdditionalInfosRequest} [getPartyAdditionalInfosRequest] - Optional parameters to filter the results.
   */
  async function getPartyAdditionalInfos(
    stage,
    getPartyAdditionalInfosRequest
  ) {
    isGetPartyAdditionalInfosLoading.value = true
    try {
      creditAdditionalInfos.value = await partyAPI.getPartyAdditionalInfos(
        stage,
        getPartyAdditionalInfosRequest,
        archiveAmendmentId.value
      )
    } finally {
      isGetPartyAdditionalInfosLoading.value = false
    }
  }

  const isDeletePartyCreditReportLoading = ref(false)
  const deleteCreditReportResult = ref({})

  /**
   * Deletes the credit report of a specified party.
   *
   * @param {string} stage - The stage of the party credit report.
   * @param {number} partyId - The ID of the party whose credit report is to be deleted.
   * @property {string} stage - The stage of the party credit report to be deleted.
   * @property {number} partyId - The unique identifier for the party whose credit report is to be deleted.
   */
  const deletePartyCreditReport = async (stage, partyId) => {
    isDeletePartyCreditReportLoading.value = true
    try {
      deleteCreditReportResult.value = await partyAPI.deletePartyCreditReport(
        stage,
        partyId
      )
    } finally {
      isDeletePartyCreditReportLoading.value = false
    }
  }

  const primaryContact = computed(() => {
    const {
      individuals = [],
      businesses = [],
      trusts = [],
    } = partiesObject.value
    return (
      [...individuals, ...businesses, ...trusts].find(
        (borrower) => borrower.isPrimaryContact
      ) || {}
    )
  })

  function getSensitiveInfo(stage, partyId, params) {
    return partyAPI.getPartySensitiveInfo(
      stage,
      partyId,
      params,
      archiveAmendmentId.value
    )
  }

  const isPartyDueDiligenceLoading = ref(false)
  const partyDueDiligenceList = ref([])

  /**
   * Fetches list of party due diligence information based on the stage and optional parameters.
   *
   * @param {string} stage - The stage of the loan facility process.
   * @param {Object} [params] - Optional parameters.
   * @param {number} [params.totalLoanFacilityId] - The ID of the total loan facility.
   * @param {number} [params.proposalId] - The ID of the proposal.
   */
  const getListPartyDueDiligences = async (stage, params = {}) => {
    isPartyDueDiligenceLoading.value = true
    try {
      partyDueDiligenceList.value = await partyAPI.getListPartyDueDiligences(
        stage,
        params
      )
    } finally {
      isPartyDueDiligenceLoading.value = false
    }
  }

  const isCreateDueDiligenceLoading = ref(false)
  const dueDiligenceResult = ref(null)

  /**
   * Create a new due diligence report for a party or update an existing one.
   *
   * @param {string} stage - The stage of the due diligence process.
   * @param {number} partyId - The ID of the party for which due diligence is being conducted.
   * @param {DueDiligenceReqDTO[]} dueDiligenceReqDTOs - An array of due diligence request data transfer objects.
   */
  const createPartyDueDiligence = async (
    stage,
    partyId,
    dueDiligenceReqDTOs
  ) => {
    isCreateDueDiligenceLoading.value = true
    try {
      dueDiligenceResult.value = await partyAPI.createPartyDueDiligence(
        stage,
        partyId,
        dueDiligenceReqDTOs
      )
    } finally {
      isCreateDueDiligenceLoading.value = false
    }
  }

  const isGetSingleCustomerIdentificationProgramsLoading = ref(false)
  const customerIdentificationPrograms = ref(null)

  /**
   * Retrieves detailed information about customer identification programs associated with a specific party ID.
   *
   * @param {string} stage - The stage of the process.
   * @param {number} partyId - The unique identifier of the party.
   * @param {number} totalLoanFacilityId - The ID of the total loan facility.
   * @returns {Promise<CustomerIdentificationProgramResp>} - A promise that resolves with the customer identification programs data.
   */
  const getSingleCustomerIdentificationPrograms = async (
    stage,
    partyId,
    totalLoanFacilityId
  ) => {
    isGetSingleCustomerIdentificationProgramsLoading.value = true
    try {
      customerIdentificationPrograms.value =
        await partyAPI.getCustomerIdentificationPrograms(
          stage,
          partyId,
          totalLoanFacilityId
        )
    } finally {
      isGetSingleCustomerIdentificationProgramsLoading.value = false
    }
  }

  const isUpdateCustomerIdentificationProgramLoading = ref(false)
  const customerIdentificationProgramResponse = ref({})

  /**
   * Handles the customer identification program for a party based on the provided IDs.
   *
   * @param {String} stage - The stage of the customer identification program.
   * @param {Number} partyId - The ID of the party.
   * @param {Number} cipId - The ID of the customer identification program.
   * @param {CustomerIdentificationProgramReqDTO} cipData - The data for updating the customer identification program.
   * @returns {Promise<CustomerIdentificationProgramResponse>} - A promise that resolves with the response after handling CIP.
   */
  const updateCustomerIdentificationProgram = async (
    stage,
    partyId,
    cipId,
    cipData
  ) => {
    isUpdateCustomerIdentificationProgramLoading.value = true
    try {
      customerIdentificationProgramResponse.value =
        await partyAPI.updateCustomerIdentificationProgram(
          stage,
          partyId,
          cipId,
          cipData
        )
    } finally {
      isUpdateCustomerIdentificationProgramLoading.value = false
    }
  }

  const isCalculateBrrLoading = ref(false)
  function calculatePartyBrr(stage, parties) {
    isCalculateBrrLoading.value = true
    return partyAPI.createBorrowerRiskRating(stage, parties).finally(() => {
      isCalculateBrrLoading.value = false
    })
  }

  const hasNewParty = computed(() => {
    const list = getPartyList(partiesObject.value)
    return list.some(
      (party) => party.amendmentAction === AmendmentPartyActionEnum.add
    )
  })

  return {
    partiesObject,
    partiesLoading,
    getParties,
    destructuringParties,
    normalizeParties,
    getGuarantorList,

    getPartyList,
    getEntities,

    getBorrowerList,
    getBorrowerAddressList,
    getPrimaryBorrower,

    getAssetPartyRelList,
    getPartyBusinessRelationsByPartyId,
    primaryContact,

    constraint,
    newPartyOverviewConstraint,

    isPartyCreditReportLoading,
    creditReports,
    getListPartyCreditReports,

    isCreatePartyCreditReportLoading,
    createPartyCreditReport,

    isListBusinessOwnerLexisNexisReportLoading,
    businessOwnerLexisNexisReports,
    listBusinessOwnerLexisNexisReport,

    isPullPartyCreditReportLoading,
    pullPartyCreditReportData,
    pullPartyCreditReport,

    isPullLexisNexisReportLoading,
    lexisNexisReportDetails,
    pullLexisNexisReport,

    isUpdatePartyLoading,
    updateParty,

    isGetPartyAdditionalInfosLoading,
    creditAdditionalInfos,
    getPartyAdditionalInfos,
    getSensitiveInfo,

    isUpdatePartyAdditionalInfoLoading,
    updatePartyAdditionalInfo,

    getBorrowerWithCreditReportList,
    getPrimaryBorrowerWithCreditReport,

    isDeletePartyCreditReportLoading,
    deleteCreditReportResult,
    deletePartyCreditReport,

    isPartyDueDiligenceLoading,
    partyDueDiligenceList,
    getListPartyDueDiligences,

    isCreateDueDiligenceLoading,
    dueDiligenceResult,
    createPartyDueDiligence,

    isGetSingleCustomerIdentificationProgramsLoading,
    customerIdentificationPrograms,
    getSingleCustomerIdentificationPrograms,

    isUpdateCustomerIdentificationProgramLoading,
    customerIdentificationProgramResponse,
    updateCustomerIdentificationProgram,

    isCalculateBrrLoading,
    calculatePartyBrr,

    hasNewParty,
  }
}

export const usePartiesData = useParties
