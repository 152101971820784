import { http, API_VERSION, APIStageEnum } from './http'

/**
 * @typedef {Object} UpdatePartyRequest
 * @property {string} externalPartyNumber - The external number associated with the party.
 * @property {string} borrowerRiskRating - The risk rating for the borrower.
 */

/**
 * @typedef {Object} UpdatePartyResponse
 * @property {string} message - The response message indicating the result of the update operation.
 */

/**
 * @typedef {Object} LexisNexisDocumentReport
 * @property {string} documentId - The unique identifier for the document.
 * @property {string} fileName - The name of the file.
 * @property {string} documentType - The type of the document.
 * @property {number} createdAt - The creation timestamp of the document.
 * @property {string} fileStorageId - The storage identifier for the file.
 */

/**
 * @typedef {Object} DueDiligence
 * @property {string} dueDiligenceReportType - The type of the due diligence report.
 * @property {string} note - Any notes associated with the due diligence.
 * @property {LexisNexisDocumentReport} document - The document report details.
 * @property {boolean} reportPullAble - Indicates if the report can be pulled.
 * @property {boolean} reportUploadAble - Indicates if the report can be uploaded.
 * @property {boolean} isLastReport - Indicates if this is the last report.
 */

/**
 * @typedef {Object} BusinessOwnerLexisNexisReport
 * @property {string} partyId - The unique identifier for the party.
 * @property {string} businessName - The name of the business.
 * @property {number} ownershipPercent - The percentage of ownership.
 * @property {string} fullName - The full name of the business owner.
 * @property {DueDiligence[]} dueDiligences - A list of due diligence reports.
 */

/**
 * @typedef {Object} PullLexisNexisReportResponse
 * @property {string} reportId - The unique identifier of the Lexis Nexis report.
 * @property {string} status - The status of the report pull operation.
 */

/**
 * @typedef {Object} UpdatePartyRequest
 * @property {string} externalPartyNumber - The external number associated with the party.
 * @property {string} borrowerRiskRating - The risk rating for the borrower.
 */

/**
 * @typedef {Object} UpdatePartyResponse
 * @property {string} message - The response message indicating the result of the update operation.
 */

/**
 * @typedef {Object} LexisNexisDocumentReport
 * @property {string} documentId - The unique identifier for the document.
 * @property {string} fileName - The name of the file.
 * @property {string} documentType - The type of the document.
 * @property {number} createdAt - The creation timestamp of the document.
 * @property {string} fileStorageId - The storage identifier for the file.
 */

/**
 * @typedef {Object} DueDiligence
 * @property {string} dueDiligenceReportType - The type of the due diligence report.
 * @property {string} note - Any notes associated with the due diligence.
 * @property {LexisNexisDocumentReport} document - The document report details.
 * @property {boolean} reportPullAble - Indicates if the report can be pulled.
 * @property {boolean} reportUploadAble - Indicates if the report can be uploaded.
 * @property {boolean} isLastReport - Indicates if this is the last report.
 */

/**
 * @typedef {Object} BusinessOwnerLexisNexisReport
 * @property {string} partyId - The unique identifier for the party.
 * @property {string} businessName - The name of the business.
 * @property {number} ownershipPercent - The percentage of ownership.
 * @property {string} fullName - The full name of the business owner.
 * @property {DueDiligence[]} dueDiligences - A list of due diligence reports.
 */

/**
 * @typedef {Object} PullLexisNexisReportResponse
 * @property {string} reportId - The unique identifier of the Lexis Nexis report.
 * @property {string} status - The status of the report pull operation.
 */

export const partyAPI = {
  /**
   * List party information based on the provided objectId.
   *
   * @param {string} stage - The stage of the party information to retrieve.
   * @param {Object} params - The request parameters.
   * @param {number} [params.proposalId] - The proposal ID to filter parties by.
   * @param {number} [params.applicationId] - The application ID to filter parties by.
   * @param {number} [params.totalLoanFacilityId] - The total loan facility ID to filter parties by.
   * @returns {Promise<Object>} The party information.
   *
   * @APICode {stage}.parties.list
   * @archives  APICode {stage}.archives.parties.list
   */
  getListParties(stage, params, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/parties`,
        params
      )
    }
    return http.get(`${API_VERSION}/${stage}/parties`, params)
  },

  /**
   * Get information about a single party.
   *
   * @param {Object} params
   * @param {String} stage - The stage of the API.
   * @param {Number} partyId - The ID of the party.
   * @returns {Promise<Object>} The party information.
   * @APICode: {stage}.parties.get
   * @archives APICode: {stage}.archives.parties.get
   */
  getSingleParty(stage, partyId, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/parties/${partyId}`
      )
    }
    return http.get(`${API_VERSION}/${stage}/parties/${partyId}`)
  },

  /**
   * Deletes a single party by given party ID.
   *
   * @param {Object} params
   * @param {String} params.stage - The stage of the party .
   * @param {Long} params.partyId - The ID of the party to be deleted.
   * @APICode {stage}.parties.delete
   */
  deleteSingleParty(stage, partyId) {
    return http.delete(`${API_VERSION}/${stage}/parties/${partyId}`)
  },

  /**
   * Revokes a previously deleted party by resetting its state to initial.
   * @param {RevokeParams} params - The parameters for the revoke action.
   * @returns {Promise<RevokeResponse>} A promise that resolves with a success message.
   * @APICode {stage}.parties.revoke
   */
  revokeDeletedParty(stage, partyId) {
    return http.patch(`${API_VERSION}/${stage}/parties/${partyId}/revokes`)
  },

  /**
   * Creates a new individual party record with a set of personal and contact information, identification, employment details, and addresses.
   *
   * @param {string} stage - The stage of the party creation process. Values: ESTIMATE, PROPOSAL, APPLICATION, UNDERWRITING, SERVICE, AMENDMENT.
   * @param {Object} params - The request payload containing personal, contact, identification, employment details, and addresses.
   * @returns {Promise<Object>} The response containing the created party ID.
   * @auth required
   * @APICode {stage}.parties.individuals.create
   */
  createIndividualParty(stage, params, config) {
    return http.post(
      `${API_VERSION}/${stage}/parties/individuals`,
      params,
      config
    )
  },

  /**
   * Creates or updates an association between a party and a business object, with the ability to set
   * primary contact and borrower attributes.
   *
   * @param {string} stage - The stage of the API call, to determine the permissions and logic flows.
   * @param {Object} params - The request payload containing object party relation list.
   * @param {Array} params.objectPartyRelationList - List of object party relations to be created or updated.
   * @returns {Promise<Object>} Promise object representing the operation result.
   * @APICode {stage}.parities.object-relations.create-or-update
   */
  updateObjectPartyRelation(stage, params) {
    return http.post(`${API_VERSION}/${stage}/parties/object-relations`, params)
  },

  /**
   * Updates the information of an individual party based on their unique party identifier.
   *
   * @param {string} stage - The stage of the API.
   * @param {Object} party - The request payload containing individual party information.
   * @returns {Promise<Object>} The response object indicating the success or failure of the operation.
   * @apiCode {stage}.parties.individuals.update
   */
  updateIndividualParty(stage, party) {
    return http.patch(
      `${API_VERSION}/${stage}/parties/${party.partyId}/individuals`,
      party
    )
  },

  /**
   * Unrestricts a user by removing certain restrictions associated with their party ID.
   * @param {string} stage - The stage of the party.
   * @param {string} partyId - The party ID of the user.
   * @returns {Promise<ApiResponse>} A promise that resolves when the user restrictions are removed.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/party/userUnrestriction.md
   * @APICode stage.parties.user-unrestrictions.update
   */
  updateUserUnrestrictions(stage, partyId) {
    return http.patch(`${API_VERSION}/${stage}/parties/${partyId}/user-unrestrictions`)
  },

  /**
   * Creates a business party based on the provided data.
   *
   * @param {string} stage - The stage of the party to create.
   * @param {Object} params - The request payload containing business party details.
   * @param {number|null} params.proposalId - The proposal ID.
   * @param {number|null} params.applicationId - The application ID.
   * @param {number} params.totalLoanFacilityId - The total loan facility ID.
   * @param {string} params.externalId - The external ID.
   * @param {string} params.legalName - The legal name of the business.
   * @param {string} params.shortName - The short name of the business.
   * @param {string} params.taxId - The tax ID.
   * @param {string} params.mainPhone - The main phone number.
   * @param {string} params.email - The email address.
   * @param {string} params.naicsCode - The NAICS code.
   * @param {string} params.typeOfBusiness - The type of business.
   * @param {string} params.businessRegistrationType - The business registration type.
   * @param {string} params.grossAnnualRevenue - The gross annual revenue.
   * @param {string} params.sourceOfFunds - The source of funds.
   * @param {string} params.businessFormationDate - The business formation date.
   * @param {string} params.businessFormationState - The business formation state.
   * @param {string} params.countryOfLegalEstablishment - The country of legal establishment.
   * @param {boolean} params.isNonprofit - Indicates if the business is a nonprofit.
   * @param {boolean} params.isOperatingBusiness - Indicates if it is an operating business.
   * @param {Object} params.contactAddress - The contact address.
   * @param {Array<Object>} params.userDefinedFields - The user-defined fields.
   * @returns {Promise<Object>} The response containing the created party ID.
   *
   * @APICode {stage}.parties.businesses.create
   */
  createBusinessParty(stage, params) {
    return http.post(`${API_VERSION}/${stage}/parties/businesses`, params)
  },

  /**
   * Updates business party details associated with a specific party ID.
   *
   * @param {string} stage - The stage of the party to update.
   * @param {number} partyId - The ID of the party to update.
   * @param {Object} params - The request payload containing business party details.
   * @param {string} [params.externalId] - The external ID of the business party.
   * @param {string} [params.legalName] - The legal name of the business party.
   * @param {string} [params.shortName] - The short name of the business party.
   * @param {string} [params.taxId] - The tax ID of the business party.
   * @param {string} [params.mainPhone] - The main phone number of the business party.
   * @param {string} [params.email] - The email address of the business party.
   * @param {string} [params.naicsCode] - The NAICS code of the business party.
   * @param {string} [params.typeOfBusiness] - The type of business.
   * @param {string} [params.businessRegistrationType] - The business registration type.
   * @param {string} [params.grossAnnualRevenue] - The gross annual revenue.
   * @param {string} [params.sourceOfFunds] - The source of funds.
   * @param {string} [params.businessFormationDate] - The business formation date in format YYYY-MM-DD.
   * @param {string} [params.businessFormationState] - The state of business formation.
   * @param {string} [params.countryOfLegalEstablishment] - The country of legal establishment.
   * @param {boolean} [params.isNonprofit] - Indicates if the business is a nonprofit.
   * @param {boolean} [params.isOperatingBusiness] - Indicates if the business is operating.
   * @param {Object} [params.contactAddress] - The contact address of the business party.
   * @param {Array} [params.userDefinedFields] - User defined fields for the business party.
   * @returns {Promise<Object>} Promise object representing the operation result.
   * @APICode {stage}.parties.businesses.update
   */
  updateBusinessParty(stage, party) {
    return http.patch(
      `${API_VERSION}/${stage}/parties/${party.partyId}/businesses`,
      party
    )
  },

  /**
   * Create Trust Party
   * This API is used to create a trust party.
   *
   * @param {Object} params - The request payload
   * @param {String} stage - The stage of the party to create
   * @returns {Promise<Object>} - The response object containing the created party ID
   *
   * @APICode {stage}.parties.trusts.create
   */
  createTrustParty(stage, party, config) {
    return http.post(`${API_VERSION}/${stage}/parties/trusts`, party, config)
  },

  /**
   * Updates business party details associated with a specific party ID.
   *
   * @param {Object} params - The parameters for updating the trust party.
   * @param {String} stage - The stage of the party to add.
   * @param {Long} params.partyId - The ID of the party to update.
   * @param {Object} payload - The request payload containing trust party details.
   *
   * @APICode {stage}.parties.trust.update
   */
  updateTrustParty(stage, party) {
    return http.patch(
      `${API_VERSION}/${stage}/parties/${party.partyId}/trusts`,
      party
    )
  },

  /**
   * Create Party Relation
   * This API is used to create relationships between parties.
   *
   * @param {Object} params - The parameters for creating party relation
   * @param {String} stage - The stage of the application
   * @param {Object} payload - The payload containing party relation details
   * @param {Number} payload.totalLoanFacilityId - The ID of the total loan facility
   * @param {Number} payload.applicationId - The ID of the application
   * @param {Array} payload.partyRelationList - List of party relations
   *
   * @APICode {stage}.parties.party-relations.create
   */
  createPartyRelation(stage, payload) {
    return http.post(`${API_VERSION}/${stage}/parties/party-relations`, payload)
  },

  /**
   * Deletes a list of party relations.
   *
   * @param {Object} params - The request payload containing a list of party relations to be deleted.
   * @param {Array} params.partyRelationList - List of party relations to delete.
   * @param {string} stage - The stage parameter to specify the environment (e.g., dev, prod).
   * @returns {Promise} - A promise that resolves with the response of the deletion operation.
   *
   * @APICode {stage}.parties.party-relations.delete
   */
  deletePartyRelations(stage, params) {
    return http.delete(
      `${API_VERSION}/${stage}/parties/party-relations`,
      params
    )
  },

  /**
   * Create a relation between an asset and a party.
   *
   * @param {Object} payload - The payload to create asset-party relation.
   * @param {String} stage - The stage in the URL path.
   * @returns {Promise}
   *
   * @APICode {stage}}.parties.assetRelations.create
   */
  createAssetPartyRelation(stage, payload) {
    return http.post(`${API_VERSION}/${stage}/parties/asset-relations`, payload)
  },

  /**
   * Deletes the relationship between an asset and a party.
   *
   * @param {Object} payload - The payload containing the list of asset-party relations to be deleted.
   * @param {Array} payload.assetPartyRelationList - List of asset-party relations.
   * @returns Promise resolving to the response of the deletion operation.
   *
   * Each object in assetPartyRelationList must contain:
   * - partyId: The ID of the party.
   * - assetId: The ID of the asset.
   * @APICode {stage}.assetPartyRelations.delete
   */
  deleteAssetPartyRelation(stage, payload) {
    return http.delete(
      `${API_VERSION}/${stage}/parties/asset-relations`,
      payload
    )
  },

  /**
   * Creates a user association for a party within a specified stage.
   * @param {string} stage - The stage of the party.
   * @param {number} partyId - The ID of the party.
   * @returns {Promise<UserAssociationRespDTO>} - A promise that resolves with the user association data.
   */
  createUserAssociation(stage, partyId) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/user-associations`
    )
  },

  /**
   * Update user profile information for a specific party and user.
   * @param {string} stage - The stage of the API endpoint.
   * @param {string} partyId - The unique identifier for the party.
   * @param {UpdateUserProfileReqDTO} userProfileData - The user profile data to update.
   * @returns {Promise<void>} A promise that resolves when the user profile is updated.
   */
  updateUserProfile(stage, partyId, userProfileData) {
    return http.patch(
      `${API_VERSION}/${stage}/parties/${partyId}/user-profiles`,
      userProfileData
    )
  },

  /**
   * @typedef {Object} AuthenticatePartyReqDTO
   * @property {string} authenticateType - The type of authentication to be used (SMS/EMAIL).
   */

  /**
   * @typedef {Object} AuthenticatePartyRespDTO
   * @property {string} pin - The randomly generated pin code.
   */

  /**
   * Sends an authentication message (SMS/Email) to the specified party with a randomly generated pin code.
   * @APICode party.parties.authentication-messages.create
   * @param {string} stage - The stage of the party.
   * @param {number} partyId - The ID of the party to authenticate.
   * @param {AuthenticatePartyReqDTO} requestData - The request payload containing the authentication type.
   * @returns {Promise<AuthenticatePartyRespDTO>} - A promise that resolves with the authentication response containing the pin code.
   */
  createAuthenticationMessage(stage, partyId, requestData) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/authentication-messages`,
      requestData
    )
  },

  /**
   * @typedef {Object} VerifyAuthenticationMessageResponse
   * @property {boolean Indicates} verified - if the authentication message was verified successfully.
   */

  /**
   * @typedef {Object} VerifyAuthenticationMessageOptions
   * @property {string} stage - The stage in which the verification is performed.
   * @property {number} partyId - The unique identifier of the party.
   */

  /**
   * Verifies the authentication message associated with a party.
   * @param {VerifyAuthenticationMessageOptions} options - The verification options including stage and partyId.
   * @returns {Promise<VerifyAuthenticationMessageResponse>} - A promise that resolves with the verification result.
   * @property {string} options.stage - The stage in which the verification is performed.
   * @property {number} options.partyId - The unique identifier of the party.
   */
  verifyAuthenticationMessage(stage, partyId) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/authentication-messages/verifications`
    )
  },

  /**
   * Retrieves sensitive information for a party based on the party ID.
   * @param {string} stage - The stage of the party.
   * @param {string} partyId - The unique identifier of the party.
   * @param {SensitiveInfoRequest} [sensitiveInfoRequest] - An object containing the name of the sensitive field to retrieve.
   * @returns {Promise<SensitiveInfoResponse>} A promise that resolves with the sensitive information of the party.
   * @apiCode {stage}.parties.sensitive-infos.get
   * @archives apiCode {stage}.archives.parties.sensitive-infos.get
   */
  getPartySensitiveInfo(
    stage,
    partyId,
    sensitiveInfoRequest = {},
    archiveAmendmentId
  ) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/parties/${partyId}/sensitive-infos`,
        sensitiveInfoRequest
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/parties/${partyId}/sensitive-infos`,
      sensitiveInfoRequest
    )
  },
  /**
   * List all credit reports for a party within a specific stage.
   * @param {string} stage - The stage of the party.
   * @param {Object} [params] - The parameters for the request.
   * @param {number} [params.applicationId] - The ID of the application.
   * @param {number} [params.totalLoanFacilityId] - The ID of the total loan facility.
   * @param {number} [params.proposalId] - The ID of the proposal.
   * @returns {Promise<CreditReport[]>} - A promise that resolves with the list of credit reports.
   * @typedef {Object} CreditReport
   * @property {string} partyId - The ID of the party.
   * @property {string} originalPartyId - The ID of the original party.
   * @property {string} partyName - The name of the party.
   * @property {string} entityType - The entity type of the party.
   * @property {boolean} isCreditLock - Indicates if the credit is locked.
   * @property {string} creditScore - The credit score of the party.
   * @property {boolean} isCreditNoHit - Indicates if there is no credit hit.
   * @property {boolean} isNoScore - Indicates if there is no score.
   * @property {boolean} isFraudAlert - Indicates if there is a fraud alert.
   * @property {boolean} isLastReport - Indicates if this is the last report.
   * @property {CreditFactor[]} creditFactors - The credit factors.
   * @property {CreditReportDocument} document - The document information.
   * @property {string} creditReportPullAt - The timestamp of when the credit report was pulled.
   * @property {string} documentSourceType - The source type of the document.
   * @typedef {Object} CreditFactor
   * @property {string} factorCode - The code of the factor.
   * @property {string} factorDesc - The description of the factor.
   * @typedef {Object} CreditReportDocument
   * @property {string} documentId - The ID of the document.
   * @property {string} documentType - The type of the document.
   * @property {string} fileName - The name of the file.
   * @property {string} mediaType - The media type of the file.
   * @property {string} fileSize - The size of the file.
   * @property {string} fileStorageId - The ID of the file storage.
   * @property {number} fileState - The state of the file.
   * @property {string} updatedAt - The timestamp of the last update.
   * @property {string[]} operationsAllowed - The operations allowed on the document.
   * @APICode {stage}.parties.credit-reports.list
   * @archives APICode {stage}.archives.parties.credit-reports.list
   */
  getListPartyCreditReports(stage, params = {}, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/parties/credit-reports`,
        params
      )
    }
    return http.get(`${API_VERSION}/${stage}/parties/credit-reports`, params)
  },

  /**
   * Post a credit report for a specified party in a given stage.
   * @param {string} stage - The stage of the party.
   * @param {number} partyId - The ID of the party.
   * @param {CreditReportReqDTO} creditReportReqDTO - The credit report request data.
   * @returns {Promise} - A promise that resolves when the credit report is created.
   * @typedef {Object} CreditReportReqDTO
   * @property {number} creditScore - The credit score.
   * @property {boolean} isCreditFreeze - Indicates if the credit is frozen.
   * @APICode {stage}.parties.credit-reports.create
   */
  createPartyCreditReport(stage, partyId, creditReportReqDTO) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/credit-reports`,
      creditReportReqDTO
    )
  },

  /**
   * Pull the credit report for a specified party.
   * @param {string} stage - The stage of the party.
   * @param {string} partyId - The ID of the party.
   * @returns {Promise<CreditReportPullRespDTO>} - A promise that resolves with the credit report pull response.
   * @typedef {Object} CreditReportPullRespDTO
   * @property {number} creditInfoId - The ID of the credit info.
   * @property {number} expireDays - The number of days until the credit info expires.
   * @APICode {stage}.parties.credit-reports.pulls.post
   */
  pullPartyCreditReport(stage, partyId) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/credit-reports/pulls`
    )
  },

  /**
   * Retrieve details about the business owner's Lexis-Nexis report based on the application ID.
   * @param {string} stage - The stage of the loan application process.
   * @param {Object} params - The parameters for the request.
   * @param {number} [params.proposalId] - The proposal ID to filter the reports.
   * @param {number} [params.applicationId] - The application ID to filter the reports.
   * @param {number} [params.totalLoanFacilityId] - The total loan facility ID to filter the reports.
   * @returns {Promise<BusinessOwnerLexisNexisReport[]>} - A promise that resolves with the list of business owner's Lexis-Nexis reports.
   * @APICode {stage}.parties.lexis-nexis-reports.ownerships.list
   * @archives APICode {stage}.archives.parties.lexis-nexis-reports.ownerships.list
   */
  listBusinessOwnerLexisNexisReport(stage, params = {}, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/parties/lexis-nexis-reports/ownerships`,
        params
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/parties/lexis-nexis-reports/ownerships`,
      params
    )
  },

  /**
   * Pull a Lexis Nexis report based on partyId.
   * @param {string} stage - The stage of the loan application process.
   * @param {string} partyId - The unique identifier for the party.
   * @returns {Promise<PullLexisNexisReportResponse>} - A promise that resolves with the Lexis Nexis report details.
   * @APICode {stage}.parties.lexis-nexis-reports.pulls.post
   */
  pullLexisNexisReport(stage, partyId) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/lexis-nexis-reports/pulls`
    )
  },

  /**
   * Update the party information by partyId.
   * @param {string} partyId - The unique identifier for the party.
   * @param {UpdatePartyRequest} data - The data to update the party information.
   * @param {string} [requestFrom] - Optional parameter to specify the request source.
   * @returns {Promise<UpdatePartyResponse>} - A promise that resolves with the update operation result.
   * @APICode {stage}.parties.update
   */
  updateParty(stage, partyId, data, requestFrom) {
    const headers = requestFrom ? { requestFrom } : undefined
    return http.patch(`${API_VERSION}/${stage}/parties/${partyId}`, data, {
      headers,
    })
  },
  /**
   * Update the credit additional information for a specific party based on the provided override settings.
   * @param {string} stage - The stage of the party credit info.
   * @param {string} partyId - The ID of the party.
   * @param {UpdatePartyAdditionalInfoRequest} updatePartyAdditionalInfoRequest - The request payload containing override settings.
   * @returns {Promise} - A promise that resolves when the update operation is successful.
   * @typedef {Object} UpdatePartyAdditionalInfoRequest
   * @property {Boolean} overrideMla - Flag to override MLA.
   * @property {Boolean} overrideOfac - Flag to override OFAC.
   * @property {Boolean} overrideFraudShield - Flag to override Fraud Shield.
   * @property {Boolean} overrideBankruptcy - Flag to override Bankruptcy.
   * @property {Boolean} overrideDeceased - Flag to override Deceased.
   * @property {String} overrideReason - Reason for the override.
   * @APICode {stage}.parties.credit-additional-infos.patch
   */
  updatePartyAdditionalInfo(stage, partyId, updatePartyAdditionalInfoRequest) {
    return http.patch(
      `${API_VERSION}/${stage}/parties/${partyId}/credit-additional-infos`,
      updatePartyAdditionalInfoRequest
    )
  },

  /**
   * Retrieve all credit-related and override information for a specified stage from the system.
   * @param {string} stage - The stage for which to fetch credit additional infos.
   * @param {GetPartyAdditionalInfosRequest} [getPartyAdditionalInfosRequest] - Optional parameters to filter the results.
   * @returns {Promise<Array<CreditAdditionalInfo>>} - A promise that resolves with the list of credit additional infos.
   * @typedef {Object} GetPartyAdditionalInfosRequest
   * @property {number} [applicationId] - The ID of the application.
   * @property {number} [totalLoanFacilityId] - The ID of the total loan facility.
   * @property {number} [proposalId] - The ID of the proposal.
   * @typedef {Object} CreditAdditionalInfo
   * @property {String} partyId - The ID of the party.
   * @property {String} originalPartyId - The original ID of the party.
   * @property {Boolean} mla - MLA flag status.
   * @property {Boolean} ofac - OFAC flag status.
   * @property {Boolean} fraudShield - Fraud Shield flag status.
   * @property {Boolean} bankRuptcy - Bankruptcy flag status.
   * @property {Boolean} deceased - Deceased flag status.
   * @property {Boolean} overrideMla - Override MLA flag status.
   * @property {Boolean} overrideOfac - Override OFAC flag status.
   * @property {Boolean} overrideFraudShield - Override Fraud Shield flag status.
   * @property {Boolean} overrideBankruptcy - Override Bankruptcy flag status.
   * @property {Boolean} overrideDeceased - Override Deceased flag status.
   * @property {Boolean} overrideSafeScan - Override Safe Scan flag status.
   * @property {Boolean} overrideAddressDiscrepancy - Override Address Discrepancy flag status.
   * @property {Boolean} overrideReason - Override Reason flag status.
   * @property {Boolean} hasCreditScore - Has Credit Score flag status.
   * @APICode {stage}.parties.credit-additional-infos.list
   * @archives APICode {stage}.archives.parties.credit-additional-infos.list
   */
  getPartyAdditionalInfos(
    stage,
    getPartyAdditionalInfosRequest,
    archiveAmendmentId
  ) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/parties/credit-additional-infos`,
        getPartyAdditionalInfosRequest
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/parties/credit-additional-infos`,
      getPartyAdditionalInfosRequest
    )
  },

  /**
   * Update relationship between parties.
   * @param {string} stage - The stage of the party relationship.
   * @param {string} partyRelationId - The ID of the party relation to update.
   * @param {UpdatePartyRelationReqDTO} requestBody - The request payload containing the details to update.
   * @returns {Promise<Object>} - A promise that resolves with the response data.
   * @typedef {Object} UpdatePartyRelationReqDTO
   * @property {string} ownershipPercent - The percentage of ownership.
   * @property {string} title - The title of the party relation.
   * @APICode {stage}.parties.party-relations.update
   */
  updatePartyRelation(stage, partyRelationId, requestBody) {
    return http.patch(
      `${API_VERSION}/${stage}/parties/party-relations/${partyRelationId}`,
      requestBody
    )
  },

  /**
   * Retrieves statistics about parties based on the stage.
   * @param {string} stage - The stage of the parties to retrieve statistics for.
   * @returns {Promise<PartyStatisticsRespDTO>} A promise that resolves with the party statistics data.
   * @APICode {stage}.parties.statistics.get
   */
  getPartyStatistics(stage) {
    return http.get(`${API_VERSION}/${stage}/parties/statistics`)
  },

  /**
   * Get detailed information on party and loan facilities under a specific lender stage.
   * @param {string} stage - The stage of the lender.
   * @param {GetPartyLoanFacilitiesParams} params - The parameters for the API call.
   * @returns {Promise<PartyLoanFacilitiesResponse>} A promise that resolves to the party and loan facilities data.
   * @typedef {Object} GetPartyLoanFacilitiesParams
   * @property {number} pageSize - The number of items per page.
   * @property {number} pageNumber - The number of the current page.
   * @property {string} [searchTerm] - The search term to filter results.
   * @property {string} [sort] - The sorting order of the results.
   * @typedef {Object} PartyLoanFacilitiesResponse
   * @property {number} pageNumber - The current page number.
   * @property {number} pageSize - The number of items per page.
   * @property {number} totalPages - The total number of pages.
   * @property {number} totalItems - The total number of items.
   * @property {Array<PartyLoanFacilityItem>} items - The list of party loan facility items.
   * @typedef {Object} PartyLoanFacilityItem
   * @property {number} partyId - The ID of the party.
   * @property {string} partyName - The name of the party.
   * @property {string} email - The email of the party.
   * @property {number} totalLoanFacilityId - The ID of the total loan facility.
   * @property {Array<LoanFacility>} loanFacilities - The list of loan facilities.
   * @typedef {Object} LoanFacility
   * @property {number} loanFacilityId - The ID of the loan facility.
   * @property {string} loanFacilityNumber - The number of the loan facility.
   * @property {string} loanFacilityName - The name of the loan facility.
   * @property {boolean} isBorrower - Indicates if the party is a borrower.
   * @property {string} status - The status of the loan facility.
   * @property {string} externalFacilityId - The external ID of the loan facility.
   * @APICode {stage}.parties.parties-with-loan-facilities.list
   */
  getPartyLoanFacilities(stage, params = {}) {
    return http.get(
      `${API_VERSION}/${stage}/parties/parties-with-loan-facilities`,
      params
    )
  },

  /**
   * Exports party and loan facility request records data to an Excel file.
   * @param {ExportParams} params - The parameters for the export.
   * @returns {Promise<ExportResponse>} - A promise that resolves with the exported data.
   * @APICode parties.parties-with-loan-facilities.exports.get
   */
  exportPartyAndLoanFacilityData(stage, params) {
    return `${API_VERSION}/${stage}/parties/parties-with-loan-facilities/exports?${params}`
  },

  /**
   * Retrieves details of parties along with related loan facilities and asset details.
   * @param {string} stage - The stage of the loan facility.
   * @param {number} partyId - The ID of the party.
   * @returns {Promise<PartyLoanFacilityAssetDetails>} A promise that resolves with the party loan facility asset details.
   * @typedef {Object} PartyLoanFacilityAssetDetails
   * @property {number} partyId - The unique identifier of the party.
   * @property {string} partyName - The name of the party.
   * @property {string} email - The email address of the party.
   * @property {Address} address - The address of the party.
   * @property {string} externalId - The external identifier of the party.
   * @property {TotalLoanFacilityAsset[]} totalLoanFacilities - List of total loan facilities with assets.
   * @typedef {Object} Address
   * @property {string} addressLine1 - The first line of the address.
   * @property {string} addressLine2 - The second line of the address (optional).
   * @property {string} addressLine3 - The third line of the address (optional).
   * @property {string} state - The state part of the address.
   * @property {string} city - The city part of the address.
   * @property {string} postalCode - The postal code of the address.
   * @typedef {Object} TotalLoanFacilityAsset
   * @property {string} assetNumbers - Comma-separated numbers of the assets.
   * @property {boolean} isPledgor - Indicates if the party is a pledgor.
   * @property {boolean} isOther - Indicates if the party is categorized as other.
   * @property {number} totalLoanFacilityId - The ID of the total loan facility.
   * @property {LoanFacility[]} loanFacilities - List of loan facilities.
   * @typedef {Object} LoanFacility
   * @property {number} loanFacilityId - The unique identifier of the loan facility.
   * @property {string} loanFacilityNumber - The number of the loan facility.
   * @property {string} nickName - The nickname of the loan facility.
   * @property {boolean} isBorrower - Indicates if the party is a borrower.
   * @property {string} status - The status of the loan facility.
   * @property {string} externalFacilityId - The external ID of the loan facility.
   * @APICode {stage}.parties.parties-with-loan-facilities-with-collateral-packages.get
   */
  getPartyLoanFacilityAssetDetails(stage, partyId) {
    return http.get(
      `${API_VERSION}/${stage}/parties/${partyId}/parties-with-loan-facilities-with-collateral-packages`
    )
  },
  /**
   * Deletes the credit report of a specified party.
   * @param {string} stage - The stage of the party credit report.
   * @param {number} partyId - The ID of the party whose credit report is to be deleted.
   * @returns {Promise<DeleteCreditReportResponse>} A promise that resolves with the deletion confirmation message.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/party/deletePartyCreditReport.md
   * @APICode stage.parties.credit-reports.delete
   */
  deletePartyCreditReport(stage, partyId) {
    return http.delete(
      `${API_VERSION}/${stage}/parties/${partyId}/credit-reports`
    )
  },
  /**
   * List due diligence information for parties based on the stage and total loan facility ID.
   * @param {string} stage - The stage of the loan facility process.
   * @param {Object} [params] - Optional parameters.
   * @param {number} [params.totalLoanFacilityId] - The ID of the total loan facility.
   * @param {number} [params.proposalId] - The ID of the proposal.
   * @returns {Promise<Array.<PartyDueDiligence>>} - A promise that resolves with an array of party due diligence information.
   * @typedef {Object} PartyDueDiligence
   * @property {string} partyId - The ID of the party.
   * @property {string} fullName - The full name of the party.
   * @property {boolean} isBorrower - Indicates if the party is a borrower.
   * @property {boolean} isGuarantor - Indicates if the party is a guarantor.
   * @property {boolean} isPledgor - Indicates if the party is a pledgor.
   * @property {boolean} isAuthorizedIndividual - Indicates if the party is an authorized individual.
   * @property {boolean} isTrustee - Indicates if the party is a trustee.
   * @property {boolean} isControlPerson - Indicates if the party is a control person.
   * @property {Array.<DueDiligence>} dueDiligences - The list of due diligences.
   * @typedef {Object} DueDiligence
   * @property {string} dueDiligenceReportType - The type of the due diligence report.
   * @property {string} note - A note regarding the due diligence.
   * @property {Array.<Document>} document - The list of documents.
   * @property {boolean} reportPullAble - Indicates if the report can be pulled.
   * @property {boolean} reportUploadAble - Indicates if the report can be uploaded.
   * @property {boolean} isLastReport - Indicates if this is the last report.
   * @typedef {Object} Document
   * @property {string} documentId - The ID of the document.
   * @property {string} fileName - The name of the file.
   * @property {string} documentType - The type of the document.
   * @property {string} createdAt - The creation date of the document.
   * @property {number} fileState - The state of the file.
   * @property {string} fileStorageId - The storage ID of the file.
   * @property {Array.<string>} operationsAllowed - The operations allowed on the document.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/party/listPartyDueDiligences.md
   * @APICode {stage}.parties.due-diligences.list
   */
  getListPartyDueDiligences(stage, params = {}) {
    return http.get(`${API_VERSION}/${stage}/parties/due-diligences`, params)
  },

  /**
   * Fetches customer identification programs based on provided identifiers.
   * @param {string} stage - The stage of the customer identification program.
   * @param {Object} [params] - The query parameters.
   * @param {number} [params.totalLoanFacilityId] - The ID of the total loan facility (optional).
   * @returns {Promise<CustomerIdentificationProgramsRespDTO>} - A promise that resolves with the customer identification programs data.
   * @APICode {stage}.parties.customer-identification-programs.get
   */
  getCustomerIdentificationPrograms(stage, params = {}) {
    return http.get(
      `${API_VERSION}/${stage}/parties/customer-identification-programs`,
      params
    )
  },

  /**
   * Retrieves detailed information about customer identification programs associated with a specific party ID.
   * @param {string} stage - The stage of the process.
   * @param {number} partyId - The unique identifier of the party.
   * @param {number} totalLoanFacilityId - The ID of the total loan facility.
   * @returns {Promise<CustomerIdentificationProgramResp>} - A promise that resolves with the customer identification programs data.
   * @APICode stage.parties.customer-identification-programs.get
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/dev-guan/api/party/getSinglePartyCip.md
   */
  getSingleCustomerIdentificationPrograms(stage, partyId, totalLoanFacilityId) {
    return http.get(
      `${API_VERSION}/${stage}/parties/${partyId}/customer-identification-programs`,
      { totalLoanFacilityId }
    )
  },

  /**
   * Refresh party details based on the provided identifiers.
   * @param {string} stage - The stage of the party to be refreshed.
   * @param {RefreshPartyPayload} payload - The payload containing identifiers and type of refresh.
   * @returns {Promise<RefreshPartyResponse>} - A promise that resolves with the result of the refresh operation.
   * @APICode {stage}.parties.refreshes.update
   */
  refreshCollateralParties(stage, payload) {
    return http.put(`${API_VERSION}/${stage}/parties/refreshes`, payload)
  },

  /**
   * Handles the customer identification program for a party based on the provided IDs.
   * @param {String} stage - The stage of the customer identification program.
   * @param {Number} partyId - The ID of the party.
   * @param {Number} cipId - The ID of the customer identification program.
   * @param {CustomerIdentificationProgramReqDTO} cipData - The data for updating the customer identification program.
   * @returns {Promise<CustomerIdentificationProgramResponse>} - A promise that resolves with the response after handling CIP.
   * @APICode stage.parties.customer-identification-programs.post
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/dev-guan/api/party/updatePartyCip.md?plain=1
   */
  updateCustomerIdentificationProgram(stage, partyId, cipId, cipData) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/customer-identification-programs/${cipId}`,
      cipData
    )
  },

  /**
   * Create a new due diligence report for a party or update an existing one.
   * @param {string} stage - The stage of the due diligence.
   * @param {number} partyId - The ID of the party.
   * @param {DueDiligenceReqDTO} dueDiligenceData - The due diligence data to be submitted.
   * @returns {Promise<Object>} - A promise that resolves with the response data.
   * @typedef {Object} DueDiligenceReqDTO
   * @property {string} dueDiligenceReportType - The type of the due diligence report.
   * @property {string} [dueNote] - Optional note for the due diligence.
   * @property {number[]} [fileStorageIds] - Optional array of file storage IDs associated with the due diligence.
   * @APICode parties.due-diligences.create
   */
  createPartyDueDiligence(stage, partyId, dueDiligenceData) {
    return http.post(
      `${API_VERSION}/${stage}/parties/${partyId}/due-diligences`,
      dueDiligenceData
    )
  },

  /**
   * Calculate the risk rating for a borrower based on the given party and application IDs.
   * @param {String} stage - The stage of the borrower risk rating calculation.
   * @param {String} partyId - The ID of the party for which the risk rating is being calculated.
   * @param {BorrowerRiskRatingReqDTO} payload - The request payload containing applicationId and partyIds.
   * @returns {Promise<RiskRatingResponse>} - A promise that resolves with the risk rating calculation result.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/party/CalculateBorrowerRiskRating.md?plain=1
   * @APICode {stage}.parties.calculations.borrower-risk-ratings.create
   */
  createBorrowerRiskRating(stage, payload) {
    return http.post(
      `${API_VERSION}/${stage}/parties/calculations/borrower-risk-ratings`,
      payload
    )
  },

  /**
   * Refresh party details based on the provided identifiers.
   * @param {string} stage - The stage of the party.
   * @param {RefreshPayload} payload - The payload containing refresh details.
   * @returns {Promise<RefreshResponse>} - A promise that resolves with the result of the refresh operation.
   * @APICode {stage}.parties.refreshes.update
   */
  refreshTotalLoanFacilityParties(stage, payload) {
    return http.put(`${API_VERSION}/${stage}/parties/refreshes`, payload)
  },
}
