import { http, API_VERSION } from './http.js'

/**
 * @typedef {Object} Connection
 * @property {string} questionId The identifier of the connected question.
 * @property {string} optionId The identifier of the connected option.
 */

/**
 * @typedef {Object} Option
 * @property {string} optionId The identifier of the option.
 * @property {string} label The label of the option.
 * @property {string} description The description of the option.
 * @property {string} sort The sorting order of the option.
 * @property {string} value The value of the option.
 */

/**
 * @typedef {Object} Link
 * @property {string} content The content of the link.
 * @property {string} url The URL of the link.
 */

/**
 * @typedef {Object} Term
 * @property {string} code The code of the term.
 * @property {string} content The content of the term.
 */

/**
 * @typedef {Object} Field
 * @property {string} fieldCode The field code.
 * @property {string} fieldLabel The label of the field.
 * @property {boolean} isDisplay Indicates if the field should be displayed.
 * @property {boolean} isRequired Indicates if the field is required.
 * @property {string} key The unique key of the field.
 */

/**
 * @typedef {Object} QuestionConfigDetail
 * @property {string} questionId The identifier of the question.
 * @property {string} level The level of the question.
 * @property {string} type The type of the question.
 * @property {string} description The description of the question.
 * @property {number} sort The sorting order of the question.
 * @property {string} model The model of the question.
 * @property {boolean} isRequired Indicates if the question is required.
 * @property {Connection[]} connections The connections of the question.
 * @property {Option[]} options The options of the question.
 * @property {string} defaultValue The default value of the question.
 * @property {string} key The unique key of the question.
 * @property {boolean} showNoForAll Indicates if the 'No for all' option should be shown.
 * @property {Link[]} links The links associated with the question.
 * @property {Term[]} terms The terms associated with the question.
 * @property {string[]} questionObject The objects associated with the question.
 * @property {Field[]} fields The fields associated with the question.
 */

/**
 * @typedef {Object} QuestionConfigResponse
 * @property {QuestionConfigDetail[]} questionConfiguration Array of question configuration details.
 */

/**
 * @typedef {Object} QuestionnaireConfigParams
 * @property {number} [applicationId] The ID of the application.
 * @property {string[]} questionLevelList The list of question levels to filter the configs.
 */
/**
 * @typedef {Object} QuestionnaireRespDTO
 * @property {number} questionAnswerId - The ID of the question and answer.
 * @property {number} partyId - The ID of the party.
 * @property {number} applicationId - The ID of the application.
 * @property {string} questionLevel - The level of the question.
 * @property {string} questionCode - The code of the question.
 * @property {string} answer - The answer to the question.
 * @property {number} answererProfileId - The profile ID of the answerer.
 * @property {number} createdAt - The timestamp when the answer was created.
 * @property {string} answererName - The name of the answerer.
 */

/**
 * @typedef {Object} QueryQuestionnaireRespDTO
 * @property {QuestionnaireRespDTO[]} questionnaires - List of questionnaires response data.
 */

/**
 * @typedef {Object} QuestionnaireReqDTO
 * @property {number} [applicationId] - The ID of the application.
 * @property {string[]} questionLevelList - List of question levels.
 */
export const questionnaireAPI = {
  /**
   * Get question and answer config based on the stage and question levels provided.
   * @param {string} stage The stage of the questionnaire.
   * @param {QuestionnaireConfigParams} params The parameters for the questionnaire config request.
   * @returns {Promise<QuestionConfigResponse>} A promise that resolves with the question configuration details.
   * @APICode {stage}.questionnaires.configs.get
   */
  getQuestionnaireConfig(stage, params) {
    return http.get(`${API_VERSION}/${stage}/questionnaires/configs`, params)
  },

  /**
   * Get detailed question and answer list based on application ID and question levels.
   * @param {string} stage - The stage of the questionnaire.
   * @param {QuestionnaireReqDTO} params - The parameters for the request.
   * @returns {Promise<QueryQuestionnaireRespDTO>} - A promise that resolves with the question and answer list.
   * @APICode {stage}.questionnaires.get
   */
  getQuestionAndAnswerList(stage, params) {
    return http.get(`${API_VERSION}/${stage}/questionnaires`, params)
  },

  /**
   * Save question and answer from UI.
   * @param {string} stage - The stage of the questionnaire.
   * @param {SaveQuestionnaireReqDTO} payload - The payload containing the questionnaires data.
   * @returns {Promise<Object>} - A promise that resolves when the question and answer are saved.
   * @typedef {Object} SaveQuestionnaireReqDTO
   * @property {number} applicationId - The ID of the application.
   * @property {Array<QuestionnaireReqDTO>} questionnaires - The list of questionnaires to save.
   * @typedef {Object} QuestionnaireReqDTO
   * @property {string} questionLevel - The level of the question.
   * @property {number} partyId - The ID of the party.
   * @property {string} questionCode - The code of the question.
   * @property {string} answerBody - The body of the answer in JSON format.
   * @APICode {stage}.questionnaires.post
   */
  createQuestionnaire(stage, payload) {
    return http.post(`${API_VERSION}/${stage}/questionnaires`, payload)
  },
}
