import { ref } from 'vue'
import { externalResourcesAPI } from '../../../api/external-resource.js'

/**
 * @typedef {Object} Option
 * @property {String} issueDate - Issue date of the option.
 * @property {String} callDate - Call date of the option.
 * @property {String} maturityDate - Maturity date of the option.
 */

/**
 * @typedef {Object} Holding
 * @property {String} secId - Security identifier.
 * @property {String} cusip - Committee on Uniform Securities Identification Procedures number.
 * @property {String} ticker - Stock ticker symbol.
 * @property {String} securityName - Name of the security.
 * @property {Number} baseCurrencyPrice - Base currency price of the security.
 * @property {Number} marketValueInBaseCurrency - Market value in base currency.
 * @property {Number} quantity - Quantity of the holdings.
 * @property {Number} eligibleQuantity - Eligible quantity of the holdings.
 * @property {String} priceDate - Date of the current price.
 * @property {Option} option - Option details.
 * @property {String} dataSourceInstitutionId - Data source institution identifier.
 */

/**
 * @typedef {Object} AccountHoldingsResponseDTO
 * @property {String} accountNumber - Account number.
 * @property {String} custodianInstitutionId - Custodian institution identifier.
 * @property {String} clientRepCode - Client representative code.
 * @property {String} collateralBaseCurrencyCode - Collateral base currency code.
 * @property {Holding[]} holdings - Array of holding objects.
 */

const isAccountBasicInfoLoading = ref(false)
const isAccountHoldingsLoading = ref(false)
const isBrokerageAccountVerificationLoading = ref(false)

/**
 * Composition API to interact with account holdings data.
 *
 * @returns {Object} Object containing attributes and methods for account holdings interaction.
 * @property {Object} isAccountHoldingsLoading - Reactive state to indicate if account holdings are being loaded.
 * @property {AccountHoldingsResponseDTO} accountHoldingsData - Reactive state holding the current account holdings data.
 * @property {Function} getAccountHoldings - Method to fetch account holdings based on specified conditions.
 */
export const useExternalResourceData = (stage) => {
  const accountHoldingList = ref({})

  /**
   * Fetches account holdings based on specified conditions.
   *
   * @param {Object} params - The parameters for the API call.
   * @param {String} params.stage - The stage.
   * @param {String} params.accountNumber - The account number.
   * @param {String} params.custodianInstitutionId - The custodian institution ID.
   * @param {String} params.clientRepCode - The client representative code.
   * @param {String} params.channelInstitutionId - The channel institution ID.
   */
  const getAccountHoldingList = (params) => {
    isAccountHoldingsLoading.value = true
    return externalResourcesAPI
      .getAccountHoldings(stage, params)
      .then((data) => {
        accountHoldingList.value = data?.holdings
      })
      .finally(() => {
        isAccountHoldingsLoading.value = false
      })
  }

  const isAccountDetailsLoading = ref(false)
  const accountDetails = ref([])

  /**
   * Fetches account holdings based on specified conditions.
   *
   * @param {Object} params - The parameters for the API call.
   * @param {String} params.stage - The stage.
   * @param {String} params.accountNumber - The account number.
   * @param {String} params.custodianInstitutionId - The custodian institution ID.
   * @param {String} params.clientRepCode - The client representative code.
   * @param {String} params.channelInstitutionId - The channel institution ID.
   */
  const getExternalResourcesAccount = async (stage, params) => {
    isAccountDetailsLoading.value = true
    return externalResourcesAPI
      .getExternalResourcesAccount(stage, params)
      .then((res) => {
        accountDetails.value = res
      })
      .finally(() => {
        isAccountDetailsLoading.value = false
      })
  }

  const accountBasicInfo = ref({})

  /**
   * Fetches account data from the backend.
   *
   * @param {Object} params - Parameters for the API call.
   * @property {number} [params.page] - Page number for pagination.
   * @property {number} [params.limit] - Number of items per page.
   */
  function getAccountBasicInfo(params = {}) {
    // TODO: replace by getExternalResourcesAccount
    isAccountBasicInfoLoading.value = true
    return externalResourcesAPI
      .getAccountBasicInfo(stage, params)
      .then((data) => {
        accountBasicInfo.value = data
      })
      .catch(() => {
        accountBasicInfo.value = null
      })
      .finally(() => {
        isAccountBasicInfoLoading.value = false
      })
  }

  const brokerageAccountCheckResp = ref({})

  /**
   * Checks the validity of the brokerage account associated with a given loan facility.
   *
   * @param {GetBrokerageAccountVerificationsParams} params - The parameters for the request.
   * @returns {Promise<BrokerageAccountCheckRespDTO>} - A promise that resolves with the result of the verification.
   * @typedef {Object} GetBrokerageAccountVerificationsParams
   * @property {number} loanFacilityId - The ID of the loan facility.
   * @property {string} accountNumber - The account number of the brokerage account.
   * @typedef {Object} BrokerageAccountCheckRespDTO
   * @property {boolean} isValid - Indicates whether the brokerage account is valid.
   */
  const getBrokerageAccountVerifications = async (params) => {
    isBrokerageAccountVerificationLoading.value = true
    try {
      const result =
        await externalResourcesAPI.getBrokerageAccountVerifications(
          stage,
          params
        )
      brokerageAccountCheckResp.value = result
      return result
    } finally {
      isBrokerageAccountVerificationLoading.value = false
    }
  }

  const isAssociatedPartiesLoading = ref(false)
  const associatedParties = ref({})

  /**
   * Searches for associated parties based on provided criteria.
   *
   * @param {Object} params - The search parameters.
   * @param {number} [params.totalLoanFacilityId] - The ID of the total loan facility.
   * @param {string} [params.searchType] - The type of search to perform.
   * @param {string} [params.searchTerm] - The term to search for.
   * @param {string} stage - The stage of the loan facility.
   * @returns {Promise<SearchPartiesResponse>} - A promise that resolves with the search results.
   */
  const getAssociatedPartiesBySearch = async (params, stage) => {
    isAssociatedPartiesLoading.value = true
    try {
      associatedParties.value =
        await externalResourcesAPI.getAssociatedPartiesBySearch(params, stage)
    } finally {
      isAssociatedPartiesLoading.value = false
    }
  }

  const referralAccounts = ref([])
  const isReferralAccountsLoading = ref(false)
  const getReferralAccounts = async (params) => {
    isReferralAccountsLoading.value = true
    try {
      const data = await externalResourcesAPI.getReferralAccounts(stage, params)
      referralAccounts.value = data.referralAccounts
    } finally {
      isReferralAccountsLoading.value = false
    }
  }

  return {
    isAccountHoldingsLoading,
    accountHoldingList,
    getAccountHoldingList,

    isAccountDetailsLoading,
    accountDetails,
    getExternalResourcesAccount,

    isAccountBasicInfoLoading,
    accountBasicInfo,
    getAccountBasicInfo,

    isBrokerageAccountVerificationLoading,
    brokerageAccountCheckResp,
    getBrokerageAccountVerifications,

    isAssociatedPartiesLoading,
    associatedParties,
    getAssociatedPartiesBySearch,

    referralAccounts,
    isReferralAccountsLoading,
    getReferralAccounts,
  }
}
