import { AssetTypeEnum } from '@/shared/organization/collateral/asset/presentation-objects/AssetBasePO'

export { AssetTypeEnum }

/** @enum */
export const PermissionValueEnum = {
  block: 1,
  readOnly: 2,
  readAndWrite: 3,
}

export const AmountRangeEnum = {
  min: 0,
  max: 999999999,
  totalMax: 9999999999,
}

export const PercentageRangeEnum = {
  min: 0.01,
  max: 100,
}

export const TotalLoanFacilityDataStructureEnum = {
  simpleLayerStructure: 'simpleLayerStructure',
  complexLayerStructure: 'complexLayerStructure',
}

export const ExceptionLevelEnum = {
  account: 'accounts',
  collateralPackage: 'collateral-packages',
  institution: 'channel-institutions',
  locProduct: 'loc-products',
  creditPolicy: 'credit-policy',
}

export const FormGalleryEnum = {
  documentSelection: 'DOCUMENT_SELECTION',
  infoReview: 'INFO_REVIEW',
  additional: 'ADDITIONAL',
  finalConfirmation: 'FINAL_CONFIRMATION',
  signingStatus: 'SIGNING_STATUS',
}

export const AssignToOtherEnum = {
  assignToOthers: 'ASSIGN_TO_OTHERS',
  escalate: 'ESCALATE',
}

export const CollateralReleaseStatusEnum = {
  pending: 'READY_FOR_APPROVAL,REVIEW_REQUIRED,PENDING',
  readyForApproval: 'READY_FOR_APPROVAL',
  reviewRequired: 'REVIEW_REQUIRED',
  approved: 'APPROVED,AUTO_APPROVED',
  rejected: 'REJECTED',
  cancelled: 'CANCELLED',
  autoApproved: 'AUTO_APPROVED',
  approvedSubmit: 'APPROVED',
}

export const CollateralReleaseStatusSubmit = {
  approved: 'APPROVED',
  rejected: 'REJECTED',
  cancelled: 'CANCELLED',
  autoApproved: 'AUTO_APPROVED',
}

export const CallResolutionActionEnum = {
  payBalance: 'PAY_BALANCE',
  addAccount: 'ADD_ACCOUNT',
  addAccountCompleted: 'ADD_ACCOUNT_COMPLETED',
  collateralAdjustment: 'COLLATERAL_ADJUSTMENT',
  collateralAdjustmentCompleted: 'COLLATERAL_ADJUSTMENT_COMPLETED',
  purchaseSecurity: 'PURCHASE_SECURITY',
  purchaseSecurityCompleted: 'PURCHASE_SECURITY_COMPLETED',
}

export const AssetClassEnum = {
  eqty: 'EQTY',
  fi: 'FI',
  managed: 'MANAGED',
  cash: 'CASH',
  cd: 'CD',
  altast: 'ALTAST',
  deriv: 'DERV',
  struct: 'STRUCT',
  depa: 'DEPA',
}

export const CallResolutionEventEnum = {
  simulatedChange: 'SIMULATED_CHANGE',
  resolveAction: 'RESOLVE_ACTION',
  addAccountCompleted: 'ADD_ACCOUNT_COMPLETED',
}

export const RiskLevelTypeEnum = {
  blue: 'BLUE',
  green: 'GREEN',
  yellow: 'YELLOW',
  red: 'RED',
  purple: 'PURPLE',
}

export const APIStageEnum = {
  estimate: 'estimate',
  proposal: 'proposal',
  origination: 'origination',
  application: 'application',
  underwriting: 'underwriting',
  service: 'service',
  amendment: 'amendment',
  controlCenter: 'controlCenter',
  utilityService: 'utilityService',
}

export const DocumentPhaseEnum = {
  digitalApplication: 'digitalApplication',
  underwriting: 'underwriting',
  service: 'service',
  amendment: 'amendment',
  amendmentApplication: 'amendmentApplication',
  amendmentUnderwriting: 'amendmentUnderwriting',
}

export const SessionKeyEnum = {
  totalLoanFacility: 'TOTAL_LOAN_FACILITY',
  lineDetailLinkBack: 'LINE_DETAIL_BACK_LINK',
  loanFacilityId: 'LOAN_FACILITY_ID',
  totalLoanFacilityId: 'TOTAL_LOAN_FACILITY_ID',
  collateralId: 'COLLATERAL_ID',
  loanDetailLinkBack: 'LOAN_DETAIL_BACK_LINK',
  pageBackStatus: 'PAGE_BACK_STATUS',
  addLoanFacilityStage: 'ADD_LOAN_FACILITY_STAGE',
  newLoanFacilityBackLink: 'NEW_LOAN_FACILITY_BACK_LINK',
}

/**
 * @readonly
 * @enum {string}
 */
export const RoleTypeEnum = {
  lender: 'LENDER',
  servicer: 'SERVICER',
  funder: 'FUNDER_USER',
  support: 'SUPPORT',
  channel: 'CHANNEL',
  investment: 'INVESTMENT',
  customer: 'CUSTOMER',
}

export const CollateralPartiesRefreshType = {
  referral: 'REFERRAL',
  search: 'SEARCH',
  investmentAccount: 'INVESTMENT_ACCOUNT',
}

export const RouterBase = {
  application: '/application',
  underwriting: '/underwriting',
  proposal: '/nav',
  service: '/service',
  cm: '/collateral-management',
  mvp: '/mvp-report',
}

export const FacilityStatusEnum = {
  active: 'ACTIVE',
}

/**
 * ! conditional and frozen property is frontend custom status for line table tab, not save in db
 * @readonly
 * @enum {string}
 */
export const LineStatusEnum = {
  active: 'ACTIVE',
  pending: 'PENDING',
  pendingClose: 'PENDING_CLOSE',
  closed: 'CLOSED',
  conditional: 'CONDITIONAL',
  frozen: 'FROZEN',
}

export const LoanStatusEnum = {
  active: 'ACTIVE',
  pending: 'PENDING',
  pendingClose: 'PENDING_CLOSE',
  closed: 'CLOSED',
  onboarding: 'ONBOARDING',
}

export const LineOriginationSourceEnum = {
  setLineLive: 'SNC',
  importBatch: 'IMPORT_BATCH',
  importUI: 'IMPORT_UI',
}

export const LoanFacilityTypeEnum = {
  loanFacility: 'LOAN_FACILITY',
  pledgedAssetMortgage: 'PLEDGED_ASSET_MORTGAGE',
  letterOfCredit: 'LETTER_OF_CREDIT',
  swap: 'SWAP',
}

export const AmendmentStatusEnum = {
  pendingSubmission: 'PENDING_SUBMISSION',
  pendingSignature: 'PENDING_SIGNATURE',
  underwriting: 'UNDERWRITING',
  pendingClosingSignature: 'PENDING_CLOSING_SIGNATURE',
  finalReview: 'FINAL_REVIEW',
  completed: 'COMPLETED',
  declined: 'DECLINED',
}

export const AmendmentTypeEnum = {
  loanAmount: 'LOAN_AMOUNT',
  interestRate: 'INTEREST_RATE',
  pledgedAccount: 'ASSET',
  loanPackage: 'LOAN_PACKAGE',
  peopleEntities: 'PERSON_ENTITY',
  deathOfBorrower: 'DEATH_OF_BORROWER',
  floorRate: 'FLOOR_RATE',
  indexChange: 'INDEX_CHANGE',
  addNewFacility: 'ADD_NEW_FACILITY',
  productTypeChange: 'PRODUCT_TYPE_CHANGE',
  addNewLoanFacility: 'ADD_NEW_LOAN_FACILITY',
  bankAccount: 'BANK_ACCOUNT',
}

export const AttentionStatusEnum = {
  pending: 'PENDING',
  resolved: 'RESOLVED',
}

export const InputMaxLengthEnum = {
  address: 40,
  amendAdditionalQuestionNote: 1000,
  amendmentNote: 3000,
  amendReturnDescription: 3000,
  amount: 16,
  assetName: 255,
  assetNumber: 64,
  checkNumber: 10,
  city: 30,
  dealSummary: 3000,
  email: 90,
  externalCid: 40,
  fullAddress: 100,
  legalName: 255,
  lineName: 40,
  loanName: 40,
  loanNote: 3000,
  loanNumber: 30,
  locNumber: 20,
  memo: 3000,
  name: 45,
  note: 500,
  obligatorCid: 20,
  percentage: 10,
  phone: 16,
  province: 40,
  purposeNote: 255,
  referralSource: 3000,
  shortName: 40,
  socialSecurityNumber: 20,
  sourceOfFunds: 300,
  sublimitAmount: 11,
  taxID: 11,
  title: 64,
  trustName: 50,
  trustRelationshipManagerName: 200,
  typeOfBusiness: 100,
  varchar: 255,
  wealthManager: 45,
  DCMAAccountNumber: 8,
  externalId: 64,
}

export const PersonNameLengthLimit = {
  first: 45,
  middle: 45,
  last: {
    min: 1,
    max: 45,
  },
  shortName: 40,
}

/** @enum **/
export const AccountPledgeStatusEnum = {
  inProgress: 'IN_PROGRESS',
  unlockHasError: 'UNLOCK_HAS_ERROR',
  unlockPending: 'UNLOCK_PENDING',
  lockdownPending: 'LOCKDOWN_PENDING',
  pledgedToOther: 'PLEDGED_TO_OTHER',
  pledgedToSn: 'PLEDGED_TO_LENDER',
  lockdownHasError: 'LOCKDOWN_HAS_ERROR',
  notPledged: 'NOT_PLEDGED',
  unknown: 'UNKNOWN',
}

export const PortfolioSourceEnum = {
  upload: 'UPLOAD',
  addAccount: 'ADD_ACCOUNT',
  loanFacility: 'LOAN_FACILITY',
}

export const TimeZoneEnum = {
  chicago: 'America/Chicago',
  newYork: 'America/New_York',
}

export const MemoTypeEnum = {
  general: 'GENERAL_MEMO',
  collateral: 'COLLATERAL_RELATED_MEMO',
  transaction: 'TRANSACTION_RELATED',
  peopleEntities: 'PEOPLE_AND_ENTITIES_RELATED_MEMO',
  callShortfall: 'CALL_SHORTFALL_RELATED',
  amendmentCreditMemo: 'AMENDMENT_CREDIT_MEMO',
  amendmentQuestionAnswerNote: 'AMENDMENT_QUESTION_ANSWER_NOTE',
  addCreditException: 'ADD_CREDIT_EXCEPTION',
  editCreditException: 'EDIT_CREDIT_EXCEPTION',
  deleteCreditException: 'DELETE_CREDIT_EXCEPTION',
  riskDocumentDownload: 'RISK_DOCUMENT_DOWNLOAD',
  appLoanNote: 'APP_LOAN_NOTE',
  uwLoanNote: 'UW_LOAN_NOTE',
  appCreditApproval: 'APP_CREDIT_APPROVAL',
  cipNote: 'CIP_NOTE',
  assetLoanNote: 'ASSET_LOAN_NOTE',
  lineLoanNote: 'LINE_LOAN_NOTE',
  paLoanNote: 'PA_LOAN_NOTE',
  loanLoanNote: 'LOAN_LOAN_NOTE',
  cmLoanNote: 'CM_LOAN_NOTE',
  cmArchiveLoanNote: 'CM_ARCHIVE_LOAN_NOTE',
  exceptionNote: 'EXCEPTION_NOTE',
  riskEventNote: 'RISK_EVENT_NOTE',
  uwQuestionAnswerNote: 'UW_QUESTION_ANSWER_NOTE',
  amendmentQuestionAnswer_note: 'AMENDMENT_QUESTION_ANSWER_NOTE',
  uwCreditMemo: 'UW_CREDIT_MEMO',
  approveComment: 'APPROVE_COMMENT',
  uwMsgEscalate: 'UW_MSG_ESCALATE',
  uwMsgAssign: 'UW_MSG_ASSIGN',
  keyRisk: 'KEY_RISK',
  uwKeyRisk: 'UW_KEY_RISK',
  loanMemo: 'LOAN_MEMO',
  assetMemo: 'ASSET_MEMO',
  loanQuestionAnswerMemo: 'LOAN_QUESTION_ANSWER_MEMO',
  exceptionRequestMemo: 'EXCEPTION_REQUEST_MEMO',
  peopleAndEntitiesRelatedMemo: 'PEOPLE_AND_ENTITIES_RELATED_MEMO',
  riskEventStarted: 'RISK_EVENT_STARTED',
  riskPlanMade: 'RISK_PLAN_MADE',
  riskPlanNoteUpdated: 'RISK_PLAN_NOTE_UPDATED',
  riskEventAddNote: 'RISK_EVENT_ADD_NOTE',
  riskSendNotice: 'RISK_SEND_NOTICE',
  uwApproveComment: 'UW_APPROVE_COMMENT',
  uwAssociation: 'UW_ASSOCIATION',
  interaction: 'INTERACTION',
}

export const ContactPhoneTypeEnum = {
  cell: 'CELL',
  home: 'HOME',
  work: 'WORK',
}

export const PhoneFieldEnum = {
  cellPhone: 'cellPhone',
  homePhone: 'homePhone',
  workPhone: 'workPhone',
}

export const PhoneFieldByTypeMap = {
  [ContactPhoneTypeEnum.cell]: PhoneFieldEnum.cellPhone,
  [ContactPhoneTypeEnum.home]: PhoneFieldEnum.homePhone,
  [ContactPhoneTypeEnum.work]: PhoneFieldEnum.workPhone,
}

export const BillRequestTypeEnum = {
  add: 'ADD_BILL',
  edit: 'EDIT_BILL',
  delete: 'DELETE_BILL',
}

/**
 * @enum
 * @deprecated
 * @see {@link PhoneFieldByTypeMap}
 */
export const phoneFieldByTypeMap = PhoneFieldByTypeMap

export const RiskModeEnum = {
  call: 'CALL',
  shortfall: 'SHORTFALL',
}

export const PeopleEntityAddTypeEnum = {
  manual: 'MANUAL',
  search: 'SEARCH',
}

/**
 * @deprecated use {@link PeopleEntityTypeEnum} instead on PersonEntityPO
 */
export const PeopleEntityTypeEnum = {
  individual: 'INDIVIDUAL',
  trust: 'TRUST',
  business: 'BUSINESS',
}

export const BorrowerTypeEnum = {
  individual: 'INDIVIDUAL_BORROWER',
  revocableTrust: 'REVOCABLE_TRUST_BORROWER',
  irrevocableTrust: 'IRREVOCABLE_TRUST_BORROWER',
  business: 'BUSINESS_BORROWER',
}

export const PeopleRelationShipEnum = {
  trustTrustee: 'TRUST_TRUSTEE',
  trustGrantor: 'TRUST_GRANTOR',
  trustRevoker: 'TRUST_OTHER_REVOKER',
  businessAuthorized: 'BUSINESS_AUTHORIZED',
  controlPerson: 'BUSINESS_CTRL_PERSON',
  businessOwner: 'BUSINESS_OWNER',
  authorizedSigner: 'AUTHORIZED_SIGNER',
  relationshipManager: 'CORP_TRUSTEE_RL_MGR',
  businessTrustee: 'BUSINESS_TRUSTEE',
}

export const IdentificationTypeEnum = {
  governmentIssuedId: 'GOVERNMENT_ISSUED_ID',
  passport: 'PASSPORT',
  driversLicense: 'DRIVERS_LICENSE',
}

export const EmploymentStatusEnum = {
  employed: 'employed',
  selfEmployed: 'self_employed',
}

export const ApplicationStatusEnum = {
  applicationInProgress: 'APPLICATION_IN_PROGRESS',
  applicationSubmitted: 'APPLICATION_SUBMITTED',
  underwriting: 'UNDERWRITING',
  revisionRequested: 'REVISION_REQUESTED',
  cancelled: 'CANCELLED',
  declined: 'DECLINED',
  locOpenRequested: 'LOC_OPEN_REQUESTED',
  completed: 'COMPLETED',
}

export const ApplicationSubStatusEnum = {
  sign: 'SIGN',
  preSign: 'PRE_SIGN',
  applicationForm: 'APPLICATION_FORM',
}

export const UnderwritingStatus = {
  active: 'ACTIVE',
  cancelled: 'CANCELLED',
  finished: 'FINISHED',
  declined: 'DECLINED',
}

export const UnderwritingSubStatus = {
  creditApprove: 'CREDIT_APPROVE',
  docPrep: 'DOC_PREP',
  docSigning: 'DOC_SIGNING',
  docSigned: 'DOC_SIGNED',
  finalApprove: 'FINAL_APPROVE',
}

export const UserLoginSourceEnum = {
  local: 'local',
}

export const DocumentCodeEnum = {
  other: 'other',
  wireForm: 'wire_form',
  lineOfCredit: 'line_of_credit',
  othersInternal: 'others_internal',
  controlAgreement: 'control_agreement',
  application: 'application',
  servicing: 'servicing',
  additional: 'additional',
  supplemental: 'supplemental_required',
  amendmentSupportingDoc: 'amendment_supporting_doc',
  creditReport: 'credit_report',
  lexisNexis: 'lexis_nexis',
  termLoanRenewal: 'term_loan_renewal',
}

export const DocumentStatusEnum = {
  created: 1,
  uploading: 2,
  scanning: 3,
  storing: 4,
  error: 5,
  ready: 6,
  deleted: 7,
  virus: 8,
}

export const RateTypeEnum = {
  variable: 1,
  fixed: 2,
  other: 3,
}

export const OperationTypeEnum = {
  add: 'ADD',
  update: 'UPDATE',
  delete: 'DELETE',
}

export const AmendmentActionEnum = {
  init: 'INIT',
  add: 'ADD',
  delete: 'DELETE',
  update: 'UPDATE',
}

export const AmendmentPartyActionEnum = {
  init: 'INIT',
  add: 'ADD',
  delete: 'DELETE',
  update: 'UPDATE',
}

export const AmendmentPartyStatusEnum = {
  initial: 'INITIAL',
  preReviewed: 'PRE_REVIEWED',
  reviewed: 'REVIEWED',
}

export const NotAvailabilityEnum = {
  notApplicable: 'N/A',
  none: 'None',
  unknown: 'Unknown',
  nil: 'Nil',
}

export const ConfigCategoryEnum = {
  proposal: 'proposal',
  digitalApplication: 'digitalApplication',
  underwriting: 'underwriting',
  application: 'application',
  servicing: 'servicing',
  collateralDataHub: 'collateralDataHub',
  sma: 'sma',
  amendment: 'amendment',
}

export const PeopleEntityRoleEnum = {
  borrower: 'borrower',
  guarantor: 'guarantor',
  pledgor: 'pledgor',
  trustee: 'trustee',
  authorizedIndividual: 'authorized_individual',
  controlPerson: 'control_person',
  authorizedSigner: 'authorized_signer',
  authorizedUser: 'authorized_user',
  primaryAdvisor: 'primary_advisor',
  primaryContact: 'primary_contact',
  relationshipManager: 'relationship_manager',
}

export const PeopleEntitySourceEnum = {
  manual: 'MANUAL',
  api: 'API',
  search: 'SEARCH',
}

export const PartyObjectRelEnum = {
  borrower: 'BORROWER',
  pledgor: 'OWNER',
  guarantor: 'GUARANTOR',
  authorizedUser: 'AUTHORIZED_USER',
}

export const EventStageEnum = {
  call: 'CALL',
  shortfall: 'SHORTFALL',
}

export const ValueTypeEnum = {
  amount: 'amount',
  percentage: 'percentage',
  scatter: 'scatter',
  text: 'text',
}

export const ListCategoryEnum = {
  line: 'LINE',
  loan: 'LOAN',
  collateral: 'COLLATERAL',
}

export const ReversalCodeEnum = {
  revDrw: 'REV_DRW',
}

export const TenantIdEnum = {
  ec542: 'ec542',
}

export const TimePeriodEnum = {
  day: 'DAY',
  month: 'MONTH',
  week: 'WEEK',
  quarter: 'QUARTER',
  halfYear: 'HALF_YEAR',
  year: 'YEAR',
}

export const CreditFactorEnum = {
  blank: '0',
}

export const AddressTypeEnum = {
  mailing: 'MAILING',
  home: 'HOME',
  contact: 'CONTACT',
}

export const DeclineReasonEnum = {
  valueOrTypeOfCollateralIsInsufficient: '1',
  creditApplicationIsIncomplete: '2',
  bankruptcy: '3',
  unsatisfactoryCreditHistory: '4',
  applicantDidNotMeetTheMinimumCreditCriteria: '5',
  coapplicantDidNotMeetTheMinimumCreditRequirements: '6',
  other: '7',
}

export const ServiceActivityLogEnum = {
  collateralException: 'CM_EXCEPTION',
  collateralRiskEvent: 'CM_RISKEVENT',
  otherOperation: 'CM_OTHER_OPERATION',
  alternativeAssets: 'CM_ASSET',
  note: 'CM_NOTE',
  interestCapitalization: 'INTEREST_CAPITALIZATION',
  reverse: 'REVERSE',
  lineMaintenance: 'LINE_MAINTENANCE',
  customer: 'CUSTOMER',
  document: 'DOCUMENT',
  bankAccount: 'BANK_ACCOUNT',
  feeAdjustment: 'FEE_ADJUSTMENT',
  fees: 'FEES',
  returnReverse: 'RETURN_REVERSE',
  adjustment: 'ADJUSTMENT',
  email: 'EMAIL',
  lineStatus: 'LINE_STATUS',
  amendment: 'AMENDMENT',
  draw: 'DRAW',
  collateral: 'COLLATERAL',
  payment: 'PAYMENT',
  statement: 'STATEMENT',
}

export const ActivityLogEventCodeEnum = {
  portfolioTypeAdjustmentExceptionAdded: 'PORTFOLIO_TYPE_ADJUSTMENT_EXCEPTION_ADDED',
  portfolioTypeAdjustmentExceptionUpdated: 'PORTFOLIO_TYPE_ADJUSTMENT_EXCEPTION_UPDATED',
  portfolioTypeAdjustmentExceptionDeleted: 'PORTFOLIO_TYPE_ADJUSTMENT_EXCEPTION_DELETED',
}

export const ExceptionReasonEnum = {
  other: 'OTHER',
}

export const ExceptionEventCodeDeletedEnum = {
  accountLevel: 'CM_ACCOUNT_LEVEL_EXCEPTION_DELETED',
  singleSecurity: 'CM_SINGLE_SECURITY_EXCEPTION_DELETED',
  collateralType: 'CM_COLLATERAL_TYPE_EXCEPTION_DELETED',
  classification: 'CM_CLASSIFICATION_EXCEPTION_DELETED',
  collateralTypeAdjustment: 'PORTFOLIO_TYPE_ADJUSTMENT_EXCEPTION_DELETED',
  portfolioTypeCellingReplace: 'PORTFOLIO_TYPE_CELLING_REPLACE_EXCEPTION_DELETED',
}

export const MarginTableRuleType = {
  line: 'LINE',
  aum: 'AUM',
  eligible: 'ELIGIBLE_COLLATERAL',
}

export const MarginTableStatusEnum = {
  enabled: 'ENABLE',
  disabled: 'DISABLE',
  pendingApproval: 'PENDING_APPROVAL',
}

export const MarginTableRuleEnum = {
  conditionLevel: {
    channel: 'CHANNEL',
    firm: 'FIRM',
    priceTier: 'PRICE_TIER',
  },
  conditionOptionFunction: {
    equal: 'EQUAL_TO',
    notEqual: 'NOT_EQUAL_TO',
    in: 'IN',
  },
}

export const PubsubEventCodeEnum = {
  applicationDataReadinessNeedRefresh: 'APPLICATION_DATA_READINESS_NEED_REFRESH',
  applicationFormGoNext: 'APPLICATION_FORM_GO_NEXT',
  updateLoanFacility: 'UPDATE_LOAN_FACILITY',
}

export const DataFieldModuleCodeEnum = {
  lineBankAccountLinkedBankAccount: 'BANK_ACCOUNT_LINKED_BANK_ACCOUNT',
  lineSummaryLinkedBankAccount: 'LINE_SUMMARY_LINKED_BANK_ACCOUNT',
  linkedBankAccount: 'LINKED_BANK_ACCOUNT',
  lineInformation: 'LINE_INFORMATION',
  drawAndPaymentBankAccount: 'DRAW_AND_PAYMENT_BANK_ACCOUNT',
  paymentOnlyBankAccount: 'PAYMENT_ONLY_BANK_ACCOUNT',
  drawAndPaymentBankAccountSavings: 'DRAW_AND_PAYMENT_BANK_ACCOUNT_SAVINGS',
  drawAndPaymentBankAccountChecking: 'DRAW_AND_PAYMENT_BANK_ACCOUNT_CHECKING',
  drawAndPaymentBankAccountBrokerage: 'DRAW_AND_PAYMENT_BANK_ACCOUNT_BROKERAGE',
  paymentOnlyBankAccountSavings: 'PAYMENT_ONLY_BANK_ACCOUNT_SAVINGS',
  paymentOnlyBankAccountChecking: 'PAYMENT_ONLY_BANK_ACCOUNT_CHECKING',
  paymentOnlyBankAccountBrokerage: 'PAYMENT_ONLY_BANK_ACCOUNT_BROKERAGE',
  paymentOnlyBankAccountMoneyMarket: 'PAYMENT_ONLY_BANK_ACCOUNT_MONEY_MARKET',
}
export const QuestionCategoryEnum = {
  applicant: 'APPLICANT_LEVEL',
  application: 'APPLICATION_LEVEL',
  attestation: 'APPLICATION_ATTESTATION',
  contingentLiabilitiesSimple: 'PFS_CONTINGENT_LIABILITIES_SIMPLE',
  contingentLiabilitiesFull: 'PFS_CONTINGENT_LIABILITIES_FULL',
  financialQuestions: 'PFS_FINANCIAL',
  additionalQuestions: 'PFS_ADDITIONAL',
  amendmentAdditionalQuestions: 'AMENDMENT_ADDITIONAL_QUESTIONS',
  businessPurpose: 'BUSINESS_PURPOSE',
  noticeLevel: 'NOTICE_LEVEL',
  personalPurpose: 'PERSONAL_PURPOSE',
  loanTransfer: 'LOAN_TRANSFER',
}

export const DataPointsTopPageNodeCodeEnum = {
  proposal: 'PROPOSAL',
  application: 'APPLICATION',
  underwriting: 'UNDERWRITING',
  line: 'LINE',
  collateralManagement: 'COLLATERAL_MANAGEMENT',
  sma: 'SMA',
  newLoanFacilityApp: 'NEW_LOAN_FACILITY_APPLICATION',
  newLoanFacilityUw: 'NEW_LOAN_FACILITY_UNDERWRITING',
}

export const DataPointsTopPageNodeKeyEnum = {
  proposal: 'proposal',
  application: 'application',
  underwriting: 'underwriting',
  line: 'line',
  collateralManagement: 'collateralManagement',
  sma: 'sma',
}

export const UPDATE_LOAN_FACILITY = 'UPDATE_LOAN_FACILITY'

export const EventCategoryEnum = {
  draw: 'DRAW',
  payment: 'PAYMENT',
  statement: 'STATEMENT',
  interestCapitalization: 'INTEREST_CAPITALIZATION',
  returnReverse: 'RETURN_REVERSE',
  document: 'DOCUMENT',
  application: 'APPLICATION',
}

export const MoneyTransferMethodEnum = {
  ach: 1,
  wire: 2,
  other: 3,
}

export const CipResultStatusEnum = {
  lineThrough: 'LINE_THROUGH',
  na: 'NA',
  resolved: 'RESOLVED',
  mismatch: 'MISMATCH',
  pass: 'PASS',
}

export const NoticeStatusEnum = {
  sent: 'SENT',
  resolved: 'RESOLVED',
  pastDue: 'PAST_DUE',
  cancelled: 'CANCELLED',
}

export const UnderwritingExceptionStatusEnum = {
  delete: 'delete',
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
}

export const WorkflowTypeEnum = {
  unspecified: 0,
  amendmentChange: 1,
  application: 2,
  secondLoanOrigination: 3,
  noWorkflow: 4,
}

export const RushReasonEnum = {
  clientAvailability: 'CLIENT_AVAIlABILITY',
  fundsNeedForClosing: 'FUNDS_NEED_FOR_CLOSING',
  aboveWatermark: 'ABOVE_WATERMARK',
  transferOfAssets: 'TRANSFER_OF_ASSETS',
  taxPayment: 'TAX_PAYMENT',
  other: 'OTHER',
}

/**
 * Enum of type of add bank account
 */
export const AddBankOperationMethodEnum = {
  manual: 'MANUAL',
  plaid: 'PLAID',
  thirdParty: 'THIRDPARTY',
  selectExisting: 'SELECT_EXISTING',
  edit: 'EDIT',
  wire: 'WIRE',
}

/**
 * Enum of add bank account
 */
export const TypeOfAddBankAccountEnum = {
  checking: 'CHECKING',
  savings: 'SAVINGS',
  brokerage: 'BROKERAGE',
  moneyMarket: 'MONEY_MARKET',
}

export const BankAccountRemittanceTypeEnum = {
  other: 'other',
  check: 'check',
  wire: 'wire',
  ach: 'ach',
}
export const BankAccountDataSourceEnum = {
  thirdParty: 'THIRDPARTY',
  manual: 'MANUAL',
  plaid: 'PLAID',
}

export const DecryptFieldEnum = {
  bankAccountAccountNumber: 'BANK_ACCOUNT_ACCOUNT_NUMBER',
  wireRecipientFurtherCreditAccountNumber: 'WIRE_RECIPIENT_FURTHER_CREDIT_ACCOUNT_NUMBER',
  bankAccountRoutingNumber: 'BANK_ACCOUNT_ROUTING_NUMBER',
  bankAccountAddress: 'BANK_ACCOUNT_ADDRESS',
  bankAccountVerification: 'BANK_ACCOUNT_VERIFICATION',
  bankAccountRelatedAccount: 'BANK_ACCOUNT_RELATED_ACCOUNT',
}

export const DrawAccountStatusEnum = {
  pending: 'PENDING',
  approved: 'APPROVED',
  denied: 'DENIED',
}

export const DataSourceValueEnum = {
  batchFile: 'BATCH',
  manualUpload: 'MANUAL_UPLOAD',
  manual: 'MANUAL',
  upload: 'UPLOAD',
  api: 'API',
  unknown: 'UNKNOWN',
}

export const AssetOwnerStructureEnum = {
  individual: 'INDIVIDUAL',
  trust: 'TRUST',
  business: 'BUSINESS',
  joint: 'JOINT',
  unknown: 'UNKNOWN',
}

/**
 * Enum for Term Business Process.
 * @enum {string}
 * @readonly
 */
export const TermBusinessProcessEnum = {
  platform: 'PLATFORM',
  servicingBankAccount: 'SER_ACCOUNT_PAYMENT',
  applicationDisclosure: 'APP_TERM_DISCLOSURE',
  applicationBankAccount: 'APP_ACCOUNT_PAYMENT',
}

export const AccountDataSourceValueEnum = {
  manualUpload: 'MANUAL_UPLOAD',
  api: 'API',
  batchFile: 'BATCH_FILE',
  thirdParty: 'THIRD_PARTY',
  manuallySearchReferralId: 'MANUALLY_SEARCH_REFERRAL_ID',
}

export const WorkflowKeyEnum = {
  dataReadinessCheck: 'DataReadinessCheck',
  partyCompletedCheck: 'PartyCompletedCheck',
}

export const PageNodeEnum = {
  totalLoanFacility: 'TOTAL_LOAN_FACILITY',
}

export const DynamicDataPointClassEnum = {
  collateralManagement: 'collateral_management',
  totalLoanFacility: 'dynamic_total_loan_facility',
  collateral: 'collateral',
  loanFacility: 'dynamic_loan_facility',
}

export const SystemOuterListTypeEnum = {
  loanFacilityType: 'LOAN_FACILITY_TYPE',
  loanType: 'LOAN_TYPES',
  loanFacilityIssueDate: 'LOAN_FACILITY_ISSUE_DATE',
  callOrShortfall: 'CALL_OR_SHORTFALL',
  lineRiskStatus: 'LINE_RISK_STATUS',
  fullList: 'FULL_LIST',
  loanFacilityBorrowerType: 'LOAN_FACILITY_BORROWER_TYPE',
}

export const CollateralReleaseTypeEnum = {
  cashOnly: 'CASH_ONLY',
  security: 'SECURITY',
}

export const CollateralSelectionTypeEnum = {
  manualSelect: 'MANUAL_SELECT',
  autoSelect: 'AUTO_SELECT',
}

export const CollateralReleasePurposeEnum = {
  paymentForLoan: 'PAYMENT_FOR_LOAN',
  buySecurities: 'BUY_SECURITIES',
  forGift: 'FOR_GIFT',
  other: 'OTHER',
}

export const SourceSecurityTypeEnum = {
  snDummyHolding: 'SN_DUMMY_HOLDING',
}

export const APIErrorCodeEnum = {
  thirdPartyDataValidationError: 260691,
  locProductUpdatedAfterAccountAdd: 170924,
  locProductUpdatedAfterAccountDeleteAndModification: 170925,
  signerMfaInvalid: 170999,
}

export const specialCodeEnum = {
  ipo30Code: 'IPO30',
  f30Code: 'F30',
}

export const UnpledgingAssetValidationRulesEnum = {
  resultInShortfallCallCheck: 'RESULT_IN_SHORTFALL_CALL_CHECK',
  resultIn95LtcCheck: 'RESULT_IN_95LTC_CHECK',
  prefundingConditionCheck: 'PREFUNDING_CONDITION_CHECK',
  deceasedFlagCheck: 'DECEASED_FLAG_CHECK',
}

export const DataTypeEnum = {
  percentage: '%',
  amount: '$',
  timestamp: 'Timestamp',
  decimal: 'Decimal',
  integer: 'Integer',
  date: 'Date',
  string: 'String',
  boolean: 'Boolean',
}

export const ConnectEnum = {
  in: 'IN',
  between: 'BETWEEN',
  and: 'AND',
  bitIn: 'BIT_IN',
}

export const SortEnum = {
  asc: 'ASC',
  desc: 'DESC',
}

export const RiskEventStatusEnum = {
  open: 'Open',
  pending: 'Pending',
  closed: 'Closed',
  suppressed: 'Suppressed',
}

export const RiskStatusEnum = {
  GREEN: 'success',
  YELLOW: 'warning',
  RED: 'danger',
  PURPLE: 'uncontrolled',
}

export const AmountUnitEnum = {
  million: 'M',
  billion: 'B',
}
