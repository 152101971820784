// useEcharts.js
import * as echarts from 'echarts/core'
import {
  BarChart,
  PieChart,
  LineChart,
  ScatterChart,
  CustomChart,
} from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  TransformComponent,
  MarkPointComponent,
  GraphicComponent,
} from 'echarts/components'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  GraphicComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  TransformComponent,
  MarkPointComponent,
  BarChart,
  LineChart,
  PieChart,
  ScatterChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  CustomChart,
])

/**
 * @deprecated This function is deprecated. Please use `useECharts` from `shared/composables/echarts/useECharts` instead.
 */
export function useECharts(ele) {
  const instance = echarts.init(ele)

  window.addEventListener('resize', function () {
    instance.resize()
  })

  function deposeInstance() {
    instance.dispose()
  }

  return { echarts, instance, deposeInstance }
}
