import { API_VERSION, http } from './http.js'

export const loanAPI = {
  /**
   * Lists all the loans available based on the provided parameters.
   * @param {LoanListParams} params - The parameters for listing the loans.
   * @returns {Promise<LoanListResponse>} - A promise that resolves with the list of loans.
   * @APICode service.loans.list
   */
  getLoans(params, config) {
    return http.get(`${API_VERSION}/service/loans`, params, config)
  },
  /**
   * Exports loan data to an Excel file with optional encryption.
   * @param {boolean} [params.shouldEncrypt=true] - Indicates whether the exported data should be encrypted.
   * @param {string} [params.headerNames] - Comma-separated string of header names for the Excel file.
   * @param {string} [params.columnNames] - Comma-separated string of column names file for the Excel.
   * @returns {Promise<ArrayBuffer>} A binary stream representing the exported Excel file.
   * @APICode service.loans.exports.get
   */
  exportLoans(params = {}) {
    return http.download(`${API_VERSION}/service/loans/exports`, params)
  },
  /**
   * Updates a specific loan's details.
   * @param {string} loanId - The ID of the loan to be updated.
   * @param {UpdateLoanDetailsPayload} payload - The payload containing the loan details to be updated.
   * @param {Object} [options] - Additional options for the request.
   * @param {string} [options.requestFrom] - The source of the request.
   * @returns {Promise<Object>} - A promise that resolves with the response of the update operation.
   * @typedef {Object} UpdateLoanDetailsPayload
   * @property {string} [loanType]
   * @property {string} loanNumber
   * @property {string} [loanName]
   * @property {string} loanStatus
   * @property {string} [issueDate]
   * @property {string} [nickname]
   * @property {string} [approvedAmount]
   * @property {string} [balance]
   * @property {number} [pendingDraw]
   * @property {number} [pendingPayment]
   * @property {number} [billedAmountDue]
   * @property {number} payoffAmount
   * @property {string} [primaryAdvisor]
   * @property {string} [purpose]
   * @property {string} [typeOfPurpose]
   * @property {string} [callCode]
   * @property {string} [relationshipManagerProfileId]
   * @property {string} [primaryOfficerProfileId]
   * @property {string} [clientRepCode]
   * @property {string} [firmInstitutionId]
   * @property {string} [lenderInstitutionId]
   * @property {string} [fundingInstitutionId]
   * @property {string} [servicingInstitutionId]
   * @property {string} [distributionChannelInstitutionId]
   * @property {string} [plCenterId]
   * @property {string} [region]
   * @property {string} [branch]
   * @property {string} [officer]
   * @property {string} [productLine]
   * @property {string} [cid]
   * @property {string} [amlCrr]
   * @property {string} [ribsContactNumber]
   * @property {number} [firstDrawAmount]
   * @property {string} [firstDrawDate]
   * @property {number} [indexRateId]
   * @property {number} [marginPercent]
   * @property {string} billDueDate
   * @property {string} [termType]
   * @property {number} term
   * @property {number} interestRate
   * @property {string} maturityDate
   * @property {string} [purposeNote]
   * @APICode service.loans.update
   */
  updateLoan(loanId, payload, options = {}) {
    const { requestFrom } = options
    const headers = requestFrom ? { requestFrom } : undefined
    return http.patch(`${API_VERSION}/service/loans/${loanId}`, payload, {
      headers,
    })
  },
  getLoansBorders(payload) {
    return http.get(`${API_VERSION}/service/loans/borders`, payload)
  },
}

/**
 * @typedef {Object} LoanFilters
 * @property {Array<String>} status
 * @property {Number} [totalLoanFacilityId]
 * @property {Number} [loanFacilityId]
 * @property {Array<Number>} [distributionChannelInstitutionId]
 * @property {Array<Number>} [lenderInstitutionId]
 * @property {Array<Number>} [firmInstitutionId]
 * @property {Array<Number>} [locProductId]
 * @property {Object} [createdAt]
 * @property {Number} [createdAt.max]
 * @property {Number} [createdAt.min]
 * @property {Object} [balance]
 * @property {Number} [balance.max]
 * @property {Number} [balance.min]
 * @property {Object} [availableCredit]
 * @property {Number} [availableCredit.max]
 * @property {Number} [availableCredit.min]
 * @property {Object} [approvedAmount]
 * @property {Number} [approvedAmount.max]
 * @property {Number} [approvedAmount.min]
 * @property {Object} [utilizationPerncet]
 * @property {Number} [utilizationPerncet.max]
 * @property {Number} [utilizationPerncet.min]
 * @property {Object} [issueDate]
 * @property {String} [issueDate.max]
 * @property {String} [issueDate.min]
 * @property {Object} [maturityDate]
 * @property {String} [maturityDate.max]
 * @property {String} [maturityDate.min]
 * @property {String} [rateType]
 * @property {Number} [term]
 * @property {Array<String>} [loanType]
 * @property {Array<String>} [primaryPurpose]
 * @property {Array<String>} [useOfProceeds]
 */

/**
 * @typedef {Object} LoanListParams
 * @property {Number} pageNum
 * @property {Number} pageSize
 * @property {String} [sort]
 * @property {String} [searchTerm]
 * @property {Array<String>} [searchKeys]
 * @property {LoanFilters} [filters]
 */

/**
 * @typedef {Object} LoanItem
 * @property {String} loanId
 * @property {String} loanNumber
 * @property {String} totalLoanFacilityId
 * @property {Number} loanFacilityId
 * @property {String} issueDate
 * @property {Number} balance
 * @property {Number} approvedAmount
 * @property {Number} availableCredit
 * @property {Number} pendingDraw
 * @property {Number} pendingPayment
 * @property {Number} annualPercentRate
 * @property {Number} interestRate
 * @property {Number} fixedInterestRatePercent
 * @property {Number} promotionalRateTableTermId
 * @property {Number} initialInterestRate
 * @property {String} rateType
 * @property {String} termType
 * @property {String} loanType
 * @property {String} facilityType
 * @property {Number} termValue
 * @property {String} termLabel
 * @property {Number} payoffAmount
 * @property {Number} billedAmountDue
 * @property {Number} indexRateId
 * @property {Number} indexRate
 * @property {String} indexRateFullName
 * @property {String} indexRateShortName
 * @property {Number} apr
 * @property {Number} originalApr
 * @property {Number} margin
 * @property {Number} dailyInterestAccrual
 * @property {Boolean} isCapRollAllowed
 * @property {Number} firstDrawAmount
 * @property {String} firstDrawDate
 * @property {String} billDueDate
 * @property {String} status
 * @property {Number} loanLastUpdateDate
 * @property {String} marginBreakdownForLender
 * @property {String} marginBreakdownForAdvisor
 * @property {String} marginBreakdownForServicer
 * @property {String} marginBreakdownForChannel
 * @property {String} marginBreakdownForFirm
 * @property {String} loanName
 * @property {Boolean} hasAutopay
 * @property {String} purpose
 * @property {String} typeOfPurpose
 * @property {String} callCode
 * @property {String} primaryAdvisor
 * @property {String} relationshipManagerProfileId
 * @property {String} primaryOfficerProfileId
 * @property {String} clientRepCode
 * @property {String} firmInstitutionId
 * @property {String} lenderInstitutionId
 * @property {String} fundingInstitutionId
 * @property {String} servicingInstitutionId
 * @property {String} distributionChannelInstitutionId
 * @property {String} plCenterId
 * @property {String} region
 * @property {String} branch
 * @property {String} officer
 * @property {String} productLine
 * @property {String} marginUpdateDate
 * @property {Number} floorRate
 * @property {Number} ceilingRate
 * @property {Boolean} enableFloorRate
 * @property {Number} todayMarginUpdateTime
 * @property {String} indexRateEffectiveDate
 * @property {Number} callValue
 * @property {String} maturityDate
 * @property {String} originalAnnualPercentRate
 * @property {String} nickname
 * @property {String} accrualBasis
 * @property {Number} totalInterestAccrual
 * @property {String} paymentFrequency
 * @property {String} closeDate
 * @property {Number} billedInterestPastDueNotPaid
 * @property {Number} billedInterestCurrentDueNotPaid
 * @property {Number} feeDueNotPaid
 * @property {Number} feeNotBilledNotPaid
 * @property {Number} interestAccrualNotBilledNotPaid
 * @property {Number} lastSyncedBalance
 * @property {String} rolloverOption
 * @property {Number} lastSyncedPayoffAmount
 * @property {Number} lastSyncedAt
 * @property {Number} utilizationPercent
 * @property {String} dateOfLastDraw
 * @property {String} collateralPackageNumber
 * @property {String} lineName
 * @property {String} borrowerNames
 * @property {String} locProductName
 * @property {String} distributionChannelName
 * @property {String} lenderName
 */

/**
 * @typedef {Object} LoanListResponse
 * @property {Number} pageNum
 * @property {Number} pageSize
 * @property {Number} total
 * @property {Array<LoanItem>} items
 */
