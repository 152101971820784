import { API_MAX_PAGE_SIZE, isEmpty } from '@/shared/utils'
import { camelCase, has } from 'lodash'
import qs from 'qs'

export function covertMarginDetailData(sourceMarginDetail) {
  if (isEmpty(sourceMarginDetail)) return sourceMarginDetail
  const targetMarginDetail = {}
  const covertFiledMap = new Map([
    ['finalMarginRate', 'final'],
    ['originalMarginRate', 'original'],
    ['overrideMarginRate', 'override'],
    ['requestMarginRate', 'request'],
  ])
  for (const key in sourceMarginDetail) {
    const covertFiledPrefix = covertFiledMap.get(key)
    if (covertFiledPrefix) {
      const covertFieldDetail = sourceMarginDetail[key]
      for (const covertFieldSuffix in covertFieldDetail) {
        const convertKey = camelCase(
          `${covertFiledPrefix} ${covertFieldSuffix}`
        )
        targetMarginDetail[convertKey] = covertFieldDetail[covertFieldSuffix]
      }
    } else {
      targetMarginDetail[key] = sourceMarginDetail[key]
    }
  }
  targetMarginDetail.originalIndexRate =
    targetMarginDetail.originalIndexRatePercent
  targetMarginDetail.overrideIndexRate =
    targetMarginDetail.overrideIndexRatePercent
  targetMarginDetail.requestIndexRate =
    targetMarginDetail.requestIndexRatePercent
  return targetMarginDetail
}

export function fetchAllData({
  fetchAPI,
  externalParams = {},
  prefixParams = [],
  listAlias = 'items',
  totalAlias = 'totalItems',
  isStrParams = false,
}) {
  let allData = []

  function requestPage(page) {
    const params = {
      pageNumber: page,
      pageSize: API_MAX_PAGE_SIZE,
      ...externalParams,
    }
    return new Promise((resolve, reject) => {
      const paramsStr = `?${qs.stringify(params)}`
      const objectParams = isStrParams ? paramsStr : params
      const fetchArgs = [...prefixParams, objectParams].filter((item) => item)
      fetchAPI
        .apply(null, fetchArgs)
        .then((res) => {
          allData = allData.concat(res[listAlias])
          if (allData.length < res[totalAlias]) {
            resolve(requestPage(page + 1))
          } else {
            resolve(allData)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return requestPage(1)
}

export function mergeObjectValidProperty(target, source) {
  const ignoreKeys = ['__ob__']
  const sourceValidKeys = Object.keys(source).filter(
    (key) => !ignoreKeys.includes(key)
  )
  for (const key of sourceValidKeys) {
    if (!has(target, key) || !isEmpty(source[key])) {
      target[key] = source[key]
    }
  }
  return target
}

export const parseTraceId = (traceId, key = '') => {
  const pairs = traceId.split(';').reduce((acc, item) => {
    const [k, v] = item.split('=')
    acc[k] = v
    return acc
  }, {})
  return key ? pairs[key] : pairs
}
