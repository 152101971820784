import { http, API_VERSION } from './http'

export const configurationAPI = {
  /**
   * Generate and retrieve a unique batch number.
   * @param {string} universe - The universe code.
   * @returns {Promise<BatchNumberRespDTO>} - A promise that resolves with the batch number data.
   * @APICode fully_authenticated
   */
  getBatchNumber() {
    return http.get(`${API_VERSION}/commons/batch-numbers`)
  },
  
  /**
   * List Website Level Configurations
   * This API is designed to list website level configurations based on the provided website code and category code.
   * It fetches both general website-level configurations and specific category configurations, merging them as necessary for the response.
   *
   * @param {string} stage - The stage of the process (e.g., proposal, application, underwriting, service).
   * @param {Object} params - The request parameters.
   * @param {string} [params.categoryCode] - The category code to fetch specific configurations for.
   * @returns {Promise<Object>} The website level configurations including general and specific category configurations.
   * @apiCode {stage}.configurations.website-levels.list
   */
  listWebsiteLevelConfigs(stage, params) {
    return http.get(
      `${API_VERSION}/${stage}/configurations/website-levels`,
      params
    )
  },

  /**
   * Lists configuration enums based on given institution ID and enum type codes.
   *
   * @param {Object} params - The request parameters.
   * @param {number} [params.institutionId] - The ID of the institution (optional).
   * @param {Array<string>} params.enumTypeCodes - The list of enum type codes (required).
   * @returns {Promise<Object>} A promise resolving to the configuration enums.
   * @apiCode configurations.enums.list
   */
  listConfigurationEnums(params) {
    return http.post(`${API_VERSION}/configurations/enums`, params)
  },

  /**
   * List Institution Level Configurations based on the given parameters.
   * It allows fetching configurations for lender and channel institutions, including general and specific category configurations, data points, dashboard data points, and customized field configurations.
   *
   * @param {string} stage - The stage of the institution config category.
   * @param {Object} params - The request parameters.
   * @param {string} [params.categoryCode] - For Estimate/Proposal only need query general institution config.
   * @param {number} [params.loanFacilityId] - The loan facility ID.
   * @param {number} [params.applicationId] - The application ID.
   * @param {number} [params.amendmentId] - The amendment ID.
   * @param {number} [params.estimateId] - The estimate ID.
   * @returns {Promise<Object>} The institution level configurations.
   * @apiCode service.configurations.listInstitutionLevels
   */
  listInstitutionLevelConfigs(stage, params) {
    return http.get(
      `${API_VERSION}/${stage}/configurations/institution-levels`,
      params
    )
  },
  /**
   * List configurations for LOC Product Level.
   *
   * @param {string} stage - The stage of the LOC product.
   * @param {Object} params - The request parameters.
   * @param {number} params.locProductId - The ID of the LOC product.
   * @param {string} [params.categoryCode] - The category code related to the stage.
   * @returns {Promise<Object>} The LOC Product Level configurations.
   * @apiCode service.configurations.locProductLevels.list
   */
  listLocProductLevelConfigurations(stage, params) {
    return http.get(
      `${API_VERSION}/${stage}/configurations/loc-product-levels`,
      params
    )
  },

  listCustodianConfigurations(stage, params) {
    // permission code: {stage}.configurations.custodians.list
    return http.get(`${API_VERSION}/${stage}/configurations/custodians`, params)
  },

  /**
   * Retrieves the SIC lists from the external configuration API.
   * @returns {Promise} A promise that resolves with the SIC lists.
   * @apiCode config.refSicCode.get
   */
  getSicLists() {
    return http.get(`${API_VERSION}/external-configuration/refSicCode/list`)
  },

  /**
   * Retrieve data points configuration based on the category code, top page node code, and page node code.
   * @param {string} stage - The current stage of the data points.
   * @param {QueryParams} queryParams - The query parameters for the request.
   * @returns {Promise<DataPointRespDTO>} - A promise that resolves with the data points configuration.
   * @typedef {Object} SuccessResponse
   * @property {DataPoint[]} dataPoints
   * @property {DataPoint[]} dashboardDataPoints
   * @property {Object[]} customizedFieldConfigs
   * @property {PageNode[]} pageNodes
   */
  getDataPoints(stage, queryParams = {}) {
    return http.get(`/v5/${stage}/configurations/data-points`, queryParams)
  },

  // permission code: {stage}.configurations.developments.get
  listDevelopmentConfigurations(stage, params) {
    return http.get(`/v5/${stage}/configurations/developments`, params)
  },

  /**
   * old API
   * @param {*} channelInstitutionId
   * @returns
   */
  firmInternalGroupList(channelInstitutionId) {
    return http.get(
      `/${API_VERSION}/configuration/institutions/${channelInstitutionId}/institution-firms`
    )
  },
  /**
   * List configuration terms based on given loan facility ID.
   * @param {string} stage - The current stage of the data points.
   * @param {Object} payload - The request parameters.
   * @param {Number} payload.loanFacilityId - The ID of the loan facility to list terms for.
   * @returns {Promise<ListTermsRespDTO>} - A promise that resolves with the list of terms.
   *
   * @typedef {Object} ListTermsRespDTO
   * @property {TermsDTO[]} items - An array of terms.
   *
   * @typedef {Object} TermsDTO
   * @property {Number} termsId - The unique ID of the terms.
   * @property {String} title - The title of the terms.
   * @property {String} code - The code associated with the terms.
   * @property {String} content - The content of the terms in HTML format.
   * @property {String} description - The description of the terms.
   * @property {String[]} termTypes - The types of terms.
   *
   * @APICode {stage}.configurations.terms.list
   */
  getTerms(stage, payload) {
    return http.get(`${API_VERSION}/${stage}/configurations/terms`, payload)
  },
}
