/***
 * Below function is for getting cm config
 ***/
import { configurationAPI } from '@/shared/api/index'
import { useStore } from '@/shared/composables/store/useStore'
import {
  APIStageEnum,
  EnumCodes,
  getSessionStorageItem,
  isEmpty,
  PubSub,
  setSessionStorageItem,
  TotalLoanFacilityDataStructureEnum,
} from '@/shared/utils'
import { ref } from 'vue'

const isGeneralConfigLoading = ref(true)
const GENERAL_CONFIG = ref({})
export const useGeneralConfig = () => {
  const store = useStore()
  const getGeneralConfig = (needSetRiskEventMode, totalLoanFacilityId) => {
    if (!isEmpty(getSessionStorageItem('GENERAL_CONFIG'))) {
      GENERAL_CONFIG.value = getSessionStorageItem('GENERAL_CONFIG')
      isGeneralConfigLoading.value = false
      return
    }

    const otherParams = totalLoanFacilityId ? { totalLoanFacilityId } : {}
    Promise.all([
      configurationAPI.listInstitutionLevelConfigs(APIStageEnum.service, {
        categoryCode: 'collateralDataHub',
        ...otherParams,
      }),
      configurationAPI.listInstitutionLevelConfigs(APIStageEnum.service, {
        categoryCode: 'general',
        ...otherParams,
      }),
      configurationAPI.listWebsiteLevelConfigs(APIStageEnum.service, {
        categoryCode: 'collateralDataHub',
        ...otherParams,
      }),
      configurationAPI.getDataPoints(APIStageEnum.service, {
        isQueryForPageNode: false,
        topPageNodeCode: 'COLLATERAL_MANAGEMENT',
        ...otherParams,
      }),
      configurationAPI.getDataPoints(APIStageEnum.service, {
        isQueryForPageNode: false,
        topPageNodeCode: 'APERTURE',
        ...otherParams,
      }),
      configurationAPI.listConfigurationEnums({
        enumTypeCodes: [...EnumCodes, 'asset_type_enum'],
        institutionId: store.state.userInfo.roleConfiguration.institutionId,
        ...otherParams,
      }),
      configurationAPI.listDevelopmentConfigurations(APIStageEnum.service),
    ]).then(
      ([
        cmInstitutionConfig,
        generalInstitutionConfig,
        websiteLevelConfig,
        cmDataPointsConfig,
        apertureDataPointsConfig,
        { enums },
        { developmentConfig },
      ]) => {
        const isActiveLendingRateException =
          cmInstitutionConfig.lenderInstitutionLevelConfigs.lendingRateException
        GENERAL_CONFIG.value = {
          isTotalLoanFacilityDataStructure:
            generalInstitutionConfig.generalLenderInstitutionLevelConfigs
              .totalLoanFacilityDataStructure ===
            TotalLoanFacilityDataStructureEnum.complexLayerStructure,
          enableUnpledgingAssetIntradayValidation:
            generalInstitutionConfig.generalLenderInstitutionLevelConfigs
              .enableUnpledgingAssetIntradayValidation,
          blockUnpledgingAssetWorkflow:
            generalInstitutionConfig.generalLenderInstitutionLevelConfigs
              .blockUnpledgingAssetWorkflow,
          unpledgingAssetValidationRules:
            generalInstitutionConfig.generalLenderInstitutionLevelConfigs
              .unpledgingAssetValidationRules,
          riskEventMode:
            cmInstitutionConfig.lenderInstitutionLevelConfigs.riskEventMode,
          intraday:
            cmInstitutionConfig.lenderInstitutionLevelConfigs
              .enableIntradayDatapoints,
          supportLrr:
            cmInstitutionConfig.generalLenderInstitutionLevelConfigs
              .supportLrrOnCM,
          collateralExceptionSettings:
            cmInstitutionConfig.generalLenderInstitutionLevelConfigs
              .collateralExceptionSettings,
          callNoticeOptionList:
            cmInstitutionConfig.lenderInstitutionLevelConfigs
              .callNoticeRecipientOption,
          cmOpenRiskEventNoticeEmailTemplateItemList:
            cmInstitutionConfig.lenderInstitutionLevelConfigs
              .cmOpenRiskEventNoticeEmailTemplateItemList,
          cmClosedRiskEventNoticeEmailTemplateItemList:
            cmInstitutionConfig.lenderInstitutionLevelConfigs
              .cmClosedRiskEventNoticeEmailTemplateItemList,
          cmRiskEventResolutionSystemAutoPopulate:
            cmInstitutionConfig.lenderInstitutionLevelConfigs
              .cmRiskEventResolutionSystemAutoPopulate,
          crrValues:
            cmInstitutionConfig.lenderInstitutionLevelConfigs
              .collateralRiskRating,
          exceptionMakerCheckerEnableMap: {
            classificationException:
              cmInstitutionConfig.lenderInstitutionLevelConfigs
                .classificationException,
            accountLevel:
              isActiveLendingRateException &&
              cmInstitutionConfig.lenderInstitutionLevelConfigs.lendingRateExceptionList.find(
                (i) => i.levelCode === 'accountLevel'
              )?.isActive,
            lineLevel:
              isActiveLendingRateException &&
              cmInstitutionConfig.lenderInstitutionLevelConfigs.lendingRateExceptionList.find(
                (i) => i.levelCode === 'lineLevel'
              )?.isActive,
            institutionLevel:
              isActiveLendingRateException &&
              cmInstitutionConfig.lenderInstitutionLevelConfigs.lendingRateExceptionList.find(
                (i) => i.levelCode === 'institutionLevel'
              )?.isActive,
            locProductLevel:
              isActiveLendingRateException &&
              cmInstitutionConfig.lenderInstitutionLevelConfigs.lendingRateExceptionList.find(
                (i) => i.levelCode === 'locProductLevel'
              )?.isActive,
            creditPolicyLevel:
              isActiveLendingRateException &&
              cmInstitutionConfig.lenderInstitutionLevelConfigs.lendingRateExceptionList.find(
                (i) => i.levelCode === 'creditPolicyLevel'
              )?.isActive,
          },

          lrrValues:
            generalInstitutionConfig.lenderInstitutionLevelConfigs
              .lineRiskRating,

          funderInstitutions: websiteLevelConfig?.funderInstitutions || [],
          bookLevelAmountUnit: (
            websiteLevelConfig?.websiteConfig?.cmBookSummaryMonetaryUnit || 'K'
          ).toUpperCase(),

          dataFields: cmDataPointsConfig.dataPoints,
          apertureDataFields: apertureDataPointsConfig.dataPoints,

          assetTypeEnum: enums.asset_type_enum,
          domicile: enums.country,
          eventReasonOptions: enums.risk_event_reason,
          riskEventType: enums.risk_event_type,

          creditTypeExceptionBasis: developmentConfig.creditTypeExceptionBasis,
          featuresConfig: developmentConfig.featuresConfig,
          enableCrossCollateralization:
            developmentConfig.enableCrossCollateralization,
          disableConcentrationLimit:
            developmentConfig.disableConcentrationLimit,
          liquidityValues: developmentConfig.liquidityValues,
          qualityValues: developmentConfig.qualityValues,
          diversificationValues: developmentConfig.diversificationValues,
          volatilityValues: developmentConfig.volatilityValues,

          // enableAdditionalInformation: null,
          // creditPolicyName
          // collateralType,
          // snType
        }

        setSessionStorageItem('GENERAL_CONFIG', GENERAL_CONFIG.value)
        if (needSetRiskEventMode) {
          const websiteCode = store.state.frontendConfig.websiteCode
          const isBny = websiteCode.toLowerCase().includes('bny')
          isBny && store.commit('collateral-management/setIsBny', isBny)
          store.commit(
            'collateral-management/setRiskEventMode',
            GENERAL_CONFIG.value.riskEventMode
          )
        }
        PubSub.shared().publish('GENERAL_CONFIG_LOADED')
        isGeneralConfigLoading.value = false
      }
    )
  }

  return {
    GENERAL_CONFIG,
    getGeneralConfig,
    isGeneralConfigLoading,
  }
}
