import { http, API_VERSION } from '@/shared/api/http'
export const securityAPI = {
  getSecurityDetail(secId) {
    // permission code: securities.get
    return http.get(`${API_VERSION}/securities/${secId}`)
  },
  /**
   * Retrieves security master codes based on a code type.
   * @param {string} universe - The universe to retrieve codes from.
   * @param {Object} [params] - Optional parameters for the API call.
   * @param {number} [params.codeType] - Optional code type for filtering.
   * @returns {Promise<SecurityMasterCodeListRespDTO>} - A promise that resolves with the list of security master codes.
   * @APICode securities.codes.list
   */
  getSecurityMasterCodes(params) {
    return http.get(`${API_VERSION}/securities/codes`, params)
  },
}
