import { ref } from 'vue'
import { billAPI } from '../../api'

/**
 * Composition API to interact with bill data including listing, updating, and deleting bills.
 *
 * @returns {Object} Object containing attributes and methods for bill interaction.
 * @property {Boolean} isBillsLoading - Reactive state to indicate if bills are being loaded.
 * @property {Function} createBills - Method to create bills.
 * @property {Function} listBills - Method to list bills.
 * @property {Function} updateBill - Method to update a bill entry by its ID.
 * @property {Function} deleteBill - Method to delete a bill entry by its record ID.
 */
export const useBillData = (stage) => {
  const isBillsLoading = ref(false)
  const isBillsCreating = ref(false)
  const isBillUpdating = ref(false)
  const isBillDeleting = ref(false)
  const billResult = ref({})

  /**
   * Creates bills for specific objects of resource.
   *
   * @param {AddBillListRequestDTO} params - The request payload to create bills.
   * @returns {Promise<Object>} A promise that resolves when the bills are created.
   */
  const createBills = async (params) => {
    isBillsCreating.value = true
    try {
      return await billAPI.createBills(stage, params)
    } finally {
      isBillsCreating.value = false
    }
  }

  /**
   * Lists bills for supported objects.
   *
   * @param {ListBillsParams} params - The parameters for listing bills.
   * @returns {Promise<Bill[]>} A promise that resolves with the list of bills.
   */
  const listBills = async (params) => {
    isBillsLoading.value = true
    try {
      billResult.value = await billAPI.listBills(stage, params)
    } finally {
      isBillsLoading.value = false
    }
    return billResult.value
  }

  /**
   * Updates a single bill entry by its bill ID.
   *
   * @param {number} loanId - The ID of the bill to relate loan id.
   * @param {UpdateBillPayload} payload - The payload containing the updated bill information.
   * @returns {Promise<void>} A promise that resolves when the bill is successfully updated.
   */
  const updateBill = async (loanId, payload) => {
    isBillUpdating.value = true
    try {
      return await billAPI.updateBill(stage, loanId, payload)
    } finally {
      isBillUpdating.value = false
    }
  }

  /**
   * Deletes a single bill.
   *
   * @param {number} loanId - The ID of the bill to relate loan id.
   * @param {deleteBillPayload} payload - The payload containing the delete bill information.
   * @returns {Promise<void>} A promise that resolves when the bill is successfully deleted.
   */
  const deleteBill = async (loanId, payload) => {
    isBillDeleting.value = true
    try {
      return await billAPI.deleteBill(stage, loanId, payload)
    } finally {
      isBillDeleting.value = false
    }
  }

  const isBillsExporting = ref(false)
  /**
   * Exports bills data to an Excel file.
   * @param {ExportOptions} [options={}] - Options for exporting the bills.
   * @param {boolean} [options.shouldEncrypt=true] - Whether the export should be encrypted.
   * @param {string} [options.headerNames] - Comma-separated string of header names.
   * @param {string} [options.columnNames] - Comma-separated string of column names.
   * @returns {Promise<Blob>} - A promise that resolves with the binary stream of the Excel file.
   */
  const exportBills = async (options) => {
    isBillsExporting.value = true
    try {
      return await billAPI.exportBills(stage, options)
    } finally {
      isBillsExporting.value = false
    }
  }

  return {
    isBillsCreating,
    createBills,
    isBillsLoading,
    billResult,
    listBills,
    isBillUpdating,
    updateBill,
    isBillDeleting,
    deleteBill,
    isBillsExporting,
    exportBills,
  }
}

/**
 * @deprecated Use `useBillData` instead.
 */
export const useBills = useBillData
