import { every } from 'lodash'
import { computed, ref, unref } from 'vue'
import { assetAPI } from '@/shared/api'
import {
  APIStageEnum,
  AccountPledgeStatusEnum,
  isEqualStringIgnoreCase,
  isIncludeStringIgnoreCase,
} from '@/shared/utils'

const assets = ref([])

export function useCollateralAssetData(stage) {
  stage ||= APIStageEnum.service

  const assetsLoading = ref(false)
  const assetsError = ref(null)

  const isAllAssetsPledged2Sn = computed(() => checkAssetsPledged2Sn(assets))
  const isAllAssetsNotPledged = computed(() => checkAssetsNotPledged(assets))

  const getCollateralPackageAssets = async (
    collateralId,
    archiveAmendmentId
  ) => {
    if (!collateralId) return
    assetsLoading.value = true
    assetsError.value = null
    try {
      const response = await assetAPI.getCollateralPackageAssets(
        stage,
        {
          collateralId,
        },
        archiveAmendmentId
      )
      const data = response?.assets || []
      assets.value = data
      return data
    } catch (err) {
      assetsError.value = err
    } finally {
      assetsLoading.value = false
    }
  }

  return {
    assets,
    assetsLoading,
    assetsError,
    isAllAssetsPledged2Sn,
    isAllAssetsNotPledged,

    checkAssetsPledged2Sn,
    checkAssetsNotPledged,
    getCollateralPackageAssets,
  }
}

/**
 * checking if any of the assets have been pledged to SN
 * @param {Array} assets
 * @returns
 */
function checkAssetsPledged2Sn(assets) {
  return every(unref(assets), (item) => {
    return isEqualStringIgnoreCase(
      item.pledgingStatus,
      AccountPledgeStatusEnum.pledgedToSn
    )
  })
}

/**
 * checking if any of the assets have not been pledged
 * @param {Array} assets
 * @returns
 */
function checkAssetsNotPledged(assets) {
  return every(unref(assets), (item) => {
    return isIncludeStringIgnoreCase(
      [AccountPledgeStatusEnum.notPledged, AccountPledgeStatusEnum.unknown],
      item.pledgingStatus
    )
  })
}
