import { computed, ref } from 'vue'
import { loanFacilityAPI } from '../../../api'
import { useRoute } from '../../route/useRoute'
import { useStore } from '../../store/useStore'
import {
  APIStageEnum,
  SessionKeyEnum,
  getSessionStorageItem,
} from '@/shared/utils'

/**
 * @typedef {Object} TotalLoanFacility
 * @property {number} totalLoanFacilityId - The ID of the total loan facility.
 * @property {string} totalFacilityNumber - The total facility number.
 * @property {string} totalFacilityName - The name of the total facility.
 * @property {string} totalFacilityNickname - The nickname of the total facility.
 * @property {string} status - The status of the total loan facility.
 * @property {string} issuedDate - The issued date of the total loan facility.
 * @property {number} secured - Indicates if the total loan facility is secured.
 */

/**
 * Composition API to interact with total loan facility data.
 *
 * @returns {Object} Object containing attributes and methods for total loan facility interaction.
 */
export const useLoanFacilityData = (stage = APIStageEnum.service) => {
  const isLoanFacilityLoading = ref(false)
  const loanFacilityData = ref({})

  /**
   * Fetches a total loan facility by its ID.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to retrieve.
   */
  const getLoanFacility = async (loanFacilityId, archiveAmendmentId) => {
    isLoanFacilityLoading.value = true
    loanFacilityData.value = {}
    try {
      loanFacilityData.value = await loanFacilityAPI.getSingleLoanFacility(
        {
          loanFacilityId,
        },
        stage,
        archiveAmendmentId
      )
    } finally {
      isLoanFacilityLoading.value = false
    }
  }

  const isLoanFacilityListLoading = ref(false)
  const loanFacilityList = ref([])

  const getLoanFacilityList = async (params = {}, _stage = stage) => {
    isLoanFacilityListLoading.value = true
    params.pageNum ??= params.pageNumber ?? 1
    try {
      params.pageNum ??= params.pageNumber ?? 1
      const data = await loanFacilityAPI.getLoanFacilityList(_stage, params)
      loanFacilityList.value = data
      return data
    } finally {
      isLoanFacilityListLoading.value = false
    }
  }

  const isGetCustodiansByInstitutionIdLoading = ref(false)
  const custodianByInstitutionIdList = ref([])

  /**
   * Fetches custodians by institution ID.
   *
   * @param {number} institutionId - The institution ID to retrieve custodians for.
   * @property {number} institutionId - The unique identifier for the institution.
   */
  const getCustodiansByInstitutionId = async (institutionId) => {
    isGetCustodiansByInstitutionIdLoading.value = true
    try {
      custodianByInstitutionIdList.value =
        await loanFacilityAPI.getCustodiansByInstitutionId(institutionId)
    } finally {
      isGetCustodiansByInstitutionIdLoading.value = false
    }
  }

  const $store = useStore()
  // template use store, todo update to top ref
  const totalLoanFacilityDetail = computed(
    () => $store.state.service.LINE_DETAIL
  )

  /**
   * Current TLF's loanFacilities
   */
  const currentLoanFacilities = computed(() => {
    const storeLoanFacilityList = totalLoanFacilityDetail.value?.children || []
    // client storeLoanFacilityList includes all loanFacilities, need filter by totalLoanFacilityId
    return storeLoanFacilityList.filter(
      (loanFacility) =>
        totalLoanFacilityDetail.value?.totalLoanFacilityId ===
        loanFacility.totalLoanFacilityId
    )
  })

  const $route = useRoute()

  const totalLoanFacilityId = computed(() => {
    return (
      $route?.query?.totalLoanFacilityId ??
      getSessionStorageItem(SessionKeyEnum.totalLoanFacilityId)
    )
  })

  const isUpdateLoanFacilityLoading = ref(false)

  async function updateLoanFacility(loanFacilityId, data) {
    isUpdateLoanFacilityLoading.value = true
    try {
      await loanFacilityAPI.updateLoanFacility({ stage, loanFacilityId }, data)
    } finally {
      isUpdateLoanFacilityLoading.value = false
    }
  }

  const isLoanFacilityFlagInfoLoading = ref(false)
  const loanFacilityFlagInfo = ref({})

  /**
   * Fetches the flag information of the loan facility based on the provided facility loan id.
   *
   * @param {string} loanFacilityId - The ID of the loan facility.
   * @param {string} stage - The stage of the loan facility.
   * @property {string} loanFacilityId - The ID of the loan facility to retrieve flag information for.
   * @property {string} stage - The stage of the loan facility to retrieve flag information for.
   */
  const getLoanFacilityFlagInfo = async (loanFacilityId, _stage = stage) => {
    isLoanFacilityFlagInfoLoading.value = true
    try {
      loanFacilityFlagInfo.value = await loanFacilityAPI.getLoanFacilityFlagInfo(loanFacilityId, stage)
    } finally {
      isLoanFacilityFlagInfoLoading.value = false
    }
  }

  return {
    currentLoanFacilities,
    totalLoanFacilityId,
    isLoanFacilityLoading,
    loanFacilityData,
    getLoanFacility,

    isLoanFacilityListLoading,
    loanFacilityList,
    getLoanFacilityList,

    isGetCustodiansByInstitutionIdLoading,
    custodianByInstitutionIdList,
    getCustodiansByInstitutionId,

    isUpdateLoanFacilityLoading,
    updateLoanFacility,

    isLoanFacilityFlagInfoLoading,
    loanFacilityFlagInfo,
    getLoanFacilityFlagInfo
  }
}
