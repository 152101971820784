import { ref } from 'vue'
import { underwritingAPI } from '../../../api'

const isUnderwritingWebsiteConfigLoading = ref(false)
const underwritingWebsiteConfig = ref({})

/**
 * Composition API to interact with underwriting data.
 *
 * @returns {Object} Object containing attributes and methods for underwriting interaction.
 */
export const useUnderwritingWebsiteConfigData = () => {
  /**
   * Retrieves a list of underwriting tasks based on the provided filter criteria.
   *
   * @param {UnderwritingFilterCriteria} [criteria] - The filter criteria for underwriting tasks.
   */
  const getUnderwritingWebsiteConfig = async (criteria = {}) => {
    try {
      underwritingWebsiteConfig.value =
        await underwritingAPI.getUnderwritingConfig(criteria)
    } catch (error) {}
  }

  return {
    isUnderwritingWebsiteConfigLoading,
    underwritingWebsiteConfig,
    getUnderwritingWebsiteConfig,
  }
}
