import { merge } from 'lodash'
import { getCurrentInstance } from 'vue'

const createTestStub = (returnValue = {}) => {
  if (process.env.NODE_ENV === 'test') {
    return typeof returnValue === 'function' ? () => ({}) : returnValue
  }
}

export const useStore = () => {
  const testStub = createTestStub({ state: { shared: {} } })
  if (testStub) return testStub

  const vm = getCurrentInstance()
  if (vm) {
    return vm.proxy.$store
  }

  if (window.vm) {
    return window.vm.$store
  }

  if (window.microAppData) {
    return window.microAppData.store
  }

  throw new Error('Cannot find store')
}

export const registerSharedStore = () => {
  const testStub = createTestStub()
  if (testStub) return testStub

  const store = useStore()
  if (store?.state?.shared) {
    return
  }
  store.registerModule('shared', {
    namespaced: true,
    state: {
      estimateInstitutionConfig: {},
      proposalInstitutionConfig: {},
      digitalApplicationInstitutionConfig: {},
      underwritingInstitutionConfig: {},
      servicingInstitutionConfig: {},
      collateralDataHubInstitutionConfig: {},
      smaInstitutionConfig: {},
      amendmentInstitutionConfig: {},
      generalInstitutionConfig: {},

      estimateLocProductConfig: {},
      proposalLocProductConfig: {},
      digitalApplicationLocProductConfig: {},
      underwritingLocProductConfig: {},
      servicingLocProductConfig: {},
      collateralDataHubLocProductConfig: {},
      smaLocProductConfig: {},
      amendmentLocProductConfig: {},

      estimateWebsiteConfig: {},
      proposalWebsiteConfig: {},
      digitalApplicationWebsiteConfig: {},
      underwritingWebsiteConfig: {},
      servicingWebsiteConfig: {},
      collateralDataHubWebsiteConfig: {},
      smaWebsiteConfig: {},
      amendmentWebsiteConfig: {},

      proposalDataFieldsConfig: {},
      applicationDataFieldsConfig: {},
      underwritingDataFieldsConfig: {},
      lineDataFieldsConfig: {},
      collateralManagementDataFieldsConfig: {},
      smaDataFieldsConfig: {},

      enumConfig: {},
    },
    mutations: {
      SET_INSTITUTION_CONFIG(state, { config, categoryCode }) {
        const configKey = `${categoryCode}InstitutionConfig`
        state[configKey] = state[configKey] ? merge({}, state[configKey], config) : config
      },
      SET_PRODUCT_CONFIG(state, { config, categoryCode }) {
        state[`${categoryCode}LocProductConfig`] = config
      },
      SET_WEBSITE_CONFIG(state, { config, categoryCode }) {
        state[`${categoryCode}WebsiteConfig`] = config
      },
      SET_DATA_FIELDS_CONFIG(state, { config, topPageNodeCode }) {
        state[`${topPageNodeCode}DataFieldsConfig`] = config
      },
      SET_ENUM_CONFIG(state, config) {
        state.enumConfig = config
      },
    },
  })

  return store
}
