/**
 * Parses the select options for a given field.
 *
 * @param {string} idPrefix - The prefix for the option IDs.
 * @param {string} fieldRenderOption - The string representation of the field render options.
 * @returns {Array} - The parsed select options.
 */
function parseSelectOptions(idPrefix, fieldRenderOption) {
  let options = fieldRenderOption || []
  if (typeof fieldRenderOption === 'string') {
    try {
      options = JSON.parse(fieldRenderOption)
    } catch (e) {}
  }
  options.forEach((option) => {
    option.id = `${idPrefix}-${option.value}`
  })
  return options
}

/**
 * Parses and validates a field's validation rule.
 *
 * @param {string|object} fieldValidateRule - The validation rule for the field.
 * @returns {object} - The parsed and validated field validation configuration.
 */
export function parseValidateRule(fieldValidateRule) {
  let fieldValidateConfig = fieldValidateRule || {}
  if (typeof fieldValidateConfig === 'string') {
    try {
      fieldValidateConfig = JSON.parse(fieldValidateConfig)
    } catch (_) {
      fieldValidateConfig = {}
    }
  }
  return fieldValidateConfig
}

/**
 * Converts a data field configuration to a dynamic form field.
 *
 * @param {string} idPrefix - The prefix for the field ID.
 * @param {object} config - The data field configuration.
 * @param {string} config.dbField - The database field.
 * @param {boolean} config.isRequire - Indicates if the field is required.
 * @param {boolean} config.isRequired - Indicates if the field is required.
 * @param {boolean} config.isDisplay - Indicates if the field is displayed.
 * @param {boolean} config.isReadonly - Indicates if the field is readonly.
 * @param {string} config.fieldRenderType - The type of field renderer.
 * @param {string} config.fieldLabel - The label of the field.
 * @param {string} config.fieldDescription - The description of the field.
 * @param {array | string} config.fieldRenderOption - The options for the field renderer.
 * @param {string} config.fieldColumn - The width of the field.
 * @param {string} config.fieldDefaultValue - The default value of the field.
 * @param {string} config.externalFieldCode - The external field code.
 * @param {object} config.fieldValidateRule - The validation rules for the field.
 * @param {number} config.fieldValidateRule.maxLength - The maximum length of the field.
 * @param {string} config.fieldValidateRule.regex - The regular expression for the field.
 * @param {number} config.fieldValidateRule.maxValue - The maximum value of the field.
 * @param {number} config.fieldValidateRule.minValue - The minimum value of the field.
 * @param {string} config.fieldValidateRule.maxDate - The maximum date of the field.
 * @param {boolean} config.fieldValidateRule.maxDateInclude - Indicates if the maximum date is inclusive.
 * @param {string} config.fieldValidateRule.minDate - The minimum date of the field.
 * @param {boolean} config.fieldValidateRule.minDateInclude - Indicates if the minimum date is inclusive.
 * @returns {object} - The converted dynamic form field.
 */
export function convertDataFieldConfigToDynamicFormField(
  idPrefix,
  {
    dbField,
    fieldRenderType,
    fieldLabel,
    fieldDescription,
    externalFieldCode,
    fieldColumn,
    fieldDefaultValue,
    fieldRenderOption,
    isDisplay = true,
    isRequire,
    isRequired,
    isReadonly,
    fieldValidateRule = {},
  },
  needValidate = false
) {
  // Helper function to create constraint object
  function createConstraint({
    maxLength = 500,
    regex,
    maxValue,
    minValue,
    maxDate,
    maxDateInclude,
    minDate,
    minDateInclude,
  }) {
    const constraint = {
      maxLength,
      maxValue,
      minValue,
      maxDate,
      maxDateInclude,
      minDate,
      minDateInclude,
      format: 'YYYY-MM-DD',
      showFormat: 'MM/DD/YYYY',
    }
    if (regex) {
      constraint.mask = {
        regex: `${regex}{0,${maxLength}}`,
        placeholder: '',
      }
    }
    return constraint
  }

  const valueKey = dbField || externalFieldCode
  const elementId = `${idPrefix}_${valueKey}`

  return {
    id: elementId,
    componentName: fieldRenderType,
    title: fieldLabel,
    description: fieldDescription,
    valueKey: valueKey,
    defaultValue: fieldDefaultValue || null,
    visible: isDisplay,
    required: needValidate ? isRequire || isRequired : false,
    requiredMark: isRequire || isRequired,
    columns: fieldColumn || 3,
    options: parseSelectOptions(elementId, fieldRenderOption),
    disabled: isReadonly,
    externalFieldCode,
    constraint: createConstraint(parseValidateRule(fieldValidateRule)),
  }
}
