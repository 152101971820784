import { http, API_VERSION } from './http'

export const locProductAPI = {
  /**
   * Retrieves a list of LOC (Line of Credit) Products.
   *
   * @param {Object} params - The request parameters.
   * @param {number} params.channelInstitutionId - The channel institution ID, required.
   * @param {number} [params.firmInternalGroupId] - The firm internal group ID, optional.
   * @param {number} [params.clientRepCodeId] - The client representative code ID, optional.
   * @returns {Promise<Object>} The list of LOC products.
   * @apiCode service.locProducts.list
   */
  getListLocProducts(params) {
    return http.get(`${API_VERSION}/loc-products`, params)
  },
  /**
   * Retrieves a single LOC product based on the given universe and product ID.
   * @param {string} universe - The universe of the LOC product.
   * @param {string} locProductId - The ID of the LOC product to retrieve.
   * @returns {Promise<LocProduct>} - A promise that resolves with the LOC product data.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/loc-product/getSingleLocProduct.md?plain=1
   * @APICode loc-products.get
   */
  getLocProductDetail(locProductId) {
    return http.get(`${API_VERSION}/loc-products/${locProductId}`)
  },
  /**
   * Retrieves the details of LOC Product change requests for a specific application.
   * @param {string} stage - The stage of the application.
   * @param {number} applicationId - The unique identifier of the application.
   * @returns {Promise<LocProductChangeRespDTO>} - A promise that resolves with the LOC Product change request details.
   * @typedef {Object} LocProductChangeRespDTO
   * @property {number} applicationId - The ID of the application.
   * @property {number} loanFacilityId - The ID of the loan facility.
   * @property {number} totalLoanFacilityId - The ID of the total loan facility.
   * @property {string} originalLocProductId - The original LOC product ID.
   * @property {string} requestLocProductId - The requested LOC product ID.
   * @property {string} finalLocProductId - The final LOC product ID.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/application/getApplicationLocProductDetails.md
   * @APICode {stage}.applications.loc-product-change-requests.get
   */
  getApplicationLocProductDetails(stage, applicationId) {
    return http.get(
      `${API_VERSION}/${stage}/applications/${applicationId}/loc-product-change-requests`
    )
  },
}
