import { calculationAPI } from '@/shared/api'
import { ref } from 'vue'

export function useCalculation(stage) {
  const isCollateralsCalculationLoading = ref(false)
  function createCollateralsCalculation(params) {
    isCollateralsCalculationLoading.value = true
    return calculationAPI
      .createCollateralsCalculation(stage, params)
      .finally(() => {
        isCollateralsCalculationLoading.value = false
      })
  }

  const isCollateralAssetsEstimationLoading = ref(false)
  const collateralAssetsEstimationResult = ref({})
  function createCollateralAssetEstimation(params) {
    isCollateralAssetsEstimationLoading.value = true
    return calculationAPI
      .createCollateralAssetEstimation(stage, params)
      .then((data) => {
        collateralAssetsEstimationResult.value = data
      })
      .finally(() => {
        isCollateralAssetsEstimationLoading.value = false
      })
  }

  return {
    isCollateralsCalculationLoading,
    createCollateralsCalculation,

    isCollateralAssetsEstimationLoading,
    collateralAssetsEstimationResult,
    createCollateralAssetEstimation,
  }
}

const isLiquidityPathsLoading = ref(false)
const isLiquidityPathsReady = ref(true)
const calculatedResult = ref({})
const calculateError = ref(null)

/**
 * Custom composable function for managing liquidity paths calculations.
 * @returns {Object} An object containing reactive variables and functions related to liquidity paths calculations.
 * @property {Ref<boolean>} isLiquidityPathsLoading - A reactive boolean indicating whether the liquidity paths calculation is currently being loaded.
 * @property {Ref<Object>} liquidityPaths - A reactive object representing the liquidity paths calculation results.
 * @property {Function} calculateLiquidityPaths - A function to fetch the liquidity paths calculation results asynchronously.
 * @param {Object} payload - The information needed to calculate liquidity paths.
 */
export const useCalculationsLiquidityPaths = () => {
  const calculateLiquidityPaths = async (payload) => {
    isLiquidityPathsLoading.value = true
    isLiquidityPathsReady.value = false
    try {
      const preserveCapitalUpsideProjectionWeight =
      payload.preserveCapitalUpsideProjectionWeight
        ? payload.minimizeTaxImplicationWeight
          ? 0.3
          : 1
        : payload.minimizeTaxImplicationWeight
        ? 0
        : 0.5
    const minimizeTaxImplicationWeight = 1 - preserveCapitalUpsideProjectionWeight
      const result = await calculationAPI.calculateLiquidityPaths({
        ...payload,
        interestRate: payload.interestRate / 100.0,
        locProductId: payload.additionalAccountInfo?.locProductId,
        longTermCapitalGainTaxRate: payload.longTermCapitalGainTaxRate / 100.0,
        shortTermCapitalGainTaxRate:
          payload.shortTermCapitalGainTaxRate / 100.0,
        preserveCapitalUpsideProjectionWeight,
        minimizeTaxImplicationWeight,
        holdings: payload.holdings.flat().map((item) => {
          return {
            ...item,
            soldMarketValue: 0,
            expectedReturn: (item.expectedReturn || 0) / 100,
          }
        }),
      })
      if (result?.optimalSolution?.costOptimization < 0) {
        throw new Error('No Result')
      } else {
        const { optimalSolution } = result || {}
        const { holdings } = optimalSolution || {}
        if (holdings?.length) {
          holdings.forEach((holding) => {
            holding.expectedReturn = (holding.expectedReturn || 0) * 100
          })
        }
        calculatedResult.value = result
        calculateError.value = null
      }
    } catch (e) {
      calculatedResult.value = {}
      calculateError.value = e
    } finally {
      isLiquidityPathsLoading.value = false
      setTimeout(() => {
        isLiquidityPathsReady.value = true
      }, 500)
    }
  }

  return {
    isLiquidityPathsLoading,
    isLiquidityPathsReady,
    calculatedResult,
    calculateError,
    calculateLiquidityPaths,
  }
}

const selectPathCalculateResult = ref({})
export const useCalculationsSelectPath = () => {
  const isSelectPathLoading = ref(false)
  const calculateSelectPath = async (payload) => {
    isSelectPathLoading.value = true
    try {
      selectPathCalculateResult.value =
        await calculationAPI.calculateLiquidityPaths({
          ...payload,
          interestRate: payload.interestRate / 100.0,
          longTermCapitalGainTaxRate:
            payload.longTermCapitalGainTaxRate / 100.0,
          shortTermCapitalGainTaxRate:
            payload.shortTermCapitalGainTaxRate / 100.0,
          preserveCapitalUpsideProjectionWeight:
            payload.preserveCapitalUpsideProjectionWeight ? 0.3 : 0,
          minimizeTaxImplicationWeight: payload.minimizeTaxImplicationWeight
            ? 0.7
            : 0,
          holdings: payload.holdings.flat(),
          customizedSellingStrategy: true,
        })
    } catch (e) {
      selectPathCalculateResult.value = {}
    } finally {
      isSelectPathLoading.value = false
    }
  }

  return {
    isSelectPathLoading,
    selectPathCalculateResult,
    calculateSelectPath,
  }
}

/**
 * @typedef {Object} RetirementIncomeCalcReqDTO
 * @property {number} initialAssetValue - The initial value of the user's assets.
 * @property {number} timeInYears - The time span in years for the retirement income calculation.
 * @property {number} initialWithdrawRate - The initial rate at which the user will withdraw from their assets.
 * @property {number} inflationRate - The rate of inflation to consider in the calculation.
 * @property {number} interestRate - The interest rate to consider for asset growth.
 * @property {boolean} [setGlidePath] - Flag to determine if a glide path should be set for the investment.
 * @property {string} [glidePathMethod] - The method to use for the glide path if setGlidePath is true.
 * @property {InvestmentMetrics} [portfolioMetrics] - The metrics for the overall investment portfolio.
 * @property {InvestmentMetrics} [equityMetrics] - The metrics for the equity portion of the investment.
 * @property {InvestmentMetrics} [bondMetrics] - The metrics for the bond portion of the investment.
 * @property {number} lendingCapacityLimitRate - The limit rate for the lending capacity.
 * @property {SblBorrowRateMapping[]} [sblBorrowRatioMappings] - Mappings for securities-based lending borrow ratios.
 * @typedef {Object} InvestmentMetrics
 * @property {number} [expectedAnnualReturn] - The expected annual return of the investment.
 * @property {number} [standardDeviation] - The standard deviation of the investment's return.
 * @typedef {Object} SblBorrowRateMapping
 * @property {string} comparisonOperator - The operator to use for comparing drawdown rates.
 * @property {number} peakDrawdownRate - The peak drawdown rate for the investment.
 * @property {number} sblBorrowRatio - The ratio for securities-based lending borrowing.
 * @typedef {Object} RetirementIncomeCalcRespDTO
 * @property {Object.<number, AssetFinancialSnapshot[]>} drawSnapshots -
 * Snapshots of financial data for different percentiles.
 * @property {Object.<number, AssetFinancialSnapshot[]>} sblSnapshots -
 * Snapshots of securities-based lending data for different percentiles.
 * @typedef {Object} AssetFinancialSnapshot
 * @property {number} year - The year of the snapshot.
 * @property {number} month - The month of the snapshot.
 * @property {number} returnRate - The return rate for that period.
 * @property {number} spendingAmount - The amount spent during that period.
 * @property {number} drawAmount - The amount drawn from the assets.
 * @property {number} borrowAmount - The amount borrowed.
 * @property {number} sblBalance - The balance of the securities-based lending.
 * @property {number} assetValue - The value of the assets.
 * @property {number} netAssetValue - The net value of the assets.
 */

/**
 * Composition API to interact with collateral calculations for retirement incomes.
 *
 * @returns {Object} Object containing attributes and methods for collateral calculations interaction.
 */
export const useCollateralCalculationsData = () => {
  const isCollateralCalculationsLoading = ref(false)
  const retirementIncomeResult = ref({})

  /**
   * Creates a retirement income calculation based on various financial metrics and inputs.
   *
   * @param {RetirementIncomeCalcReqDTO} requestData - The request data for retirement income calculation.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const calculationRetirementIncome = async (requestData) => {
    isCollateralCalculationsLoading.value = true
    try {
      retirementIncomeResult.value =
        await calculationAPI.calculationRetirementIncome({
          initialAssetValue: requestData.initialAssetValue,
          timeInYears: requestData.timeInYears,
          initialWithdrawRate: requestData.initialWithdrawPercentage / 100,
          setGlidePath: requestData.setGlidePath,
          glidePathMethod: requestData.glidePathMethod,
          bondMetrics: {
            expectedAnnualReturn:
              requestData.bondMetrics.expectedAnnualReturnPercentage / 100,
            standardDeviation:
              requestData.bondMetrics.standardDeviationPercentage / 100,
          },
          equityMetrics: {
            expectedAnnualReturn:
              requestData.equityMetrics.expectedAnnualReturnPercentage / 100,
            standardDeviation:
              requestData.equityMetrics.standardDeviationPercentage / 100,
          },
          portfolioMetrics: {
            expectedAnnualReturn:
              requestData.portfolioMetrics.expectedAnnualReturnPercentage / 100,
            standardDeviation:
              requestData.portfolioMetrics.standardDeviationPercentage / 100,
          },
          inflationRate: requestData.inflationPercentage / 100,
          interestRate: requestData.interestPercentage / 100,
          lendingCapacityLimitRate:
            requestData.lendingCapacityLimitPercentage / 100,
          sblBorrowRatioMappings: requestData.sblBorrowRatioMappings.map(
            (mapping) => ({
              comparisonOperator: mapping.comparisonOperator,
              peakDrawdownRate: mapping.peakDrawdownPercentage / 100,
              sblBorrowRatio: mapping.sblBorrowPercentage / 100,
            })
          ),
        })
    } finally {
      isCollateralCalculationsLoading.value = false
    }
  }

  return {
    isCollateralCalculationsLoading,
    retirementIncomeResult,
    calculationRetirementIncome,
  }
}
