import { activityLogAPI } from '@/shared/api/activity-log'
import { formatCurrency, globalThis } from '@/shared/utils'
import { isNaN, isObject, upperCase } from 'lodash'

let _this
if (process.env.NODE_ENV === 'development') {
  _this = window.vm
} else {
  _this = window.microAppData?.vue?.prototype
}

const baseUrl = '/v5'

function getDataPoint(module, key, vm) {
  const { dataFields } = vm.$utils.getSessionStorage('GENERAL_CONFIG', {})
  const allDataPoints = dataFields?.[module] || []
  const noTargetData = {
    display: false,
    name: '',
  }
  if (!allDataPoints.length) {
    return noTargetData
  }
  const dataPoint = allDataPoints.filter((i) => i.fieldCode === key)
  if (!dataPoint || dataPoint.length === 0) {
    return noTargetData
  }
  const { isDisplay, fieldLabel } = dataPoint[0]
  return {
    display: isDisplay,
    name: fieldLabel,
  }
}

function getConfigFlagDataList(vm) {
  const configFlagList = [
    {
      fieldCode: 'concentrationFlag',
      fieldName: vm.$ts('concentration'),
      color: '#ffcf24',
      value: 256,
    },
    {
      fieldCode: 'deceasedClientFlag',
      fieldName: vm.$ts('deceased_client'),
      color: '#0a2236',
      value: 2048,
    },
    {
      fieldCode: 'prefundingConditionFlag',
      fieldName: vm.$ts('prefunding_condition'),
      color: '#CCDBFF',
      value: 512,
    },
    {
      fieldCode: 'withdrawalOverrideFlag',
      fieldName: vm.$ts('withdrawal_override'),
      color: '#CCDBFF',
      value: 1024,
    },
    {
      fieldCode: 'hardDollarCovenant',
      fieldName: vm.$ts('hard_dollar_covenant'),
      color: '#CCCCCC',
      value: 33554432,
    },
  ]
  return configFlagList
    .filter(
      (configFlag) =>
        getDataPoint(
          'CM_MONITORING_COLLATERAL_GENERAL',
          configFlag.fieldCode,
          vm
        ).display
    )
    .map((configFlag) => {
      return {
        name: configFlag.fieldName,
        color: configFlag.color,
        value: configFlag.value,
      }
    })
}

const getFlagList = function (vm) {
  const { enableCrossCollateralization = false } = vm.$utils.getSessionStorage(
    'GENERAL_CONFIG',
    {}
  )
  let flagList = [
    { name: vm.$ts('security_seasoning'), color: '#5344FF', value: 4194304 },
    { name: vm.$ts('unrecognized_security'), color: '#E59138', value: 2097152 },
    { name: vm.$ts('collateral_call'), color: '#9013FE', value: 128 },
    {
      name: vm.$ts('collateral_shortfall'),
      color: '#ED4849',
      value: 64,
    },
    { name: vm.$ts('no_holdings'), color: '#2DA4FF', value: 16 },
    { name: vm.$ts('exception'), color: '#4D627B', value: 8 },
    { name: vm.$ts('purpose_loan'), color: '#CCDBFF', value: 4 },
    {
      name: vm.$ts('cross_collateralization'),
      color: '#B3FFD9',
      value: 2,
      display: enableCrossCollateralization,
    },
  ]
  flagList = flagList.filter((flag) => flag.display !== false)
  return flagList
}

const getCollateralFlagFilterTypes = function (vm) {
  const { riskEventMode = 'SHORTFALL', enableCrossCollateralization = false } =
    vm.$utils.getSessionStorage('GENERAL_CONFIG', {})

  const configFlagList = getConfigFlagDataList(vm).map((item) => {
    return {
      label: item.name,
      value: item.value,
    }
  })

  const collateralFlagTypes = [
    {
      label: vm.$ts('cross_collateralization'),
      value: 2,
      display: enableCrossCollateralization,
    },
    {
      label: vm.$ts('purpose_loan'),
      value: 4,
    },
    {
      label: vm.$ts('exception'),
      value: 8,
    },
    {
      label: vm.$ts('no_holdings'),
      value: 16,
    },
    {
      label: vm.$ts('collateral_shortfall'),
      value: 64,
      display: riskEventMode !== 'CALL',
    },
    {
      label: vm.$ts('collateral_call'),
      value: 128,
    },
    { label: vm.$ts('unrecognized_security'), value: 2097152 },
    { label: vm.$ts('security_seasoning'), value: 4194304 },
  ]
  const collateralFlagFilterList = [
    ...collateralFlagTypes,
    ...configFlagList,
  ].filter((flag) => flag.display !== false)
  return vm.$lodash.sortBy(collateralFlagFilterList, ['label'])
}

const formatFlagData = function (vm, data) {
  const { collateralFlags } = data
  let flagList = getFlagList(vm)
  flagList = vm.$lodash.sortBy(
    [...flagList, ...getConfigFlagDataList(vm)],
    ['name']
  )
  return flagList.filter(
    (flag) => (collateralFlags & flag.value) === flag.value
  )
}
const formatEventFlag = function (vm, row) {
  const { collateralFlags } = row
  let flagList = getFlagList(vm)
  flagList = vm.$lodash.sortBy(
    [...flagList, ...getConfigFlagDataList(vm)],
    ['name']
  )
  const collateralFlagsNames = flagList
    .filter((flag) => (collateralFlags & flag.value) === flag.value)
    .map((flag) => flag.name)
  return collateralFlagsNames.length
    ? `"${collateralFlagsNames.join(', ')}"`
    : ''
}

const collateralFlagExportFilter = (collateralFlags) => {
  let flagList = getFlagList(window.vm)
  flagList = window.vm.$lodash.sortBy(
    [...flagList, ...getConfigFlagDataList(window.vm)],
    ['name']
  )
  const collateralFlagsNames = flagList
    .filter((flag) => (collateralFlags & flag.value) === flag.value)
    .map((flag) => flag.name)
  return collateralFlagsNames.length
    ? `"${collateralFlagsNames.join(' | ')}"`
    : '-'
}

function getConditionList(
  fullList = [],
  conditionDataKeyList = [],
  currentListCode = '',
  conditionListCodes = []
) {
  return fullList.filter((columns) =>
    conditionListCodes.includes(currentListCode)
      ? true
      : !conditionDataKeyList.includes(columns.dataKey)
  )
}

function formatYesNoText(flag = false) {
  return flag ? globalThis().$ts('yes') : globalThis().$ts('no')
}

function getMaskPIIStatus(vm, tableOptions, reportName) {
  return new Promise((resolve) => {
    const { columns, selectFields } = tableOptions || vm.tableOptions
    const selectFieldsKeys = getSelectFieldsKeys(columns, selectFields)
    const piiKeys = [
      'lineName',
      'parentLineName',
      'childLineName',
      'nickname',
      'loanName',
      'collateralName',
      'assetName',
      'assetNames',
      'advisorName',
      'borrowerNames',
      'primaryAdvisorName',
      'clientName',
      'relationshipGroupName',
      'singleName',
      'email',
    ]
    let hasPiiFieldsSelected = false
    const hasUnmaskPIIAuth =
      vm.$store.state.userInfo?.roleConfiguration
        ?.isAllowExportWithoutEncryption
    piiKeys.forEach((i) => {
      if (selectFieldsKeys.includes(i)) {
        hasPiiFieldsSelected = true
      }
    })
    if (hasPiiFieldsSelected) {
      vm.$s_confirm({
        type: 'warning',
        title: vm.$ts('export_pii_alert_title'),
        message: vm.$ts('export_pii_alert_description'),
        confirmText: vm.$ts('yes'),
        cancelText: vm.$ts('no'),
        customNode: hasUnmaskPIIAuth
          ? (h) =>
              h(
                's-button',
                {
                  class: 'ml-4',
                  attrs: { 'button-type': 'outline-primary' },
                  nativeOn: {
                    click: () => {
                      const cancelButton = document.querySelector(
                        '.s-confirm .s-confirm-cancel-button'
                      )
                      if (cancelButton) cancelButton.click()
                      activityLogAPI.createActivityLog({
                        eventObjValue: reportName,
                        eventCode: 'CM_REPORT_EXPORTED_WITH_UNMASKED_PII',
                      })
                      resolve(false)
                    },
                  },
                },
                vm.$ts('export_pii_unmask_btn_title')
              )
          : '',
        onOk() {
          activityLogAPI.createActivityLog({
            eventObjValue: reportName,
            eventCode: 'CM_REPORT_EXPORTED_WITH_MASKED_PII',
          })
          resolve(true)
        },
      })
    } else {
      activityLogAPI.createActivityLog({
        eventObjValue: reportName,
        eventCode: 'CM_REPORT_EXPORTED',
      })
      resolve(true)
    }
  })
}

function getSelectFieldsKeys(columns, selectFields) {
  const fieldsData = selectFields?.fieldsData || []
  let selectFieldsKeys = fieldsData.map((i) => {
    return columns.find((j) => j.title === i)?.dataKey
  })
  if (!selectFieldsKeys?.length) {
    selectFieldsKeys = columns
      .filter((column) => column.dataKey)
      .map((column) => column.dataKey)
  }
  return selectFieldsKeys
}

/**
 * enumToTypeList
 * enumList Object
 * turn { 0: 'a', 1: 'b' } or { 'zero': 'a', 'one': 'b'}
 * to [{ label: 'a', value: 0 }, { label: 'b', value: 1}]
 * or [{ label: 'a', value: 'zero' },{ label: 'b', value: 'one' }]
 */
function enumToTypeList(enumList, isI18n = false) {
  if (!isObject(enumList)) return
  const typeList = []
  for (const [key, value] of Object.entries(enumList)) {
    let typeValue = key * 1
    typeValue = isNaN(typeValue) ? key : typeValue
    typeList.push({
      label: isI18n ? globalThis().$ts(value) : value,
      value: typeValue,
    })
  }
  return typeList
}

/**
 * enumToOptions
 * enumList Object
 */
function enumToOptions(enumList, isI18n = false) {
  if (!isObject(enumList)) return
  const typeList = []
  for (const [key, value] of Object.entries(enumList)) {
    typeList.push({
      text: isI18n ? globalThis().$ts(value) : value,
      value: isNaN(key * 1) ? key : key * 1,
    })
  }
  return typeList
}

/**
 * get Cookie
 * @param name
 */
function getCookie(name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  if (!!arr && Array.isArray(arr)) {
    return arr[2]
  } else {
    return null
  }
}

function formatYaxisLabel(thisObj, initValue) {
  // if number is less than 1000, no unitLabel, use the number itself
  const value = Math.abs(initValue)
  let result
  if (value < 1000) {
    result = value
  } else {
    // if number is between 1000(include) - 0.1M, use 'K' as unitLabel
    // if number is between 1M(include) - 1B, use 'M' as unitLabel
    // if number is equal or bigger than 1B, use 'B' as unitLabel
    let unitLabel = 'M'
    let unit = 1000000
    if (value < 1000000) {
      unitLabel = 'K'
      unit = 1000
    } else if (value >= 1000000000) {
      unitLabel = 'B'
      unit = 1000000000
    }
    const numberIsBigThanTen =
      Math.abs(
        Number(thisObj.$utils.format.number(value / unit, { format: '0,0.0' }))
      ) >= 10
    // if the number is bigger than ten after formatted, remove the decimal, otherwise, keep one decimal
    const formattedNumber = numberIsBigThanTen
      ? thisObj.$utils.format.number(value / unit, { format: '0,0' })
      : thisObj.$utils.format.number(value / unit, { format: '0,0.0' })
    result = `${formattedNumber}${unitLabel}`
  }
  return initValue < 0 ? `$ -${result}` : `$ ${result}`
}

function shortCurrency(
  customCurrency,
  val,
  { format = '$0,0.0', invalid = '-', unit = 'K', append = true }
) {
  unit = upperCase(unit)
  if (val === null || val === undefined) {
    return invalid || '-'
  }
  if (unit === 'M') {
    val = parseFloat(val) / 1000000
  } else if (unit === 'B') {
    val = parseFloat(val) / 1000000000
  } else if (unit === 'K') {
    val = parseFloat(val) / 1000
  }
  if (val < 0.00001 && val > 0) {
    val = 0
  }
  const text = customCurrency(val, { format, invalid })
  if (text !== invalid) {
    return `${text}${append ? unit : ''}`
  }
  return text
}

const decodeFlag = function (planEnum, flag, needArray, needI18n, vm) {
  let result = ''
  const target = []
  const planActions = []
  Object.keys(planEnum).forEach((i) => {
    if ((flag & i) !== 0) {
      target.push(i)
    }
  })
  if (needArray) {
    result = target
  } else {
    for (const [key, value] of Object.entries(planEnum)) {
      if (needI18n) {
        target.includes(key) && planActions.push(vm.$ts(value))
      } else {
        target.includes(key) && planActions.push(value)
      }
    }
    result = planActions.join(', ')
  }
  return result
}

const advancedExportFilename = function (
  vm,
  fileName,
  exportDate = '',
  endExportDate
) {
  fileName = fileName || vm.$ts('file')
  let timeDate = vm.$utils.format.date(
    exportDate || vm.$utils.getSessionStorage('LOAN_BOOK_SUMMARY').asOfDate,
    {
      format: 'MMDDYYYY',
    }
  )
  if (endExportDate) {
    endExportDate = vm.$utils.format.date(endExportDate, {
      format: 'MMDDYYYY',
    })
    timeDate = `${timeDate}_${endExportDate}`
  }
  return (
    fileName
      .trim()
      .match(/[a-z0-9%$>=<()]+/gi)
      .join('_')
      .toUpperCase() + `-${timeDate}`
  )
}

const formatSecurityTips = (vm, security) => {
  const { ticker, isin, sedol, cusip } = security
  const securityTips = vm.$lodash.compact([ticker, isin, sedol, cusip])
  return securityTips.join(', ')
}

const getLocalDate = () => {
  const date = new Date().toLocaleDateString('en-US')
  const arr = date.split('/')
  const m = arr[0] > 9 ? arr[0] : `0${arr[0]}`
  const d = arr[1] > 9 ? arr[1] : `0${arr[1]}`
  return `${m}/${d}/${arr[2]}`
}

function getYNFilter(flag = false) {
  return flag ? window.vm.$ts('y') : window.vm.$ts('n')
}

function getXAxisData(data, locBalance) {
  const { maintenanceExcessEquity, requiredEquity } = data
  const loanBalanceVal = formatCurrency(locBalance || data.locBalance, {
    format: '$0,0',
  })
  const maintenanceExcessEquityVal = formatCurrency(maintenanceExcessEquity, {
    format: '$0,0',
  })
  const requiredEquityVal = formatCurrency(requiredEquity, {
    format: '$0,0',
  })
  return [
    `${globalThis().$ts('balance')}:\n     ${loanBalanceVal}`,
    `${globalThis().$ts('excess_equity')}:\n     ${maintenanceExcessEquityVal}`,
    `${globalThis().$ts('required_equity')}:\n     ${requiredEquityVal}`,
  ]
}

function getSimulatedChartName(stressTestCondition) {
  const { historyEventName, factor, subsector, sector, holding, shock } =
    stressTestCondition
  const shockModifier = shock && shock > 0 ? '+' : ''
  if (historyEventName) {
    return historyEventName
  } else if (subsector) {
    return `${subsector} (${shockModifier}${shock}%)`
  } else if (sector) {
    return `GICS ${globalThis().$ts(
      'sector'
    )} ${sector} (${shockModifier}${shock}%)`
  } else if (holding) {
    return `${globalThis().$ts(
      'single_security_exposure'
    )} (${shockModifier}${shock}%)`
  } else {
    return `${factor} (${shockModifier}${shock}%)`
  }
}

function covertConfigEnumLabel(vm, sourceEnum, configEnum) {
  if (vm.$utils.isEmpty(configEnum)) {
    return sourceEnum
  }
  sourceEnum.forEach((item) => {
    const configItem = configEnum.find((config) => config.code === item.value)
    if (configItem) {
      item.label = configItem.label
      item.text = configItem.label
    }
  })
  return sourceEnum
}

function getBackendTypeList(res, key) {
  let typeList = []
  let typeMap = res[key]?.values || res[key]
  let needForInTypeMap = true
  if (Array.isArray(typeMap) && typeMap.length > 0) {
    const typeItemData = typeMap[0]
    if (typeItemData.hasOwnProperty('label')) {
      typeList = typeMap
      needForInTypeMap = false
    } else {
      typeMap = typeItemData
    }
  } 
  if (needForInTypeMap) {
    for (const k in typeMap) {
      typeList.push({
        label: typeMap[k],
        value: k,
      })
    }
    if (key === 'custodianName') {
      typeList.push({
        label: globalThis().$ts('other'),
        value: ',',
      })
    }
  }
  if (['funder', 'funders'].includes(key)) {
    return typeList.sort((a, b) => b.label.localeCompare(a.label))
  } else {
    return typeList
  }
}   

export {
  _this,
  advancedExportFilename,
  baseUrl,
  collateralFlagExportFilter,
  covertConfigEnumLabel,
  decodeFlag,
  enumToOptions,
  enumToTypeList,
  formatEventFlag,
  formatFlagData,
  formatSecurityTips,
  formatYaxisLabel,
  formatYesNoText,
  getBackendTypeList,
  getCollateralFlagFilterTypes,
  getConditionList,
  getConfigFlagDataList,
  getCookie,
  getDataPoint,
  getFlagList,
  getLocalDate,
  getMaskPIIStatus,
  getSimulatedChartName,
  getXAxisData,
  getYNFilter,
  shortCurrency,
}
