import { http, API_VERSION } from './http.js'

const CUSTOMERS_URL = `${API_VERSION}/customer-users`

export const customerUserAPI = {
  /**
   * Retrieves a custom user by their ID.
   * @param {number} id - The ID of the custom user.
   * @returns {Promise<Object>} A promise that resolves with the custom user data.
   */
  getCustomerUserById(id) {
    return http.get(`${CUSTOMERS_URL}/${id}`, { allProfile: true })
  },

  /**
   * Adds a custom user.
   * @param {Object} params - The parameters for adding the custom user.
   * @returns {Promise<Object>} A promise that resolves with the added custom user data.
   */
  addCustomerUser(params) {
    return http.post(`${CUSTOMERS_URL}`, params)
  },

  /**
   * Edits a custom user.
   * @param {number} uid - The ID of the custom user to edit.
   * @param {Object} params - The parameters for editing the custom user.
   * @returns {Promise<Object>} A promise that resolves with the edited custom user data.
   */
  updateCustomerUser(userId, params) {
    return http.patch(`${CUSTOMERS_URL}/${userId}`, params)
  },

  /**
   * Retrieves a list of custom users.
   * @param {Object} params - The parameters for retrieving the custom user list.
   * @returns {Promise<Array<Object>>} A promise that resolves with an array of custom user data.
   */
  getCustomerUserList: async function (params) {
    delete params.searchTerm
    if (
      Object.prototype.hasOwnProperty.call(params, 'activated') &&
      params.activated !== 'true' &&
      params.activated !== 'false'
    ) {
      delete params.activated
    }
    if (
      Object.prototype.hasOwnProperty.call(params, 'isEnabled') &&
      params.isEnabled !== 'true' &&
      params.isEnabled !== 'false'
    ) {
      delete params.isEnabled
    }
    if (Object.prototype.hasOwnProperty.call(params, 'createdAtMin')) {
      params.createdAtBefore = params.createdAtMin
      delete params.createdAtMin
    }
    if (Object.prototype.hasOwnProperty.call(params, 'createdAtMax')) {
      params.createdAtAfter = params.createdAtMax
      delete params.createdAtMax
    }
    const res = await http.get(`${CUSTOMERS_URL}`, params)
    return res.data
  },

  /**
   * Retrieves customer activities for a specific user.
   * @param {number} userId - The ID of the user.
   * @param {number} [loginRecordCount] - The number of login records to retrieve.
   * @returns {Promise<Array<BizEventSummaryDTO>>} A promise that resolves with an array of business events.
   */
  getCustomerUserActivities(userId, loginRecordCount) {
    return http.get(`${CUSTOMERS_URL}/${userId}/activities`, {
      loginRecordCount,
    })
  },
}
