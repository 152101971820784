import { http, API_VERSION, APIStageEnum } from '@/shared/api/http'
import { formatCapitalize } from '@/shared/utils/index'
import { useAmendmentData } from '@/shared/composables/data'

export const distributionAPI = {
  /**
   * @typedef {Object} MarketValue
   * @property {number} cashMarketValue - Cash market value.
   * @property {number} equitiesMarketValue - Equities market value.
   * @property {number} fixedIncomeMarketValue - Fixed income market value.
   * @property {number} otherMarketValue - Other market value.
   * @property {number} alternativeInvestmentMarketValue - Alternative investment market value.
   * @property {number} hardAssetMarketValue - Hard asset market value.
   * @property {number} insuranceMarketValue - Insurance market value.
   * @property {number} crossCollateralizationMarketValue - Cross collateralization market value.
   * @property {number} realEstateMarketValue - Real estate market value.
   * @property {number} depositMarketValue - Deposit market value.
   */

  /**
   * @typedef {Object} QueryParams
   * @property {number} [estimateId] - Estimate ID.
   * @property {number} [proposalId] - Proposal ID.
   * @property {number} [applicationId] - Application ID.
   * @property {number} [underwritingId] - Underwriting ID.
   * @property {number} [collateralId] - Collateral ID.
   * @property {number} [amendmentCollateralId] - Amendment collateral ID.
   */
  /**
   * Retrieves market values for collateral packages based on the specified universe, stage, and aggregation level.
   * @param {string} universe - The universe of the assets.
   * @param {string} stage - The stage of the assets.
   * @param {string} aggregationLevel - The aggregation level of the assets.
   * @param {QueryParams} [queryParams] - Optional query parameters.
   * @returns {Promise<MarketValue>} Promise object representing the market value of the asset classes.
   * @APICode `{stage}.holdings.distributions.asset-classes.{aggregationLevel}`
   */
  getAssetClassMarketValue(stage, aggregationLevel, aggregationLevelId) {
    // permission code: {stage}.holdings.distributions.asset-classes.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`

    if (stage === APIStageEnum.amendment) {
      const { archiveAmendmentId } = useAmendmentData()
      if (archiveAmendmentId.value) {
        return http.get(
          `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/asset-classes/${aggregationLevel}`,
          { [key]: aggregationLevelId }
        )
      }
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/asset-classes/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },

  /**
   * Gets the distribution of holdings by asset regions based on the specified aggregation level.
   * @param {string} stage - The stage of the holdings.
   * @param {string} aggregationLevel - The level of aggregation for the holdings distribution.
   * @param {Object} [params] - The optional parameters for the API call.
   * @param {number} [params.estimateId] - The ID of the estimate.
   * @param {number} [params.proposalId] - The ID of the proposal.
   * @param {number} [params.applicationId] - The ID of the application.
   * @param {number} [params.underwritingId] - The ID of the underwriting.
   * @param {number} [params.collateralId] - The ID of the collateral.
   * @param {number} [params.amendmentCollateralId] - The ID of the amendment collateral.
   * @param {number} [params.assetId] - The ID of the asset.
   * @param {number} [params.evaluationAssetId] - The ID of the evaluation asset.
   * @param {number} [params.amendmentAssetId] - The ID of the amendment asset.
   * @returns {Promise<HoldingDistributionList>} - A promise that resolves with the holdings distribution list.
   * @typedef {Object} HoldingDistributionList
   * @property {Array<HoldingDistributionDetail>} holdingDistributionList - List of holdings distribution details.
   * @typedef {Object} HoldingDistributionDetail
   * @property {String} type - The type of holding.
   * @property {number} marketValue - The market value of the holding.
   * @property {number} percentage - The percentage of the holding.
   * @property {number} count - The count of holdings.
   * @APICode {stage}.holdings.distributions.asset-regions.{aggregationLevel}
   */
  getHoldingsDistributionByAssetRegions(
    stage,
    aggregationLevel,
    aggregationLevelId
  ) {
    // permission code: {stage}.holdings.distributions.asset-regions.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`

    const { archiveAmendmentId } = useAmendmentData()

    if (stage === APIStageEnum.amendment && archiveAmendmentId.value) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/asset-regions/${aggregationLevel}?${key}=${aggregationLevelId}`
      )
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/asset-regions/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },

  /**
   * Gets the distribution of holdings by asset regions based on the specified aggregation level.
   * @param {string} stage - The stage of the holdings.
   * @param {string} aggregationLevel - The level of aggregation for the holdings distribution.
   * @param {Object} [params] - The optional parameters for the API call.
   * @param {number} [params.estimateId] - The ID of the estimate.
   * @param {number} [params.proposalId] - The ID of the proposal.
   * @param {number} [params.applicationId] - The ID of the application.
   * @param {number} [params.underwritingId] - The ID of the underwriting.
   * @param {number} [params.collateralId] - The ID of the collateral.
   * @param {number} [params.amendmentCollateralId] - The ID of the amendment collateral.
   * @param {number} [params.assetId] - The ID of the asset.
   * @param {number} [params.evaluationAssetId] - The ID of the evaluation asset.
   * @param {number} [params.amendmentAssetId] - The ID of the amendment asset.
   * @returns {Promise<HoldingDistributionList>} - A promise that resolves with the holdings distribution list.
   * @typedef {Object} HoldingDistributionList
   * @property {Array<HoldingDistributionDetail>} holdingDistributionList - List of holdings distribution details.
   * @typedef {Object} HoldingDistributionDetail
   * @property {String} type - The type of holding.
   * @property {number} marketValue - The market value of the holding.
   * @property {number} percentage - The percentage of the holding.
   * @property {number} count - The count of holdings.
   * @APICode {stage}.holdings.distributions.asset-regions.{aggregationLevel}
   */
  getCollateralHoldingsDistributionsByInvestmentClasses(stage, collateralId) {
    // permission code: {stage}.holdings.distributions.asset-regions.collateral/.asset
    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/investment-classes/collateral?collateralId=${collateralId}`
    )
  },

  /**
   * Fetches the distribution of assets by types within a specific stage and aggregation level.
   * @param {string} stage - The stage of the holdings.
   * @param {string} aggregationLevel - The level of aggregation for the holdings distribution.
   * @param {AssetDistributionParams} [params] - The optional parameters for the API call.
   * @returns {Promise<HoldingsDistributionRespDTO>} - A promise that resolves with the distribution of assets.
   * @typedef {Object} AssetDistributionParams
   * @property {number} [estimateId] - The ID related to estimates.
   * @property {number} [proposalId] - The ID related to proposals.
   * @property {number} [applicationId] - The ID related to applications.
   * @property {number} [underwritingId] - The ID related to underwritings.
   * @property {number} [collateralId] - The ID related to collaterals.
   * @property {number} [amendmentCollateralId] - The ID related to amendment collaterals.
   * @property {number} [assetId] - The ID related to assets.
   * @property {number} [evaluationAssetId] - The ID related to evaluation of assets.
   * @property {number} [amendmentAssetId] - The ID related to amendment assets.
   * @typedef {Object} HoldingsDistributionRespDTO
   * @property {Array<HoldingDistributionDetailRespDTO>} holdingDistributionList - List of asset distribution details.
   * @typedef {Object} HoldingDistributionDetailRespDTO
   * @property {string} type - The type of the asset.
   * @property {number} marketValue - The market value of the asset type.
   * @property {number} percentage - The percentage of the asset type.
   * @property {number} count - The count of the asset type.
   * @APICode {stage}.holdings.distributions.asset-types.{aggregationLevel}
   */
  getAssetDistribution(stage, aggregationLevel, aggregationLevelId) {
    // permission code: {stage}.holdings.distributions.asset-types.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`
    const { archiveAmendmentId } = useAmendmentData()

    if (stage === APIStageEnum.amendment && archiveAmendmentId.value) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/asset-types/${aggregationLevel}?${key}=${aggregationLevelId}`
      )
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/asset-types/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },
  /**
   * Fetches the distribution of assets by types within a specific stage and aggregation level.
   * @param {string} stage - The stage of the holdings.
   * @param {string} aggregationLevel - The level of aggregation for the holdings distribution.
   * @param {AssetDistributionParams} [params] - The optional parameters for the API call.
   * @returns {Promise<HoldingsDistributionRespDTO>} - A promise that resolves with the distribution of assets.
   * @typedef {Object} AssetDistributionParams
   * @property {number} [estimateId] - The ID related to estimates.
   * @property {number} [proposalId] - The ID related to proposals.
   * @property {number} [applicationId] - The ID related to applications.
   * @property {number} [underwritingId] - The ID related to underwritings.
   * @property {number} [collateralId] - The ID related to collaterals.
   * @property {number} [amendmentCollateralId] - The ID related to amendment collaterals.
   * @property {number} [assetId] - The ID related to assets.
   * @property {number} [evaluationAssetId] - The ID related to evaluation of assets.
   * @property {number} [amendmentAssetId] - The ID related to amendment assets.
   * @typedef {Object} HoldingsDistributionRespDTO
   * @property {Array<HoldingDistributionDetailRespDTO>} holdingDistributionList - List of asset distribution details.
   * @typedef {Object} HoldingDistributionDetailRespDTO
   * @property {string} type - The type of the asset.
   * @property {number} marketValue - The market value of the asset type.
   * @property {number} percentage - The percentage of the asset type.
   * @property {number} count - The count of the asset type.
   * @APICode {stage}.holdings.distributions.asset-types.{aggregationLevel}
   */
  getCollateralAssetDistribution(stage, collateralId) {
    // permission code: {stage}.holdings.distributions.asset-types.collateral/.asset
    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/asset-types/collateral?collateralId=${collateralId}`
    )
  },

  /**
   * @typedef {Object} HoldingDistributionDetail
   * @property {string} type - The type of holding.
   * @property {number} marketValue - The market value of the holding.
   * @property {number} percentage - The percentage of the holding.
   * @property {number} count - The count of holdings.
   */

  /**
   * Retrieves the distribution of bond maturities based on the specified aggregation level.
   * @param {string} stage - The stage of the holdings distribution.
   * @param {string} aggregationLevel - The aggregation level for the distribution.
   * @param {GetBondMaturitiesParams} [params] - Optional parameters for filtering the results.
   * @returns {Promise<BondMaturitiesRespDTO>} - A promise that resolves with the bond maturities distribution data.
   * @typedef {Object} GetBondMaturitiesParams
   * @property {number} [estimateId] - ID related to the estimate stage and collateral aggregation level.
   * @property {number} [proposalId] - ID related to the proposal stage and collateral aggregation level.
   * @property {number} [applicationId] - ID related to the application stage and collateral aggregation level.
   * @property {number} [underwritingId] - ID related to the underwriting stage and collateral aggregation level.
   * @property {number} [collateralId] - ID related to the service stage and collateral aggregation level.
   * @property {number} [amendmentCollateralId] - ID related to the amendment stage and collateral aggregation level.
   * @property {number} [assetId] - ID related to the service stage and asset aggregation level.
   * @property {number} [evaluationAssetId] - ID related to the estimate/proposal/application/underwriting stages and asset aggregation level.
   * @property {number} [amendmentAssetId] - ID related to the amendment stage and asset aggregation level.
   * @typedef {Object} BondMaturitiesRespDTO
   * @property {BondMaturityDTO[]} bondMaturityList - List of bond maturities.
   * @typedef {Object} BondMaturityDTO
   * @property {string} maturity - The maturity category.
   * @property {number} marketValue - The market value of the category.
   * @property {number} percentage - The percentage of the category.
   * @APICode {stage}.holdings.distributions.bond-maturities.{aggregationLevel}
   */
  getBondMaturitiesDistribution(stage, aggregationLevel, aggregationLevelId) {
    // permission code: {stage}.holdings.distributions.bond-maturities.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`

    const { archiveAmendmentId } = useAmendmentData()

    if (stage === APIStageEnum.amendment && archiveAmendmentId.value) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/bond-maturities/${aggregationLevel}?${key}=${aggregationLevelId}`
      )
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/bond-maturities/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },

  /**
   * @typedef {Object} BondRating
   * @property {String} rating - The bond rating identifier.
   * @property {Number} marketValue - The market value associated with the bond rating.
   * @property {Number} percentage - The percentage of the bond rating.
   * @property {Number} holdingCount - The count of holdings for the bond rating.
   */

  /**
   * @typedef {Object} BondRatingsDistributionResponse
   * @property {BondRating[]} bondRatingList - List of bond ratings with their details.
   */
  /**
   * Retrieves the distribution of bond ratings by the specified aggregation level.
   * @param {Object} params - The parameters for the API call.
   * @param {string} params.stage - The stage of the distribution.
   * @param {string} params.aggregationLevel - The aggregation level for the distribution.
   * @param {number} [params.estimateId] - The estimate ID (optional).
   * @param {number} [params.proposalId] - The proposal ID (optional).
   * @param {number} [params.applicationId] - The application ID (optional).
   * @param {number} [params.underwritingId] - The underwriting ID (optional).
   * @param {number} [params.collateralId] - The collateral ID (optional).
   * @param {number} [params.amendmentCollateralId] - The amendment collateral ID (optional).
   * @param {number} [params.assetId] - The asset ID (optional).
   * @param {number} [params.evaluationAssetId] - The evaluation asset ID (optional).
   * @param {number} [params.amendmentAssetId] - The amendment asset ID (optional).
   * @returns {Promise<BondRatingsDistributionResponse>} A promise that resolves with the distribution of bond ratings.
   * @APICode {stage}.holdings.distributions.bond-ratings.{aggregationLevel}
   */
  getBondRatingsDistribution(stage, aggregationLevel, aggregationLevelId) {
    // permission code: {stage}.holdings.distributions.bond-ratings.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`

    const { archiveAmendmentId } = useAmendmentData()

    if (stage === APIStageEnum.amendment && archiveAmendmentId.value) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/bond-ratings/${aggregationLevel}?${key}=${aggregationLevelId}`
      )
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/bond-ratings/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },

  /**
   * Retrieves distributions of holdings by investment classes for a given stage and aggregation level.
   * @param {string} stage - The stage of the holdings.
   * @param {string} aggregationLevel - The aggregation level for the holdings distribution.
   * @param {Object} [params] - The optional parameters to filter the holdings distribution.
   * @returns {Promise<HoldingDistributionList>} A promise that resolves with the holdings distribution data.
   * @typedef {Object} HoldingDistributionList
   * @property {Array<HoldingDistributionDetail>} holdingDistributionList - List of holding distribution details.
   * @typedef {Object} HoldingDistributionDetail
   * @property {String} type - The type of investment class.
   * @property {Number} marketValue - The market value of the holding.
   * @property {Number} percentage - The percentage of the holding.
   * @property {Number} count - The count of holdings.
   * @APICode {stage}.holdings.distributions.investment-classes.{aggregationLevel}
   */
  getHoldingsDistributionsByInvestmentClasses(
    stage,
    aggregationLevel,
    aggregationLevelId
  ) {
    // permission code: {stage}.holdings.distributions.investment-classes.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`

    const { archiveAmendmentId } = useAmendmentData()

    if (stage === APIStageEnum.amendment && archiveAmendmentId.value) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/investment-classes/${aggregationLevel}?${key}=${aggregationLevelId}`
      )
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/investment-classes/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },

  /**
   * Retrieves the distribution of issuer details for a given stage and aggregation level.
   * @param {string} stage - The stage of the holdings.
   * @param {string} aggregationLevel - The aggregation level for the holdings distribution.
   * @param {IssuerDetailsParams} [params] - Optional parameters for filtering the distribution data.
   * @returns {Promise<HoldingDistributionList>} - A promise that resolves with the holding distribution list.
   *
   * @typedef {Object} IssuerDetailsParams
   * @property {number} [estimateId] - The ID of the estimate to filter by.
   * @property {number} [proposalId] - The ID of the proposal to filter by.
   * @property {number} [applicationId] - The ID of the application to filter by.
   * @property {number} [underwritingId] - The ID of the underwriting to filter by.
   * @property {number} [collateralId] - The ID of the collateral to filter by.
   * @property {number} [amendmentCollateralId] - The ID of the amendment collateral to filter by.
   * @property {number} [assetId] - The ID of the asset to filter by.
   * @property {number} [evaluationAssetId] - The ID of the evaluation asset to filter by.
   * @property {number} [amendmentAssetId] - The ID of the amendment asset to filter by.
   *
   * @typedef {Object} HoldingDistributionList
   * @property {Array<HoldingDistributionDetail>} holdingDistributionList - List of holding distribution details.
   *
   * @typedef {Object} HoldingDistributionDetail
   * @property {string} type - The type of the holding.
   * @property {number} marketValue - The market value of the holding.
   * @property {number} percentage - The percentage of the holding.
   * @property {number} count - The count of the holdings.
   *
   * @APICode {stage}.holdings.distributions.issuers.{aggregationLevel}
   */
  getIssuerDetailsDistribution(stage, aggregationLevel, aggregationLevelId) {
    // permission code: {stage}.holdings.distributions.issuers.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`
    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/issuers/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },

  /**
   * Get the distribution of municipal bonds based on the specified aggregation level and optional filters.
   * @param {string} stage - The stage of the distribution.
   * @param {string} aggregationLevel - The level of aggregation for the distribution.
   * @param {Object} [options] - Optional filters for the API.
   * @param {number} [options.estimateId] - The estimate ID filter.
   * @param {number} [options.proposalId] - The proposal ID filter.
   * @param {number} [options.applicationId] - The application ID filter.
   * @param {number} [options.underwritingId] - The underwriting ID filter.
   * @param {number} [options.collateralId] - The collateral ID filter.
   * @param {number} [options.amendmentCollateralId] - The amendment collateral ID filter.
   * @param {number} [options.assetId] - The asset ID filter.
   * @param {number} [options.evaluationAssetId] - The evaluation asset ID filter.
   * @param {number} [options.amendmentAssetId] - The amendment asset ID filter.
   * @returns {Promise<MuniBondsDistribution>} - A promise that resolves with the municipal bonds distribution.
   * @typedef {Object} MuniBondsDistribution
   * @property {MuniBondItem[]} muniBondList - The list of municipal bond items.
   * @typedef {Object} MuniBondItem
   * @property {string} name - The name of the item.
   * @property {number} marketValue - The market value of the item.
   * @property {number} securityCount - The security count of the item.
   * @property {number} percentage - The percentage of the item.
   * @APICode {stage}.holdings.distributions.muni-bonds.{aggregationLevel}
   */
  getMunicipalBondsDistribution(stage, aggregationLevel, aggregationLevelId) {
    // permission code: {stage}.holdings.distributions.muni-bonds.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`

    const { archiveAmendmentId } = useAmendmentData()

    if (stage === APIStageEnum.amendment && archiveAmendmentId.value) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/muni-bonds/${aggregationLevel}?${key}=${aggregationLevelId}`
      )
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/muni-bonds/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },

  /**
   * Retrieves the distribution of stock sectors based on the given universe, stage, and aggregation level.
   * @param {string} universe - The universe of the data.
   * @param {string} stage - The stage of the data.
   * @param {string} aggregationLevel - The aggregation level of the data.
   * @param {Object} [params] - Optional parameters based on stage and aggregationLevel.
   * @returns {Promise<StockSectorListResponse>} A promise that resolves with the stock sector distribution data.
   * @typedef {Object} StockSectorListResponse
   * @property {StockSector[]} stockSectorList - List of stock sectors with their details.
   * @typedef {Object} StockSector
   * @property {string} sectorAbbr - Abbreviation of the sector.
   * @property {string} sectorType - Type of the sector.
   * @property {number} marketValue - Market value of the sector.
   * @property {number} percentage - Percentage of the sector.
   * @APICode service.holdings.distributions.muni-bonds.book,
   *          service.holdings.distributions.muni-bonds.collateral,
   *          service.holdings.distributions.muni-bonds.asset,
   *          underwriting.holdings.distributions.muni-bonds.collateral,
   *          underwriting.holdings.distributions.muni-bonds.asset,
   *          amendment.holdings.distributions.muni-bonds.collateral,
   *          amendment.holdings.distributions.muni-bonds.asset
   */
  getStockSectorDistribution(stage, aggregationLevel, aggregationLevelId) {
    // permission code: {stage}.holdings.distributions.stock-sectors.collateral/.asset
    const key =
      stage === 'amendment'
        ? `amendment${formatCapitalize(aggregationLevel)}Id`
        : `${aggregationLevel}Id`

    const { archiveAmendmentId } = useAmendmentData()

    if (stage === APIStageEnum.amendment && archiveAmendmentId.value) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId.value}/holdings/distributions/stock-sectors/${aggregationLevel}?${key}=${aggregationLevelId}`
      )
    }

    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/stock-sectors/${aggregationLevel}?${key}=${aggregationLevelId}`
    )
  },
  /**
   * Retrieves the distribution of stock sectors based on the given universe, stage, and aggregation level.
   * @param {string} universe - The universe of the data.
   * @param {string} stage - The stage of the data.
   * @param {string} aggregationLevel - The aggregation level of the data.
   * @param {Object} [params] - Optional parameters based on stage and aggregationLevel.
   * @returns {Promise<StockSectorListResponse>} A promise that resolves with the stock sector distribution data.
   * @typedef {Object} StockSectorListResponse
   * @property {StockSector[]} stockSectorList - List of stock sectors with their details.
   * @typedef {Object} StockSector
   * @property {string} sectorAbbr - Abbreviation of the sector.
   * @property {string} sectorType - Type of the sector.
   * @property {number} marketValue - Market value of the sector.
   * @property {number} percentage - Percentage of the sector.
   * @APICode service.holdings.distributions.muni-bonds.book,
   *          service.holdings.distributions.muni-bonds.collateral,
   *          service.holdings.distributions.muni-bonds.asset,
   *          underwriting.holdings.distributions.muni-bonds.collateral,
   *          underwriting.holdings.distributions.muni-bonds.asset,
   *          amendment.holdings.distributions.muni-bonds.collateral,
   *          amendment.holdings.distributions.muni-bonds.asset
   */
  getCollateralStockSectorDistribution(stage, collateralId) {
    // permission code: {stage}.holdings.distributions.stock-sectors.collateral/.asset
    return http.get(
      `${API_VERSION}/${stage}/holdings/distributions/stock-sectors/collateral?collateralId=${collateralId}`
    )
  },
}
