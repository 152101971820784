import { http, API_VERSION } from '@/shared/api/http'
export const calculationAPI = {
  /**
   * Creates an asset estimate based on provided collateral and asset information.
   * @param {string} stage - The stage at which the calculation is performed.
   * @param {CollateralAssetEstimateRequest} data - The collateral and asset information.
   * @returns {Promise<CollateralAssetEstimateResponse>} - A promise that resolves with the asset estimate data.
   * @typedef {Object} CollateralAssetEstimateRequest
   * @property {string} [estimateId] - The ID of the estimate.
   * @property {string} [proposalId] - The ID of the proposal.
   * @property {string} [applicationId] - The ID of the application.
   * @property {string} [collateralId] - The ID of the collateral.
   * @property {string} [amendmentCollateralId] - The ID of the amendment collateral.
   * @property {boolean} [calculateWithCollateralAssets] - Whether to calculate with collateral assets.
   * @property {AssetDTO} asset - The asset data.
   * @typedef {Object} AssetDTO
   * @property {string} custodianInstitutionId - The ID of the custodian institution.
   * @property {string} custodianCode - The code of the custodian.
   * @property {string} assetNumber - The number of the asset.
   * @property {string} assetType - The type of the asset.
   * @property {string} assetName - The name of the asset.
   * @property {string} pledgingStatus - The pledging status of the asset.
   * @property {number} marketValueReportedCurrency - The market value in the reported currency.
   * @property {string} reportedCurrencyCode - The code of the reported currency.
   * @property {number} marketValueBaseCurrency - The market value in the base currency.
   * @property {number} advanceRate - The advance rate of the asset.
   * @property {number} maintenanceRate - The maintenance rate of the asset.
   * @property {HoldingDetailDTO[]} holdings - The holdings details.
   * @typedef {Object} HoldingDetailDTO
   * @property {string} dataSourceInstitutionId - The ID of the data source institution.
   * @property {string} collateralBaseCurrencyCode - The base currency code of the collateral.
   * @property {number} exchangeRate - The exchange rate.
   * @property {string} secId - The security ID.
   * @property {string} cusip - The CUSIP number.
   * @property {string} isin - The ISIN number.
   * @property {string} sedol - The SEDOL number.
   * @property {string} ticker - The ticker symbol.
   * @property {string} securityName - The name of the security.
   * @property {string} sourceSecurityId - The ID of the source security.
   * @property {string} sourceSecurityIdType - The type of the source security ID.
   * @property {string} domicile - The domicile of the security.
   * @property {string} holdingCurrencyCode - The currency code of the holding.
   * @property {number} marketValueHoldingCurrency - The market value in the holding currency.
   * @property {number} marketValueBaseCurrency - The market value in the base currency.
   * @property {number} quantity - The quantity.
   * @property {number} eligibleQuantity - The eligible quantity.
   * @property {number} pledgedQuantity - The pledged quantity.
   * @property {number} collateralBaseCurrencyPrice - The price in the base currency of the collateral.
   * @property {number} holdingCurrencyPrice - The price in the holding currency.
   * @property {number} unitPriceFactor - The unit price factor.
   * @property {boolean} isSecurityIdentified - Whether the security is identified.
   * @property {boolean} isHoldingEligible - Whether the holding is eligible.
   * @property {boolean} isHaircutTriggered - Whether a haircut is triggered.
   * @typedef {Object} CollateralAssetEstimateResponse
   * @property {string} estimateId - The ID of the estimate.
   * @property {string} proposalId - The ID of the proposal.
   * @property {string} applicationId - The ID of the application.
   * @property {string} collateralId - The ID of the collateral.
   * @property {string} amendmentCollateralId - The ID of the amendment collateral.
   * @property {string} calculatedAt - The timestamp when calculation was done.
   * @property {string} collateralBaseCurrencyCode - The base currency code of the collateral.
   * @property {AssetDTO} asset - The asset data.
   * @APICode {stage}.collaterals.calculations.asset-estimates.create
   */
  createCollateralAssetEstimation(stage, data) {
    return http.post(
      `${API_VERSION}/${stage}/collaterals/calculations/asset-estimates`,
      data
    )
  },

  /**
   * Triggers the calculation of collaterals based on the provided stage and data.
   * @param {string} stage - The stage of the financial service.
   * @param {CollateralsCalculationData} data - The data required for the calculation.
   * @returns {Promise<Object>} - A promise that resolves with the calculation result.
   * @typedef {Object} CollateralsCalculationData
   * @property {string} [estimateId] - The estimate ID related to the calculation.
   * @property {string} [proposalId] - The proposal ID related to the calculation.
   * @property {string} [applicationId] - The application ID related to the calculation.
   * @property {string} [collateralId] - The collateral ID related to the calculation.
   * @property {string} [amendmentCollateralId] - The amendment collateral ID related to the calculation.
   * @APICode {stage}.collaterals.calculations.create
   */
  createCollateralsCalculation(stage, data) {
    return http.post(`${API_VERSION}/${stage}/collaterals/calculations`, data)
  },

  /**
   * Calculates the optimal and other feasible liquidity solutions based on the provided financial and holding information.
   * @APICode collaterals.calculations.liquidity-paths.create
   * @param {Object} payload - The information needed to calculate liquidity paths.
   * @param {string} payload.totalLoanFacilityId - The ID of the total loan facility.
   * @param {string} payload.locProductId - The ID of the line of credit product.
   * @param {number} payload.totalLiquidityNeeds - The total liquidity needs.
   * @param {number} payload.durationMonth - The duration in months.
   * @param {number} payload.interestRate - The interest rate.
   * @param {number} payload.shortTermCapitalGainTaxRate - The short term capital gain tax rate.
   * @param {number} payload.longTermCapitalGainTaxRate - The long term capital gain tax rate.
   * @param {boolean} payload.deductibleInterest - Indicates if the interest is deductible.
   * @param {Array} payload.holdings - The holdings.
   * @param {boolean} payload.customizedSellingStrategy - Indicates if a customized selling strategy is used.
   * @param {boolean} payload.minimizeAllocationDeviation - Indicates if allocation deviation should be minimized.
   * @param {number} payload.minimizeTaxImplicationWeight - The weight to minimize tax implications.
   * @param {number} payload.preserveCapitalUpsideProjectionWeight - The weight to preserve capital upside projection.
   * @returns {Promise<Object>} The liquidity calculation results.
   */
  calculateLiquidityPaths({
    totalLoanFacilityId,
    totalLiquidityNeeds,
    durationMonth,
    interestRate,
    shortTermCapitalGainTaxRate,
    longTermCapitalGainTaxRate,
    deductibleInterest,
    holdings,
    customizedSellingStrategy,
    minimizeAllocationDeviation,
    minimizeTaxImplicationWeight,
    preserveCapitalUpsideProjectionWeight,
    locProductId,
  }) {
    return http.post(
      `${API_VERSION}/collaterals/calculations/liquidity-paths`,
      {
        totalLoanFacilityId,
        totalLiquidityNeeds,
        durationMonth,
        interestRate,
        shortTermCapitalGainTaxRate,
        longTermCapitalGainTaxRate,
        deductibleInterest,
        holdings,
        customizedSellingStrategy,
        minimizeAllocationDeviation,
        minimizeTaxImplicationWeight,
        preserveCapitalUpsideProjectionWeight,
        locProductId,
      },
      {
        ignoreErrorCodes: ['DAL_PARAM_VALIDATE_ERROR'],
      }
    )
  },
  /**
   * Calculate retirement incomes based on various financial metrics and inputs.
   * @param {RetirementIncomeCalcReqDTO} requestData - The request data for retirement income calculation.
   * @returns {Promise<RetirementIncomeCalcRespDTO>} - A promise that resolves with the calculated retirement incomes.
   * @typedef {Object} RetirementIncomeCalcReqDTO
   * @property {number} initialAssetValue - The initial value of the user's assets.
   * @property {number} timeInYears - The time span in years for the retirement income calculation.
   * @property {number} initialWithdrawRate - The initial rate at which the user will withdraw from their assets.
   * @property {number} inflationRate - The rate of inflation to consider in the calculation.
   * @property {number} interestRate - The interest rate to consider for asset growth.
   * @property {boolean} [setGlidePath] - Flag to determine if a glide path should be set for the investment.
   * @property {string} [glidePathMethod] - The method to use for the glide path if setGlidePath is true.
   * @property {InvestmentMetrics} [portfolioMetrics] - The metrics for the overall investment portfolio.
   * @property {InvestmentMetrics} [equityMetrics] - The metrics for the equity portion of the investment.
   * @property {InvestmentMetrics} [bondMetrics] - The metrics for the bond portion of the investment.
   * @property {number} lendingCapacityLimitRate - The limit rate for the lending capacity.
   * @property {SblBorrowRateMapping[]} [sblBorrowRatioMappings] - Mappings for securities-based lending borrow ratios.
   * @typedef {Object} InvestmentMetrics
   * @property {number} [expectedAnnualReturn] - The expected annual return of the investment.
   * @property {number} [standardDeviation] - The standard deviation of the investment's return.
   * @typedef {Object} SblBorrowRateMapping
   * @property {string} comparisonOperator - The operator to use for comparing drawdown rates.
   * @property {number} peakDrawdownRate - The peak drawdown rate for the investment.
   * @property {number} sblBorrowRatio - The ratio for securities-based lending borrowing.
   * @typedef {Object} RetirementIncomeCalcRespDTO
   * @property {Object.<number, AssetFinancialSnapshot[]>} drawSnapshots -
   * Snapshots of financial data for different percentiles.
   * @property {Object.<number, AssetFinancialSnapshot[]>} sblSnapshots -
   * Snapshots of securities-based lending data for different percentiles.
   * @typedef {Object} AssetFinancialSnapshot
   * @property {number} year - The year of the snapshot.
   * @property {number} month - The month of the snapshot.
   * @property {number} returnRate - The return rate for that period.
   * @property {number} spendingAmount - The amount spent during that period.
   * @property {number} drawAmount - The amount drawn from the assets.
   * @property {number} borrowAmount - The amount borrowed.
   * @property {number} sblBalance - The balance of the securities-based lending.
   * @property {number} assetValue - The value of the assets.
   * @property {number} netAssetValue - The net value of the assets.
   * @APICode collaterals.calculations.retirement-incomes.create
   */
  calculationRetirementIncome(requestData) {
    return http.post(
      `${API_VERSION}/collaterals/calculations/retirement-incomes`,
      requestData
    )
  },
}
