import { computed, ref } from 'vue'
import { map, some } from 'lodash'
import { useInstitutionConfig } from '../../config/useInstitutionConfig'
import { useAmendmentData } from './amendment'
import { applicationAttentionAPI } from '@/shared/api/application/attention'
import {
  API_MAX_PAGE_SIZE,
  APIStageEnum,
  AttentionStatusEnum,
  isEqualStringIgnoreCase,
} from '@/shared/utils'

const applicationAttentions = ref([])
export const useApplicationAttentions = () => {
  const { amendment } = useAmendmentData()
  const { attentionNeededList } = useInstitutionConfig()
  const resolving = ref(false)

  const getApplicationAttentions = async () => {
    applicationAttentions.value = []
    const res = await applicationAttentionAPI.getApplicationAttentions(
      APIStageEnum.amendment,
      {
        pageNum: 1,
        pageSize: API_MAX_PAGE_SIZE,
        'filters.applicationId': amendment.value?.applicationId,
      }
    )
    applicationAttentions.value = map(res.items || [], (item) => ({
      ...item,
      attentionNeededLabel: getAttentionLabelByCode(item.attentionNeededCode),
      isEnableActionButton: getIsEnableActionButton(item.attentionNeededCode),
      isResolve: isEqualStringIgnoreCase(
        item.attentionNeededStatus,
        AttentionStatusEnum.resolved
      ),
    }))

    applicationAttentions.value.sort((a, b) => {
      const indexA = attentionNeededList.value.findIndex(item => item.code === a.attentionNeededCode)
      const indexB = attentionNeededList.value.findIndex(item => item.code === b.attentionNeededCode)
      return indexA - indexB
    })
  }

  const updateApplicationAttentions = async (params) => {
    resolving.value = true
    await applicationAttentionAPI.updateApplicationAttentions(
      APIStageEnum.amendment,
      amendment.value?.applicationId,
      params
    ).finally(() => {
      resolving.value = false
    })
  }

  const getAttentionLabelByCode = (code) => {
    return attentionNeededList.value?.find((item) => item.code === code)?.label
  }

  const getIsEnableActionButton = (code) => {
    return attentionNeededList.value?.find((item) => item.code === code)?.isEnableActionButton
  }

  const attentionAllResolved = computed(() => {
    return applicationAttentions.value.every((item) => item.isResolve)
  })

  const someAttentionNotResolved = computed(() => {
    return some(applicationAttentions.value, (item) => !item.isResolve)
  })

  return {
    getApplicationAttentions,
    applicationAttentions,
    updateApplicationAttentions,
    attentionAllResolved,
    someAttentionNotResolved,
    resolving,
  }
}
