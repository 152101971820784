import { http, API_VERSION } from './http'

export const memoAPI = {
  /**
   * Creates memos for specific objects of resource.
   * @APICode {stage}.memos.create
   *
   * @param {string} stage - The stage of the resource.
   * @param {AddMemoListRequestDTO} params - The request payload to create memos.
   * @returns {Promise<Object>} A promise that resolves when the memos are created.
   * @typedef {Object} AddMemoListRequestDTO
   * @property {AddMemoRequestDTO[]} memos - List of memo creation requests.
   * @typedef {Object} AddMemoRequestDTO
   * @property {string} memoType - The type of the memo.
   * @property {string} memoSubject - The subject of the memo.
   * @property {string} memoDetail - The detail of the memo.
   * @property {number} [estimateGroupId] - The estimate group ID.
   * @property {number} [applicationId] - The application ID.
   * @property {number} [underwritingId] - The underwriting ID.
   * @property {number} [questionAnswerId] - The question answer ID.
   * @property {number} [profileId] - The profile ID.
   * @property {number} [peCipId] - The PE CIP ID.
   * @property {number} [amendmentId] - The amendment ID.
   * @property {number} [loanId] - The loan ID.
   * @property {number} [loanFacilityId] - The loan facility ID.
   * @property {@number} [assetId] - The asset ID.
   * @property {number} [creditExceptionId] - The credit exception ID.
   * @property {number} [riskEventId] - The risk event ID.
   */
  createMemos(stage, params) {
    return http.post(`${API_VERSION}/${stage}/memos`, params)
  },

  /**
   * List memos for supported objects.
   * @APICode {stage}.memos.list
   *
   * @param {string} stage - stage of The the object.
   * @param {ListMemosParams} params - The parameters for listing memos.
   * @returns {Promise<Memo[]>} - A promise that resolves with the list of memos.
   * @typedef {Object} ListMemosParams
   * @property {number} pageNumber - The page number.
   * @property {number} pageSize - The size of the page.
   * @property {string[]} [filters.memoType] - List of memo types.
   * @property {number[]} [filters.estimateGroupId] - List of estimate group IDs.
   * @property {number[]} [filters.applicationId] - List of application IDs.
   * @property {number[]} [filters.underwritingId] - List of underwriting IDs.
   * @property {number[]} [filters.peCidId] - List of PE CID IDs.
   * @property {number[]} [filters.profileId] - List of profile IDs.
   * @property {number[]} [filters.loanId] - List of loan IDs.
   * @property {number[]} [filters.loanFacilityId] - List of loan facility IDs.
   * @property {number[]} [filters.assetId] - List of asset IDs.
   * @property {number[]} [filters.amendmentId] - List of amendment IDs.
   * @property {number[]} [filters.riskEventId] - List of risk event IDs.
   * @property {number[]} [filters.creditExceptionId] - List of credit exception IDs.
   * @typedef {Object} Memo
   * @property {string} memoId - The ID of the memo.
   * @property {string} memoType - The type of the memo.
   * @property {string} memoSubject - The subject of the memo.
   * @property {string} memoDetail - The detail of the memo.
   * @property {number} applicationId - The ID of the application.
   * @property {string} creatorName - The name of the creator.
   * @property {string} creatorUserId - The user ID of the creator.
   * @property {number} createdAt - The timestamp of memo creation.
   */
  listMemos(stage, params) {
    return http.get(`${API_VERSION}/${stage}/memos`, params)
  },

  /**
   * Updates a single memo entry by its memo ID.
   * @APICode {stage}.memos.update
   *
   * @param {string} stage - The stage in which the memo exists.
   * @param {number} memoId - The ID of the memo to update.
   * @param {UpdateMemoPayload} payload - The payload containing the updated memo information.
   * @typedef UpdateMemoPayload
   * @property {string} memoType - The type of the memo.
   * @property {string} memoSubject - The subject of the memo.
   * @property {string} memoDetail - The detailed content of the memo.
   */
  updateMemo(stage, memoId, payload) {
    return http.patch(`${API_VERSION}/${stage}/memos/${memoId}`, payload)
  },

  /**
   * Deletes a single memo entry by its record ID.
   * @APICode {stage}.memos.delete
   *
   * @param {string} stage - The stage in which the memo exists.
   * @param {number} recordId - The unique identifier of the memo to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the memo is successfully deleted.
   */
  deleteMemo(stage, recordId) {
    return http.delete(`${API_VERSION}/${stage}/memos/${recordId}`)
  },
  /**
   * Exports memos data to an Excel file.
   * @param {string} stage - The stage of the memos to be exported.
   * @param {ExportOptions} [options={}] - Options for exporting the memos.
   * @param {boolean} [options.shouldEncrypt=true] - Whether the export should be encrypted.
   * @param {string} [options.headerNames] - Comma-separated string of header names.
   * @param {string} [options.columnNames] - Comma-separated string of column names.
   * @returns {Promise<Blob>} - A promise that resolves with the binary stream of the Excel file.
   * @APICode {stage}.memos.exports.get
   */
  exportMemos(stage, options = {}) {
    return http.download(`${API_VERSION}/${stage}/memos/exports`, options, {})
  },

  /**
   * Exports memos data url.
   * @param {string} stage - The stage of the memos to be exported.
   * @param {ExportOptions} [options={}] - Options for exporting the memos.
   * @param {boolean} [options.shouldEncrypt=true] - Whether the export should be encrypted.
   * @param {string} [options.headerNames] - Comma-separated string of header names.
   * @param {string} [options.columnNames] - Comma-separated string of column names.
   * @returns {string} - The url of the exported memos data.
   * @APICode {stage}.memos.exports.get
   */
  exportMemosUrl(stage, params) {
    return `${API_VERSION}/${stage}/memos/exports?${params}`
  },
}
