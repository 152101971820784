import { http, API_VERSION } from './http.js'

/**
 * @typedef {Object} PlanDocument
 * @property {string} documentId - The unique identifier of the document.
 * @property {string} documentName - The name of the document.
 * @property {string} documentType - The type of the document, e.g., PDF.
 */

/**
 * @typedef {Object} RiskEventDetails
 * @property {string} riskEventId - The identifier of the risk event.
 * @property {string} locId - The identifier of the line of credit (LOC) related to the risk event.
 * @property {string} eventStage - The current stage of the risk event.
 * @property {string} eventNumber - The number assigned to the risk event.
 * @property {number} eventReason - The reason code for the risk event.
 * @property {number} eventValue - The value associated with the risk event.
 * @property {number} cushionToCall - The cushion amount before a call is made.
 * @property {number} eventAge - The age of the risk event in days.
 * @property {string} eventStatus - The current status of the risk event.
 * @property {number} eventFlags - Flags associated with the risk event.
 * @property {number} collateralFlags - Flags associated with the collateral.
 * @property {string} startDate - The start date of the risk event.
 * @property {string} callStartDate - The start date of the call.
 * @property {string} startDataDate - The date when the data started being recorded.
 * @property {string} liquidationDate - The date of liquidation if applicable.
 * @property {string} closeDate - The date when the risk event was closed.
 * @property {string} closeDataDate - The date when the closing data was recorded.
 * @property {string} planProcessStatus - The status of the plan process.
 * @property {number} planActions - The number of actions in the plan.
 * @property {string} planDueDate - The due date for the plan.
 * @property {number} planPassDue - Whether the plan is past due.
 * @property {number} planPassDueDays - The number of days the plan is past due.
 * @property {string} planNote - Notes associated with the plan.
 * @property {string} remedyDueDate - The due date for remedies.
 * @property {number} remedyPassDue - Whether the remedy is past due.
 * @property {number} remedyPassDueDays - The number of days the remedy is past due.
 * @property {string} planCreatedBy - The identifier of the user who created the plan.
 * @property {string} planCreatedAt - The timestamp when the plan was created.
 * @property {string} planUpdatedBy - The name of the user who last updated the plan.
 * @property {string} planUpdatedAt - The timestamp when the plan was last updated.
 * @property {number} resolutionMethod - The method used for resolution.
 * @property {string} comments - Comments associated with the risk event.
 * @property {string} commentsUpdatedBy - The identifier of the user who last updated the comments.
 * @property {string} commentsUpdatedAt - The timestamp when the comments were last updated.
 * @property {string} suppressionLastBreachDate - The last date when a breach was suppressed.
 * @property {boolean} planExisted - Whether a plan existed.
 * @property {string} locNumber - The number of the line of credit (LOC).
 * @property {string} lineName - The name of the line.
 * @property {number} locBalance - The balance of the LOC.
 * @property {number} pendingDraw - The amount pending to be drawn from the LOC.
 * @property {number} locAmount - The amount of the LOC.
 * @property {number} marketValue - The market value of the collateral.
 * @property {number} lendingValue - The lending value of the collateral.
 * @property {number} excessEquity - The excess equity available.
 * @property {number} maintenanceValue - The maintenance value required.
 * @property {string} advisorName - The name of the advisor.
 * @property {string} borrowerNames - The names of the borrowers.
 * @property {number} suppressedValue - The value that has been suppressed.
 * @property {string} suppressedDate - The date of suppression.
 * @property {string} asOfDate - The date as of which the details are provided.
 * @property {string} primaryAdvisorEmailAddress - The email address of the primary advisor.
 * @property {string} csEmailAddress - The email address for customer service.
 * @property {string[]} individualEmailAddress - A list of individual email addresses.
 * @property {PlanDocument[]} planDocuments - A list of documents related to the plan.
 */

/**
 * @typedef {Object} Holding
 * @property {number} dataSourceInstitutionId - The data source institution identifier.
 * @property {string} secId - The security identifier.
 * @property {string} sourceSecurityId - The source security identifier.
 * @property {string} cusip - The CUSIP number.
 * @property {string} isin - The ISIN number.
 * @property {string} sedol - The SEDOL number.
 * @property {string} ticker - The ticker symbol.
 * @property {string} securityName - The name of the security.
 * @property {string} holdingCurrencyCode - The currency code of the holding.
 * @property {string} assetClass - The asset class of the holding.
 * @property {string} universe - The universe the holding belongs to.
 * @property {number} marketValueHoldingCurrency - The market value in the holding currency.
 * @property {number} marketValueBaseCurrency - The market value in the base currency.
 * @property {number} quantity - The quantity of the holding.
 * @property {number} eligibleQuantity - The eligible quantity of the holding.
 * @property {number|null} pledgedQuantity - The pledged quantity of the holding.
 * @property {number} collateralBaseCurrencyPrice - The collateral price in the base currency.
 * @property {number} holdingCurrencyPrice - The price in the holding currency.
 * @property {number} unitPriceFactor - The unit price factor.
 * @property {string} specialCode - A special code related to the holding.
 */

/**
 * @typedef {Object} RiskEventResolutionRequest
 * @property {string} totalLoanFacilityId - The total loan facility identifier.
 * @property {string} actionType - The type of action being performed.
 * @property {number} totalBalance - The total balance.
 * @property {number} lendingValue - The lending value.
 * @property {number} maintenanceValue - The maintenance value.
 * @property {number} marketValue - The market value.
 * @property {Holding[]} holdings - An array of holdings.
 * @property {Holding[]} changedHoldings - An array of changed holdings.
 */

/**
 * @typedef {Object} RiskEventResolutionResponse
 * @property {number} totalBalance - The total balance after resolution.
 * @property {number} lendingValue - The lending value after resolution.
 * @property {number} maintenanceValue - The maintenance value after resolution.
 * @property {number} marketValue - The market value after resolution.
 * @property {string} stoplightCode - The stoplight code indicating risk level.
 * @property {number} riskEventValue - The value of the risk event.
 * @property {Holding[]} holdings - An array of updated holdings.
 * @property {Holding[]} changedHoldings - An array of changed holdings after resolution.
 */

export const riskEventAPI = {
  getRiskEventList(params, config) {
    return http.get(`${API_VERSION}/service/risk-events`, params, config)
  },
  getRiskEventListBorders(params) {
    return http.get(`${API_VERSION}/service/risk-events/borders`, params)
  },
  /**
   * Fetches the details of a specific risk event.
   * @APICode service.risk-events.get
   * @param {string} riskEventId - The ID of the risk event to retrieve.
   * @returns {Promise<RiskEventDetails>} - A promise that resolves with the details of the risk event.
   */
  getRiskEventDetails(riskEventId) {
    return http.get(`${API_VERSION}/service/risk-events/${riskEventId}`)
  },
  /**
   * Calculate risk event resolutions for a total loan facility.
   * @param {RiskEventResolutionRequest} resolutionRequest - The request payload for risk event resolution.
   * @returns {Promise<RiskEventResolutionResponse>} - A promise that resolves with the risk event resolution data.
   */
  createRiskEventCalculationsResolution(resolutionRequest) {
    return http.post(
      `${API_VERSION}/service/risk-events/calculations/resolutions`,
      resolutionRequest
    )
  },

  /**
   * Preview the notice of a risk event based on the given risk event ID and email code.
   * @param {number} riskEventId - The ID of the risk event.
   * @param {string} emailCode - The email code to use for the notice preview.
   * @returns {Promise<RiskEventNoticePreview>} A promise that resolves with the details of the risk event notice preview.
   * @typedef {Object} RiskEventNoticePreview
   * @property {string} subject - The subject of the risk event notice.
   * @property {string} content - The content of the risk event notice.
   * @property {string} source - The source of the risk event notice.
   * @APICode service.risk-events.notices.get
   */
  getRiskEventNoticePreview(riskEventId, emailCode) {
    return http.get(
      `${API_VERSION}/service/risk-events/${riskEventId}/notices/previews`,
      {
        emailCode,
      }
    )
  },

  /**
   * Retrieve parameters for a notice based on a risk event.
   *
   * @param {GetNoticeParamsOptions} [options] - The options for retrieving notice parameters.
   * @returns {Promise<RiskEventNoticeParamsRespDTO>} - A promise that resolves with the notice parameters.
   *
   * @typedef {Object} GetNoticeParamsOptions
   * @property {String} [universe] - The universe filter.
   * @property {String} templateCode - The template filter.
   *
   * @typedef {Object} RiskEventNoticeParamsRespDTO
   * @property {String[]} emailParams - List of email parameters.
   *
   * @APICode service.risk-events.notices.params.get
   */
  getNoticeParams(options = {}) {
    return http.get(
      `${API_VERSION}/service/risk-events/notices/params`,
      options
    )
  },

  /**
   * Retrieve a list of email addresses based on the risk event ID and the type of recipient.
   * @param {string} riskEventId - The ID of the risk event.
   * @param {Object} params - The query parameters for the API call.
   * @param {string} params.totalLoanFacilityId - The ID of the loan facility.
   * @param {string} params.sendToType - The type of recipient to send the notice to.
   * @returns {Promise<NoticeEmail[]>} - A promise that resolves with a list of notice email addresses.
   * @typedef {Object} NoticeEmail
   * @property {string} name - The name associated with the email address.
   * @property {string} emailAddress - The email address of the recipient.
   * @property {string} roleType - The role type of the recipient.
   * @property {string} externalId - An external identifier for the recipient.
   * @APICode service.risk-events.notices.emails.get
   */
  getNoticeEmails(riskEventId, params) {
    return http.get(
      `${API_VERSION}/service/risk-events/${riskEventId}/notices/emails`,
      params
    )
  },

  /**
   * Retrieve batch emails for notice based on risk event IDs and send type.
   * @param {Object} params - The parameters for the request.
   * @param {Array<number>} params.riskEventIdList - List of risk event IDs.
   * @param {string} [params.sendToType] - The type of recipient to send to.
   * @returns {Promise<NoticeEmailsRespDTO>} A promise that resolves with the notice emails details.
   * @typedef {Object} NoticeEmailsRespDTO
   * @property {Array<NoticeEmailDetailDTO>} noticeEmailsDetails - List of notice email details.
   * @typedef {Object} NoticeEmailDetailDTO
   * @property {string} locId - The ID of the line of credit.
   * @property {string} riskEventId - The ID of the risk event.
   * @property {string} lineName - The name of the line.
   * @property {string} locNumber - The number of the line of credit.
   * @property {string} eventNumber - The number of the event.
   * @property {Array<NoticeEmailUserInfoDTO>} userInfoList - List of user information.
   * @property {RiskEventNoticeDataRespDTO} data - The notice data.
   * @typedef {Object} NoticeEmailUserInfoDTO
   * @property {string} userId - The ID of the user.
   * @property {string} email - The email of the user.
   * @typedef {Object} RiskEventNoticeDataRespDTO
   * @property {string} subject - The subject of the notice.
   * @property {string} body - The body of the notice.
   * @APICode service.risk-events.notices.emails.list
   */
  getBatchNoticeEmails(params) {
    return http.get(`${API_VERSION}/service/risk-events/notices/emails`, params)
  },

  /**
   * Retrieves the email data associated with a particular risk event.
   * @param {string} riskEventId - The ID of the risk event.
   * @returns {Promise<NoticeMailDataRespDTO>} - A promise that resolves with the notice email data.
   * @typedef {Object} NoticeMailDataRespDTO - The response data transfer object for notice mail.
   * @property {String} primaryAdvisorName - The name of the primary advisor.
   * @property {String} primaryAdvisorPhone - The phone number of the primary advisor.
   * @property {String} locAmount - The amount of the line of credit.
   * @property {String} eventValue - The value of the risk event.
   * @property {String} callValue - The value of the call.
   * @property {String} locBalance - The balance of the line of credit.
   * @property {String} marketValue - The market value.
   * @property {String} maintenanceValue - The maintenance value.
   * @property {String} lendingValue - The lending value.
   * @property {String} locNumber - The number of the line of credit.
   * @property {String} maskedLocNumber - The masked number of the line of credit.
   * @property {String} excessEquity - The excess equity.
   * @property {String} borrowerNames - The names of the borrowers.
   * @property {String} primaryBorrowerName - The name of the primary borrower.
   * @property {String} primaryBorrowerEmail - The email of the primary borrower.
   * @property {String} primaryBorrowerAddress - The address of the primary borrower.
   * @property {String} primaryBorrowerCity - The city of the primary borrower.
   * @property {String} primaryBorrowerState - The state of the primary borrower.
   * @property {String} primaryBorrowerZip - The ZIP code of the primary borrower.
   * @property {String} currentDate - The current date.
   * @property {String} previousBusinessDay - The previous business day.
   * @property {String} startDate - The start date.
   * @property {String} callStartDate - The start date of the call.
   * @property {String} liquidationDate - The liquidation date.
   * @property {String} planDueDate - The due date of the plan.
   * @property {String} allBorrowerNames - All borrower names.
   * @property {PersonEntityEmailItemDTO[]} allBorrowerList - List of all borrowers.
   * @property {String} notBorrowerPledgorNames - Names of non-borrower pledgors.
   * @property {PersonEntityEmailItemDTO[]} notBorrowerPledgorList - List of non-borrower pledgors.
   * @property {Boolean} isNotBorrowerPledgorExisted - Indicates if non-borrower pledgor exists.
   * @property {String} primaryOfficerName - The name of the primary officer.
   * @property {String} suppressValue - The suppress value.
   * @property {String} suppressDate - The suppress date.
   * @property {String} suppressNote - The suppress note.
   * @property {String} nickname - The nickname.
   * @property {String} assetNumbers - The number of assets.
   * @property {AssetTypeHoldingEmailItemRespDTO[]} assetTypeHoldingList - List of asset type holdings.
   *
   * @typedef {Object} PersonEntityEmailItemDTO - The DTO for person entity with email.
   * @property {String} name - The name of the person.
   * @property {String} city - The city of the person.
   * @property {String} address - The address of the person.
   * @property {String} state - The state of the person.
   * @property {String} zipCode - The ZIP code of the person.
   * @property {String} email - The email of the person.
   *
   * @typedef {Object} AssetTypeHoldingEmailItemRespDTO - The DTO for asset type holding for email.
   * @property {String} universeAlias - The alias of the asset universe.
   * @property {String} lendingValue - The lending value of the asset.
   * @property {String} marketValue - The market value of the asset.
   * @property {String} advanceRate - The advance rate of the asset.
   *
   * @APICode service.risk-events.notices.data.get
   */
  getNoticeData(riskEventId) {
    return http.get(
      `${API_VERSION}/service/risk-events/${riskEventId}/notices/data`
    )
  },

  /**
   * Sends an email notice related to a risk event.
   * @param {string} riskEventId - The ID of the risk event.
   * @param {RiskEventNoticeRequest} noticeRequest - The request payload for the notice.
   * @returns {Promise<Object>} - A promise that resolves when the email notice is sent.
   * @typedef {Object} RiskEventNoticeRequest
   * @property {String} emailCode - The email template code.
   * @property {String} sendToType - The type of recipient to send the notice to.
   * @property {String} subject - The subject of the email.
   * @property {String} content - The content of the email.
   * @property {String[]} emailParamFieldList - List of parameter fields for the email.
   * @property {String[]} to - List of email addresses to send the notice to.
   * @property {String[]} cc - List of email addresses to be CC'd on the notice.
   * @APICode service.risk-events.notices.create
   */
  sendRiskEventNotice(riskEventId, noticeRequest) {
    return http.post(
      `${API_VERSION}/service/risk-events/${riskEventId}/notices`,
      noticeRequest
    )
  },

  /**
   * Sends batch notices for risk events.
   * @param {RiskEventNoticeReqDTO} noticeDetails - The details for the risk event notice.
   * @returns {Promise<Object>} A promise that resolves when the notice is successfully sent.
   * @typedef {Object} RiskEventNoticeReqDTO
   * @property {number[]} riskEventIds - Array of risk event IDs.
   * @property {string} emailCode - The email template code.
   * @property {string} sendToType - The type of recipient (e.g., CUSTOMER).
   * @property {string} subject - The email subject.
   * @property {string} content - The email content.
   * @property {string[]} emailParamFieldList - List of email parameter fields.
   * @property {string[]} to - Array of recipient email addresses.
   * @property {string[]} cc - Array of CC email addresses.
   * @APICode service.risk-events.notices.post
   */
  sendRiskEventNotices(noticeDetails) {
    return http.post(
      `${API_VERSION}/service/risk-events/notices`,
      noticeDetails
    )
  },

  /**
   * @typedef {Object} MailAccount
   * @property {String} mailAddress - Email address of the account.
   * @property {String} displayName - Display name of the account holder.
   * @property {Number} userId - User ID associated with the account.
   * @property {String} externalId - External identifier of the account.
   * @property {String} roleType - Role type of the account holder (e.g., sender, recipient).
   * @typedef {Object} MailAttachmentResource
   * @property {String} fileName - Name of the attachment file.
   * @property {String} fileData - Base64 encoded string of the file data.
   * @typedef {Object} EmailInfo
   * @property {MailAccount} from - Information about the sender.
   * @property {MailAccount[]} to - Array of recipient information.
   * @property {MailAccount[]} cc - Array of CC recipient information.
   * @property {MailAccount[]} replyTo - Array of reply-to recipient information.
   * @property {MailAccount[]} bcc - Array of BCC recipient information.
   * @property {String} priority - Email priority (e.g., High).
   * @property {String} subject - Subject of the email.
   * @property {String} body - Body content of the email.
   * @property {MailAttachmentResource[]} attachments - Array of email attachments.
   * Retrieve the detailed information of a specific email notice.
   * @param {Number} riskEventId - The ID of the risk event.
   * @param {String} emailInfoId - The ID of the email info.
   * @param {Object} [params] - Optional parameters.
   * @param {String} [params.universe] - Optional parameter for universe.
   * @returns {Promise<EmailInfo>} - A promise that resolves with the detailed email notice information.
   * @APICode service.risk-events.notices.info.get
   */
  getNoticeInfo(riskEventId, emailInfoId, params = {}) {
    return http.get(
      `${API_VERSION}/service/risk-events/${riskEventId}/notices/info/${emailInfoId}`,
      params
    )
  },

  /**
   * Retrieves the count of risk events grouped by their status.
   * @returns {Promise<RiskEventCount>} A promise that resolves with the counts of risk events.
   * @typedef {Object} RiskEventCount
   * @property {number} openCount - The count of open risk events.
   * @property {number} closedCount - The count of closed risk events.
   * @property {number} suppressedCount - The count of suppressed risk events.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch/api/risk-event/getRiskEventsCount.md
   * @APICode service.risk-events.statistics.get
   */
  getRiskEventCount() {
    return http.get(`${API_VERSION}/service/risk-events/statistics`)
  },
}
