import { sortBy } from 'lodash'
import { unref } from 'vue'
import { configurationAPI } from '../../api/configuration.js'
import {
  ConfigKeyByEnumCodeMap,
  EnumCodes,
  EnumConfigKey,
  formatText,
  isEqualStringIgnoreCase,
  PeopleEntityTypeEnum,
} from '../../utils'
import { useI18n } from '../i18n/useI18n.js'
import { registerSharedStore, useStore } from './useStore.js'

export const enumConfig = {}

/**
 * @deprecated please use getEnumOptionLabel
 */
export function getDictionaryOptionsLabel(value, options = []) {
  return formatText(
    unref(options).find((item) => isEqualStringIgnoreCase(item.value, value))
      ?.text
  )
}

export const useEnumConfigStore = () => {
  const store = useStore()
  const { t } = useI18n()

  registerSharedStore()

  Object.assign(enumConfig, store.state?.shared?.enumConfig)
  enumConfig[EnumConfigKey.usStates] = sortBy(
    enumConfig[EnumConfigKey.usStates],
    'enumCode'
  )

  // wait add enum by DAL
  const partyTypeOptions = [
    {
      text: t('individual'),
      value: PeopleEntityTypeEnum.individual,
    },
    {
      text: t('business'),
      value: PeopleEntityTypeEnum.business,
    },
    {
      text: t('trust'),
      value: PeopleEntityTypeEnum.trust,
    },
  ].map((item) => {
    item.label = item.text
    return item
  })

  const yesOrNoOptions = [
    {
      text: t('yes'),
      value: true,
    },
    {
      text: t('no'),
      value: false,
    },
  ]

  function enumToOptions(enums = [], customKeys) {
    const orderedEnum = sortBy(enums, 'displayOrder').filter(
      (item) => item.isActive
    )

    const text = customKeys?.text || 'displayLabel'
    const value = customKeys?.value || 'enumCode'

    return orderedEnum.map((item) => {
      return {
        ...item,
        text: item[text],
        label: item[text],
        value: item[value],
      }
    })
  }

  function toNumericalOptions(options) {
    return options.map((item) => {
      return {
        ...item,
        text: item.text,
        value: item.numericalValue,
      }
    })
  }

  /**
   * Get the label of the option from the enum configuration
   * @param {string} configKey
   * @param {string | number} value
   * @returns
   */
  function getEnumOptionLabel(configKey, value) {
    const options = enumConfig[configKey] || []
    return formatText(
      options.find((item) => isEqualStringIgnoreCase(item.value, value))?.text
    )
  }

  const getConfigurationEnums = (params) => {
    params = {
      enumTypeCodes: EnumCodes,
      ...params,
    }
    return configurationAPI.listConfigurationEnums(params).then(({ enums }) => {
      let config = Object.entries(enums).reduce((acc, [key, value]) => {
        const configKey =
          ConfigKeyByEnumCodeMap.find((item) => item.enumCode === key)
            ?.configKey ?? ''
        configKey && (acc[configKey] = enumToOptions(value, key))
        return acc
      }, {})

      config = addCustomEnumConfig(config)
      config = reFormatStateEnum(config)
      Object.assign(enumConfig, config)
      store.commit('shared/SET_ENUM_CONFIG', enumConfig)

      return enums
    })
  }

  const addCustomEnumConfig = (config) => {
    config[EnumConfigKey.partyType] = partyTypeOptions
    config[EnumConfigKey.yesOrNo] = yesOrNoOptions
    return config
  }

  const reFormatStateEnum = (config) => {
    config[EnumConfigKey.usStates] = config[EnumConfigKey.usStates].map(
      (stateItem) => {
        return {
          ...stateItem,
          text: stateItem.enumCode,
        }
      }
    )
    return config
  }

  return {
    enumConfig,
    enumToOptions,
    toNumericalOptions,
    getEnumOptionLabel,
    getConfigurationEnums,
    EnumConfigKey,
    getDictionaryOptionsLabel,
  }
}
