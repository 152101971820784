export const EnumConfigKey = {
  accountType: 'accountType',
  adjustmentPostedReason: 'adjustmentPostedReason',
  amendmentChangeType: 'amendmentChangeType',
  amendmentPage: 'amendmentPage',
  amendmentPartyAction: 'amendmentPartyAction',
  amendmentPartyStatus: 'amendmentPartyStatus',
  amendmentReturnFlag: 'amendmentReturnFlag',
  amendmentStatus: 'amendmentStatus',
  /**
   * @deprecated
   * @see {@link EnumConfigKey.amendmentChangeType}
   */
  amendmentTask: 'amendmentTask',
  amendmentTypeOfPurpose: 'amendmentTypeOfPurpose',
  amlRiskRating: 'amlRiskRating',
  annualIncomeRange: 'annualIncomeRange',
  applicationStatus: 'applicationStatus',
  applicationSubStatus: 'applicationSubStatus',
  assetPledgeStatus: 'assetPledgeStatus',
  assetType: 'assetType',
  assetClassification: 'assetClassification',
  assetSubtype: 'assetSubtype',
  assetUpdateFrequency: 'assetUpdateFrequency',
  automationLevel: 'automationLevel',
  bankAccountRemittanceType: 'bankAccountRemittanceType',
  bankAccountType: 'bankAccountType',
  billingFrequency: 'billingFrequency',
  businessEntityType: 'businessEntityType',
  businessPurpose: 'businessPurpose',
  borrowerRiskRating: 'borrowerRiskRating',
  collateralReleaseStatus: 'collateralReleaseStatus',
  contactPhoneType: 'contactPhoneType',
  countries: 'countries',
  countryOfLegalEstablishments: 'countryOfLegalEstablishments',
  creditFactors: 'creditFactors',
  declineReason: 'declineReason',
  drawFreezeReason: 'drawFreezeReason',
  drawNeedsExtraApproval: 'drawNeedsExtraApproval',
  drawNeedsExtraApprovalReasonCode: 'drawNeedsExtraApprovalReasonCode',
  drawPostedType: 'drawPostedType',
  drawRequestStatus: 'drawRequestStatus',
  employmentStatus: 'employmentStatus',
  fedCallCode: 'fedCallCode',
  feePlan: 'feePlan',
  florideSolutionEnum: 'florideSolutionEnum',
  formGalleryTaskStatus: 'formGalleryTaskStatus',
  formGalleryTaskType: 'formGalleryTaskType',
  grossAnnualRevenue: 'grossAnnualRevenue',
  hasAutopay: 'hasAutopay',
  identificationType: 'identificationType',
  interestCapitalizationStatus: 'interestCapitalizationStatus',
  isAdditionalDrawAllowed: 'isAdditionalDrawAllowed',
  isCapRollAllowed: 'isCapRollAllowed',
  loanFacilityStatus: 'loanFacilityStatus',
  loanFacilityType: 'loanFacilityType',
  externalFacilityType: 'externalFacilityType',
  loanPackageStatus: 'loanPackageStatus',
  loanPurpose: 'loanPurpose',
  loanStatus: 'loanStatus',
  loanTermType: 'loanTermType',
  loanType: 'loanType',
  maritalStatus: 'maritalStatus',
  maturityDatePolicy: 'maturityDatePolicy',
  memoType: 'memoType',
  occupation: 'occupation',
  partyDrawFreezeReasonCode: 'partyDrawFreezeReasonCode',
  paymentFrequency: 'paymentFrequency',
  paymentRuleMethod: 'paymentRuleMethod',
  paymentScheduleMethod: 'paymentScheduleMethod',
  partyType: 'partyType',
  personalPurpose: 'personalPurpose',
  userRestrictedReason: 'userRestrictedReason',
  purposeOfFunds: 'purposeOfFunds',
  rateType: 'rateType',
  reverseAndReturnReason: 'reverseAndReturnReason',
  reverseReason: 'reverseReason',
  riskPlanType: 'riskPlanType',
  riskStoplightCode: 'riskStoplightCode',
  securedindicator: 'securedindicator',
  servPurposeType: 'servPurposeType',
  serviceActivityLogType: 'serviceActivityLogType',
  serviceLifeOfLineType: 'serviceLifeOfLineType',
  snTransactionDescription: 'snTransactionDescription',
  snTransactionType: 'snTransactionType',
  statementMethod: 'statementMethod',
  subLimitOptions: 'subLimitOptions',
  suffixList: 'suffixList',
  termType: 'termType',
  totalLoanFacilityStatus: 'totalLoanFacilityStatus',
  transactionStatus: 'transactionStatus',
  trustModification: 'trustModification',
  typeOfPurpose: 'typeOfPurpose',
  underwritingStatus: 'underwritingStatus',
  underwritingSubStatus: 'underwritingSubStatus',
  usStates: 'usStates',
  yesOrNo: 'yesOrNo',
  locProductTerm: 'locProductTerm',
  nachaReturnRequestType: 'nachaReturnRequestType',
  nachaReturnFileProcessingStatus: 'nachaReturnFileProcessingStatus',
  drawRequestVerificationFlag: 'drawRequestVerificationFlag',
  transactionType: 'transactionType',
  originationCycleTimeType: 'originationCycleTimeType',
  summaryReportType: 'summaryReportType',
  reportPeriod: 'reportPeriod',
  displayBy: 'displayBy',
  exceptionGroup: 'exceptionGroup',
  accountPositionsSyncStatus: 'accountPositionsSyncStatus',
  sourceOfWealth: 'sourceOfWealth',
  annualIncome: 'annualIncome',
  applicationCancelReason: 'applicationCancelReason',
  appPaymentFrequency: 'appPaymentFrequency',
  counterofferStatus: 'counterofferStatus',
  envelopeStatus: 'envelopeStatus',
  estimatedNumDrawsMonthly: 'estimatedNumDrawsMonthly',
  mfaMethod: 'mfaMethod',
  nameSuffix: 'nameSuffix',
  netWorthEstimate: 'netWorthEstimate',
  noticeStatus: 'noticeStatus',
  appPurposeType: 'appPurposeType',
  rushReason: 'rushReason',
  signerStatus: 'signerStatus',
  signingMethod: 'signingMethod',
  trusteeAuthority: 'trusteeAuthority',
  yearsWithAdvisor: 'yearsWithAdvisor',
  cipResultStatus: 'cipResultStatus',
  overrideField: 'overrideField',
  accountPledgeStatus: 'accountPledgeStatus',
  aumTiers: 'aumTiers',
  exceptionType: 'exceptionType',
  underwritingExceptionStatus: 'underwritingExceptionStatus',
  underwritingExceptionAction: 'underwritingExceptionAction',
  exceptionReason: 'exceptionReason',
  personRelationship: 'personRelationship',
  dayOfWeek: 'dayOfWeek',
  ordinalOfMonth: 'ordinalOfMonth',
  emailFrequency: 'emailFrequency',
  riskEventReason: 'riskEventReason',
  riskEventType: 'riskEventType',

  // TODO: add enum to ccc
  covenantRuleStatus: 'covenantRuleStatus',
  covenantRuleType: 'covenantRuleType',
  covenantRuleCategory: 'covenantRuleCategory',
  frequencyUnit: 'frequencyUnit',
  gracePeriodUnit: 'gracePeriodUnit',
  covenantTaskStatus: 'covenantTaskStatus',
  covenantTaskResolutionMethod: 'covenantTaskResolutionMethod',
  covenantTaskType: 'covenantTaskType',

  // Custom static options
  ratingCompany: 'ratingCompany',
  assetUnderManagementUnit: 'assetUnderManagementUnit',
  propertySizeUnit: 'propertySizeUnit',
  positionsLatestSourceType: 'positionsLatestSourceType',
}
export const ConfigKeyByEnumCodeMap = [
  {
    enumCode: 'account_type',
    configKey: EnumConfigKey.accountType,
    name: '',
  },
  {
    enumCode: 'us_state',
    configKey: EnumConfigKey.usStates,
    name: '',
  },
  {
    enumCode: 'country',
    configKey: EnumConfigKey.countries,
    name: '',
  },
  {
    enumCode: 'name_suffix',
    configKey: EnumConfigKey.suffixList,
    name: '',
  },
  {
    enumCode: 'business_entity_type',
    configKey: EnumConfigKey.businessEntityType,
    name: '',
  },
  {
    enumCode: 'brr_dict_enum',
    configKey: EnumConfigKey.borrowerRiskRating,
    name: '',
  },
  {
    enumCode: 'gross_annual_revenue',
    configKey: EnumConfigKey.grossAnnualRevenue,
    name: '',
  },
  {
    enumCode: 'annual_income_range',
    configKey: EnumConfigKey.annualIncomeRange,
    name: '',
  },
  {
    enumCode: 'marital_status',
    configKey: EnumConfigKey.maritalStatus,
    name: '',
  },
  {
    enumCode: 'employment_status',
    configKey: EnumConfigKey.employmentStatus,
    name: '',
  },
  {
    enumCode: 'occupation',
    configKey: EnumConfigKey.occupation,
    name: '',
  },
  {
    enumCode: 'asset_type',
    configKey: EnumConfigKey.assetType,
    name: '',
  },
  {
    enumCode: 'asset_type_enum',
    configKey: EnumConfigKey.assetType,
    name: '',
  },
  {
    enumCode: 'asset_sub_type_enum',
    configKey: EnumConfigKey.assetSubtype,
    name: '',
  },
  {
    enumCode: 'asset_classification_enum',
    configKey: EnumConfigKey.assetClassification,
    name: '',
  },
  {
    enumCode: 'business_purpose',
    configKey: EnumConfigKey.businessPurpose,
    name: '',
  },
  {
    enumCode: 'party_type',
    configKey: EnumConfigKey.partyType,
    name: '',
  },
  {
    enumCode: 'personal_purpose',
    configKey: EnumConfigKey.personalPurpose,
    name: '',
  },
  {
    enumCode: 'user_restricted_reason',
    configKey: EnumConfigKey.userRestrictedReason,
    name: '',
  },
  {
    enumCode: 'loan_purpose',
    configKey: EnumConfigKey.loanPurpose,
    name: '',
  },
  {
    enumCode: 'trust_modification',
    configKey: EnumConfigKey.trustModification,
    name: '',
  },
  {
    enumCode: 'country_legal_establishment',
    configKey: EnumConfigKey.countryOfLegalEstablishments,
    name: '',
  },
  {
    enumCode: 'purpose_of_funds',
    configKey: EnumConfigKey.purposeOfFunds,
    name: '',
  },
  {
    enumCode: 'loan_term_type_enum',
    configKey: EnumConfigKey.loanTermType,
    name: '',
  },
  {
    enumCode: 'loan_package_status_enum',
    configKey: EnumConfigKey.loanPackageStatus,
    name: '',
  },
  {
    enumCode: 'sublimit_option',
    configKey: EnumConfigKey.subLimitOptions,
    name: '',
  },
  {
    enumCode: 'amendment_type_of_purpose_enum',
    configKey: EnumConfigKey.amendmentTypeOfPurpose,
    name: '',
  },
  {
    enumCode: 'pledge_status_type_enum',
    configKey: EnumConfigKey.assetPledgeStatus,
    name: '',
  },
  {
    enumCode: 'stoplight_code_enum',
    configKey: EnumConfigKey.riskStoplightCode,
    name: '',
  },
  {
    enumCode: 'draw_freeze_reason',
    configKey: EnumConfigKey.drawFreezeReason,
    name: '',
  },
  {
    enumCode: 'loan_status_enum',
    configKey: EnumConfigKey.loanStatus,
    name: '',
  },
  {
    enumCode: 'form_gallery_task_status_enum',
    configKey: EnumConfigKey.formGalleryTaskStatus,
    name: '',
  },
  {
    enumCode: 'form_gallery_task_type_enum',
    configKey: EnumConfigKey.formGalleryTaskType,
    name: '',
  },
  {
    enumCode: 'payment_bank_account_type_enum',
    configKey: EnumConfigKey.bankAccountType,
    name: '',
  },
  {
    enumCode: 'transaction_reversal_ind_enum',
    configKey: EnumConfigKey.transactionStatus,
    name: '',
  },
  {
    enumCode: 'rate_type_enum',
    configKey: EnumConfigKey.rateType,
    name: '',
  },
  {
    enumCode: 'app_phone_type_enum',
    configKey: EnumConfigKey.contactPhoneType,
    name: '',
  },
  {
    enumCode: 'identification_type_enum',
    configKey: EnumConfigKey.identificationType,
    name: '',
  },
  {
    enumCode: 'risk_plan_type',
    configKey: EnumConfigKey.riskPlanType,
    name: '',
  },
  {
    enumCode: 'amendment_task_enum',
    configKey: EnumConfigKey.amendmentTask,
    name: '',
  },
  {
    enumCode: 'amendment_status_enum',
    configKey: EnumConfigKey.amendmentStatus,
    name: '',
  },
  {
    enumCode: 'loan_type_enum',
    configKey: EnumConfigKey.loanType,
    name: '',
  },
  {
    enumCode: 'cap_and_roll_status_enum',
    configKey: EnumConfigKey.interestCapitalizationStatus,
    name: 'InterestCapitalizationStatus',
  },
  {
    enumCode: 'collateral_release_status_enum',
    configKey: EnumConfigKey.collateralReleaseStatus,
    name: '',
  },
  {
    enumCode: 'amendment_page',
    configKey: EnumConfigKey.amendmentPage,
    name: '',
  },
  {
    enumCode: 'bank_account_remittance_type',
    configKey: EnumConfigKey.bankAccountRemittanceType,
    name: '',
  },
  {
    enumCode: 'draw_request_status',
    configKey: EnumConfigKey.drawRequestStatus,
    name: '',
  },
  {
    enumCode: 'application_sub_status_enum',
    configKey: EnumConfigKey.applicationSubStatus,
    name: '',
  },
  {
    enumCode: 'payment_frequency',
    configKey: EnumConfigKey.paymentFrequency,
    name: '',
  },
  {
    enumCode: 'payment_rule_method_enum',
    configKey: EnumConfigKey.paymentRuleMethod,
    name: '',
  },
  {
    enumCode: 'fed_call_code',
    configKey: EnumConfigKey.fedCallCode,
    name: '',
  },
  {
    enumCode: 'fee_plan_enum',
    configKey: EnumConfigKey.feePlan,
    name: '',
  },
  {
    enumCode: 'florida_solution_enum',
    configKey: EnumConfigKey.florideSolutionEnum,
    name: '',
  },
  {
    enumCode: 'loan_facility_type',
    configKey: EnumConfigKey.loanFacilityType,
    name: '',
  },
  {
    enumCode: 'external_facility_type',
    configKey: EnumConfigKey.externalFacilityType,
    name: '',
  },
  {
    enumCode: 'draw_needs_extra_approval',
    configKey: EnumConfigKey.drawNeedsExtraApproval,
    name: '',
  },
  {
    enumCode: 'draw_needs_extra_approval_reason_code',
    configKey: EnumConfigKey.drawNeedsExtraApprovalReasonCode,
    name: '',
  },
  {
    enumCode: 'loan_facility_status',
    configKey: EnumConfigKey.loanFacilityStatus,
    name: '',
  },
  {
    enumCode: 'term_type',
    configKey: EnumConfigKey.termType,
    name: '',
  },
  {
    enumCode: 'is_cap_roll_allowed',
    configKey: EnumConfigKey.isCapRollAllowed,
    name: '',
  },
  {
    enumCode: 'is_additional_draw_allowed',
    configKey: EnumConfigKey.isAdditionalDrawAllowed,
    name: '',
  },
  {
    enumCode: 'billing_frequency',
    configKey: EnumConfigKey.billingFrequency,
    name: '',
  },
  {
    enumCode: 'statement_method',
    configKey: EnumConfigKey.statementMethod,
    name: '',
  },
  {
    enumCode: 'has_autopay',
    configKey: EnumConfigKey.hasAutopay,
    name: '',
  },
  {
    enumCode: 'total_loan_facility_status_enum',
    configKey: EnumConfigKey.totalLoanFacilityStatus,
    name: '',
  },
  {
    enumCode: 'secured_indicator_enum',
    configKey: EnumConfigKey.securedindicator,
    name: '',
  },
  {
    enumCode: 'serv_purpose_type_enum',
    configKey: EnumConfigKey.servPurposeType,
    name: '',
  },
  {
    enumCode: 'app_purpose_type_enum',
    configKey: EnumConfigKey.typeOfPurpose,
    name: '',
  },
  {
    enumCode: 'maturity_date_policy',
    configKey: EnumConfigKey.maturityDatePolicy,
    name: '',
  },
  {
    enumCode: 'memo_type',
    configKey: EnumConfigKey.memoType,
    name: '',
  },
  {
    enumCode: 'automation_level_enum',
    configKey: EnumConfigKey.automationLevel,
    name: '',
  },
  {
    enumCode: 'aml_risk_rating_enum',
    configKey: EnumConfigKey.amlRiskRating,
    name: '',
  },
  {
    enumCode: 'service_activity_log_type_enum',
    configKey: EnumConfigKey.serviceActivityLogType,
    name: '',
  },
  {
    enumCode: 'service_life_of_line_type_enum',
    configKey: EnumConfigKey.serviceLifeOfLineType,
    name: '',
  },
  {
    enumCode: 'payment_schedule_method_enum',
    configKey: EnumConfigKey.paymentScheduleMethod,
    name: '',
  },
  {
    enumCode: 'party_draw_freeze_reason_code_enum',
    configKey: EnumConfigKey.partyDrawFreezeReasonCode,
    name: '',
  },
  {
    enumCode: 'draw_posted_type',
    configKey: EnumConfigKey.drawPostedType,
    name: '',
  },
  {
    enumCode: 'sn_transaction_type',
    configKey: EnumConfigKey.snTransactionType,
    name: '',
  },
  {
    enumCode: 'application_status_enum',
    configKey: EnumConfigKey.applicationStatus,
    name: '',
  },
  {
    enumCode: 'application_sub_status_enum',
    configKey: EnumConfigKey.applicationSubStatus,
    name: '',
  },
  {
    enumCode: 'underwriting_status_enum',
    configKey: EnumConfigKey.underwritingStatus,
    name: '',
  },
  {
    enumCode: 'underwriting_sub_status_enum',
    configKey: EnumConfigKey.underwritingSubStatus,
    name: '',
  },
  {
    enumCode: 'reverse_reason',
    configKey: EnumConfigKey.reverseReason,
    name: '',
  },
  {
    enumCode: 'reverse_and_return_reason',
    configKey: EnumConfigKey.reverseAndReturnReason,
    name: '',
  },
  {
    enumCode: 'sn_transaction_description',
    configKey: EnumConfigKey.snTransactionDescription,
    name: '',
  },
  {
    enumCode: 'amendment_return_flag',
    configKey: EnumConfigKey.amendmentReturnFlag,
    name: '',
  },
  {
    enumCode: 'amendment_change_type_enum',
    configKey: EnumConfigKey.amendmentChangeType,
    name: 'AmendmentTypeEnum',
  },
  {
    enumCode: 'amendment_party_action_enum',
    configKey: EnumConfigKey.amendmentPartyAction,
    name: '',
  },
  {
    enumCode: 'amendment_party_status_enum',
    configKey: EnumConfigKey.amendmentPartyStatus,
    name: '',
  },
  {
    enumCode: 'credit_factor',
    configKey: EnumConfigKey.creditFactors,
    name: '',
  },
  {
    enumCode: 'decline_reason',
    configKey: EnumConfigKey.declineReason,
    name: '',
  },
  {
    enumCode: 'loc_product_term_enum',
    configKey: EnumConfigKey.locProductTerm,
    name: '',
  },
  {
    enumCode: 'nacha_return_request_type_enum',
    configKey: EnumConfigKey.nachaReturnRequestType,
    name: '',
  },
  {
    enumCode: 'nacha_return_file_processing_status_enum',
    configKey: EnumConfigKey.nachaReturnFileProcessingStatus,
    name: '',
  },
  {
    enumCode: 'loan_draw_request_verification_flag_enum',
    configKey: EnumConfigKey.drawRequestVerificationFlag,
    name: '',
  },
  {
    enumCode: 'adjustment_posted_reason',
    configKey: EnumConfigKey.adjustmentPostedReason,
    name: '',
  },
  {
    enumCode: 'transaction_type_enum',
    configKey: EnumConfigKey.transactionType,
  },
  {
    enumCode: 'origination_cycle_time_type_enum',
    configKey: EnumConfigKey.originationCycleTimeType,
  },
  {
    enumCode: 'summary_report_type_enum',
    configKey: EnumConfigKey.summaryReportType,
  },
  {
    enumCode: 'report_period_enum',
    configKey: EnumConfigKey.reportPeriod,
  },
  {
    enumCode: 'display_by_enum',
    configKey: EnumConfigKey.displayBy,
  },
  {
    enumCode: 'exception_group_enum',
    configKey: EnumConfigKey.exceptionGroup,
  },
  {
    enumCode: 'account_positions_sync_status_enum',
    configKey: EnumConfigKey.accountPositionsSyncStatus,
    name: '',
  },
  {
    enumCode: 'source_of_wealth',
    configKey: EnumConfigKey.sourceOfWealth,
    name: '',
  },
  {
    enumCode: 'annual_income_range',
    configKey: EnumConfigKey.annualIncome,
    name: '',
  },
  {
    enumCode: 'application_cancel_reason',
    configKey: EnumConfigKey.applicationCancelReason,
    name: '',
  },
  {
    enumCode: 'app_payment_frequency_enum',
    configKey: EnumConfigKey.appPaymentFrequency,
    name: '',
  },
  {
    enumCode: 'uw_counter_offer_counter_offer_status_enum',
    configKey: EnumConfigKey.counterofferStatus,
    name: '',
  },
  {
    enumCode: 'envelope_status_enum',
    configKey: EnumConfigKey.envelopeStatus,
    name: '',
  },
  {
    enumCode: 'estimated_num_draws_monthly',
    configKey: EnumConfigKey.estimatedNumDrawsMonthly,
    name: '',
  },
  {
    enumCode: 'mfa_method_enum',
    configKey: EnumConfigKey.mfaMethod,
    name: '',
  },
  {
    enumCode: 'name_suffix',
    configKey: EnumConfigKey.nameSuffix,
    name: '',
  },
  {
    enumCode: 'net_worth_estimate_enum',
    configKey: EnumConfigKey.netWorthEstimate,
    name: '',
  },
  {
    enumCode: 'notice_status_enum',
    configKey: EnumConfigKey.noticeStatus,
    name: '',
  },
  {
    enumCode: 'app_purpose_type_enum',
    configKey: EnumConfigKey.appPurposeType,
    name: '',
  },
  {
    enumCode: 'rush_reason_enum',
    configKey: EnumConfigKey.rushReason,
    name: '',
  },
  {
    enumCode: 'signer_status_enum',
    configKey: EnumConfigKey.signerStatus,
    name: '',
  },
  {
    enumCode: 'signing_method_enum',
    configKey: EnumConfigKey.signingMethod,
    name: '',
  },
  {
    enumCode: 'trustee_authority_enum',
    configKey: EnumConfigKey.trusteeAuthority,
    name: '',
  },
  {
    enumCode: 'years_with_advisors',
    configKey: EnumConfigKey.yearsWithAdvisor,
    name: '',
  },
  {
    enumCode: 'cip_result_status_enum',
    configKey: EnumConfigKey.cipResultStatus,
    name: '',
  },
  {
    enumCode: 'override_field',
    configKey: EnumConfigKey.overrideField,
    name: '',
  },
  {
    enumCode: 'account_pledge_status_enum',
    configKey: EnumConfigKey.accountPledgeStatus,
  },
  {
    enumCode: 'aum',
    configKey: EnumConfigKey.aumTiers,
  },
  {
    enumCode: 'exception_type',
    configKey: EnumConfigKey.exceptionType,
  },
  {
    enumCode: 'underwriting_exception_status_enum',
    configKey: EnumConfigKey.underwritingExceptionStatus,
  },
  {
    enumCode: 'underwriting_exception_action_enum',
    configKey: EnumConfigKey.underwritingExceptionAction,
  },
  {
    enumCode: 'exception_reason_enum',
    configKey: EnumConfigKey.exceptionReason,
  },
  {
    enumCode: 'person_relationship_enum',
    configKey: EnumConfigKey.personRelationship,
  },
  {
    enumCode: 'day_of_week_enum',
    configKey: EnumConfigKey.dayOfWeek,
  },
  {
    enumCode: 'ordinal_of_month_enum',
    configKey: EnumConfigKey.ordinalOfMonth,
  },
  {
    enumCode: 'email_frequency_enum',
    configKey: EnumConfigKey.emailFrequency,
  },
  {
    enumCode: 'risk_event_reason',
    configKey: EnumConfigKey.riskEventReason,
  },
  {
    enumCode: 'risk_event_type',
    configKey: EnumConfigKey.riskEventType,
  },
]

export const EnumCodes = ConfigKeyByEnumCodeMap.map((item) => item.enumCode)
