import { ref } from 'vue'
import { collateralAPI } from '@/shared/api/collateral/collateral'
import { useAmendmentData } from '@/shared/composables/data'
/**
 * @typedef {Object} Asset
 * @property {string} assetId - Unique identifier for the asset.
 * @property {string} accountNumber - Number of the account holding the asset.
 * @property {string} assetType - Type of the asset.
 * @property {string} assetName - Name of the asset.
 * @property {number} custodianInstitutionId - ID of the custodian institution.
 * @property {string} accountLatestSourceType - Source type of the latest account information.
 * @property {string} holdingsAsOfDate - Date of the holdings information.
 * @property {number} numberOfHoldings - Number of holdings.
 * @property {number} marketValue - Market value of the asset.
 * @property {number} lendingValue - Lending value of the asset.
 * @property {number} maintenanceValue - Maintenance value of the asset.
 * @property {string} eligibleType - Eligibility type of the asset.
 * @property {number} securityIntermediaryInstitutionId - ID of the security intermediary institution.
 * @property {string} dataSource - Source of the asset data.
 * @property {number} holdingPledgedCount - Count of pledged holdings.
 */

/**
 * @typedef {Object} CollateralAssetsSummary
 * @property {number} totalAccountCounts - Total number of accounts.
 * @property {number} totalHoldingCounts - Total number of holdings.
 * @property {number} totalMarketValue - Total market value.
 * @property {number} totalLendingValue - Total lending value.
 * @property {number} totalMaintenanceValue - Total maintenance value.
 * @property {Asset[]} assets - List of assets.
 */

/**
 * @typedef {Object} CollateralPackageDTO
 * @property {string} assetName - Name of the collateral package.
 * @property {string} externalCollateralPackageNumber - External identifier for the collateral package.
 * @property {number} availableCreditAmount - Amount of credit available.
 * @property {string} collateralBaseCurrencyCode - Base currency code for the collateral.
 * @property {string} diversificationRating - Rating for diversification.
 * @property {number} excessEquity - Amount of excess equity.
 * @property {number} excessEquityRatio - Ratio of excess equity.
 * @property {number} excessLendingValue - Value of excess lending.
 * @property {boolean} isEligibleForAutoApproval - Eligibility for auto approval.
 * @property {number} maxAutoApprovalAmount - Maximum amount for auto approval.
 * @property {number} lendingValue - Value of lending.
 * @property {number} loanToValue - Loan to value ratio.
 * @property {string} liquidityRating - Rating for liquidity.
 * @property {number} advanceRate - Rate of advance.
 * @property {number} maintenanceRate - Rate of maintenance.
 * @property {number} maintenanceValue - Value of maintenance.
 * @property {number} marketValue - Market value.
 * @property {number} eligibleMarketValue - Market value that is eligible.
 * @property {number} pendingReleaseAmount - Amount pending release.
 * @property {string} qualityRating - Rating for quality.
 * @property {number} requiredEquity - Equity required.
 * @property {string} volatilityRating - Rating for volatility.
 * @property {string} collateralRating - Overall rating for the collateral.
 * @property {string} asOfDate - Date of the collateral valuation.
 * @property {string} calculateAt - Date of calculation.
 * @property {number} creditPolicyId - Identifier for the credit policy.
 * @property {number} collateralFlags - Flags related to the collateral.
 * @property {number} totalBalance - Total balance.
 * @property {number} totalLoanFacilityId - Identifier for the total loan facility.
 * @property {string} stoplightCode - Stoplight code.
 * @property {AssetDTO[]} assets - List of assets.
 */

/**
 * @typedef {Object} AssetDTO
 * @property {string} assetId - Identifier for the asset.
 * @property {string} assetName - Name of the asset.
 * @property {string} assetNumber - Number of the asset.
 * @property {string} assetType - Type of the asset.
 * @property {number} advanceRate - Rate of advance for the asset.
 * @property {string} assetClassification - Classification of the asset.
 * @property {string} collateralBaseCurrencyCode - Base currency code for the asset's collateral.
 * @property {number} concentrationLimitAmount - Amount for the concentration limit.
 * @property {number} concentrationLimitRate - Rate for the concentration limit.
 * @property {string} custodianCode - Code for the custodian.
 * @property {string} custodianInstitutionId - Identifier for the custodian institution.
 * @property {string} eligibleStatus - Eligibility status of the asset.
 * @property {string} externalAssetId - External identifier for the asset.
 * @property {string} investmentAccountTypeCode - Code for the type of investment account.
 * @property {boolean} isMarginInvestmentAccount - Indicates if the account is a margin investment account.
 * @property {boolean} isPrimaryInvestmentAccount - Indicates if the account is a primary investment account.
 * @property {number} lendingValue - Lending value of the asset.
 * @property {number} maintenanceRate - Maintenance rate for the asset.
 * @property {number} maintenanceValue - Maintenance value for the asset.
 * @property {number} marginBalance - Margin balance of the asset.
 * @property {number} marketValue - Market value of the asset.
 * @property {string} pledgedDate - Date when the asset was pledged.
 * @property {string} pledgingErrorDetail - Detail of any error in pledging.
 * @property {string} pledgingStatus - Status of the asset's pledging.
 * @property {string} priceTier - Price tier of the asset.
 * @property {number} requiredEquity - Equity required for the asset.
 * @property {string} siInstitutionId - Identifier for the SI institution.
 * @property {string} asOfDate - Date of the asset valuation.
 * @property {string} calculateAt - Date of calculation for the asset.
 */

/**
 * Composition API to interact with collateral assets and collateral package details.
 *
 * @returns {Object} Object containing attributes and methods for collateral interaction.
 */

const collateralPackageDetails = ref({})
export const useCollateralData = (stage) => {
  const { archiveAmendmentId } = useAmendmentData()

  const isCollateralPortfolioExceptionStatusLoading = ref(false)
  const collateralPortfolioExceptionStatus = ref({})
  const getCollateralCalculationExceptionStatus = (collateralId, params) => {
    isCollateralPortfolioExceptionStatusLoading.value = true
    return collateralAPI
      .getCollateralCalculationExceptionStatus(stage, collateralId, params)
      .then((data) => {
        collateralPortfolioExceptionStatus.value = data
      })
      .finally(() => {
        isCollateralPortfolioExceptionStatusLoading.value = false
      })
  }

  const isUpdateCollateralPackageLoading = ref(false)
  function updateCollateralPackage(collateralId, params) {
    isUpdateCollateralPackageLoading.value = true
    return collateralAPI
      .updateCollateralPackageDetails(stage, collateralId, params)
      .finally(() => {
        isUpdateCollateralPackageLoading.value = false
      })
  }

  const isCollateralAssetsSummaryLoading = ref(false)
  const collateralAssetsSummary = ref({})
  /**
   * Fetches the summary of collateral assets for a given stage and collateral ID.
   *
   * @param {string} stage - The stage of the loan process.
   * @param {number} collateralId - The ID of the collateral.
   * @returns {Promise<CollateralAssetsSummary>} - A promise that resolves with the collateral assets summary.
   */
  const getCollateralAssetsSummary = (collateralId) => {
    isCollateralAssetsSummaryLoading.value = true
    return collateralAPI
      .getCollateralAssetsSummary(stage, collateralId, archiveAmendmentId.value)
      .then((data) => {
        collateralAssetsSummary.value = data
      })
      .finally(() => {
        isCollateralAssetsSummaryLoading.value = false
      })
  }

  const isCollateralPackageDetailsLoading = ref(false)

  /**
   * Get collateral package details by stage and collateralId.
   *
   * @param {string} stage - The stage of the API.
   * @param {number} collateralId - The unique ID of the collateral package.
   * @returns {Promise<CollateralPackageDTO>} - A promise that resolves with the details of the collateral package.
   */
  const getCollateralPackageDetails = (collateralId) => {
    isCollateralPackageDetailsLoading.value = true
    return collateralAPI
      .getCollateralPackageDetails(
        stage,
        collateralId,
        archiveAmendmentId.value
      )
      .then((data) => {
        collateralPackageDetails.value = data
      })
      .finally(() => {
        isCollateralPackageDetailsLoading.value = false
      })
  }

  /**
   * Triggers the calculation of collaterals.
   *
   * @param {string} stage - The stage at which the calculation is performed.
   * @param {CollateralsCalculationReqDTO} collateralsCalculationReq - The request data for collateral calculation.
   */
  const isCollateralsCalculationLoading = ref(false)
  const calculateCollaterals = (params) => {
    isCollateralsCalculationLoading.value = true
    return collateralAPI.calculateCollaterals(stage, params).finally(() => {
      isCollateralsCalculationLoading.value = false
    })
  }

  const isHoldingsRealTimeDataLoading = ref(false)
  const holdingsRealTimeData = ref({})
  const getCollateralRealTimeData = (collateralId) => {
    isHoldingsRealTimeDataLoading.value = true
    return collateralAPI
      .getCollateralRealTimeData(stage, collateralId)
      .then((data) => {
        holdingsRealTimeData.value = data
      })
      .finally(() => {
        isHoldingsRealTimeDataLoading.value = false
      })
  }

  const isMaxCollateralReleaseValueLoading = ref(false)
  const maxCollateralReleaseValue = ref({})
  /**
   * Fetches the maximum collateral release value by collateral package ID.
   *
   * @param {number} collateralPackageId - The unique ID of the collateral package.
   */
  const getMaxCollateralReleaseValue = async (collateralPackageId) => {
    isMaxCollateralReleaseValueLoading.value = true
    try {
      maxCollateralReleaseValue.value =
        await collateralAPI.getMaxCollateralReleaseValue(
          stage,
          collateralPackageId
        )
    } finally {
      isMaxCollateralReleaseValueLoading.value = false
    }
  }

  const isListCollateralPackagesLoading = ref(false)
  const collateralPackageRes = ref({})
  const getListCollateralPackages = (params) => {
    isListCollateralPackagesLoading.value = true
    return collateralAPI
      .getListCollateralPackages(stage, params)
      .then((data) => {
        collateralPackageRes.value = data
      })
      .finally(() => {
        isListCollateralPackagesLoading.value = false
      })
  }

  const isCollateralPackageHoldingsLoading = ref(false)
  const collateralPackageHoldings = ref({})

  /**
   * Fetches collateral package holdings by collateral package ID and parameters.
   *
   * @param {number} collateralPackageId - The unique ID of the collateral package.
   * @param {Object} params - Parameters for the holdings request.
   */
  const getCollateralPackageHoldings = async (collateralPackageId, params) => {
    isCollateralPackageHoldingsLoading.value = true
    try {
      collateralPackageHoldings.value =
        await collateralAPI.getCollateralPackageHoldings(
          stage,
          collateralPackageId,
          params
        )
    } finally {
      isCollateralPackageHoldingsLoading.value = false
    }
  }

  const assetTypeHoldings = ref([])
  const isGetCollateralAssetTypeHoldingsLoading = ref(false)
  function getCollateralAssetTypeHoldings(assetId, params) {
    return collateralAPI
      .getCollateralAssetTypeHoldings(stage, assetId, params)
      .then((data) => {
        assetTypeHoldings.value = data
      })
      .finally(() => {
        isGetCollateralAssetTypeHoldingsLoading.value = false
      })
  }

  const isCollateralPledging = ref(false)
  /**
   * Pledge assets for a given collateral in a specific stage.
   *
   * @param {String} stage - The stage of the collateral.
   * @param {Number} collateralId - The ID of the collateral.
   * @returns {Promise<void>}
   */
  const pledgeAssets = async (collateralId) => {
    isCollateralPledging.value = true
    try {
      return await collateralAPI.pledgeAssets(stage, collateralId)
    } finally {
      isCollateralPledging.value = false
    }
  }

  const isCustomCollateralAnalysisLoading = ref(false)
  const customCollateralAnalysisData = ref({})
  /**
   * Fetches a custom collateral analysis report for a specific collateral.
   *
   * @param {String} collateralId - The ID of the collateral.
   * @param {String} [archiveId] - The ID of the archive.
   */
  const getCustomCollateralAnalysisReport = async (collateralId, archiveId) => {
    isCustomCollateralAnalysisLoading.value = true
    try {
      customCollateralAnalysisData.value =
        await collateralAPI.getCustomCollateralAnalysisReport(
          collateralId,
          archiveId
        )
    } finally {
      isCustomCollateralAnalysisLoading.value = false
    }
  }

  const isCreateCollateralPackageLoading = ref(false)
  const collateralPackageData = ref({})

  /**
   * Creates a collateral package with the specified details.
   *
   * @param {string} stage - The stage of the collateral package.
   * @param {CreateCollateralPackageRequest} createCollateralPackageRequest - The request object containing collateral package details.
   * @returns {Promise<CreateCollateralPackageResponse>} A promise that resolves with the created collateral package ID.
   */
  const createCollateralPackage = async (createCollateralPackageRequest) => {
    isCreateCollateralPackageLoading.value = true
    try {
      collateralPackageData.value = await collateralAPI.createCollateralPackage(
        stage,
        createCollateralPackageRequest
      )
    } finally {
      isCreateCollateralPackageLoading.value = false
    }
  }

  return {
    isCollateralPortfolioExceptionStatusLoading,
    collateralPortfolioExceptionStatus,
    getCollateralCalculationExceptionStatus,

    isUpdateCollateralPackageLoading,
    updateCollateralPackage,

    isCollateralAssetsSummaryLoading,
    collateralAssetsSummary,
    getCollateralAssetsSummary,

    isCollateralPackageDetailsLoading,
    collateralPackageDetails,
    getCollateralPackageDetails,

    isCollateralsCalculationLoading,
    calculateCollaterals,

    isHoldingsRealTimeDataLoading,
    holdingsRealTimeData,
    getCollateralRealTimeData,

    isMaxCollateralReleaseValueLoading,
    maxCollateralReleaseValue,
    getMaxCollateralReleaseValue,

    isListCollateralPackagesLoading,
    collateralPackageRes,
    getListCollateralPackages,

    isCollateralPackageHoldingsLoading,
    collateralPackageHoldings,
    getCollateralPackageHoldings,

    isCollateralPledging,
    pledgeAssets,

    getCollateralAssetTypeHoldings,
    isGetCollateralAssetTypeHoldingsLoading,
    assetTypeHoldings,

    isCustomCollateralAnalysisLoading,
    customCollateralAnalysisData,
    getCustomCollateralAnalysisReport,

    isCreateCollateralPackageLoading,
    collateralPackageData,
    createCollateralPackage,
  }
}
