import _ from 'lodash'
import * as format from './format'

export const filter = Object.entries(format).reduce((t, [k, f]) => {
  if (_.isFunction(f)) {
    t[k] =
      (options = {}) =>
      (mix) =>
        f(mix, options)
  }
  return t
}, {})
