import { http, API_VERSION } from './http'

export const billAPI = {

  /**
   * Create, edit, or delete a bill change request for a specific loan.
   * @apiCode {stage}.loans.bill-change-requests.create
   *
   * @param {string} stage - The stage of the resource.
   * @param {number} loanId - The ID of the loan.
   * @param {Object} params - The request payload containing bill change request details.
   * @param {string} params.requestType - The type of request (add/edit/delete).
   * @param {number} params.originalBillId - The ID of the original bill.
   * @param {number} params.billedTotalAmount - The total amount billed.
   * @param {number} params.billNotPaidTotal - The total amount not paid.
   * @param {number} params.billedPrincipalAmount - The principal amount billed.
   * @param {number} params.billNotPaidTotalPrincipalAmount - The principal amount not paid.
   * @param {number} params.billedInterestAmount - The interest amount billed.
   * @param {number} params.billNotPaidInterestAmount - The interest amount not paid.
   * @returns {Promise<Object>} The response containing the ID of the loan bill change request.
   */
  createBills(stage, params) {
    return http.post(`${API_VERSION}/${stage}/loans/${loanId}/bill-change-requests`, params)
  },

  /**
   * Lists bills with optional filtering and pagination.
   * @apiCode {stage}.loans.bill-summaries.list
   *
   * @param {string} stage - The stage of the resource.
   * @param {Object} params - The parameters for listing bills.
   * @param {number} [params.pageNum] - The page number for pagination.
   * @param {number} [params.pageSize] - The size of the page for pagination.
   * @param {string} [params.sort] - The sorting criteria.
   * @param {Object} [params.filters] - The filtering criteria.
   * @param {string} [params.filters.totalLoanFacilityId] - Filter by total loan facility ID.
   * @param {string} [params.filters.loanFacilityId] - Filter by loan facility ID.
   * @param {string} [params.filters.loanId] - Filter by loan ID.
   * @param {string} [params.filters.billNotPaidTotal.min] - Minimum not paid total filter.
   * @param {string} [params.filters.billNotPaidTotal.max] - Maximum not paid total filter.
   * @param {string} [params.filters.billDueDate.min] - Minimum bill due date filter.
   * @param {string} [params.filters.billDueDate.max] - Maximum bill due date filter.
   * @returns {Promise<Object>} The list of bills with pagination and filtering applied.
   */
  listBills(stage, params) {
    return http.get(`${API_VERSION}/${stage}/loans/bills`, params)
  },

  /**
   * Create, edit, or delete a bill change request for a specific loan.
   * @apiCode {stage}.loans.bill-change-requests.create
   *
   * @param {string} stage - The stage of the resource.
   * @param {number} loanId - The ID of the loan.
   * @param {Object} params - The request payload containing bill change request details.
   * @param {string} params.requestType - The type of request (add/edit/delete).
   * @param {number} params.originalBillId - The ID of the original bill.
   * @param {number} params.billedTotalAmount - The total amount billed.
   * @param {number} params.billNotPaidTotal - The total amount not paid.
   * @param {number} params.billedPrincipalAmount - The principal amount billed.
   * @param {number} params.billNotPaidTotalPrincipalAmount - The principal amount not paid.
   * @param {number} params.billedInterestAmount - The interest amount billed.
   * @param {number} params.billNotPaidInterestAmount - The interest amount not paid.
   * @returns {Promise<Object>} The response containing the ID of the loan bill change request.
   */
  updateBill(stage, loanId, payload) {
    return http.post(`${API_VERSION}/${stage}/loans/${loanId}/bill-change-requests`, payload)
  },

  /**
  * Create, edit, or delete a bill change request for a specific loan.
  * @apiCode {stage}.loans.bill-change-requests.create
  *
  * @param {string} stage - The stage of the resource.
  * @param {number} loanId - The ID of the loan.
  * @param {Object} params - The request payload containing bill change request details.
  * @param {string} params.requestType - The type of request (add/edit/delete).
  * @param {number} params.originalBillId - The ID of the original bill.
  * @param {number} params.billedTotalAmount - The total amount billed.
  * @param {number} params.billNotPaidTotal - The total amount not paid.
  * @param {number} params.billedPrincipalAmount - The principal amount billed.
  * @param {number} params.billNotPaidTotalPrincipalAmount - The principal amount not paid.
  * @param {number} params.billedInterestAmount - The interest amount billed.
  * @param {number} params.billNotPaidInterestAmount - The interest amount not paid.
  * @returns {Promise<Object>} The response containing the ID of the loan bill change request.
  */
  deleteBill(stage, loanId, params) {
    return http.post(`${API_VERSION}/${stage}/loans/${loanId}/bill-change-requests`, params)
  },

  /**
   * Exports bills data to an Excel file.
   * @param {string} stage - The stage of the bills to be exported.
   * @param {ExportOptions} [options={}] - Options for exporting the bills.
   * @param {boolean} [options.shouldEncrypt=true] - Whether the export should be encrypted.
   * @param {string} [options.headerNames] - Comma-separated string of header names.
   * @param {string} [options.columnNames] - Comma-separated string of column names.
   * @returns {Promise<Blob>} - A promise that resolves with the binary stream of the Excel file.
   * @APICode {stage}.bills.exports.get
   */
  exportBills(stage, options = {}) {
    return http.download(`${API_VERSION}/${stage}/loans/bills/exports`, options, {})
  },
}
