import _ from 'lodash'
import { toBigNumber } from '../utils/transfer'

export function requiredInput(field, required = true) {
  return {
    required,
    message: field
      ? window.vm.$t('field_can_not_be_blank', {
          field: field,
        })
      : '',
    transform(value) {
      return _.isString(value) ? _.trim(value) : _.toString(value)
    },
  }
}

/**
 * @deprecated Please use 'select' instead
 * @param {*} field
 * @param {*} required
 * @param {*} type
 * @returns
 */
export function requiredSelect(field, required = true, type = 'string') {
  if (arguments.length === 2 && typeof required === 'string') {
    type = required || 'string'
    required = true
  }
  return {
    type,
    required,
    message: field
      ? window.vm.$t('please_select_field', {
          fieldName: field,
        })
      : '',
    transform(value) {
      return _.isString(value) ? _.trim(value) : value
    },
  }
}

export function requiredRadio(field, required = true) {
  return {
    required,
    type: 'boolean',
    message: field
      ? window.vm.$t('please_select_field', {
          fieldName: field,
        })
      : '',
  }
}

export function requiredMessage(message, required = true) {
  return {
    required,
    message: message || '',
    transform(value) {
      return _.isString(value) ? _.trim(value) : value
    },
  }
}

/**
 * Get select validate rule
 * @param {object} options
 * @param {string} [options.field] field label
 * @param {string} [options.type] validate value type
 * @param {string} [options.required] this field is required
 * @param {string} [options.message] custom error message
 * @returns
 */
export function select({
  field,
  type = 'string',
  required = true,
  warning = false,
  message = window.vm.$t('please_select'),
} = {}) {
  return {
    type,
    warning,
    required,
    message:
      (field
        ? window.vm.$t('please_select_field', {
            fieldName: field,
          })
        : '') || message,
    transform(value) {
      return _.isString(value)
        ? _.trim(value)
        : type === 'string'
        ? _.toString(value)
        : value
    },
  }
}

/**
 * @desc Get a rule for validate email address
 * @method email
 * @param {string | object} [param] - Field name
 * @param {string} [param.fieldName] - Field name
 * @param {string} [param.message] - Validate failed message
 * @returns Rule object
 */
export function email(param) {
  let message = null
  let fieldName = null
  if (typeof param === 'string') {
    fieldName = param
  } else if (typeof param === 'object') {
    message = param.message
    fieldName = param.fieldName
  }
  return {
    type: 'email',
    message:
      message ||
      window.vm.$t(
        fieldName
          ? 'field_is_not_a_valid_email_address'
          : 'this_is_not_a_valid_email_address',
        {
          field: fieldName,
        }
      ),
    transform(value) {
      return _.isString(value) ? _.trim(value) : value
    },
  }
}

/**
 * @desc Validate number less than max value
 * @deprecated Deprecated in 4.2.20, please use lessThan instead.
 * @param {string} field Validate field name
 * @param {*} minValue
 * @param {*} included
 * @returns
 */
export function maxValue(field, maxVal, included = true) {
  return {
    validator(_rule, value) {
      value = toBigNumber(value)
      if (included) {
        return value.isLessThanOrEqualTo(toBigNumber(maxVal))
      } else {
        return value.isLessThan(toBigNumber(maxVal))
      }
    },
    transform(value) {
      return _.trim(value)
    },
    message: getGreaterOrLessThanMessage(field, maxVal, included, false),
  }
}

/**
 * @desc Validate number greater than min value
 * @deprecated Deprecated in 4.2.20, please use greaterThan instead.
 * @param {string} field Validate field name
 * @param {*} minValue
 * @param {*} included
 * @returns
 */
export function minValue(field, minVal, included = false) {
  return {
    validator(_rule, value) {
      value = toBigNumber(value)

      if (included) {
        return value.isGreaterThanOrEqualTo(toBigNumber(minVal))
      } else {
        return value.isGreaterThan(toBigNumber(minVal))
      }
    },
    transform(value) {
      return _.trim(value)
    },
    message: getGreaterOrLessThanMessage(field, minVal, included, true),
  }
}

/**
 * @desc Validate number greater than threshold
 * @param { string } [fieldName] Validate field name
 * @param { number | string } threshold Greater than threshold, support $11,123.00 format
 * @param { boolean } [equalTo=false] allow equal to threshold
 * @param { string } message Validate failed message
 * @param { function } transform Value transform function
 * @param { function } validate Custom validate function
 * @returns { object } Validate rule object
 */
export function greaterThan({
  fieldName = '',
  threshold,
  equalTo = false,
  message,
  transform,
  validate,
  warning = false,
}) {
  if (threshold === undefined || threshold === null) {
    return null
  }

  return {
    warning,
    validator:
      validate ||
      function (_rule, value) {
        value = toBigNumber(value)
        return equalTo
          ? value.isGreaterThanOrEqualTo(toBigNumber(threshold))
          : value.isGreaterThan(toBigNumber(threshold))
      },
    transform:
      transform ||
      function (value) {
        return _.isString(value) ? _.trim(value) : value
      },
    message:
      message ||
      getGreaterOrLessThanMessage(fieldName, threshold, equalTo, true),
  }
}

/**
 * @method lessThan
 * @desc Validate number less than threshold
 * @param { string } [fieldName] Validate field name
 * @param { number | string } threshold Less than threshold,support $11,123.00 format
 * @param { boolean } [equalTo=false] allow equal to threshold
 * @param { string } message Validate failed message
 * @param { function } transform Value transform function
 * @param { function } validate Custom validate function
 * @returns { object } Validate rule object
 */
export function lessThan({
  fieldName,
  threshold,
  equalTo = false,
  message,
  transform,
  validate,
  warning = false,
}) {
  if (threshold === undefined || threshold === null) {
    return null
  }
  return {
    warning,
    validator:
      validate ||
      function (_rule, value) {
        value = toBigNumber(value)
        return equalTo
          ? value.isLessThanOrEqualTo(toBigNumber(threshold))
          : value.isLessThan(toBigNumber(threshold))
      },
    transform:
      transform ||
      function (value) {
        return _.isString(value) ? _.trim(value) : value
      },
    message:
      message ||
      getGreaterOrLessThanMessage(fieldName, threshold, equalTo, false),
  }
}

/**
 * Get phone validate rule
 * @param {string} field field label
 * @returns rule
 */
export function phone(field = 'The phone number') {
  // 999-999-9999
  const number = 10

  return {
    validator: (_rule, value) => {
      return value
        ? value.toString().replace(/\D/gi, '').length === number
        : true
    },
    transform(value) {
      return _.isString(value) ? _.trim(value) : value
    },
    message: window.vm.$t('field_must_be_number_digits', {
      field,
      number,
    }),
  }
}

/**
 * @method zipcode
 * @desc Get a validate rule for zipcode
 * @param {object | string} param field name or message
 * @returns
 */
export function zipcode(param) {
  let message = null
  if (typeof param === 'object') {
    message = param.message
  }
  return {
    pattern: /^(\d{5}$)|^(\d{5}-?\d{4}$)/,
    transform(value) {
      return _.trim(value)
    },
    message: message || window.vm.$ts('this_is_not_a_valid_zip_code'),
  }
}

export function lengthLimit(field, minLen = 0, maxLen = 255) {
  return {
    validator: (_rule, value) => {
      if (value && typeof value === 'string') {
        const len = _.trim(value).length
        if (len < minLen || len > maxLen) return false
      }
      return true
    },
    message: window.vm.$t(
      'field_length_shall_not_be_greater_than_or_less_than',
      {
        field,
        maxLen,
        minLen,
      }
    ),
  }
}

/**
 *
 * @param {string} field field name
 * @param {array} words an array of strings containing prohibited words
 * @returns
 */
export function prohibitedWords(field, words = []) {
  let joinedString = ''
  let validWords = []
  let formatWords = []
  if (Array.isArray(words) && words.length > 0) {
    validWords = words.filter((word) => typeof word === 'string')
    formatWords = validWords.map((word) => `"${word}"`)
    joinedString = formatWords.join(', ')
  }
  return {
    validator: (_rule, value) => {
      let regex = null
      for (const word of validWords) {
        regex = new RegExp(`\\b${word}\\b`, 'i') // 'i' means ignore case
        if (regex.test(value)) return false
      }
      return true
    },
    message: window.vm.$t('strings_is_not_allowed_in_field', {
      strings: joinedString,
      field,
    }),
  }
}

export function lastName() {
  return {
    message: window.vm.$t('please_input_a_valid_last_name'),
    asyncValidator: (_rule, value, callback) => {
      if (!value) return callback(undefined)

      let flag
      const arr = ['JR', 'SR', 'II', 'III', 'IV']
      const strictArr = ['AND', 'PARTNERSHIP']

      strictArr.forEach((i) => {
        i = new RegExp('\\b' + i + '\\b', 'i')
        i.test(value) && (flag = false)
      })
      if (flag !== false) {
        arr.forEach((i) => {
          const reg = new RegExp('\\b' + i + '\\b', 'i')
          if (
            reg.test(value) &&
            value.search(reg) !== value.length - i.length
          ) {
            flag = false
          }
        })
      }
      callback(flag)
    },
  }
}

export function ssnAndItin({ fieldName = '', message } = {}) {
  function allCharactersSame(str) {
    str = _.toString(str)
    return str.split('').every((i) => i === str.charAt(0))
  }
  return {
    validator: (_rule, value) => {
      if (value && value.replace(/(^\s*)|(\s*$)/g, '').length === 9) {
        const regexItin =
          /\d{3}([5][0-9]|[6][0-5]|[7][0-9]|[8][0-8]|[9][0-2]|[9][4-9])\d{4}/g
        const regex = /^(?!000|666)[0-8]\d{2}(?!00)\d{2}(?!0000)\d{4}$/
        if (value.startsWith('9')) {
          return regexItin.test(value) && !allCharactersSame(value)
        }
        return (
          regex.test(value) &&
          value !== '123456789' &&
          !allCharactersSame(value)
        )
      }
      return true
    },
    message:
      message ||
      window.vm.$t('please_input_a_valid_value', {
        field: fieldName,
      }),
    trigger: 'blur',
  }
}

export function ssn() {
  function allCharactersSame(str) {
    str = _.toString(str)
    return str.split('').every((i) => i === str.charAt(0))
  }
  return {
    validator: (_rule, value) => {
      if (value && value.replace(/(^\s*)|(\s*$)/g, '').length === 9) {
        const regex = /^(?!000|666)[0-8]\d{2}(?!00)\d{2}(?!0000)\d{4}$/
        return (
          regex.test(value) &&
          value !== '123456789' &&
          !allCharactersSame(value)
        )
      }
      return true
    },
    message: window.vm.$t('please_input_a_valid_social_security_number'),
    trigger: 'blur',
  }
}

/**
 * Get a validate rule for date picker range
 * @param {object} options
 * @param {string} [options.field] field label
 * @param {string} [options.required] this field is required
 * @param {string} [options.message] custom error message
 * @returns
 */
export function datePickerRange({
  field,
  required = true,
  warning = false,
  message = window.vm.$t('please_select'),
} = {}) {
  return {
    warning,
    required,
    message:
      (field
        ? window.vm.$t('please_select_field', {
            fieldName: field,
          })
        : '') || message,
    validator(_rule, value) {
      return _.isArray(value) && value.length > 1
        ? _.isString(value[0]) && _.isString(value[1])
        : false
    },
  }
}

function getGreaterOrLessThanMessage(field, threshold, equalTo, isGreaterThan) {
  const messageMap = {
    0b000: 'this_value_must_be_less_than_threshold',
    0b001: 'this_value_must_be_greater_than_threshold',
    0b010: 'this_value_must_be_less_than_or_equal_to_threshold',
    0b011: 'this_value_must_be_greater_than_or_equal_to_threshold',
    0b100: 'field_must_be_less_than_threshold',
    0b101: 'field_must_be_greater_than_threshold',
    0b110: 'field_must_be_less_than_or_equal_to_threshold',
    0b111: 'field_must_be_greater_than_or_equal_to_threshold',
  }

  const key = (!!field << 2) | (!!equalTo << 1) | (!!isGreaterThan << 0)
  const messageKey = messageMap[key]

  return window.vm.$t(messageKey, {
    field,
    threshold,
  })
}
