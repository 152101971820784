export function convertArrayToOptions(
  source,
  { textKey, descriptionKey, valueKey, sortFunc, sortKey }
) {
  if (!Array.isArray(source)) {
    return []
  }
  const options = []
  source.forEach((item) => {
    if (
      item[valueKey] &&
      item[textKey] &&
      !options.find((o) => o.value === item[valueKey])
    ) {
      options.push({
        text: item[textKey],
        description: item[descriptionKey],
        value: item[valueKey],
        sort: item[sortKey || textKey],
        disabled: item.disabled !== undefined ? !!item.disabled : false,
        valueObj: item,
      })
    }
  })
  if (sortFunc) {
    return options.sort(sortFunc)
  }
  return sortKey ? sortIgnoreCase(options, 'sort') : options
}

export function sortIgnoreCase(arr, sortKey, asc = true) {
  const sortOrder = asc ? -1 : 1
  arr.sort((item1, item2) => {
    const text1 =
      typeof item1[sortKey] === 'string'
        ? item1[sortKey].toUpperCase()
        : item1[sortKey]
    const text2 =
      typeof item2[sortKey] === 'string'
        ? item2[sortKey].toUpperCase()
        : item2[sortKey]
    if (text1 < text2) {
      return sortOrder
    }
    return text1 > text2 ? -sortOrder : 0
  })
  return arr
}

/**
 * Join string.
 * @example joinWithSeparator(' ', this.firstName, this.middleName, this.lastName)
 * @param {String} separator separator
 * @param {Any} args need join strings
 * @returns result string
 */
export function joinWithSeparator(separator, ...args) {
  return args
    .flat()
    .filter((item) => {
      return item !== undefined && item !== null && item !== ''
    })
    .join(separator)
}

/**
 * Get last item of array.
 * @param {Array} arr array
 * @returns last item of array
 */
export function lastArrayItem(arr) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return null
  }
  return arr[arr.length - 1]
}
