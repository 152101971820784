import { computed, ref } from 'vue'
import { configurationAPI } from '../../api/configuration.js'
import { useStore, registerSharedStore } from './useStore.js'
import { APIStageEnum, DocumentPhaseEnum } from '@/shared/utils/enum.js'

export const ProductConfigCategoryCodeEnum = {
  proposal: 'proposal',
  application: 'digitalApplication',
  underwriting: 'underwriting',
  service: 'servicing',
  cm: 'collateralDataHub',
  amendment: 'amendment',
}

const getListLocProductLevelConfigs = (store, stage, params) => {
  return configurationAPI
    .listLocProductLevelConfigurations(stage, params)
    .then((res) => {
      store.commit('shared/SET_PRODUCT_CONFIG', {
        config: res,
        categoryCode: params.categoryCode,
      })
    })
}

export const useEstimateLocProductConfigStore = () => {
  const isEstimateLocProductConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const estimateLocProductConfig = computed(
    () => sharedState.value.estimateLocProductConfig
  )

  const getEstimateListLocProductLevelConfigs = () => {
    isEstimateLocProductConfigLoading.value = true
    return getListLocProductLevelConfigs(store, 'estimate').finally(() => {
      isEstimateLocProductConfigLoading.value = false
    })
  }
  return {
    isEstimateLocProductConfigLoading,
    estimateLocProductConfig,
    getEstimateListLocProductLevelConfigs,
  }
}

export const useProposalLocProductConfigStore = () => {
  const isProposalLocProductConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const proposalLocProductConfig = computed(
    () => sharedState.value.proposalLocProductConfig
  )

  const getProposalListLocProductLevelConfigs = () => {
    isProposalLocProductConfigLoading.value = true
    return getListLocProductLevelConfigs(store, 'proposal').finally(() => {
      isProposalLocProductConfigLoading.value = false
    })
  }
  return {
    isProposalLocProductConfigLoading,
    proposalLocProductConfig,
    getProposalListLocProductLevelConfigs,
  }
}

export const useApplicationLocProductConfigStore = () => {
  const isApplicationLocProductConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const applicationLocProductConfig = computed(
    () => sharedState.value.applicationLocProductConfig
  )

  const getApplicationListLocProductLevelConfigs = (params) => {
    isApplicationLocProductConfigLoading.value = true
    return getListLocProductLevelConfigs(store, APIStageEnum.origination, {
      ...params,
      documentPhase: DocumentPhaseEnum.digitalApplication,
    }).finally(() => {
      isApplicationLocProductConfigLoading.value = false
    })
  }
  return {
    isApplicationLocProductConfigLoading,
    applicationLocProductConfig,
    getApplicationListLocProductLevelConfigs,
  }
}

export const useUnderwritingLocProductConfigStore = () => {
  const isUnderwritingLocProductConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const underwritingLocProductConfig = computed(
    () => sharedState.value.underwritingLocProductConfig
  )

  const getUnderwritingListLocProductLevelConfigs = (params) => {
    isUnderwritingLocProductConfigLoading.value = true
    return getListLocProductLevelConfigs(store, APIStageEnum.origination, {
      ...params,
      documentPhase: DocumentPhaseEnum.underwriting,
    }).finally(() => {
      isUnderwritingLocProductConfigLoading.value = false
    })
  }
  return {
    isUnderwritingLocProductConfigLoading,
    underwritingLocProductConfig,
    getUnderwritingListLocProductLevelConfigs,
  }
}

export const useServiceLocProductConfigStore = () => {
  const isServiceLocProductConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const servicingLocProductConfig = computed(
    () => sharedState.value.servicingLocProductConfig
  )

  const collateralDataHubLocProductConfig = computed(
    () => sharedState.value.serviceLocProductConfig
  )

  const smaLocProductConfig = computed(
    () => sharedState.value.serviceLocProductConfig
  )

  const getServiceListLocProductLevelConfigs = (params) => {
    isServiceLocProductConfigLoading.value = true
    return getListLocProductLevelConfigs(store, 'service', params).finally(
      () => {
        isServiceLocProductConfigLoading.value = false
      }
    )
  }
  return {
    isServiceLocProductConfigLoading,
    servicingLocProductConfig,
    collateralDataHubLocProductConfig,
    smaLocProductConfig,
    getServiceListLocProductLevelConfigs,
  }
}

export const useAmendmentLocProductConfigStore = () => {
  const isAmendmentLocProductConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const amendmentLocProductConfig = computed(
    () => sharedState.value.amendmentLocProductConfig
  )

  const getAmendmentListLocProductLevelConfigs = (params) => {
    isAmendmentLocProductConfigLoading.value = true
    return getListLocProductLevelConfigs(store, 'amendment', params).finally(
      () => {
        isAmendmentLocProductConfigLoading.value = false
      }
    )
  }
  return {
    isAmendmentLocProductConfigLoading,
    amendmentLocProductConfig,
    getAmendmentListLocProductLevelConfigs,
  }
}
