function newViewConstraint(constraint) {
  return {
    title: constraint?.title ?? '',
    visible: constraint?.visible ?? false,
    disabled: constraint?.disabled ?? false,
    required: constraint?.required ?? false,
    fieldModuleKey: constraint?.fieldModuleKey ?? '',
  }
}

export function useConstraints() {
  return { newViewConstraint }
}
