import { ref } from 'vue'
import { applicationAPI } from '@/shared/api/application/application'

export const useApplicationData = (stage) => {
  const isApplicationLoading = ref(false)
  const isApplicationUpdating = ref(false)

  /**
   * Updates the application with the given payload data.
   *
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application.
   * @param {Object} payload
   * @param {String} payload.loanRiskRating - The loan risk rating.
   * @param {String} payload.purpose - The purpose of the loan.
   * @param {String} payload.purposeNote - Notes about the purpose.
   * @param {String} payload.typeOfPurpose - The type of purpose.
   * @param {String} payload.externalFacilityCid - External facility customer ID.
   * @param {String} payload.justification - Justification for the request.
   * @param {String} payload.loanFacilityType - The type of loan facility.
   * @param {String} payload.netWorthEstimate - The net worth estimate.
   * @param {Number} payload.requestApprovedAmount - The amount approved for the request.
   * @param {String} payload.yearsWithAdvisor - The number of years with the advisor.
   */
  const updateApplication = async (applicationId, payload) => {
    isApplicationUpdating.value = true
    try {
      return await applicationAPI.updateApplication(
        stage,
        applicationId,
        payload
      )
    } finally {
      isApplicationUpdating.value = false
    }
  }

  /**
   * Update the margin rates for a specific application.
   *
   * @param {number} applicationId - The ID of the application.
   * @param {UpdateApplicationMarginPayload} payload - The margin change request details.
   * @typedef {Object} UpdateApplicationMarginPayload
   * @property {number} marginPercent - The percentage of the margin.
   * @property {number} interestPercentForAdvisor - The interest percentage for the advisor.
   * @property {number} interestPercentForServicer - The interest percentage for the servicer.
   * @property {number} interestPercentForDistributionChannel - The interest percentage for the distribution channel.
   * @property {number} interestPercentForFirm - The interest percentage for the firm.
   * @property {number} floorRate - The minimum rate for the margin.
   * @property {number} ceilingRate - The maximum rate for the margin.
   * @property {number} indexRateId - The ID of the index rate.
   * @property {string} overrideReason - The reason for overriding the current rates.
   */
  const updateApplicationMargin = async (applicationId, payload) => {
    isApplicationUpdating.value = true
    try {
      return await applicationAPI.updateApplicationMargin(
        stage,
        applicationId,
        payload
      )
    } finally {
      isApplicationUpdating.value = false
    }
  }

  /**
   * Cancel an application by updating the status and associated summaries, and sending an event log.
   *
   * @param {Object} params - The parameters for the cancellation of the application.
   * @param {string} params.stage - The stage of the application.
   * @param {string} params.applicationId - The ID of the application to cancel.
   * @returns {Promise<ApplicationCancellationResponse>} A promise that resolves with the cancellation response.
   */
  const updateApplicationCancellation = async (applicationId) => {
    isApplicationUpdating.value = true
    try {
      return await applicationAPI.updateApplicationCancellation(stage)
    } finally {
      isApplicationUpdating.value = false
    }
  }

  /**
   * Submits an application request to update the status of an application.
   *
   * @param {string} applicationId - The ID of the application.
   */
  const createApplicationSubmission = async (applicationId) => {
    isApplicationUpdating.value = true
    try {
      return await applicationAPI.createApplicationSubmission(
        stage,
        applicationId
      )
    } finally {
      isApplicationUpdating.value = false
    }
  }

  /**
   * Deletes an application by stage and application ID.
   *
   * @param {string} applicationId - The unique identifier of the application.
   * @returns {Promise<void>} A promise that resolves when the application is deleted.
   * @APICode {stage}.applications.delete
   */
  const deleteApplication = async (applicationId) => {
    isApplicationLoading.value = true
    try {
      await applicationAPI.deleteApplication(stage, applicationId)
    } finally {
      isApplicationLoading.value = false
    }
  }

  /**
   * Fetches the details of a specific application by its unique identifier.
   *
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The unique identifier of the application.
   * @property {string} stage - The stage of the application to fetch.
   * @property {string} applicationId - The unique identifier for the application.
   */
  const isApplicationDetailGetting = ref(false)
  const applicationDetails = ref({})
  const getApplicationDetails = async (stage, applicationId) => {
    isApplicationDetailGetting.value = true
    try {
      applicationDetails.value = await applicationAPI.getApplicationDetails(
        stage,
        applicationId
      )
    } finally {
      isApplicationDetailGetting.value = false
    }
  }

  const isApplicationDataReadinessLoading = ref(false)
  const applicationDataReadiness = ref([])

  /**
   * Fetches the data readiness of an application.
   *
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The unique identifier of the application.
   * @param {GetDataReadinessParams} [params] - The parameters for the data readiness check.
   * @returns {Promise<DataReadinessResponse[]>} - A promise that resolves with the data readiness information.
   */
  const getApplicationDataReadiness = async (
    stage,
    applicationId,
    params = {}
  ) => {
    isApplicationDataReadinessLoading.value = true
    try {
      applicationDataReadiness.value =
        await applicationAPI.getApplicationDataReadiness(
          stage,
          applicationId,
          params
        )
    } finally {
      isApplicationDataReadinessLoading.value = false
    }
  }

  const isApplicationAssistingUserLoading = ref(false)
  const assistingUsers = ref([])

  /**
   * Fetches a list of assisting users related to a specific application.
   *
   * @param {string} stage - The stage of the application.
   * @param {string} applicationId - The ID of the application.
   * @property {string} universe - The universe of the application.
   * @property {string} stage - The stage of the application.
   * @property {string} applicationId - The ID of the application to retrieve assisting users for.
   */
  const getAssistingUsers = async (stage, applicationId) => {
    isApplicationAssistingUserLoading.value = true
    try {
      assistingUsers.value = await applicationAPI.getAssistingUsers(
        stage,
        applicationId
      )
    } finally {
      isApplicationAssistingUserLoading.value = false
    }
  }

  /**
   * Retrieves the available operations for a given application.
   *
   * @param {string} universe - The universe identifier.
   * @param {string} stage - The stage identifier.
   * @param {string} applicationId - The application identifier.
   * @returns {Promise<OperationRespDTO[]>} - A promise that resolves with the list of available operations.
   */

  const isApplicationOperationsLoading = ref(false)
  const applicationOperations = ref([])
  const getAvailableOperations = async (stage, applicationId) => {
    isApplicationOperationsLoading.value = true
    try {
      applicationOperations.value = await applicationAPI.getAvailableOperations(
        stage,
        applicationId
      )
    } finally {
      isApplicationOperationsLoading.value = false
    }
  }

  return {
    isApplicationLoading,
    isApplicationUpdating,
    updateApplicationMargin,
    updateApplication,
    updateApplicationCancellation,
    createApplicationSubmission,
    deleteApplication,

    applicationDetails,
    getApplicationDetails,
    isApplicationDetailGetting,

    isApplicationDataReadinessLoading,
    applicationDataReadiness,
    getApplicationDataReadiness,

    isApplicationAssistingUserLoading,
    assistingUsers,
    getAssistingUsers,

    isApplicationOperationsLoading,
    applicationOperations,
    getAvailableOperations,
  }
}
