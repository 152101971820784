import { API_VERSION, http } from '@/shared/api/http.js'
export const underwritingAPI = {
  /**
   * List underwritings based on various filters.
   * @param {string} stage - The stage of underwritings to list.
   * @param {Object} queryParams - The query parameters for listing underwritings.
   * @param {number} queryParams.pageSize - The number of items per page.
   * @param {number} queryParams.pageNumber - The current page number.
   * @param {string} [queryParams.searchTerm] - The search term to filter underwritings.
   * @param {string} [queryParams.sort] - The sort order for listing underwritings.
   * @param {Filters} [queryParams.filters] - The filters to apply to the underwriting list.

   * @APICode {stage}.underwritings.list
   */
  getAmendmentUnderwritingList(stage, params) {
    return http.get(`${API_VERSION}/${stage}/underwritings`, params)
  },
  /**
   * Get statistics related to underwriting processes, grouped by different statuses.
   * @param {string} stage - The stage of the underwriting process.
   * @APICode stage.underwritings.statistics.get
   */
  getUnderwritingStatistics(stage) {
    return http.get(`${API_VERSION}/${stage}/underwritings/statistics`)
  },

  /**
   * Exports underwriting records data to an Excel file.
   * @param {string} stage - The stage of the underwriting records to be exported.
   * @param {ExportParams} params - The parameters for the export operation.
   * @returns {Promise<UnderwritingExportResp>} - A promise that resolves with the exported data.
   * @APICode {stage}.underwritings.exports.get
   */
  listUnderwritingExport(stage, params) {
    return http.download(
      `${API_VERSION}/${stage}/underwritings/exports`,
      params
    )
  },
  /**
   * Retrieve the filter ranges for underwriting.
   * @param {string} stage - The stage of the underwriting process.
   * @property {String[]} [statuses] - List of underwriting statuses.
   * @property {String[]} [subStatuses] - List of underwriting sub-statuses.
   * @property {WorkingGroupDTO[]} workingGroups - List of working groups.
   * @property {String} id - The ID of the working group.
   * @property {String} name - The name of the working group.
   * @APICode {stage}.underwritings.borders.get
   */
  getUnderwritingBorders(stage, params) {
    return http.get(`${API_VERSION}/${stage}/underwritings/borders`, params)
  },
  /**
   * Retrieves a list of users and their respective working groups that can be assigned to an application process.
   * @param {string} stage - The stage of the application process.
   * @param {number} underwritingId - The ID of the underwriting process.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.assignType] - The type of assignment (ESCALATE/ASSIGN_TO_OTHERS).
   * @returns {Promise<AssignableUsersResponse>} - A promise that resolves with the list of assignable users and working groups.
   * @APICode {stage}.underwritings.assignable-users.get
   */
  getAssignableUsers(stage, underwritingId, options = {}) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/assignable-users`,
      options
    )
  },

  /**
   * Assign a user and a working group to an underwriting task.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting to assign.
   * @returns {Promise<Object>} - A promise that resolves when the assignment is successful.
   * @typedef {Object} assignReviewer
   * @property {string} reviewerUserProfileId - The ID of the reviewer user profile.
   * @property {string} workingGroupId - The ID of the working group.
   * @APICode {stage}.underwritings.assignees.update
   */
  updateAssignUnderwritingReviewer(stage, underwritingId, assignReviewer) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/assignees`,
      assignReviewer
    )
  },
  /**
   * Cancel the underwriting request by updating the status to 'CANCEL' and log the cancellation event.
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} underwritingId - The ID of the underwriting to cancel.
   * @APICode {stage}.underwritings.cancellations.update
   */
  cancelUnderwritingRequest(stage, underwritingId, data) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/cancellations`,
      data
    )
  },
  /**
   * Update the approved amount of an underwriting.
   * @param {string} stage - The stage of the underwriting.
   * @param {string} underwritingId - The ID of the underwriting.
   * @APICode {stage}.underwritings.approved-amount-change-requests.update
   */
  updateUnderwritingApprovedAmount(stage, underwritingId, data) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/approved-amount-change-requests`,
      data
    )
  },

  /**
   * Update the margin rates for a specific underwriting.
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} underwritingId - The ID of the underwriting.
   * @param {UpdateMarginRequest} marginRequest - The margin change request details.
   * @returns {Promise<Object>} - A promise that resolves when the margin rates are updated.
   * @typedef {Object} UpdateMarginRequest
   * @property {number} marginPercent - The percentage of the margin rate.
   * @property {number} interestPercentForAdvisor - The interest percentage for advisors.
   * @property {number} interestPercentForServicer - The interest percentage for servicers.
   * @property {number} interestPercentForDistributionChannel - The interest percentage for distribution channels.
   * @property {number} interestPercentForFirm - The interest percentage for the firm.
   * @property {number} floorRate - The minimum rate that can be set.
   * @property {number} ceilingRate - The maximum rate that can be set.
   * @property {number} indexRateId - The ID of the index rate.
   * @property {string} overrideReason - The reason for overriding the current rates.
   * @APICode {stage}.underwritings.margin-change-requests.update
   */
  updateUnderwritingMargin(stage, underwritingId, marginRequest) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/margin-change-requests`,
      marginRequest
    )
  },
  /**
   * Retrieves a list of amendment underwriting review pages based on the underwritingId.
   *
   * @param {string} stage - The stage of the API environment.
   * @param {string} underwritingId - The unique identifier for the underwriting.
   * @returns {Promise<Object>} A promise that resolves with the list of amendment underwriting review pages.
   * @apiCode {stage}.underwritings.page-reviews.get
   */
  getListAmendmentUnderwritingReviewPages(stage, underwritingId) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/page-reviews`
    )
  },
  getAssignees(applicationId, operateCode) {
    return http.get(
      `${API_VERSION}/underwriting/${applicationId}/assign-user?operateCode=${operateCode}`
    )
  },
  assignToOther({ applicationId, ...params }) {
    return http.post(
      `${API_VERSION}/underwriting/${applicationId}/assign-to-other`,
      params
    )
  },
  escalate({ applicationId, ...params }) {
    return http.post(
      `${API_VERSION}/underwriting/${applicationId}/escalate`,
      params
    )
  },

  /**
   * Get a list of underwriting tasks based on various filters.
   * @param {UnderwritingListParams} params - The parameters to filter underwriting tasks.
   * @returns {Promise<UnderwritingList>} - A promise that resolves with the underwriting list data.
   * @typedef {Object} UnderwritingListParams
   * @property {Boolean} [unassignedTasks] - To query data that not assigned.
   * @property {Boolean} [individualTasks] - To query data that assigned to individuals.
   * @property {Boolean} [allWorkingGroupTasks] - To query data that assigned to groups.
   * @property {Boolean} [myTask] - To query data that assigned to me.
   * @property {Boolean} [myWorkingGroupTasks] - To query data that assigned to my working group.
   * @typedef {Object} UnderwritingList
   * @property {Array.<UnderwritingTask>} items - The list of underwriting tasks.
   * @property {Number} pageNumber - The current page number.
   * @property {Number} pageSize - The size of each page.
   * @property {Number} totalItems - The total number of items.
   * @property {Number} totalPages - The total number of pages.
   * @typedef {Object} UnderwritingTask
   * @property {String} applicationId - The ID of the application.
   * @property {String} [callCode] - The call code.
   * @property {String} distributionChannelInstitutionId - The institution ID of the distribution channel.
   * @property {String} distributionChannelShortName - The short name of the distribution channel.
   * @property {String} applicationName - The name of the application.
   * @property {String} applicationNumber - The number of the application.
   * @property {String} personEntityNames - Names of the entities involved.
   * @property {String} primaryAdvisorName - The name of the primary advisor.
   * @property {Array.<Label>} labels - The labels associated with the task.
   * @property {String} [rushReason] - The reason for rush processing, if any.
   * @property {String} status - The status of the application.
   * @property {String} statusCode - The code representing the status.
   * @property {String} subStatus - The sub-status of the application.
   * @property {String} subStatusCode - The code representing the sub-status.
   * @property {String} [signStatus] - The sign status.
   * @property {String} [signStatusCode] - The code representing the sign status.
   * @property {Number} progressPercent - The percentage of progress.
   * @property {String} requestedLocAmount - The requested line of credit amount.
   * @property {Number} marketValue - The market value.
   * @property {Number} lendingValue - The lending value.
   * @property {Number} createdAt - The timestamp when the task was created.
   * @property {String} createdDate - The date when the task was created.
   * @property {String} [cancelReason] - The reason for cancellation, if any.
   * @property {Number} [age] - The age.
   * @property {Number} clientRepCodeId - The client representative code ID.
   * @property {String} clientRepCode - The client representative code.
   * @property {String} clientRepCodeName - The name of the client representative code.
   * @property {Array.<Number>} [watcherUserIds] - The user IDs of watchers.
   * @property {Boolean} [autoApprove] - Whether the task is auto-approved.
   * @property {String} primaryUnderwriterName - The name of the primary underwriter.
   * @property {String} primaryUnderwriterProfileId - The profile ID of the primary underwriter.
   * @property {String} custodianAccountNumbers - The custodian account numbers.
   * @property {String} riskLevel - The risk level.
   * @property {String} [referralId] - The referral ID, if any.
   * @property {Boolean} enableReactivate - Whether reactivation is enabled.
   * @property {String} completedPageNames - The names of completed pages.
   * @property {String} term - The term.
   * @property {Boolean} enableResumeNotice - Whether resume notice is enabled.
   * @property {String} noticeExpiredLeftDays - The number of days left before notice expiration.
   * @property {Number} creditAge - The credit age.
   * @property {String} locNumber - The line of credit number.
   * @property {String} loanNumber - The loan number.
   * @property {Number} lastModifiedAt - The timestamp of last modification.
   * @property {Number} [finishedAt] - The timestamp when finished, if any.
   * @property {String} [exceptionStatus] - The exception status, if any.
   * @property {String} recommendedLoanRiskRating - The recommended loan risk rating.
   * @property {String} [overrideLoanRiskRating] - The overridden loan risk rating, if any.
   * @property {Number} [netWorthEstimate] - The estimated net worth.
   * @property {Number} [yearsWithAdvisor] - The number of years with the advisor.
   * @property {Number} uwSignedStartedAt - The timestamp when underwriter signing started.
   * @property {Number} uwSignedEndedAt - The timestamp when underwriter signing ended.
   * @property {String} collateralAccountTypes - The types of collateral accounts.
   * @property {Array.<String>} assetTypes - The types of assets.
   * @property {String} custodianNames - The names of custodians.
   * @property {String} siCustodianNames - The names of SI custodians.
   * @property {String} signingMethods - The methods of signing.
   * @property {Number} signingExpiresOn - The timestamp when signing expires.
   * @property {String} pledgeStatus - The status of the pledge.
   * @property {Number} [declinedAt] - The timestamp when declined, if any.
   * @property {Number} approvedLocAmount - The approved line of credit amount.
   * @property {String} relationshipManagerName - The name of the relationship manager.
   * @property {String} workingGroupId - The ID of the working group.
   * @property {String} workingGroupName - The name of the working group.
   * @property {Number} durationOfApplication - The duration of the application.
   * @property {String} creditApprover - The name of the credit approver.
   * @property {String} liveLineApprover - The name of the live line approver.
   * @property {String} creatorUserName - The username of the creator.
   * @property {String} recommendedBorrowerRiskRating - The recommended borrower risk rating.
   * @property {String} [overrideBorrowerRiskRating] - The overridden borrower risk rating, if any.
   * @property {String} [loanPackageStatus] - The status of the loan package, if any.
   * @property {String} [loanPackageStatusCode] - The code representing the loan package status, if any.
   * @property {String} [relatedPeTypes] - The related PE types, if any.
   * @typedef {Object} Label
   * @property {String} labelCode - The code of the label.
   * @property {String} labelName - The name of the label.
   * @APICode underwriting.list.get
   */
  getUnderwritingList(params) {
    return http.post(`${API_VERSION}/underwriting/list`, params)
  },

  getUnderwritingConfig() {
    return http.get(`${API_VERSION}/underwriting/system`)
  },
  getRegBTrackerData(params) {
    return http.get(`${API_VERSION}/underwriting/dash-board/creditAge`, params)
  },
  getUnderwritingDataFields(params) {
    return http.get(`${API_VERSION}/underwriting/data-point`, params)
  },
  getUnderwritingLoanTypeDetail(params) {
    return http.get(`${API_VERSION}/underwriting/dash-board/loanType`, params)
  },
  getUnderwritingCount(params) {
    return http.get(`${API_VERSION}/underwriting/list/count`, params)
  },
  getMyTasksData(params) {
    return http.get(`${API_VERSION}/underwriting/dash-board/my-task`, params)
  },
  /**
   * Retrieves the summary details of an underwriting process using a specified underwriting ID.
   * @param {String} stage - The stage of the underwriting process.
   * @param {Number} underwritingId - The ID of the underwriting process.
   * @returns {Promise<UnderwritingSummary>} A promise that resolves with the summary details of the underwriting process.
   * @APICode {stage}.underwritings.get
   */
  getUnderwritingSummary(stage, underwritingId) {
    return http.get(`${API_VERSION}/${stage}/underwritings/${underwritingId}`)
  },

  /**
   * Resets the underwriting process for a specific underwriting ID.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting to reset.
   * @returns {Promise<Object>} - A promise that resolves when the underwriting reset is successful.
   * @APICode {stage}.underwritings.resets.update
   */
  replaceUnderwritingReset(stage, underwritingId) {
    return http.put(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/resets`
    )
  },

  /**
   * Updates the status of a page review in the underwriting process.
   *
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} applicationId - The ID of the application.
   * @param {UpdateReviewStatusReqDTO} reviewStatus - The review status details.
   * @returns {Promise<void>} A promise that resolves when the status is updated.
   * @typedef {Object} UpdateReviewStatusReqDTO
   * @property {string} pageName - The name of the page to update.
   * @property {string} status - The new status of the page review.
   * @APICode {stage}.underwriting.page-reviews.patch
   */
  updateUnderwritingPageReviewStatus(stage, applicationId, params) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${applicationId}/page-reviews/${params.pageReviewId}`,
      params
    )
  },

  /**
   * Generates a PDF document for loan modifications.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @returns {Promise<Object>} - A promise that resolves with the generated PDF document.
   * @APICode {stage}.underwirtings.loan-modifications.pdf-generations.get
   */
  getLoanModificationsPDF(stage, underwritingId) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/loan-modifications/pdf-generations`
    )
  },
  /**
   * Retrieves various risk rate options based on the underwriting application ID.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting application.
   * @returns {Promise<UnderwritingRiskRateOptions>} - A promise that resolves with the risk rate options.
   * @typedef {Object} UnderwritingRiskRateOptions
   * @property {String[]} loanRiskRatingOptions - Array of loan risk rating options.
   * @property {String[]} borrowerRiskRatingOptions - Array of borrower risk rating options.
   * @property {Boolean} brrDisplayAllEntity - Indicates if all entities are displayed for BRR.
   * @property {Boolean} supportBrr - Indicates if BRR is supported.
   * @APICode {stage}.underwritings.risk-rate-options.get
   */
  getRiskRateOptions(stage, underwritingId) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/risk-rate-options`
    )
  },
  /* Assign a user and a working group to an underwriting task.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {AssignReviewerReqDTO} params - The request payload to assign the reviewer.
   * @returns {Promise<UnderwritingAssigneeResponse>} - A promise that resolves with the result of the assignment operation.
   * @APICode {stage}.underwritings.assignees.update
   */
  updateUnderwritingAssignee(stage, underwritingId, params) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/assignees`,
      params
    )
  },

  /**
   * Updates the final approval process for an underwriting.
   * @param {string} stage - The stage of the underwriting.
   * @param {string} underwritingId - The ID of the underwriting.
   * @returns {Promise<string>} - A promise that resolves with the success message of the final approval process.
   * @APICode {stage}.underwritings.final-approvals.update
   */
  replaceFinalApprovals(stage, underwritingId) {
    return http.put(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/final-approvals`
    )
  },

  /**
   * Approves the credit for an underwriting amendment.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting to approve credit for.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   * @APICode stage.underwritings.credit-approvals.post
   */
  createCreditApproval(stage, underwritingId) {
    return http.post(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/credit-approvals`
    )
  },

  /**
   * Updates amendment requests by initializing tasks, updating status, and sending notifications.
   * @param {string} stage - The stage of the amendment process.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {AmendmentReturnedStatusReqVO} data - The amendment return status request data.
   * @returns {Promise<void>} - A promise that resolves when the update is successful.
   * @APICode {stage}.underwritings.return-to-requests.update
   */
  returnToRequests(stage, underwritingId, data) {
    return http.put(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/return-to-requests`,
      data
    )
  },
  /**
   * Updates the application decline status and handles associated processes.
   *
   * @param {String} stage - The stage of the underwriting process.
   * @param {Number} underwritingId - The unique identifier of the underwriting.
   * @param {params} params - The decline preview request data transfer object.
   * @returns {Promise<DeclineApplicationResponse>} - A promise that resolves with the result of the decline operation.
   * @APICode {stage}.underwritings.declines.update
   */
  updateDeclineStatus(stage, underwritingId, params) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/declines`,
      params
    )
  },
  /**
   * Generates a decline preview based on the underwritingId.
   * @param {String} stage - The stage of the underwriting process.
   * @param {Number} underwritingId - The ID of the underwriting.
   * @param {PartyDeclineReasonReqDTO} payload - The request payload containing the decline reasons.
   * @returns {Promise<DeclinePreviewResponse>} - A promise that resolves with the decline preview data.
   * @APICode {stage}.underwritings.decline-previews.create
   */
  createDeclinePreview(stage, underwritingId, payload) {
    return http.post(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/decline-previews`,
      payload,
      {
        responseType: 'blob',
      }
    )
  },

  /**
   * Retrieves the available operations for a given underwriting based on its status and stage.
   * @param {string} stage - The stage of the underwriting.
   * @param {string} underwritingId - The ID of the underwriting.
   * @param {string} pageCode - The code of the page.
   * @returns {Promise<OperationRespVO[]>} - A promise that resolves with the list of available operations.
   * @APICode stage.underwritings.available-operations.get
   */
  getAvailableOperations(stage, underwritingId, params) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/available-operations`,
      params
    )
  },

  /**
   * List all covenants associated with an underwriting stage and ID.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting to list covenants for.
   * @returns {Promise<ListCovenantResponse>} - A promise that resolves with the list of covenants.
   * @typedef {Object} ListCovenantResponse
   * @property {CovenantDTO[]} covenants - An array of covenants.
   * @typedef {Object} CovenantDTO
   * @property {string} covenantCode - The code of the covenant.
   * @property {string} covenantRule - The rule of the covenant.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/DEVPT-uw-hershey/api/underwriting/listCovenants.md
   * @APICode stage.underwritings.covenants.list
   */
  getListCovenant(stage, underwritingId) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/covenants`
    )
  },
  /**
   * Replaces existing covenants for a given underwriting stage and ID with new ones.
   * @param {string} stage - The underwriting stage.
   * @param {string} underwritingId - The underwriting ID.
   * @param {UpdateCovenantRequest} updateCovenantRequest - The request payload containing new covenants.
   * @returns {Promise<Object>} - A promise that resolves when the covenants are updated.
   * @typedef {Object} UpdateCovenantRequest
   * @property {CovenantDTO[]} covenant - List of covenant data transfer objects.
   * @typedef {Object} CovenantDTO
   * @property {string} covenantCode - The code of the covenant.
   * @property {string} covenantRule - The rule of the covenant.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/DEVPT-uw-hershey/api/underwriting/updateCovenants.md
   * @APICode {stage}.underwritings.covenants.update
   */
  updateCovenant(stage, underwritingId, updateCovenantRequest) {
    return http.put(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/covenants`,
      updateCovenantRequest
    )
  },
  /**
   * Retrieves risk points for underwritings in a finished state, filtered by the page code.
   * @param {string} stage - The stage of the underwriting process.
   * @param {number} underwritingId - The ID of the underwriting.
   * @param {Object} params - The query parameters for the request.
   * @param {string} params.pageCode - The code of the page to filter the risk points.
   * @returns {Promise<CommonResponsePage>} - A promise that resolves with the risk points data.
   * @APICode stage.underwritings.risk-points.get
   */
  getRiskPoints(stage, underwritingId, { pageCode }) {
    return http.get(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/risk-points`,
      { pageCode }
    )
  },

  /**
   * Resets the underwriting process for an application by applicationId.
   * @param {string} stage - The stage of the underwriting process.
   * @param {string} underwritingId - The ID of the underwriting to reset.
   * @returns {Promise<void>} A promise that resolves when the reset is successful.
   * @APISpecUrl https://github.com/starlight-bd/internaldocs/blob/api_center_dev_branch_sunny/api/underwriting/updateUnderwritingReset.md
   * @APICode v5.underwritings.resets.patch
   */
  updateUnderwritingReset(stage, underwritingId) {
    return http.patch(
      `${API_VERSION}/${stage}/underwritings/${underwritingId}/resets`
    )
  },
}
