import { http, API_VERSION } from './http'

const CUSTOMERS_PROFILE_URL = `${API_VERSION}/customer-profile`

export const customerProfileAPI = {
  /**
   * Updates a customer user's information by their user ID.
   * @param {number} profileId - The ID of the user.
   *   @param {UserCreateParam} update -Param The object containing the user's updated information.
   * @returns {Promise<UserHasProfilesVO>} A promise that resolves with the updated user's information.
   */
  updateCustomerProfile(profileId, updateParam) {
    return http.patch(`${CUSTOMERS_PROFILE_URL}/${profileId}`, updateParam)
  },
}
