export const PartyTopFieldsModuleEnum = {
  service: 'service',
  amendment: 'amendment',
}

const servicePartyDataPointMaps = {
  // 1.1 line portal data point config for individual
  INDIVIDUAL_PARTY: {
    basicDP: 'INDIVIDUAL_PARTY',
    identificationDP: 'INDIVIDUAL_PARTY_IDENTIFICATION',
    employmentDP: 'INDIVIDUAL_PARTY_EMPLOYMENT',
    homeAddressDP: 'INDIVIDUAL_PARTY_HOME_ADDRESS',
    mailingAddressDP: 'INDIVIDUAL_PARTY__MAILING_ADDRESS',
    keyAttributesDP: 'INDIVIDUAL_PARTY__KEY_ATT',
  },
  INDIVIDUAL_PARTY_SINGER: {
    basicDP: 'INDIVIDUAL_PARTY_SINGER',
    identificationDP: 'INDIVIDUAL_PARTY_SINGER_IDENTIFICATION',
    employmentDP: 'INDIVIDUAL_PARTY_SINGER_EMPLOYMENT',
    homeAddressDP: 'INDIVIDUAL_PARTY_SINGER_HOME_ADDRESS',
    mailingAddressDP: 'INDIVIDUAL_PARTY_SINGER_MAILING_ADDRESS',
  },
  BBO_INDIVIDUAL: {
    basicDP: 'BBO_INDIVIDUAL',
    identificationDP: 'BBOI_IDENTIFICATION',
    employmentDP: 'BBOI_EMPLOYMENT',
    homeAddressDP: 'BBOI_HOME_ADDRESS',
    mailingAddressDP: 'BBOI_MAILING_ADDRESS',
    keyAttributesDP: 'BBO_INDIVIDUAL.KEY_ATT',
  },
  BBO_BUSINESS_AUTH_INDIVIDUAL: {
    basicDP: 'BBO_BUSINESS_AUTH_INDIVIDUAL',
    identificationDP: 'BBO_BUSINESS_AUTH_INDIVIDUAL_IDENTIFICATION',
    employmentDP: 'BBO_BUSINESS_AUTH_INDIVIDUAL_EMPLOYMENT',
    homeAddressDP: 'BBO_BUSINESS_AUTH_INDIVIDUAL_HOME_ADDRESS',
    mailingAddressDP: 'BBO_BUSINESS_AUTH_INDIVIDUAL_MAILING_ADDRESS',
    keyAttributesDP: 'BBO_BUSINESS_AUTH_INDIVIDUAL_KEY_ATT',
  },
  BBO_TRUST_INDIVIDUAL: {
    basicDP: 'BBO_TRUST_INDIVIDUAL',
    identificationDP: 'BBO_TRUST_INDIVIDUAL_IDENTIFICATION',
    employmentDP: 'BBO_TRUST_INDIVIDUAL_EMPLOYMENT',
    homeAddressDP: 'BBO_TRUST_INDIVIDUAL_HOME_ADDRESS',
    mailingAddressDP: 'BBO_TRUST_INDIVIDUAL_MAILING_ADDRESS',
    keyAttributesDP: 'BBO_TRUST_INDIVIDUAL_KEY_ATT',
  },
  BUSINESS_BORROWER_AUTHORIZED_INDIVIDUAL: {
    basicDP: 'BUSINESS_BORROWER_AUTHORIZED_INDIVIDUAL',
    identificationDP: 'BBAI_IDENTIFICATION',
    employmentDP: 'BBAI_EMPLOYMENT',
    homeAddressDP: 'BBAI_HOME_ADDRESS',
    mailingAddressDP: 'BBAI_MAILING_ADDRESS',
    keyAttributesDP: 'BUSINESS_BORROWER_AUTHORIZED_INDIVIDUAL.KEY_ATT',
  },
  BUSINESS_CONTROL_PERSON: {
    basicDP: 'BUSINESS_CONTROL_PERSON',
    identificationDP: 'BUSINESS_CONTROL_PERSON_IDENTIFICATION',
    employmentDP: 'BUSINESS_CONTROL_PERSON_EMPLOYMENT',
    homeAddressDP: 'BUSINESS_CONTROL_PERSON_HOME_ADDRESS',
    mailingAddressDP: 'BUSINESS_CONTROL_PERSON_MAILING_ADDRESS',
    keyAttributesDP: 'BUSINESS_CONTROL_PERSON.KEY_ATT',
  },
  TBI_INDIVIDUAL_GRANTOR: {
    basicDP: 'TBI_INDIVIDUAL_GRANTOR',
    identificationDP: 'TBIIG_IDENTIFICATION',
    employmentDP: 'TBIIG_EMPLOYMENT',
    homeAddressDP: 'TBIIG_HOME_ADDRESS',
    mailingAddressDP: 'TBIIG_MAILING_ADDRESS',
    keyAttributesDP: 'TBI_INDIVIDUAL_GRANTOR.KEY_ATT',
  },
  TBI_TRUST_REVOKER: {
    basicDP: 'TBI_TRUST_REVOKER',
    identificationDP: 'TBIIR_IDENTIFICATION',
    employmentDP: 'TBIIR_EMPLOYMENT',
    homeAddressDP: 'TBIIR_HOME_ADDRESS',
    mailingAddressDP: 'TBIIR_MAILING_ADDRESS',
    keyAttributesDP: 'TBI_TRUST_REVOKER.KEY_ATT',
  },
  TBT_INDIVIDUAL_TRUSTEE: {
    basicDP: 'TBT_INDIVIDUAL_TRUSTEE',
    identificationDP: 'TBTIT_IDENTIFICATION',
    employmentDP: 'TBTIT_EMPLOYMENT',
    homeAddressDP: 'TBTIT_HOME_ADDRESS',
    mailingAddressDP: 'TBTIT_MAILING_ADDRESS',
    keyAttributesDP: 'TBT_INDIVIDUAL_TRUSTEE.KEY_ATT',
  },
  TBTIT_AUTH_SIGNER: {
    basicDP: 'TBTIT_AUTH_SIGNER',
    identificationDP: 'TBTIT_AUTH_SIGNER_IDENTIFICATION',
    employmentDP: 'TBTIT_AUTH_SIGNER_EMPLOYMENT',
    homeAddressDP: 'TBTIT_AUTH_SIGNER_HOME_ADDRESS',
    mailingAddressDP: 'TBTIT_AUTH_SIGNER_MAILING_ADDRESS',
    keyAttributesDP: 'TBTIT_AUTH_SIGNER_KEY_ATT',
  },
  TBTCT_RELATIONSHIP_MANAGER: {
    basicDP: 'TBTCT_RELATIONSHIP_MANAGER',
    identificationDP: 'TBTCTRM_IDENTIFICATION',
    employmentDP: 'TBTCTRM_EMPLOYMENT',
    homeAddressDP: 'TBTCTRM_HOME_ADDRESS',
    mailingAddressDP: 'TBTCTRM_MAILING_ADDRESS',
    keyAttributesDP: 'TBTCT_RELATIONSHIP_MANAGER.KEY_ATT',
  },
  // 1.2 line portal data point config for business
  BUSINESS_BORROWER_INFORMATION: {
    basicDP: 'BUSINESS_BORROWER_INFORMATION',
    contactAddressDP: 'BBI_CONTACT_ADDRESS',
    keyAttributesDP: 'BUSINESS_BORROWER_INFORMATION.KEY_ATT',
  },
  BBO_BUSINESS: {
    basicDP: 'BBO_BUSINESS',
    keyAttributesDP: 'BBO_BUSINESS.KEY_ATT',
  },
  TBT_CORPORATE_TRUSTEE: {
    basicDP: 'TBT_CORPORATE_TRUSTEE',
    keyAttributesDP: 'TBT_CORPORATE_TRUSTEE.KEY_ATT',
  },
  // 1.3 line portal data point config for trust
  TRUST_BORROWER_INFORMATION: {
    basicDP: 'TRUST_BORROWER_INFORMATION',
    contactAddressDP: 'TBI_CONTACT_ADDRESS',
    keyAttributesDP: 'TRUST_BORROWER_INFORMATION.KEY_ATT',
  },
  BBO_TRUST: {
    basicDP: 'BBO_TRUST',
    keyAttributesDP: 'BBO_TRUST.KEY_ATT',
  },
  BBO_TRUST_CORPORATE: {
    basicDP: 'BBO_TRUST_CORPORATE',
    keyAttributesDP: 'BBO_TRUST_CORPORATE_KEY_ATT',
  },
  // 2.1 amendment portal data point config for individual
  AMENDMENT_INDIVIDUAL_PARTY: {
    basicDP: 'AMENDMENT_INDIVIDUAL_PARTY',
    identificationDP: 'AMENDMENT_INDIVIDUAL_PARTY_IDENTIFICATION',
    employmentDP: 'AMENDMENT_INDIVIDUAL_PARTY_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_INDIVIDUAL_PARTY_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_INDIVIDUAL_PARTY_MAILING_ADDRESS',
    keyAttributesDP: 'A_INDIVIDUAL_BORROWER.KEY_ATT',
  },
  AMENDMENT_INDIVIDUAL_PARTY_SINGER: {
    basicDP: 'AMENDMENT_INDIVIDUAL_PARTY_SINGER',
    identificationDP: 'AMENDMENT_INDIVIDUAL_PARTY_SINGER_IDENTIFICATION',
    employmentDP: 'AMENDMENT_INDIVIDUAL_PARTY_SINGER_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_INDIVIDUAL_PARTY_SINGER_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_INDIVIDUAL_PARTY_SINGER_MAILING_ADDRESS',
  },
  AMENDMENT_BBO_INDIVIDUAL: {
    basicDP: 'AMENDMENT_BBO_INDIVIDUAL',
    identificationDP: 'AMENDMENT_BBOI_IDENTIFICATION',
    employmentDP: 'AMENDMENT_BBOI_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_BBOI_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_BBOI_MAILING_ADDRESS',
    keyAttributesDP: 'A_BBO_INDIVIDUAL.KEY_ATT',
  },
  A_BBO_BUSINESS_AUTH_INDIVIDUAL: {
    basicDP: 'A_BBO_BUSINESS_AUTH_INDIVIDUAL',
    identificationDP: 'A_BBO_BUSINESS_AUTH_INDIVIDUAL_IDENTIFICATION',
    employmentDP: 'A_BBO_BUSINESS_AUTH_INDIVIDUAL_EMPLOYMENT',
    homeAddressDP: 'A_BBO_BUSINESS_AUTH_INDIVIDUAL_HOME_ADDRESS',
    mailingAddressDP: 'A_BBO_BUSINESS_AUTH_INDIVIDUAL_MAILING_ADDRESS',
    keyAttributesDP: 'A_BBO_BUSINESS_AUTH_INDIVIDUAL_KEY_ATT',
  },
  A_BBO_TRUST_INDIVIDUAL: {
    basicDP: 'A_BBO_TRUST_INDIVIDUAL',
    identificationDP: 'A_BBO_TRUST_INDIVIDUAL_IDENTIFICATION',
    employmentDP: 'A_BBO_TRUST_INDIVIDUAL_EMPLOYMENT',
    homeAddressDP: 'A_BBO_TRUST_INDIVIDUAL_HOME_ADDRESS',
    mailingAddressDP: 'A_BBO_TRUST_INDIVIDUAL_MAILING_ADDRESS',
    keyAttributesDP: 'A_BBO_TRUST_INDIVIDUAL_KEY_ATT',
  },
  AMENDMENT_BUSINESS_BORROWER_AUTHORIZED_INDIVIDUAL: {
    basicDP: 'AMENDMENT_BUSINESS_BORROWER_AUTHORIZED_INDIVIDUAL',
    identificationDP: 'AMENDMENT_BBAI_IDENTIFICATION',
    employmentDP: 'AMENDMENT_BBAI_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_BBAI_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_BBAI_MAILING_ADDRESS',
    keyAttributesDP: 'A_BUSINESS_BORROWER_AUTHORIZED_INDIVIDUAL.KEY_ATT',
  },
  AMENDMENT_TBI_INDIVIDUAL_GRANTOR: {
    basicDP: 'AMENDMENT_TBI_INDIVIDUAL_GRANTOR',
    identificationDP: 'AMENDMENT_TBIIG_IDENTIFICATION',
    employmentDP: 'AMENDMENT_TBIIG_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_TBIIG_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_TBIIG_MAILING_ADDRESS',
    keyAttributesDP: 'A_TBI_INDIVIDUAL_GRANTOR.KEY_ATT',
  },
  AMENDMENT_TBI_TRUST_REVOKER: {
    basicDP: 'AMENDMENT_TBI_TRUST_REVOKER',
    identificationDP: 'AMENDMENT_TBIIR_IDENTIFICATION',
    employmentDP: 'AMENDMENT_TBIIR_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_TBIIR_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_TBIIR_MAILING_ADDRESS',
    keyAttributesDP: 'A_TBI_TRUST_REVOKER.KEY_ATT',
  },
  AMENDMENT_TBT_INDIVIDUAL_TRUSTEE: {
    basicDP: 'AMENDMENT_TBT_INDIVIDUAL_TRUSTEE',
    identificationDP: 'AMENDMENT_TBTIT_IDENTIFICATION',
    employmentDP: 'AMENDMENT_TBTIT_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_TBTIT_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_TBTIT_MAILING_ADDRESS',
    keyAttributesDP: 'A_TBT_INDIVIDUAL_TRUSTEE.KEY_ATT',
  },
  A_TBTIT_AUTH_SIGNER: {
    basicDP: 'A_TBTIT_AUTH_SIGNER',
    identificationDP: 'A_TBTIT_AUTH_SIGNER_IDENTIFICATION',
    employmentDP: 'A_TBTIT_AUTH_SIGNER_EMPLOYMENT',
    homeAddressDP: 'A_TBTIT_AUTH_SIGNER_HOME_ADDRESS',
    mailingAddressDP: 'A_TBTIT_AUTH_SIGNER_MAILING_ADDRESS',
    keyAttributesDP: 'A_TBTIT_AUTH_SIGNER_KEY_ATT',
  },
  AMENDMENT_BUSINESS_CONTROL_PERSON: {
    basicDP: 'AMENDMENT_BUSINESS_CONTROL_PERSON',
    identificationDP: 'AMENDMENT_BUSINESS_CONTROL_PERSON_IDENTIFICATION',
    employmentDP: 'AMENDMENT_BUSINESS_CONTROL_PERSON_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_BUSINESS_CONTROL_PERSON_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_BUSINESS_CONTROL_PERSON_MAILING_ADDRESS',
    keyAttributesDP: 'A_BUSINESS_CONTROL_PERSON.KEY_ATT',
  },
  AMENDMENT_TBTCT_RELATIONSHIP_MANAGER: {
    basicDP: 'AMENDMENT_TBTCT_RELATIONSHIP_MANAGER',
    identificationDP: 'AMENDMENT_TBTCTRM_IDENTIFICATION',
    employmentDP: 'AMENDMENT_TBTCTRM_EMPLOYMENT',
    homeAddressDP: 'AMENDMENT_TBTCTRM_HOME_ADDRESS',
    mailingAddressDP: 'AMENDMENT_TBTCTRM_MAILING_ADDRESS',
    keyAttributesDP: 'A_TBTCT_RELATIONSHIP_MANAGER.KEY_ATT',
  },
  // 2.2 amendment portal data point config for business
  AMENDMENT_BUSINESS_BORROWER_INFORMATION: {
    basicDP: 'AMENDMENT_BUSINESS_BORROWER_INFORMATION',
    contactAddressDP: 'AMENDMENT_BBI_CONTACT_ADDRESS',
    keyAttributesDP: 'A_BUSINESS_BORROWER_INFORMATION.KEY_ATT',
  },
  AMENDMENT_BBO_BUSINESS: {
    basicDP: 'AMENDMENT_BBO_BUSINESS',
    keyAttributesDP: 'A_BBO_BUSINESS.KEY_ATT',
  },
  A_BBO_TRUST_CORPORATE: {
    basicDP: 'A_BBO_TRUST_CORPORATE',
    keyAttributesDP: 'A_BBO_TRUST_CORPORATE_KEY_ATT',
  },
  AMENDMENT_TBT_CORPORATE_TRUSTEE: {
    basicDP: 'AMENDMENT_TBT_CORPORATE_TRUSTEE',
    keyAttributesDP: 'A_TBT_CORPORATE_TRUSTEE.KEY_ATT',
  },
  // 2.3 amendment portal data point config for trust
  AMENDMENT_TRUST_BORROWER_INFORMATION: {
    basicDP: 'AMENDMENT_TRUST_BORROWER_INFORMATION',
    contactAddressDP: 'AMENDMENT_TBI_CONTACT_ADDRESS',
    keyAttributesDP: 'A_TRUST_BORROWER_INFORMATION.KEY_ATT',
  },
  AMENDMENT_BBO_TRUST: {
    basicDP: 'AMENDMENT_BBO_TRUST',
    keyAttributesDP: 'A_BBO_TRUST.KEY_ATT',
  },
}

function getAllKeyAttributeKeys() {
  return Object.values(servicePartyDataPointMaps)
    .map((item) => item.keyAttributesDP)
    .filter((item) => item)
}

export const usePartyFieldModuleKeys = () => {
  return {
    servicePartyDataPointMaps,
    getAllKeyAttributeKeys,
  }
}
