import { http, API_VERSION } from '@/shared/api/http.js'

/**
 * @typedef {Object} CreditExceptionReasonDetail
 * @property {string} code - The code of the credit exception reason.
 * @property {string} label - The label of the credit exception reason.
 */

/**
 * @typedef {Object} CreditExceptionReasonsResponse
 * @property {CreditExceptionReasonDetail[]} reasons - List of credit exception reasons.
 */

export const creditExceptionAPI = {
  /**
   * Retrieves the credit exception reasons based on the lender institution configuration.
   * @param {string} stage - The stage of the lender institution.
   * @returns {Promise<CreditExceptionReasonsResponse>} - A promise that resolves with the credit exception reasons.
   * @APICode {stage}.credit-exceptions.reasons.list
   */
  getCreditExceptionReasons(stage) {
    return http.get(`${API_VERSION}/${stage}/credit-exceptions/reasons`)
  },
}
