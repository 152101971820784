import { concat, filter, find, has, toString, values, trim, some } from 'lodash'
import {
  AmendmentPartyActionEnum,
  PHONE_COUNTRY_CODES,
  PeopleEntityTypeEnum,
  PeopleRelationShipEnum,
  formatText,
  isEmpty,
  isEqualStringIgnoreCase,
  isIncludeStringIgnoreCase,
  isNormalEmpty,
  joinWithSeparator,
  PartyObjectRelEnum,
  BorrowerTypeEnum,
  formatZipCode,
} from '@/shared/utils'

export function combinePartyFullName(party) {
  const personName = joinWithSeparator(
    ' ',
    party?.firstName,
    party?.middleName,
    party?.lastName,
    party?.suffix
  )
  switch (party?.entityType ?? party?.partyType) {
    case PeopleEntityTypeEnum.individual:
      return formatText(personName)
    case PeopleEntityTypeEnum.trust:
    case PeopleEntityTypeEnum.business:
      return formatText(party?.legalName)
    default:
      return formatText(personName)
  }
}

export function combinePartyShortName(party) {
  const shortNameForIndividual = joinWithSeparator(
    ' ',
    party?.firstName,
    party?.middleName?.substring(0, 1).toUpperCase(),
    party?.lastName,
    party?.suffix
  ).substring(0, 40)
  const shortNameForEntity = joinWithSeparator(' ', party?.legalName).substring(
    0,
    40
  )
  switch (party?.entityType) {
    case PeopleEntityTypeEnum.individual:
      return trim(shortNameForIndividual) || null
    case PeopleEntityTypeEnum.trust:
    case PeopleEntityTypeEnum.business:
      return trim(shortNameForEntity) || null
    default:
      return trim(shortNameForIndividual) || null
  }
}

export function combineAddress(address, isLineBreak = true) {
  const text = (value) => value || ''
  const baseAddress = combineBaseAddress(address)
  const city = text(address?.city)
  const stateOrProvince = address?.state
    ? text(address?.state)
    : text(address?.province)
  const postalCode = address?.postalCode
    ? formatZipCode(address?.postalCode)
    : ''

  // for hide address default country value
  const isValidAddress = [baseAddress, city, stateOrProvince, postalCode].some(
    (item) => !isNormalEmpty(item)
  )
  const country = isValidAddress ? text(address?.country) : ''

  const splitRegionInfo =
    `${city}, ${stateOrProvince} ${postalCode} ${country}`.trim()
  const regionInfo =
    `${city} ${stateOrProvince} ${postalCode} ${country}`.trim()

  const oneLineAddress = `${baseAddress} ${regionInfo}`.trim()

  let lineBreakAddress = `${baseAddress}\n ${splitRegionInfo}`

  lineBreakAddress = isNormalEmpty(oneLineAddress)
    ? formatText()
    : lineBreakAddress
  return isLineBreak ? lineBreakAddress : oneLineAddress
}

export function combineBaseAddress(address) {
  const text = (value) => value || ''
  const addressLine1 = text(address?.addressLine1)
  const addressLine2 = text(address?.addressLine2)
  const addressLine3 = text(address?.addressLine3)
  const baseAddress = trim(`${addressLine1} ${addressLine2} ${addressLine3}`)
  return baseAddress
}

export function combinePartyNameWithoutMiddleName(party) {
  const personName = joinWithSeparator(
    ' ',
    party?.firstName,
    party?.lastName,
    party?.suffix
  ).trim()
  switch (party?.entityType ?? party?.partyType) {
    case PeopleEntityTypeEnum.individual:
      return formatText(personName)
    case PeopleEntityTypeEnum.trust:
    case PeopleEntityTypeEnum.business:
      return formatText(party?.legalName)
    default:
      return formatText(personName)
  }
}

export function getIsShowUserStatus(party) {
  return (
    has(party.userInfo, 'isActivated') &&
    (party.userInfo?.isActivated ||
      party.userInfo?.invitationSentAt ||
      party.userInfo?.lastLoginAt)
  )
}

export function formatInternationalPhoneText(phone) {
  let isMatch = false
  let matchCode = null
  for (const countryCode of PHONE_COUNTRY_CODES) {
    if (phone.includes(countryCode)) {
      isMatch = true
      matchCode = countryCode
      break
    }
  }
  if (isMatch) {
    const codeSize = matchCode.length
    const regionCode = phone.substring(0, codeSize)
    const phoneNumber = phone.substring(codeSize)
    return `${regionCode} ${encryptPhone(phoneNumber)}`
  } else {
    return encryptPhone(phone)
  }
}

export function encryptPhone(value) {
  if (!value || value === '-') {
    return '-'
  }
  value = value.replace(/\D+/g, '')
  return `XXX-XXX-${value.substring(value.length - 4)}`
}

export const SSNHideRule = (v) => `***-**-${v.substr(5, 4)}`
export const SSNShowRule = (v) =>
  `${v.substr(0, 3)}-${v.substr(3, 2)}-${v.substr(5, 4)}`
export const TaxIDHideRule = (v) => `**-***${v.substr(5, 4)}`
export const TaxIDShowRule = (v) => `${v.substr(0, 2)}-${v.substr(2, 7)}`

export const replaceDashToEmpty = (v) => {
  if (isEmpty(v)) {
    return ''
  } else {
    return toString(v).replace(/-/g, '')
  }
}
export const normalizeDashData = (v, rule) => {
  return rule(replaceDashToEmpty(v))
}

export function destructuringParties(partiesObject) {
  const individuals = partiesObject?.individuals || []
  const businesses = partiesObject?.businesses || []
  const trusts = partiesObject?.trusts || []
  const partyBusinessRelList = partiesObject?.partyBusinessRelList || []
  const assetPartyRelList = partiesObject?.assetPartyRelList || []
  const otherAffiliatedPartyList = partiesObject?.otherAffiliatedPartyList || []

  return {
    individuals,
    businesses,
    trusts,
    partyBusinessRelList,
    assetPartyRelList,
    otherAffiliatedPartyList,
  }
}

// The roles that can be invited are as follows:
// - Individual borrower
// - Authorized individual of the borrower business
// - Individual trustee of the borrower trust
// - Control person of the borrower business
// - Corporate trustee relationship manager of the borrower trust
export function getRoleCanInvite(party, partiesObject) {
  const roleCanInvite =
    isIndividualBorrower(party, partiesObject) ||
    isAuthorizedIndividualInBorrowerBusiness(party, partiesObject) ||
    isTrusteeInBorrowerTrust(party, partiesObject) ||
    isControlPersonInBorrowerBusiness(party, partiesObject) ||
    isRelationshipManagerInBorrowerTrust(party, partiesObject) ||
    party?.isAuthorizedUser

  return roleCanInvite
}

function isIndividualBorrower(party, partiesObject) {
  const { individuals } = destructuringParties(partiesObject)
  return individuals.some((individual) => individual.isBorrower && individual.partyId === party.partyId)
}

function isAuthorizedIndividualInBorrowerBusiness(party, partiesObject) {
  const { businesses } = destructuringParties(partiesObject)
  return businesses.some(
    (item) =>
      item.isBorrower &&
      item.children.some(
        (child) =>
          child.partyId === party.partyId &&
          isEqualStringIgnoreCase(
            child.relationshipType,
            PeopleRelationShipEnum.businessAuthorized
          )
      )
  )
}

function isTrusteeInBorrowerTrust(party, partiesObject) {
  const { trusts } = destructuringParties(partiesObject)
  return trusts.some(
    (item) =>
      item.isBorrower &&
      item.children.some(
        (child) =>
          child.partyId === party.partyId &&
          isEqualStringIgnoreCase(
            child.relationshipType,
            PeopleRelationShipEnum.trustTrustee
          )
      )
  )
}

function isControlPersonInBorrowerBusiness(party, partiesObject) {
  const { businesses } = destructuringParties(partiesObject)
  return businesses.some(
    (item) =>
      item.isBorrower &&
      item.children.some(
        (child) =>
          child.partyId === party.partyId &&
          isEqualStringIgnoreCase(
            child.relationshipType,
            PeopleRelationShipEnum.controlPerson
          )
      )
  )
}

export function isRelationshipManagerInBorrowerTrust(party, partiesObject) {
  const { trusts } = destructuringParties(partiesObject)
  return trusts
    .filter((item) => item.isBorrower)
    .some((trust) =>
      trust.corporateTrustees.some((corporateTrustee) => {
        return corporateTrustee?.relationshipManager?.partyId === party?.partyId
      })
    )
}

export const getFirstNonEmptyPropertyValue = (obj) => {
  const valueList = values(obj)

  const nonEmptyValue = find(valueList, (value) => !isEmpty(value))

  return nonEmptyValue
}

export function isUS(country) {
  return isNormalEmpty(country) || isEqualStringIgnoreCase(country, 'US')
}

export function getPartyList(partiesObject) {
  const { individuals, businesses, trusts } =
    destructuringParties(partiesObject)
  return concat(
    individuals.map((individual) => ({
      ...individual,
      entityType: PeopleEntityTypeEnum.individual,
    })),
    businesses.map((business) => ({
      ...business,
      entityType: PeopleEntityTypeEnum.business,
    })),
    trusts.map((trust) => ({
      ...trust,
      entityType: PeopleEntityTypeEnum.trust,
    }))
  )
}

export function getPartyListByLoanFacilityId(loanFacilityId, partiesObject) {
  const { partyBusinessRelList } = destructuringParties(partiesObject)
  const partyBusinessRelWithoutLoanFacility = partyBusinessRelList.filter(
    (rel) => rel.loanFacilityId !== loanFacilityId
  )
  const partyBusinessRelWithLoanFacility = partyBusinessRelList.filter(
    (rel) => rel.loanFacilityId === loanFacilityId
  )
  const partyList = getPartyList(partiesObject)
  return partyList
    .filter(
      (party) =>
        !partyBusinessRelWithoutLoanFacility.some((rel) =>
          isEqualStringIgnoreCase(rel.partyId, party.partyId)
        )
    )
    .map((party) => {
      const loanFacilityParty =
        partyBusinessRelWithLoanFacility.find((item) =>
          isEqualStringIgnoreCase(item.partyId, party.partyId)
        ) || {}
      return {
        ...party,
        ...loanFacilityParty,
      }
    })
}

export function getBorrowerList(partiesObject) {
  const partyList = getPartyList(partiesObject)
  return partyList.filter((party) => party.isBorrower)
}

export function getLoanFacilityBorrowerList(loanFacilityId, partiesObject) {
  const { partyBusinessRelList } = destructuringParties(partiesObject)
  const getLoanFacilityBorrowerIds = partyBusinessRelList
    .filter((rel) => rel.loanFacilityId === loanFacilityId)
    .map((rel) => rel.partyId)
  const partyList = getPartyList(partiesObject)
  return partyList.filter(
    (party) =>
      party.isBorrower && getLoanFacilityBorrowerIds.includes(party.partyId)
  )
}

export function getLoanFacilityBorrowerTypes(loanFacilityId, partiesObject) {
  const loanFacilityBorrowerList = getLoanFacilityBorrowerList(
    loanFacilityId,
    partiesObject
  )
  const existIndividualBorrower = some(loanFacilityBorrowerList, (borrower) =>
    isEqualStringIgnoreCase(
      borrower.entityType,
      PeopleEntityTypeEnum.individual
    )
  )
  const existBusinessBorrower = some(loanFacilityBorrowerList, (borrower) =>
    isEqualStringIgnoreCase(borrower.entityType, PeopleEntityTypeEnum.business)
  )
  const existRevocableTrust = some(
    loanFacilityBorrowerList,
    (borrower) =>
      isEqualStringIgnoreCase(
        borrower.entityType,
        PeopleEntityTypeEnum.trust
      ) && borrower.isRevocable
  )
  const existIrrevocableTrust = some(
    loanFacilityBorrowerList,
    (borrower) =>
      isEqualStringIgnoreCase(
        borrower.entityType,
        PeopleEntityTypeEnum.trust
      ) && !borrower.isRevocable
  )
  const borrowerTypes = [
    {
      type: BorrowerTypeEnum.individual,
      visible: existIndividualBorrower,
    },
    {
      type: BorrowerTypeEnum.business,
      visible: existBusinessBorrower,
    },
    {
      type: BorrowerTypeEnum.revocableTrust,
      visible: existRevocableTrust,
    },
    {
      type: BorrowerTypeEnum.irrevocableTrust,
      visible: existIrrevocableTrust,
    },
  ]
    .filter((borrowerType) => borrowerType.visible)
    .map((borrowerType) => borrowerType.type)
  return borrowerTypes
}

export function getPrimaryBorrower(partiesObject) {
  const { partyBusinessRelList } = destructuringParties(partiesObject)
  const borrowerList = getBorrowerList(partiesObject)
  const primaryPartyId = partyBusinessRelList.find(
    (party) => party.isPrimaryBorrower
  )?.partyId
  const primaryBorrower = borrowerList.find(
    (party) => primaryPartyId && party.partyId === primaryPartyId
  )
  return primaryBorrower
}

/**
 * top role include pledgor , borrower , guarantor
 * @param {*} partiesObject
 * @returns
 */
export function getTopRolePartyList(partiesObject) {
  const topRolePartyList = getPartyList(partiesObject)
  return topRolePartyList.filter(
    (party) => party.isBorrower || party.isPledgor || party.isGuarantor
  )
}

export function getNewPartyList(partiesObject) {
  const partyList = getPartyList(partiesObject)
  return filter(partyList, (party) => {
    return isEqualStringIgnoreCase(
      party.amendmentAction,
      AmendmentPartyActionEnum.add
    )
  })
}

export function getDeletedPartyList(partiesObject) {
  const partyList = getPartyList(partiesObject)
  return filter(partyList, (party) => {
    return isEqualStringIgnoreCase(
      party.amendmentAction,
      AmendmentPartyActionEnum.delete
    )
  })
}

const needReviewChildRoles = [
  PeopleRelationShipEnum.businessAuthorized,
  PeopleRelationShipEnum.trustTrustee,
  PeopleRelationShipEnum.trustGrantor,
  PeopleRelationShipEnum.controlPerson,
  PeopleRelationShipEnum.businessOwner,
  PeopleRelationShipEnum.authorizedSigner,
]
const isNeedReviewRole = (relationshipType) => {
  return isIncludeStringIgnoreCase(needReviewChildRoles, relationshipType)
}
export const getExistNotReviewedParty = (partiesObject, notReviewedStatus) => {
  const topRolePartyList = getTopRolePartyList(partiesObject)
  const partyNotReviewed = (amendmentStatus) => {
    return isIncludeStringIgnoreCase(notReviewedStatus, amendmentStatus)
  }
  return topRolePartyList.some((party) => {
    if (partyNotReviewed(party?.amendmentStatus)) {
      return true
    }
    return party.children.some((secondaryParty) => {
      if (
        isNeedReviewRole(secondaryParty.relationshipType) &&
        partyNotReviewed(secondaryParty?.amendmentStatus)
      ) {
        return true
      }
      const tertiaryParties = [
        secondaryParty?.relationshipManager,
        secondaryParty?.authorizedSigner,
      ]
      return tertiaryParties.some((tertiaryParty) =>
        partyNotReviewed(tertiaryParty?.amendmentStatus)
      )
    })
  })
}

export const combineOwnerListForAssets = (assets, partiesObject) => {
  if (isEmpty(assets)) return []
  const { assetPartyRelList } = destructuringParties(partiesObject)
  const partyList = getPartyList(partiesObject)
  return assets.map((asset) => {
    const owners = assetPartyRelList
      .filter(
        (rel) =>
          isEqualStringIgnoreCase(
            rel.relationshipType,
            PartyObjectRelEnum.pledgor
          ) && rel.assetId === asset.assetId
      )
      .map((rel) => {
        const party = find(partyList, (party) => party.partyId === rel.partyId)
        return party
      })
    return { ...asset, owners }
  })
}

export const getScreenName = (party) => {
  switch (party.entityType) {
    case PeopleEntityTypeEnum.business:
    case PeopleEntityTypeEnum.trust:
      return party.legalName || ''
    default:
      return joinWithSeparator(
        ' ',
        party.firstName,
        party.middleName,
        party.lastName,
        party.suffix
      )
  }
}
