import { computed, ref } from 'vue'
import { configurationAPI } from '../../api/configuration'
import { useStore, registerSharedStore } from './useStore.js'
import { APIStageEnum, DataPointsTopPageNodeCodeEnum } from '@/shared/utils'

const amendmentDataFields = ref({})

export function useAmendmentDataFields() {
  const getAmendmentDataFields = () => {
    return configurationAPI
      .getDataPoints(APIStageEnum.amendment, {
        isQueryForPageNode: false,
        topPageNodeCode: DataPointsTopPageNodeCodeEnum.line,
      })
      .then((response) => {
        amendmentDataFields.value = response || {}
      })
  }

  return {
    amendmentDataFields,
    getAmendmentDataFields,
  }
}

const getDataPoints = (store, stage, params) => {
  return configurationAPI
    .getDataPoints(stage, {
      isQueryForPageNode: false,
      topPageNodeCode: params.topPageNodeCode,
    })
    .then((res) => {
      store.commit('shared/SET_DATA_FIELDS_CONFIG', {
        config: res,
        topPageNodeCode: params.topPageNodeCode,
      })
    })
}

export const useProposalDataFieldsConfigStore = () => {
  const isProposalDataFieldsConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const proposalDataFieldsConfig = computed(
    () => sharedState.value.proposalDataFieldsConfig
  )

  const getProposalListInstitutionLevelConfigs = () => {
    isProposalDataFieldsConfigLoading.value = true
    return getDataPoints(store, DataPointsTopPageNodeCodeEnum.proposal).finally(
      () => {
        isProposalDataFieldsConfigLoading.value = false
      }
    )
  }
  return {
    isProposalDataFieldsConfigLoading,
    proposalDataFieldsConfig,
    getProposalListInstitutionLevelConfigs,
  }
}

export const useApplicationDataFieldsConfigStore = () => {
  const isApplicationDataFieldsConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const applicationDataFieldsConfig = computed(
    () => sharedState.value.applicationDataFieldsConfig
  )

  const getApplicationListInstitutionLevelConfigs = () => {
    isApplicationDataFieldsConfigLoading.value = true
    return getDataPoints(
      store,
      DataPointsTopPageNodeCodeEnum.application
    ).finally(() => {
      isApplicationDataFieldsConfigLoading.value = false
    })
  }
  return {
    isApplicationDataFieldsConfigLoading,
    applicationDataFieldsConfig,
    getApplicationListInstitutionLevelConfigs,
  }
}

export const useLineDataFieldsConfigStore = () => {
  const isLineDataFieldsConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const lineDataFieldsConfig = computed(
    () => sharedState.value.lineDataFieldsConfig
  )

  const getLineListInstitutionLevelConfigs = () => {
    isLineDataFieldsConfigLoading.value = true
    return getDataPoints(store, DataPointsTopPageNodeCodeEnum.line).finally(
      () => {
        isLineDataFieldsConfigLoading.value = false
      }
    )
  }
  return {
    isLineDataFieldsConfigLoading,
    lineDataFieldsConfig,
    getLineListInstitutionLevelConfigs,
  }
}

export const useUnderwritingDataFieldsConfigStore = () => {
  const isUnderwritingDataFieldsConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const underwritingDataFieldsConfig = computed(
    () => sharedState.value.underwritingDataFieldsConfig
  )

  const getUnderwritingListInstitutionLevelConfigs = () => {
    isUnderwritingDataFieldsConfigLoading.value = true
    return getDataPoints(
      store,
      DataPointsTopPageNodeCodeEnum.underwriting
    ).finally(() => {
      isUnderwritingDataFieldsConfigLoading.value = false
    })
  }
  return {
    isUnderwritingDataFieldsConfigLoading,
    underwritingDataFieldsConfig,
    getUnderwritingListInstitutionLevelConfigs,
  }
}

export const useCollateralManagementDataFieldsConfigStore = () => {
  const isCollateralManagementDataFieldsConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const collateralManagementDataFieldsConfig = computed(
    () => sharedState.value.collateralManagementDataFieldsConfig
  )

  const getCollateralManagementListInstitutionLevelConfigs = (params) => {
    isCollateralManagementDataFieldsConfigLoading.value = true
    return getDataPoints(
      store,
      DataPointsTopPageNodeCodeEnum.collateralManagement,
      params
    ).finally(() => {
      isCollateralManagementDataFieldsConfigLoading.value = false
    })
  }
  return {
    isCollateralManagementDataFieldsConfigLoading,
    collateralManagementDataFieldsConfig,
    getCollateralManagementListInstitutionLevelConfigs,
  }
}

export const useSmaDataFieldsConfigStore = () => {
  const isSmaDataFieldsConfigLoading = ref(false)
  const store = useStore()
  registerSharedStore()

  const sharedState = computed(() => store.state?.shared || {})

  const smaDataFieldsConfig = computed(
    () => sharedState.value.smaDataFieldsConfig
  )

  const getSmaListInstitutionLevelConfigs = (params) => {
    isSmaDataFieldsConfigLoading.value = true
    return getDataPoints(
      store,
      DataPointsTopPageNodeCodeEnum.sma,
      params
    ).finally(() => {
      isSmaDataFieldsConfigLoading.value = false
    })
  }
  return {
    isSmaDataFieldsConfigLoading,
    smaDataFieldsConfig,
    getSmaListInstitutionLevelConfigs,
  }
}

const proposalDataFields = ref({})
export function useProposalDataFields() {
  const getProposalDataFields = () => {
    return configurationAPI
      .getDataPoints(APIStageEnum.proposal, {
        isQueryForPageNode: false,
        topPageNodeCode: 'PROPOSAL',
      })
      .then((response) => {
        proposalDataFields.value = response || {}
      })
  }
  return {
    proposalDataFields,
    getProposalDataFields,
  }
}
