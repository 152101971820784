import { isEmpty, set, find, get } from "lodash"
import { ref, unref } from "vue"
import { configurationAPI } from "@/shared/api"
import { APIStageEnum, sanitizeHtml } from "@/shared/utils"

const termsStore = ref({})

/**
 * @param {Object} options
 * @param {string} [options.stage]
 * @param {boolean} [options.useExist]
 * @returns {Object}
 */
export const useTermsData = ({ stage = APIStageEnum.service, useExist = true } = {}) => {
  const currentTermList = ref([])
  const isListTermsLoading = ref(false)

  async function loadTermList(id) {
    const loanFacilityId = unref(id)
    let termList = get(termsStore.value, loanFacilityId, [])
    if (!loanFacilityId) return termList
    try {
      isListTermsLoading.value = true
      if (!useExist || isEmpty(termList)) {
        const result = await configurationAPI.getTerms(stage, { loanFacilityId })
        termList = result.items || []
      }
      currentTermList.value = termList
      set(termsStore.value, loanFacilityId, termList)
      return termList
    } finally {
      isListTermsLoading.value = false
    }
  }

  /**
   * @param {string} code
   * @param {string} [path]
   * @returns {any}
   */
  function getTermInfo(code, path) {
    const term = find(currentTermList.value, { code }) || {}
    if (!path) return term
    return get(term, path)
  }

  function getParsedTerm(code, path = 'content') {
    const raw = getTermInfo(code, path)
    if (path !== 'content') return decodeHTMLEntities(raw)
    return sanitizeHtml(raw || '')
  }

  function hasTerm(code) {
    return !isEmpty(getTermInfo(code))
  }

  function decodeHTMLEntities(text) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }

  return {
    isListTermsLoading,
    currentTermList,
    loadTermList,
    getTermInfo,
    getParsedTerm,
    hasTerm,
  }
}
