import { http, API_VERSION, APIStageEnum } from '@/shared/api/http'

export const assetAPI = {
  /**
   * Checks if assets exist in the system based on the provided parameters.
   * @param {CheckAssetExistenceParams} params - The parameters for the asset existence check.
   * @returns {Promise<AssetExistenceRespDTO>} A promise that resolves with the asset existence response.
   * @typedef {Object} CheckAssetExistenceParams
   * @property {number} custodianInstitutionId - The ID of the custodian institution.
   * @property {string[]} assetNumberList - The list of asset numbers to check.
   * @typedef {Object} AssetExistenceRespDTO
   * @property {AssetExistenceDTO[]} assetExistences - The list of asset existence information.
   * @typedef {Object} AssetExistenceDTO
   * @property {string} assetNumber - The number of the asset.
   * @property {number} custodianInstitutionId - The ID of the custodian institution.
   * @property {boolean} exist - Indicates if the asset exists.
   * @APICode assets.existences
   */
  getAssetExistences(params) {
    return http.get(`${API_VERSION}/assets/existences`, params)
  },

  /**
   * Creates a new asset in the system.
   * @param {CreateAssetParams} params - The parameters required to create an asset.
   * @returns {Promise<CreateAssetResponse>} - A promise that resolves with the created asset ID.
   * @typedef {Object} CreateAssetParams
   * @property {string} stage - The stage in which the asset is created.
   * @property {CollateralInfo} collateralInfo - Information about the collateral.
   * @property {string} estimateId - The estimate ID associated with the asset.
   * @property {string} proposalId - The proposal ID associated with the asset.
   * @property {string} applicationId - The application ID associated with the asset.
   * @property {string} collateralId - The collateral ID associated with the asset.
   * @property {string} amendmentCollateralId - The amendment collateral ID.
   * @property {string} custodianInstitutionId - The custodian institution ID.
   * @property {string} custodianCode - The custodian code.
   * @property {string} siInstitutionId - The SI institution ID.
   * @property {string} assetNumber - The asset number.
   * @property {string} assetNumberRaw - The raw asset number.
   * @property {string} assetName - The name of the asset.
   * @property {string} assetDescription - The description of the asset.
   * @property {string} assetType - The type of the asset.
   * @property {string} assetSubType - The subtype of the asset.
   * @property {string} assetClassification - The classification of the asset.
   * @property {string} externalAssetId - The external asset ID.
   * @property {string} fileMasterId - The file master ID.
   * @property {string} referralId - The referral ID.
   * @property {string} pledgingStatus - The pledging status.
   * @property {string} pledgedDate - The date the asset was pledged.
   * @property {string} pledgingStatusDescription - The description of the pledging status.
   * @property {string} pledgingMethod - The method of pledging.
   * @property {string} accountLatestSourceType - The latest source type of the account.
   * @property {number} transferOfAssetProgress - The progress of asset transfer.
   * @property {string} aumTier - The AUM tier.
   * @property {string} collateralBaseCurrencyCode - The base currency code of the collateral.
   * @property {number} marketValueBaseCurrency - The market value in base currency.
   * @property {number} eligibleMarketValue - The eligible market value.
   * @property {number} marketValueReportedCurrency - The market value in reported currency.
   * @property {string} marketValueUpdatedDate - The date the market value was updated.
   * @property {number} advanceRate - The advance rate.
   * @property {number} maintenanceRate - The maintenance rate.
   * @property {string} updatedFrequency - The frequency of updates.
   * @property {string} valuationType - The type of valuation.
   * @property {string} marketValueValuationDate - The date of market value valuation.
   * @property {string} reportedCurrencyCode - The reported currency code.
   * @property {number} requiredEquity - The required equity.
   * @property {number} maintenanceValue - The maintenance value.
   * @property {number} lendingValue - The lending value.
   * @property {number} minimumHoldAmount - The minimum hold amount.
   * @property {boolean} isClosed - Whether the asset is closed.
   * @property {number} concentrationLimitAmount - The concentration limit amount.
   * @property {number} concentrationLimitRate - The concentration limit rate.
   * @property {string} changingPackageStatus - The status of changing package.
   * @property {string} holdingAsOfDate - The holding as of date.
   * @property {string} actionStatus - The action status.
   * @property {string} letterRecipient - The recipient of the letter.
   * @property {string} letterStatus - The status of the letter.
   * @property {string} dataSource - The data source.
   * @property {UserDefinedField[]} userDefinedFields - User defined fields.
   * @property {InvestmentAccount} investmentAccount - Information about the investment account.
   * @property {HardAsset} hardAsset - Information about the hard asset.
   * @property {AlternativeInvestment} alternativeInvestment - Information about the alternative investment.
   * @property {LifeInsurance} lifeInsurance - Information about the life insurance.
   * @property {RealEstate} realEstate - Information about the real estate.
   * @property {Holding[]} holdings - A list of holdings.
   * @typedef {Object} CollateralInfo
   * @property {string} totalLoanFacilityId - The total loan facility ID.
   * @property {string} externalCollateralId - The external collateral ID.
   * @property {number} creditPolicyId - The credit policy ID.
   * @property {string} collateralName - The name of the collateral.
   * @typedef {Object} UserDefinedField
   * @property {string} id - The ID of the user-defined field.
   * @property {string} value - The value of the user-defined field.
   * @typedef {Object} InvestmentAccount
   * @property {string} positionsSyncStatus - The sync status of positions.
   * @property {string} positionsLatestSourceType - The latest source type of positions.
   * @property {string} eligibleStatus - The eligibility status.
   * @property {string} eligibilityWarningDetail - The eligibility warning detail.
   * @property {boolean} isPrimaryInvestmentAccount - Whether it is the primary investment account.
   * @property {boolean} isMarginInvestmentAccount - Whether it is a margin investment account.
   * @property {number} marginBalance - The margin balance.
   * @property {string} investmentAccountTypeCode - The investment account type code.
   * @property {boolean} hasRegularDistribution - Whether it has regular distribution.
   * @property {string} distributionPayee - The payee of the distribution.
   * @property {number} distributionAmount - The amount of the distribution.
   * @property {string} distributionFrequency - The frequency of distribution.
   * @property {string} distributionFrequencyDescription - The description of the distribution frequency.
   * @property {string} holdingAsOfDate - The holding as of date.
   * @property {number} cashEquivalent - The cash equivalent.
   * @typedef {Object} HardAsset
   * @property {number} currentAppraisalValue - The current appraisal value.
   * @property {string} appraisalDate - The date of appraisal.
   * @property {string} appraiser - The appraiser.
   * @property {string} aircraftMake - The make of the aircraft.
   * @property {string} aircraftModel - The model of the aircraft.
   * @property {string} aircraftSerialNumber - The serial number of the aircraft.
   * @property {number} aircraftAge - The age of the aircraft.
   * @property {number} totalEngineHours - The total engine hours.
   * @property {string} yachtMake - The make of the yacht.
   * @property {string} yachtModel - The model of the yacht.
   * @property {string} yachtLocation - The location of the yacht.
   * @property {string} yachtSerialNumber - The serial number of the yacht.
   * @typedef {Object} AlternativeInvestment
   * @property {number} currentTotalValue - The current total value.
   * @property {number} irrPercent - The internal rate of return percent.
   * @property {number} expectedYearlyReturnPercent - The expected yearly return percent.
   * @property {number} assetUnderManagementValue - The value of the asset under management.
   * @property {string} assetUnderManagementUnit - The unit of the asset under management value.
   * @property {number} assetUnderManagementIntactValue - The intact value of the asset under management.
   * @property {string} fundManager - The fund manager.
   * @property {string} assetManagementFirm - The asset management firm.
   * @property {string} lockUpUntilDate - The lock-up until date.
   * @property {string} fundDomicile - The domicile of the fund.
   * @property {number} fundVintageYear - The vintage year of the fund.
   * @typedef {Object} LifeInsurance
   * @property {number} currentCashValue - The current cash value.
   * @property {number} currentSurrenderValue - The current surrender value.
   * @property {number} deathBenefit - The death benefit.
   * @property {string} policyOwnerName - The name of the policy owner.
   * @property {string} insuranceCarrier - The insurance carrier.
   * @property {string} ratingOfCarrier - The rating of the carrier.
   * @property {string} ratingCompany - The rating company.
   * @property {string} lastRatingDate - The last rating date.
   * @property {string} personInsured - The person insured.
   * @property {string} policyBeneficiary - The beneficiary of the policy.
   * @typedef {Object} RealEstate
   * @property {number} lenderInstitutionId - The lender institution ID.
   * @property {number} currentAppraisalValue - The current appraisal value.
   * @property {string} appraisalDate - The date of appraisal.
   * @property {string} appraiser - The appraiser.
   * @property {number} currentNetValue - The current net value.
   * @property {string} propertyAddress - The address of the property.
   * @property {string} propertyType - The type of the property.
   * @property {number} outstandingMortgage - The outstanding mortgage.
   * @property {number} propertySizeValue - The size value of the property.
   * @property {string} propertySizeUnit - The size unit of the property.
   * @property {number} occupancyPercent - The occupancy percent.
   * @property {number} yearlyRentalIncome - The yearly rental income.
   * @property {number} netOperatingIncome - The net operating income.
   * @property {number} yearlyTotalDebtService - The yearly total debt service.
   * @property {number} debtServiceCoverageRatio - The debt service coverage ratio.
   * @property {string} propertyInsuranceCarrier - The property insurance carrier.
   * @property {string} perilsCovered - The perils covered.
   * @property {number} coverageAmount - The coverage amount.
   * @property {boolean} hasRegXHud - Whether it has Reg X Hud.
   * @property {boolean} hasRegXGfe - Whether it has Reg X Gfe.
   * @property {boolean} isFloodZone - Whether it is a flood zone.
   * @typedef {Object} Holding
   * @property {number} dataSourceInstitutionId - The data source institution ID.
   * @property {string} collateralBaseCurrencyCode - The collateral base currency code.
   * @property {number} exchangeRate - The exchange rate.
   * @property {number} secId - The SEC ID.
   * @property {string} sourceSecurityId - The source security ID.
   * @property {string} sourceSecurityIdType - The source security ID type.
   * @property {string} cusip - The CUSIP.
   * @property {string} isin - The ISIN.
   * @property {string} sedol - The SEDOL.
   * @property {string} ticker - The ticker.
   * @property {string} securityName - The name of the security.
   * @property {string} domicile - The domicile.
   * @property {string} holdingCurrencyCode - The holding currency code.
   * @property {string} assetClass - The asset class.
   * @property {string} universe - The universe.
   * @property {string} investmentClass - The investment class.
   * @property {string} sourceSecurityType - The source security type.
   * @property {number} lenderSecurityTypeId - The lender security type ID.
   * @property {number} lenderAssetTypeId - The lender asset type ID.
   * @property {number} marketValueHoldingCurrency - The market value in holding currency.
   * @property {number} marketValueBaseCurrency - The market value in base currency.
   * @property {number} quantity - The quantity.
   * @property {number} eligibleQuantity - The eligible quantity.
   * @property {number} pledgedQuantity - The pledged quantity.
   * @property {number} depositInterestRatePercent - The deposit interest rate percent.
   * @property {number} depositHoldAmount - The deposit hold amount.
   * @property {string} depositMaturityDate - The deposit maturity date.
   * @property {number} weightPercent - The weight percent.
   * @property {number} collateralBaseCurrencyPrice - The collateral base currency price.
   * @property {number} holdingCurrencyPrice - The holding currency price.
   * @property {number} unitPriceFactor - The unit price factor.
   * @property {number} priceSource - The price source.
   * @property {string} priceDate - The price date.
   * @property {string} exchangeCode - The exchange code.
   * @property {number} requiredEquityRate - The required equity rate.
   * @property {number} concentrationLimitAmount - The concentration limit amount.
   * @property {number} concentrationLimitRate - The concentration limit rate.
   * @property {number} lendingValue - The lending value.
   * @property {number} maintenanceValue - The maintenance value.
   * @property {number} requiredEquity - The required equity.
   * @property {number} liquidity - The liquidity.
   * @property {string} specialCode - The special code.
   * @property {number} advanceRateCode - The advance rate code.
   * @property {number} requiredEquityRateCode - The required equity rate code.
   * @property {number} concentrationLimitCode - The concentration limit code.
   * @property {string} asOfDate - The as of date.
   * @property {number} lenderAssetTypeIdSource - The lender asset type ID source.
   * @typedef {Object} CreateAssetResponse
   * @property {string} assetId - The ID of the created asset.
   * @APICode {stage}.assets.create
   */
  createAsset(stage, params) {
    return http.post(`${API_VERSION}/${stage}/assets`, params)
  },

  /**
   * @typedef {Object} InvestmentAccount
   * @property {String} positionsSyncStatus The synchronization status of the positions.
   * @property {String} positionsLatestSourceType The latest source type of the positions.
   * @property {String} eligibleStatus The eligibility status.
   * @property {String} eligibilityWarningDetail The detail of the eligibility warning.
   * @property {Boolean} isPrimaryInvestmentAccount Indicates if it is the primary investment account.
   * @property {Boolean} isMarginInvestmentAccount Indicates if it is a margin investment account.
   * @property {Number} marginBalance The balance of the margin.
   * @property {String} investmentAccountTypeCode The type code of the investment account.
   * @property {Boolean} hasRegularDistribution Indicates if there is a regular distribution.
   * @property {String} distributionPayee The payee of the distribution.
   * @property {Number} distributionAmount The amount of the distribution.
   * @property {String} distributionFrequency The frequency of the distribution.
   * @property {String} distributionFrequencyDescription The description of the distribution frequency.
   * @property {String} holdingAsOfDate The date of holding as of.
   * @property {Number} cashEquivalent The cash equivalent amount.
   */

  /**
   * @typedef {Object} HardAsset
   * @property {Number} currentAppraisalValue The current appraisal value.
   * @property {String} appraisalDate The date of the appraisal.
   * @property {String} appraiser The appraiser.
   * @property {String} aircraftMake The make of the aircraft.
   * @property {String} aircraftModel The model of the aircraft.
   * @property {String} aircraftSerialNumber The serial number of the aircraft.
   * @property {Number} aircraftAge The age of the aircraft.
   * @property {Number} totalEngineHours The total engine hours.
   * @property {String} yachtMake The make of the yacht.
   * @property {String} yachtModel The model of the yacht.
   * @property {String} yachtLocation The location of the yacht.
   * @property {String} yachtSerialNumber The serial number of the yacht.
   */

  /**
   * @typedef {Object} LifeInsurance
   * @property {Number} currentCashValue The current cash value.
   * @property {Number} currentSurrenderValue The current surrender value.
   * @property {Number} deathBenefit The death benefit amount.
   * @property {String} policyOwnerName The name of the policy owner.
   * @property {String} insuranceCarrier The insurance carrier.
   * @property {String} ratingOfCarrier The rating of the carrier.
   * @property {String} ratingCompany The rating company.
   * @property {String} lastRatingDate The last date of rating.
   * @property {String} personInsured The person insured.
   * @property {String} policyBeneficiary The beneficiary of the policy.
   */

  /**
   * @typedef {Object} AlternativeInvestment
   * @property {Number} currentTotalValue The current total value.
   * @property {Number} irrPercent The internal rate of return in percent.
   * @property {Number} expectedYearlyReturnPercent The expected yearly return in percent.
   * @property {Number} assetUnderManagementValue The value of assets under management.
   * @property {String} assetUnderManagementUnit The unit of assets under management.
   * @property {Number} assetUnderManagementIntactValue The intact value of assets under management.
   * @property {String} fundManager The fund manager.
   * @property {String} assetManagementFirm The asset management firm.
   * @property {String} lockUpUntilDate The lock-up until date.
   * @property {String} fundDomicile The domicile of the fund.
   * @property {Number} fundVintageYear The vintage year of the fund.
   */

  /**
   * @typedef {Object} RealEstate
   * @property {Number} lenderInstitutionId The institution ID of the lender.
   * @property {Number} currentAppraisalValue The current appraisal value.
   * @property {String} appraisalDate The date of the appraisal.
   * @property {String} appraiser The appraiser.
   * @property {Number} currentNetValue The current net value.
   * @property {String} propertyAddress The address of the property.
   * @property {String} propertyType The type of the property.
   * @property {Number} outstandingMortgage The outstanding mortgage amount.
   * @property {Number} propertySizeValue The size value of the property.
   * @property {String} propertySizeUnit The size unit of the property.
   * @property {Number} occupancyPercent The occupancy percent.
   * @property {Number} yearlyRentalIncome The yearly rental income.
   * @property {Number} netOperatingIncome The net operating income.
   * @property {Number} yearlyTotalDebtService The yearly total debt service.
   * @property {Number} debtServiceCoverageRatio The debt service coverage ratio.
   * @property {String} propertyInsuranceCarrier The insurance carrier of the property.
   * @property {String} perilsCovered The perils covered.
   * @property {Number} coverageAmount The amount of coverage.
   * @property {Boolean} hasRegXHud Indicates if there is Reg X Hud.
   * @property {Boolean} hasRegXGfe Indicates if there is Reg X Gfe.
   * @property {Boolean} isFloodZone Indicates if it is in a flood zone.
   */

  /**
   * @typedef {Object} AssetDetail
   * @property {InvestmentAccount} investmentAccount The investment account details.
   * @property {HardAsset} hardAsset The hard asset details.
   * @property {LifeInsurance} lifeInsurance The life insurance details.
   * @property {AlternativeInvestment} alternativeInvestment The alternative investment details.
   * @property {RealEstate} realEstate The real estate details.
   * @property {String} externalAssetId The external asset ID.
   * @property {Number} collateralId The collateral ID.
   * @property {Number} portfolioId The portfolio ID.
   * @property {String} referralId The referral ID.
   * @property {Number} custodianInstitutionId The custodian institution ID.
   * @property {String} custodianCode The custodian code.
   * @property {Number} siInstitutionId The SI institution ID.
   * @property {String} assetNumber The asset number.
   * @property {String} assetNumberRaw The raw asset number.
   * @property {String} assetType The type of the asset.
   * @property {String} assetSubtype The subtype of the asset.
   * @property {String} assetClassification The classification of the asset.
   * @property {String} assetName The name of the asset.
   * @property {String} assetDescription The description of the asset.
   * @property {String} currency The currency.
   * @property {String} effectiveDate The effective date.
   * @property {String} updateFrequency The update frequency.
   * @property {String} valuationType The valuation type.
   * @property {String} pledgingStatus The pledging status.
   * @property {String} pledgingStatusDescription The pledging status description.
   * @property {String} dataSource The data source.
   * @property {String} accountLatestSourceType The latest source type of the account.
   * @property {Number} marketValue The market value.
   * @property {String} marketValueUpdatedDate The market value updated date.
   * @property {Number} eligibleMarketValue The eligible market value.
   * @property {Number} lendingValue The lending value.
   * @property {Number} transferOfAssetProgress The transfer of asset progress.
   * @property {String} aumTier The AUM tier.
   * @property {String} collateralBaseCurrencyCode The base currency code of the collateral.
   * @property {Number} marketValueBaseCurrency The market value in the base currency.
   * @property {String} reportedCurrencyCode The reported currency code.
   * @property {Number} marketValueReportedCurrency The market value in the reported currency.
   * @property {Number} requiredEquity The required equity.
   * @property {Number} maintenanceValue The maintenance value.
   * @property {Number} minimumHoldAmount The minimum hold amount.
   * @property {Number} advanceRate The advance rate.
   * @property {Number} maintenanceRate The maintenance rate.
   * @property {Number} concentrationLimitAmount The concentration limit amount.
   * @property {Number} concentrationLimitRate The concentration limit rate.
   * @property {Number} weightPercent The weight percent.
   * @property {Number} allocEquityRate The allocation equity rate.
   * @property {Number} allocFiRate The allocation fixed income rate.
   * @property {Number} allocCashRate The allocation cash rate.
   * @property {String} asOfDate The as of date.
   * @property {Boolean} isClosed Indicates if it is closed.
   * @property {Number} changingPackageStatus The changing package status.
   * @property {Number} updatedByUserId The ID of the user who updated.
   * @property {Number} updatedBy The updated by.
   * @property {Number} updatedAt The updated at.
   * @property {Number} listFlags The list flags.
   * @property {String} assetAttr0 The asset attribute 0.
   * @property {String} assetAttr1 The asset attribute 1.
   * @property {String} assetAttr2 The asset attribute 2.
   * @property {String} assetAttr3 The asset attribute 3.
   * @property {String} assetAttr4 The asset attribute 4.
   * @property {Number} createdAt The created at.
   */
  /**
   * Get asset detail by stage and asset ID.
   * @param {String} stage The stage of the asset.
   * @param {Number} assetId The ID of the asset.
   * @returns {Promise<AssetDetail>} A promise that resolves with the asset detail data.
   * @APICode {stage}.assets.get
   */
  getAssetDetail(stage, assetId) {
    return http.get(`${API_VERSION}/${stage}/assets/${assetId}`)
  },

  /**
   * Retrieves detailed information about asset holdings based on various filter criteria and asset ID.
   * @param {string} stage - The stage of the asset.
   * @param {number} assetId - The unique identifier of the asset.
   * @param {AssetHoldingsParams} [params] - The optional parameters for filtering the asset holdings.
   * @returns {Promise<AssetHoldingsResponse>} - A promise that resolves with the detailed asset holdings information.
   * @typedef {Object} AssetHoldingsParams
   * @property {string} [search] - A search term to filter the asset holdings.
   * @property {AssetHoldingsFilters} [filters] - Filters to apply to the asset holdings.
   * @typedef {Object} AssetHoldingsFilters
   * @property {RangeFilter} [collateralBaseCurrencyPrice] - Filter for collateral base currency price.
   * @property {RangeFilter} [quantity] - Filter for quantity.
   * @property {RangeFilter} [eligibleQuantity] - Filter for eligible quantity.
   * @property {RangeFilter} [pledgedQuantity] - Filter for pledged quantity.
   * @property {RangeFilter} [marketValueBaseCurrency] - Filter for market value in base currency.
   * @property {RangeFilter} [advanceRate] - Filter for advance rate.
   * @property {RangeFilter} [initialAdvanceRate] - Filter for initial advance rate.
   * @property {RangeFilter} [lendingValue] - Filter for lending value.
   * @property {RangeFilter} [requiredEquityRate] - Filter for required equity rate.
   * @property {RangeFilter} [initialRequiredEquityRate] - Filter for initial required equity rate.
   * @property {RangeFilter} [requiredEquity] - Filter for required equity.
   * @property {RangeFilter} [maintenanceValue] - Filter for maintenance value.
   * @property {RangeFilter} [weightPercent] - Filter for weight percent.
   * @property {RangeFilter} [intradayPrice] - Filter for intraday price.
   * @property {RangeFilter} [intradayMarketValueChangePercentage] - Filter for intraday market value change percentage.
   * @typedef {Object} RangeFilter
   * @property {number} [max] - The maximum value for the filter.
   * @property {number} [min] - The minimum value for the filter.
   * @typedef {Object} AssetHoldingsResponse
   * @property {number} pageNumber - The current page number.
   * @property {number} pageSize - The size of the page.
   * @property {number} totalPages - The total number of pages.
   * @property {number} totalItems - The total number of items.
   * @property {Array<CollateralHoldingDetail>} items - The list of collateral holding details.
   * @typedef {Object} CollateralHoldingDetail
   * @property {number} collateralHoldingDetailId - The ID of the collateral holding detail.
   * @property {number} portfolioId - The ID of the portfolio.
   * @property {string} portfolioDate - The date of the portfolio.
   * @property {number} portfolioCalculatedAt - The timestamp when the portfolio was calculated.
   * @property {number} dataSourceInstitutionId - The ID of the data source institution.
   * @property {string} collateralBaseCurrencyCode - The base currency code for the collateral.
   * // Additional properties omitted for brevity.
   * @APICode {stage}.assets.holdings.list
   */
  getAssetHoldings(stage, assetId, params = {}) {
    const queryParams = {
      ...params,
      pageSize: params.pageSize || 20,
      pageNum: params.pageNum || 1,
    }
    return http.get(
      `${API_VERSION}/${stage}/assets/${assetId}/holdings`,
      queryParams
    )
  },
  /**
   * @typedef {Object} Filters
   * @property {number} [estimateId] - The estimate ID to filter assets.
   * @property {number} [proposalId] - The proposal ID to filter assets.
   * @property {number} [applicationId] - The application ID to filter assets.
   * @property {number} [collateralId] - The collateral ID to filter assets.
   * @property {number} [amendmentCollateralId] - The amendment collateral ID to filter assets.
   * @property {Array<number>} [custodianInstitutionId.values] - List of custodian institution IDs to filter assets.
   * @property {Array<string>} [updatedFrequency.values] - List of updated frequencies to filter assets.
   * @property {Array<string>} [assetType.values] - List of asset types to filter assets.
   * @property {string} [pledgedDate.min] - Minimum pledged date to filter assets.
   * @property {string} [pledgedDate.max] - Maximum pledged date to filter assets.
   * @property {string} [marketValueUpdatedDate.min] - Minimum market value updated date to filter assets.
   * @property {string} [marketValueUpdatedDate.max] - Maximum market value updated date to filter assets.
   * @property {number} [marketValue.min] - Minimum market value to filter assets.
   * @property {number} [marketValue.max] - Maximum market value to filter assets.
   * @property {number} [weightPercent.min] - Minimum weight percent to filter assets.
   * @property {number} [weightPercent.max] - Maximum weight percent to filter assets.
   * @property {number} [advanceRate.min] - Minimum advance rate to filter assets.
   * @property {number} [advanceRate.max] - Maximum advance rate to filter assets.
   * @property {number} [maintenanceRate.min] - Minimum maintenance rate to filter assets.
   * @property {number} [maintenanceRate.max] - Maximum maintenance rate to filter assets.
   * @property {string} [effectiveDate.min] - Minimum effective date to filter assets.
   * @property {string} [effectiveDate.max] - Maximum effective date to filter assets.
   * @property {number} [equityRate.min] - Minimum equity rate to filter assets.
   * @property {number} [equityRate.max] - Maximum equity rate to filter assets.
   * @property {number} [fixedIncomeRate.min] - Minimum fixed income rate to filter assets.
   * @property {number} [fixedIncomeRate.max] - Maximum fixed income rate to filter assets.
   * @property {number} [cashRate.min] - Minimum cash rate to filter assets.
   * @property {number} [cashRate.max] - Maximum cash rate to filter assets.
   * @property {number} [updatedAt.min] - Minimum updated at to filter assets.
   * @property {number} [updatedAt.max] - Maximum updated at to filter assets.
   */

  /**
   * @typedef {Object} AssetItem
   * @property {string} assetId - The ID of the asset.
   * @property {string} collateralId - The ID of the collateral.
   * @property {string} assetNumber - The asset number.
   * @property {string} assetName - The name of the asset.
   * @property {string} assetDescription - The description of the asset.
   * @property {string} assetType - The type of the asset.
   * @property {string} assetSubType - The subtype of the asset.
   * @property {string} assetClassification - The classification of the asset.
   * @property {string} valuationType - The valuation type of the asset.
   * @property {string} updatedFrequency - The frequency of updates to the asset.
   * @property {string} pledgedDate - The date the asset was pledged.
   * @property {number} marketValue - The market value of the asset.
   * @property {number} lendingValue - The lending value of the asset.
   * @property {number} advanceRate - The advance rate of the asset.
   * @property {number} maintenanceRate - The maintenance rate of the asset.
   * @property {string} marketValueUpdatedDate - The date the market value was updated.
   * @property {number} weightPercent - The weight percentage of the asset.
   * @property {string} asOfDate - The date of the asset's status.
   * @property {string} effectiveDate - The effective date of the asset's valuation.
   * @property {string} externalAssetId - The external ID of the asset.
   * @property {string} custodianInstitutionId - The ID of the custodian institution.
   * @property {string} custodianCode - The code of the custodian.
   * @property {string} siInstitutionId - The ID of the SI institution.
   * @property {string} collateralBaseCurrencyCode - The base currency code of the collateral.
   * @property {number} concentrationLimitAmount - The concentration limit amount.
   * @property {number} concentrationLimitRate - The concentration limit rate.
   * @property {number} requiredEquity - The required equity.
   * @property {number} maintenanceValue - The maintenance value.
   * @property {string} pledgingStatus - The pledging status.
   * @property {string} pledgingErrorDetail - Details of any pledging errors.
   * @property {string} eligibleStatus - The eligibility status.
   * @property {string} priceTier - The price tier.
   * @property {boolean} isPrimaryInvestmentAccount - Whether it is a primary investment account.
   * @property {boolean} isMarginInvestmentAccount - Whether it is a margin investment account.
   * @property {number} marginBalance - The margin balance.
   * @property {string} investmentAccountTypeCode - The investment account type code.
   * @property {string} investmentAccountType - The investment account type.
   * @property {string} calculatedAt - The time of calculation.
   * @property {number} updatedAt - The time the asset was last updated.
   * @property {string} collateralName - The name of the collateral.
   * @property {string} externalCollateralId - The external ID of the collateral.
   */

  /**
   * @typedef {Object} AssetListResponse
   * @property {number} pageNumber - The current page number.
   * @property {number} pageSize - The number of items per page.
   * @property {number} totalPages - The total number of pages.
   * @property {number} totalItems - The total number of items.
   * @property {Array<AssetItem>} items - The list of assets.
   */

  /**
   * Lists assets based on the provided stage and filters.
   * @param {string} stage - The stage of the assets.
   * @param {Object} params - The parameters for the API call.
   * @param {number} params.pageNumber - The page number to retrieve.
   * @param {number} params.pageSize - The size of the page to retrieve.
   * @param {string} [params.search] - The search term to filter assets.
   * @param {string} [params.sort] - The sorting parameter for the asset list.
   * @param {Filters} [params.filters] - The filters to apply to the asset list.
   * @returns {Promise<AssetListResponse>} - A promise that resolves with the list of assets.
   * @APICode {stage}.assets.list
   */
  getAssets(stage, { pageNumber, pageSize, search, sort, filters }) {
    const queryParams = {
      pageNumber,
      pageSize,
      search,
      sort,
      ...filters,
    }
    return http.get(`${API_VERSION}/${stage}/assets`, queryParams)
  },

  /**
   * Update the pledge status of an asset.
   * @param {string} stage - The stage of the loan facility process.
   * @param {string} assetId - The ID of the asset to pledge.
   * @returns {Promise<PledgeAssetResponse>} A promise that resolves with the result of the pledge operation.
   * @typedef {Object} PledgeAssetResponse
   * @property {string} message - A message indicating the success or failure of the operation.
   * @APICode service.assets.pledges, amendment.assets.pledges
   */
  updatePledgeStatus(stage, assetId, params) {
    return http.patch(
      `${API_VERSION}/${stage}/assets/${assetId}/pledges`,
      params
    )
  },

  /**
   * Unpledges an asset by its ID.
   * @param {string} stage - The stage of the asset unpledge process.
   * @param {string} assetId - The ID of the asset to be unpledged.
   * @returns {Promise<Object>} - A promise that resolves with the result of the unpledge operation.
   * @APICode {stage}.assets.unpledges
   */
  updateAssetUnpledge(stage, assetId, params) {
    return http.patch(
      `${API_VERSION}/${stage}/assets/${assetId}/unpledges`,
      params
    )
  },

  /**
   * Sends an unpledge notice via email.
   *
   * @param {number} assetId - The ID of the asset.
   * @param {UnpledgeNoticeRequest} unpledgeNoticeRequest - The request payload for unpledge notice.
   * @returns {Promise<void>} - A promise that resolves when the email is sent.
   * @typedef {Object} UnpledgeNoticeRequest
   * @property {string[]} to - Array of email addresses to send the notice to.
   * @property {string[]} cc - Array of email addresses to be included as CC.
   * @property {string} [content] - The content of the email. Optional.
   * @APICode service.assets.unpledge-notices.create
   */
  createUnpledgeNotice(stage, assetId, unpledgeNoticeRequest) {
    return http.post(
      `${API_VERSION}/${stage}/assets/${assetId}/unpledge-notices`,
      unpledgeNoticeRequest
    )
  },

  /**
   * Generates a preview of an unpledge notice for a specific asset.
   *
   * @param {number} assetId - The ID of the asset.
   * @returns {Promise<UnpledgeNoticePreview>} A promise that resolves with the unpledge notice preview.
   *
   * @typedef {Object} UnpledgeNoticePreview
   * @property {string} subject - The subject of the unpledge notice.
   * @property {string} content - The content of the unpledge notice.
   * @property {string} source - The source of the unpledge notice.
   *
   * @APICode service.assets.unpledge-notices.previews.get
   */
  getUnpledgeNoticePreview(stage, assetId) {
    return http.get(
      `${API_VERSION}/${stage}/assets/${assetId}/unpledge-notices/previews`
    )
  },

  updateAsset(stage, assetId, params) {
    // permission code: {stage}.assets.update
    return http.put(`${API_VERSION}/${stage}/assets/${assetId}`, params)
  },

  deleteAsset(stage, assetId) {
    // permission code: {stage}.assets.delete
    return http.delete(`${API_VERSION}/${stage}/assets/${assetId}`)
  },

  updateAssetHoldings(stage, assetId, params) {
    // permission code: {stage}.asset.holdings.update
    return http.put(
      `${API_VERSION}/${stage}/assets/${assetId}/holdings`,
      params
    )
  },

  getAssetHistories(stage, assetId, params) {
    // permission code: {stage}.assets.histories.list
    return http.get(
      `${API_VERSION}/${stage}/assets/${assetId}/histories`,
      params
    )
  },

  /**
 * @typedef {Object} AssetDetail
 * @property {string} assetId - The unique identifier of the asset.
 * @property {string} assetNumber - The number of the asset.
 * @property {string} assetName - The name of the asset.
 * @property {string} assetType - The type of the asset.
 * @property {string} assetClassification - The classification of the asset.
 * @property {string} externalAssetId - The external identifier of the asset.
 * @property {string} custodianInstitutionId - The custodian institution ID.
 * @property {string} custodianCode - The custodian code.
 * @property {string} siInstitutionId - The SI institution ID.
 * @property {string} asOfDate - The date the information is as of.
 * @property {string} collateralBaseCurrencyCode - The base currency code for the collateral.
 * @property {number} marketValue - The market value of the asset.
 * @property {number} lendingValue - The lending value of the asset.
 * @property {number} advanceRate - The advance rate of the asset.
 * @property {number} maintenanceRate - The maintenance rate of the asset.
 * @property {number} concentrationLimitAmount - The concentration limit amount.
 * @property {number} concentrationLimitRate - The concentration limit rate.
 * @property {number} requiredEquity - The required equity for the asset.
 * @property {number} maintenanceValue - The maintenance value of the asset.
 * @property {string} pledgingStatus - The pledging status of the asset.
 * @property {string} pledgingErrorDetail - Details of any pledging error.
 * @property {string} pledgedDate - The date the asset was pledged.
 * @property {string} eligibleStatus - The eligibility status of the asset.
 * @property {string} priceTier - The price tier of the asset.
 * @property {boolean} isPrimaryInvestmentAccount - Whether it's a primary investment account.
 * @property {boolean} isMarginInvestmentAccount - Whether it's a margin investment account.
 * @property {number} marginBalance - The margin balance of the investment account.
 * @property {string} investmentAccountTypeCode - The investment account type code.
 * @property {string} investmentAccountType - The investment account type.
 * @property {string} calculatedAt - The timestamp when calculations were made.

 * @typedef {Object} CollateralAssetDetailsResponse
 * @property {AssetDetail[]} assets - An array of asset details.

 * @typedef {Object} GetCollateralAssetDetailsParams
 * @property {number} [proposalId] - The ID of the proposal.
 * @property {number} [applicationId] - The ID of the application.
 * @property {number} [collateralId] - The ID of the collateral.
 * @property {string} stage - The stage of the party to add.

  /**
   * Get Collateral Asset details.
   * @param {GetCollateralAssetDetailsParams} params - The parameters for the request.
   * @returns {Promise<CollateralAssetDetailsResponse>} - A promise that resolves with the collateral asset details.
   * @APICode {stage}.collateral-packages.assets.get
   */
  getCollateralAssets(stage, queryParams, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/collateral-packages/assets`,
        queryParams
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/collateral-packages/assets`,
      queryParams
    )
  },

  /**
   * Object assets based on the provided parameters.
   * @param {Object} params - Parameters for listing assets.
   * @param {String} params.distributionChannelInstitutionId
   * @param {String} params.locProductId
   * @param {Object} params.collateral
   * @returns {Promise<AssetsDetail>} - A promise that resolves with the assets information.
   *
   * @APICode api.assets.calculate
   */
  calculateAssets(params) {
    return http.post(`${API_VERSION}/assets/calculate`, params)
  },

  /**
   * Fetches the details of collateral assets based on the provided stage and optional parameters.
   *
   * @param {string} stage - The stage of the party to add. Possible values: ESTIMATE, PROPOSAL, APPLICATION, UNDERWRITING, SERVICE, AMENDMENT.
   * @param {Object} params - The optional parameters to filter the collateral assets.
   * @param {number} [params.proposalId] - The ID of the proposal.
   * @param {number} [params.applicationId] - The ID of the application.
   * @param {number} [params.collateralId] - The ID of the collateral.
   * @returns {Promise<Object>} The promise object that resolves to the collateral asset details.
   * @auth required: YES
   * @permissions required: {stage}.collateral-packages.assets.get
   */
  getCollateralPackageAssets(stage, params, archiveAmendmentId) {
    if (stage === APIStageEnum.amendment && archiveAmendmentId) {
      return http.get(
        `${API_VERSION}/${stage}/archives/${archiveAmendmentId}/collateral-packages/assets`,
        params
      )
    }
    return http.get(
      `${API_VERSION}/${stage}/collateral-packages/assets`,
      params
    )
  },
  /**
   * List assets based on the provided parameters.
   * @param {Object} params - Parameters for listing assets.
   * @param {Number} params.pageNumber - The page number to retrieve.
   * @param {Number} params.pageSize - The size of the page to retrieve.
   * @param {String} [params.search] - The search query.
   * @param {String} [params.sort] - The sorting criteria.
   * @param {AssetFilters} [params.filters] - The filters to apply.
   * @returns {Promise<AssetPageInfo>} - A promise that resolves with the asset page information.
   *
   * @APICode {stage}.assets.list
   */
  listAssets(
    stage,
    { pageNumber, pageSize, search = '', sort = '', filters = {} }
  ) {
    return http.get(`${API_VERSION}/${stage}/assets`, {
      pageNumber,
      pageSize,
      search,
      sort,
      ...filters,
    })
  },

  listAssetLetters(stage, params) {
    return http.get(`${API_VERSION}/${stage}/assets/letters`, params)
  },

  revokeAsset(stage, assetId) {
    return http.post(`${API_VERSION}/${stage}/assets/${assetId}/revokes`)
  },

  /**
   * Perform what-if calculations on given collaterals.
   * @param {number} collateralId - The ID of the collateral to calculate.
   * @param {WhatIfCalculationReqDTO} calculationData - The data required for the what-if calculation.
   * @returns {Promise<WhatIfCalculationRespDTO>} - A promise that resolves with the what-if calculation results.
   * @APICode service.collaterals.what-if.calculations.create
   */
  whatIfCalculations(calculationData) {
    return http.post(
      `${API_VERSION}/service/collaterals/calculations/estimates`,
      calculationData
    )
  },

  /**
   * Retrieves a list of related mails for a specific asset based on the provided asset ID.
   *
   * @param {string} universe - The universe identifier.
   * @param {number} assetId - The ID of the asset.
   * @param {number} emailInfoId - The ID of the email info.
   * @returns {Promise<Object>} The list of related mails for the specified asset.
   * @apiCode service.assets.mails.get
   */
  getAssetRelatedMails(assetId, emailInfoId, totalLoanFacilityId) {
    return http.get(
      `${API_VERSION}/service/assets/${assetId}/mails/${emailInfoId}?totalLoanFacilityId=${totalLoanFacilityId}`
    )
  },

  updatePrimaryAccountForAsset(stage, params) {
    return http.put(`${API_VERSION}/${stage}/assets/primary-account`, params)
  },

  getAssetUnpledgeValidations(stage, assetId) {
    return http.post(`${API_VERSION}/${stage}/assets/${assetId}/unpledge-validations`)
  }
}
